import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import { dataIdFromObject } from '@dietlabs/components/src/Apollo/Apollo';
import { DateType } from 'types/CommonTypes';
import ShoppingListMarkProductAsBoughtMutation from './ShoppingListMarkProductAsBoughtMutation';
import ShoppingListProductFragment from './ShoppingListProductFragment';
import optimisticResponse from './ShoppingListMarkProductAsBoughtOptimisticResponse';

class ShoppingListMarkProductAsBoughtConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        period: PropTypes.shape({
            start: DateType.isRequired,
            end: DateType.isRequired,
        }),
    };

    static defaultProps = {
        period: undefined,
    };

    markProductAsBought = mutate => async ({ key, productId, __typename }) => {
        if (!this.props.period) {
            throw new Error(
                'Tried to mark product as bought with undefined period'
            );
        }

        const { start, end } = this.props.period;

        const variables = {
            request: {
                productId,
                datePeriod: { start, end },
            },
        };

        const response = await mutate({
            variables,
            optimisticResponse,

            update: (cache, { data }) => {
                const { isBought } = data.me.shoppingListMarkProductAsBought;

                const newProductData = {
                    __typename,
                    key,
                    isBought,
                };
                cache.writeFragment({
                    fragment: ShoppingListProductFragment,
                    id: dataIdFromObject(newProductData),
                    data: newProductData,
                });
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={ShoppingListMarkProductAsBoughtMutation}>
                {mutate =>
                    children({
                        markProductAsBought: this.markProductAsBought(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default ShoppingListMarkProductAsBoughtConnectorApollo;
