import UserSettings from 'UserSettings/UserSettings';

const PATH_SETTINGS = '/settings';

const routes = [
    {
        path: PATH_SETTINGS,
        component: UserSettings,
        private: true,
        exact: true,
        title: 'settings/title',
        mobileAppTabIdentifier: 'more',
    },
];

export { PATH_SETTINGS, routes };
