import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import { withApollo } from 'react-apollo';
import InterventionConcludeMutation from './InterventionConcludeMutation';
import InterventionFetchQuery from '../Fetch/InterventionFetchQuery';
import InterventionsListFetchQuery from '../FetchList/InterventionsListFetchQuery';

class InterventionConcludeConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        period: PropTypes.shape().isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    createConclude = mutate => async (date, answers) => {
        const variables = {
            date,
            answers,
        };

        const response = await mutate({
            variables,
        });

        return response;
    };

    refetchQueries = async () => {
        await this.props.client.query({
            query: InterventionFetchQuery,
            variables: { period: this.props.period },
            fetchPolicy: 'no-cache',
        });

        await this.props.client.query({
            query: InterventionsListFetchQuery,
            variables: { period: this.props.period },
            fetchPolicy: 'no-cache',
        });
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation
                mutation={InterventionConcludeMutation}
                onCompleted={this.refetchQueries}
            >
                {mutate =>
                    children({
                        conclude: this.createConclude(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default withApollo(InterventionConcludeConnectorApollo);
