import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

class SettingsSubscriptionsPlaceholder extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;
        return (
            <Container className="mt-3">
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <h2>{t('settings/subscriptions/heading')}</h2>
                            <p className="placeholder">&nbsp;</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { SettingsSubscriptionsPlaceholder };
export default withLocale(SettingsSubscriptionsPlaceholder);
