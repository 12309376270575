import gql from 'graphql-tag';

const mutation = gql`
    mutation PaymentMake(
        $token: String!
        $methodId: Int
        $channelId: String
        $successUrl: String
        $cancelUrl: String
    ) {
        sale {
            makePayment(
                token: $token
                methodId: $methodId
                channelId: $channelId
                successUrl: $successUrl
                cancelUrl: $cancelUrl
            ) {
                __typename
                ... on Payment {
                    id
                    token
                    url
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;

export default mutation;
