import gql from 'graphql-tag';

const query = gql`
    query InterventionsList($period: DatePeriodInputType!) {
        me {
            intervention(period: $period) {
                __typename
                ... on UserInterventionVisible {
                    key
                    type
                    date
                    revealed
                    concluded
                }
                ... on UserInterventionHidden {
                    key
                    type
                    date
                    message
                }
            }
        }
    }
`;

export default query;
