import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';
import Mutation from './PaymentCloneMutation';

const paymentClone = token =>
    GraphQLFetch.runMutation(Mutation, { token }).then(res => {
        const clonedPayment = {};
        try {
            clonedPayment.token = res.data.sale.clonePayment.token;
        } catch (exception) {
            clonedPayment.token = null;
        }

        return clonedPayment;
    });

export default paymentClone;
