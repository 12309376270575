import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { PATHS } from 'config/paths';
import withOnCriticalError from 'view/ErrorBoundary/withOnCriticalError';

export class ShoppingCartBankTransferComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            paymentExists: null,
            payment: null,
        };
    }

    async componentDidMount() {
        try {
            const payment = await this.props.loadPayment(
                this.props.match.params.token
            );
            this.setState({
                initialized: true,
                paymentExists: payment.exists,
                payment,
            });
        } catch (error) {
            this.props.onCriticalError(error);
        }
    }

    render() {
        let result = '';
        if (this.state.initialized) {
            if (this.state.paymentExists === false) {
                window.location = PATHS.EXTERNAL.HOME;
                result = null;
            } else {
                result = this.renderView();
            }
        } else {
            result = this.constructor.renderPlaceholder();
        }

        return result;
    }

    renderView() {
        return (
            <section>
                {this.state.payment.token}
                <Container>
                    <Link to={PATHS.CART.INDEX}>Back to cart</Link>
                </Container>
            </section>
        );
    }

    static renderPlaceholder() {
        return <div data-test="placeholder">placeholder</div>;
    }
}

ShoppingCartBankTransferComponent.propTypes = {
    loadPayment: PropTypes.func.isRequired,
    onCriticalError: PropTypes.func.isRequired,
};

export default withOnCriticalError(
    withRouter(ShoppingCartBankTransferComponent)
);
