import React from 'react';
import { Container, Row, Col, Progress } from 'reactstrap';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

class DietSettings extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        progress: PropTypes.number.isRequired,
        renderAction: PropTypes.func.isRequired,
    };

    render() {
        const { t, children, progress, renderAction } = this.props;

        return (
            <Layout page="diet-settings">
                <div className="full-screen">
                    <header>
                        {progress > 0 ? (
                            <Container>
                                <h1 className="text-center">
                                    {t('diet-settings/title')}
                                </h1>
                                <Progress value={progress} />
                            </Container>
                        ) : (
                            ''
                        )}
                    </header>

                    <div className="action-buttons">
                        <Container>{renderAction()}</Container>
                    </div>

                    {/* <Form onSubmit={event => this.handleNext(event)} noValidate> */}
                    <Container>
                        <Row>
                            <Col
                                xs={12}
                                md={{ size: 8, offset: 2 }}
                                lg={{ size: 6, offset: 3 }}
                            >
                                {children()}
                            </Col>
                        </Row>
                    </Container>

                    <div className="action-buttons2">
                        <Container>
                            <Row>
                                <Col
                                    xs={12}
                                    md={{ size: 8, offset: 2 }}
                                    lg={{ size: 6, offset: 3 }}
                                >
                                    {renderAction()}
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    {/* </Form> */}
                    <div className="diet-settings-bottom-push" />
                </div>
            </Layout>
        );
    }
}

export default withLocale(DietSettings);
