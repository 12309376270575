import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import { withLocale } from '@dietlabs/components';
import PageContainer from 'Layout/Container/PageContainer';
import Header from 'Layout/Header';
import CachedDataMessage from '@dietlabs/components/src/CachedDataMessage/CachedDataMessage';
import HistoryChartPeriodSelect from 'Layout/HistoryChartPeriodSelect';

import Chart from './HydrationHistoryChart';
import Actions from './HydrationHistoryActions';

class HydrationHistoryContainer extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
        dateFrom: PropTypes.instanceOf(Date).isRequired,
        dateTo: PropTypes.instanceOf(Date).isRequired,
        unit: PropTypes.string,
        defaultDailyGoal: PropTypes.number,
        days: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.instanceOf(Date).isRequired,
                valueValue: PropTypes.number.isRequired,
                goalValue: PropTypes.number.isRequired,
            })
        ),
        historyLength: PropTypes.number.isRequired,
        setHistoryLength: PropTypes.func.isRequired,
    };

    static defaultProps = {
        unit: null,
        defaultDailyGoal: null,
        days: [],
    };

    handleHistoryLengthChange = event => {
        const value = parseInt(event.target.value, 10);
        this.props.setHistoryLength(value);
    };

    render() {
        return (
            <Fragment>
                <Header>{this.props.t('route-title/hydration-history')}</Header>
                <PageContainer>
                    <CachedDataMessage
                        cacheHit={this.props.cacheHit}
                        hasNetworkError={this.props.hasNetworkError}
                    />
                    <Row className="mt-2">
                        <Col md={{ offset: 3, size: 6 }}>
                            <HistoryChartPeriodSelect
                                value={this.props.historyLength}
                                onChange={this.handleHistoryLengthChange}
                            />
                        </Col>
                    </Row>
                    <Chart
                        loading={this.props.loading}
                        dateFrom={this.props.dateFrom}
                        dateTo={this.props.dateTo}
                        unit={this.props.unit}
                        days={this.props.days}
                        defaultDailyGoal={this.props.defaultDailyGoal}
                    />
                    <nav className="mt-3">
                        <Actions />
                    </nav>
                </PageContainer>
            </Fragment>
        );
    }
}

export { HydrationHistoryContainer };
export default withLocale(HydrationHistoryContainer);
