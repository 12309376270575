import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import DietSettingsQuery from './DietSettingsQuery';

class DietSettingsConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Query query={DietSettingsQuery} fetchPolicy="no-cache">
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.dietSettings = deepClone(data.me);
                    }
                    return children(result);
                }}
            </Query>
        );
    }
}

export default DietSettingsConnectorApollo;
