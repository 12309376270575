import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { PATH_MOTIVATION_TEST } from 'MotivationTest/paths';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import InterventionIndex from './InterventionsIndex';
import InterventionsPlaceholder from './InterventionsPlaceholder';
import { PATH_INTERVENTIONS_INTRO } from './paths';

class InterventionsIndexContainer extends React.Component {
    static propTypes = {
        interventionsList: PropTypes.arrayOf(
            PropTypes.shape({
                __typename: PropTypes.string,
                date: PropTypes.string,
                type: PropTypes.string,
                message: PropTypes.string, // if "UserInterventionHidden"
                revealed: PropTypes.bool, // if "UserInterventionVisible"
            })
        ).isRequired,
    };

    render() {
        const type = this.props.interventionsList[0].__typename;

        if (type === 'UserInterventionIntroduction') {
            callNativeAppRemoveLastRoute('motivation');
            return <Redirect to={PATH_INTERVENTIONS_INTRO} />;
        }

        if (type === 'UserInterventionTestRequiredException') {
            callNativeAppRemoveLastRoute('motivation');
            return <Redirect to={PATH_MOTIVATION_TEST} />;
        }

        return (
            <InterventionIndex
                interventionsList={this.props.interventionsList}
            />
        );
    }
}

export default withLoading(
    InterventionsIndexContainer,
    InterventionsPlaceholder
);
