import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import UserDataQuery from './UserDataQuery';

class UserDataConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Query query={UserDataQuery}>
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loadingUserData: loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.userData = deepClone(data.me);
                        if (data.me) {
                            global.localStorage.setItem(
                                'userId',
                                JSON.stringify(data.me.id)
                            );
                        }
                    }
                    return children(result);
                }}
            </Query>
        );
    }
}

export default UserDataConnectorApollo;
