import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { formatIsoDate } from 'utils/dateFormatter';
import DayPlanDayReaplacementsQuery from './DayPlanDayReplacementsQuery';

class DayPlanDayReplacementsConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    fetch = async (search = '') => {
        const result = await this.props.client.query({
            query: DayPlanDayReaplacementsQuery,
            variables: {
                date: formatIsoDate(this.props.date),
                text: search,
            },
            fetchPolicy: 'network-only',
        });

        return result;
    };

    render() {
        const { children } = this.props;

        return children({
            loadDayPlanDayReplacements: this.fetch,
        });
    }
}

export default withApollo(DayPlanDayReplacementsConnectorApollo);
