import React, { Component } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

class DayPlanPrintComponent extends Component {
    render() {
        return (
            <table className="print-table">
                <thead>
                    <tr>
                        <td>
                            <Container>
                                <p className="small text-center">
                                    {this.props.t('print-header')}
                                </p>
                                <hr />
                            </Container>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{this.props.children()}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <Container>
                                <hr />
                                <p className="small text-center">
                                    {this.props.t('print-copyright1', {
                                        email: this.props.userEmail,
                                    })}
                                    <br />
                                    {this.props.t('print-copyright2')}
                                </p>
                            </Container>
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }
}

DayPlanPrintComponent.propTypes = {
    userEmail: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
};

export default withLocale(DayPlanPrintComponent);
