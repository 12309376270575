import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { SHOPPING_CART_CLEAR } from 'view/ShoppingCart/Clear/ShoppingCartClearActionType';
import Mutation from './ShoppingCartPaymentMakeMutation';
import {
    SHOPPING_CART_PAYMENT_MAKE_FAILED_RECEIVED_RESPONSE,
    SHOPPING_CART_PAYMENT_MAKE_VALIDATION_EXCEPTION,
    SHOPPING_CART_PAYMENT_MAKE_SUCCESS,
} from './ShoppingCartPaymentMakeActionType';

export const shoppingCartPaymentMakeAction = (
    token,
    methodId,
    channelId,
    successUrl,
    cancelUrl
) => dispatch =>
    GraphQLFetch.runMutation(Mutation, {
        token,
        methodId,
        channelId,
        successUrl,
        cancelUrl,
    })
        .then(res => {
            if (
                res &&
                res.data &&
                res.data.sale &&
                res.data.sale.makePayment &&
                res.data.sale.makePayment.__typename
            ) {
                let result = null;
                const payment = res.data.sale.makePayment;

                switch (res.data.sale.makePayment.__typename) {
                    case 'Payment':
                        result = {
                            type: SHOPPING_CART_PAYMENT_MAKE_SUCCESS,
                            url: payment.url,
                        };
                        dispatch({ type: SHOPPING_CART_CLEAR });
                        break;
                    case 'ValidationException':
                        result = {
                            type: SHOPPING_CART_PAYMENT_MAKE_VALIDATION_EXCEPTION,
                            error: ValidationErrors.createFromObject(
                                res.data.sale.makePayment
                            ),
                        };
                        break;
                }

                return result;
            }

            return dispatch({
                type: SHOPPING_CART_PAYMENT_MAKE_FAILED_RECEIVED_RESPONSE,
            });
        })
        .catch(() => {
            dispatch({
                type: SHOPPING_CART_PAYMENT_MAKE_FAILED_RECEIVED_RESPONSE,
            });
        });
