import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

import ShoppingListConnectorApollo from 'ShoppingList/Index/Connector/Apollo/ShoppingListConnectorApollo';
import ShoppingListMarkProductAsBoughtConnectorApollo from 'ShoppingList/Index/Connector/Apollo/ShoppingListMarkProductAsBoughtConnectorApollo';
import ShoppingListMarkProductAsNotBoughtConnectorApollo from 'ShoppingList/Index/Connector/Apollo/ShoppingListMarkProductAsNotBoughtConnectorApollo';
import ShoppingListIndexContainer from 'ShoppingList/Index/ShoppingListIndexContainer';

import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import PageContainer from 'Layout/Container/PageContainer';

class ShoppingListPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    state = {
        period: undefined,
    };

    handlePeriodChange = period => this.setState({ period });

    render() {
        const { t } = this.props;

        return (
            <Layout page="shopping-list">
                <div className="bg-white">
                    <Header>{t('shopping-list/title')}</Header>
                    <PageContainer>
                        <ShoppingListConnectorApollo period={this.state.period}>
                            {shoppingListData => (
                                <ShoppingListMarkProductAsBoughtConnectorApollo
                                    {...shoppingListData.shoppingList}
                                >
                                    {({ markProductAsBought }) => (
                                        <ShoppingListMarkProductAsNotBoughtConnectorApollo
                                            {...shoppingListData.shoppingList}
                                        >
                                            {({ markProductAsNotBought }) => (
                                                <ShoppingListIndexContainer
                                                    {...{
                                                        markProductAsBought,
                                                        markProductAsNotBought,
                                                    }}
                                                    {...shoppingListData}
                                                    handlePeriodChange={
                                                        this.handlePeriodChange
                                                    }
                                                />
                                            )}
                                        </ShoppingListMarkProductAsNotBoughtConnectorApollo>
                                    )}
                                </ShoppingListMarkProductAsBoughtConnectorApollo>
                            )}
                        </ShoppingListConnectorApollo>
                    </PageContainer>
                </div>
            </Layout>
        );
    }
}

export { ShoppingListPage };
export default withLocale(ShoppingListPage);
