import { connect } from 'react-redux';
import { shoppingCartPaymentMakeAction } from 'view/ShoppingCart/Confirm/Payment/Make/ShoppingCartPaymentMakeAction';
import ShoppingCartConfirmPaymentCodComponent from 'components/ShoppingCart/Confirm/Payment/ShoppingCartConfirmPaymentCodComponent';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    make: token => dispatch(shoppingCartPaymentMakeAction(token)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartConfirmPaymentCodComponent);
