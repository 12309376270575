import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import { createDateObject, formatIsoDate, addDays } from 'utils/dateFormatter';
import deepClone from 'utils/deepClone';

import DayPlanQuery from './DayPlanQuery';
import * as types from '../../../../DayPlanTypes';

const TRIMMED_DAYS = 180;

class DayPlanConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        forceRefreshData: PropTypes.bool.isRequired,
    };

    static trimDates(availableDays) {
        const trimmed = availableDays.slice(-TRIMMED_DAYS);
        return trimmed;
    }

    render() {
        const { children, date, forceRefreshData } = this.props;

        return (
            <Query
                query={DayPlanQuery}
                variables={{
                    date: formatIsoDate(date),
                    canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
                }}
                fetchPolicy={
                    forceRefreshData ? 'network-only' : 'cache-and-network'
                }
            >
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.dayPlan = deepClone(data.me.dayPlan);
                        result.availableDays = this.constructor.trimDates(
                            data.me.diet.availableDays
                        );
                        global.localStorage.setItem(
                            'availableDays',
                            JSON.stringify(result.availableDays)
                        );
                        result.categories = data.me.diet.set.categories;
                        result.hasActiveSubscription =
                            undefined !==
                            data.me.activeSubscriptions.find(
                                sub => sub.givesAccessToDiet
                            );

                        if (result.dayPlan.date) {
                            result.dayPlan.date = createDateObject(
                                result.dayPlan.date
                            );
                        }
                        result.accessTo = data.me.diet.accessTo
                            ? createDateObject(data.me.diet.accessTo)
                            : undefined;
                        result.dietAvailabilityDate =
                            data.me.dayPlan.__typename ===
                                types.DIET_IN_FUTURE && data.me.dayPlan.date
                                ? createDateObject(data.me.dayPlan.date)
                                : undefined;
                        result.goal = data.me.goal;
                        result.isHolidayMenu = Boolean(
                            data.me.diet.set.holiday
                        );
                        result.isTimeToNagForCurrentMeasurement =
                            data.me.isTimeToNagForCurrentMeasurement;
                        result.alreadyCopied =
                            data.me.diet.nextDaySet.dietSetId ===
                            data.me.diet.set.dietSetId;
                        result.fastingStatistics = data.me.fastingStatistics;
                        result.setId = data.me.diet.set.userDietSetId;
                        result.mealsEaten = data.me.diet.mealsEaten;
                        result.dietId =
                            data.me.dayPlan.__typename === types.DAY_PLAN
                                ? data.me.dayPlan.diet.dietId
                                : undefined;
                        result.isVegetarian =
                            data.me.dayPlan.__typename === types.DAY_PLAN
                                ? data.me.dayPlan.diet.isVegetarian
                                : undefined;
                        result.isPescoVegetarian =
                            data.me.dayPlan.__typename === types.DAY_PLAN
                                ? data.me.dayPlan.diet.isPescoVegetarian
                                : undefined;
                    }
                    return children(result);
                }}
            </Query>
        );
    }
}

export default DayPlanConnectorApollo;
