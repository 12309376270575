import gql from 'graphql-tag';

export default gql`
    mutation MeShoppingListMarkProductAsNotBoughtMutation(
        $request: UserShoppingListMarkProductInputType
    ) {
        me {
            shoppingListMarkProductAsNotBought(request: $request) {
                ... on UserShoppingListMarkProductAsNotBoughtSuccess {
                    code
                    isBought
                }
            }
        }
    }
`;
