import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Row, Col } from 'reactstrap';
import InterventionTaskTextComponent from './InterventionTaskTextComponent';
import InterventionTaskStringComponent from './InterventionTaskStringComponent';
import InterventionTaskCheckboxesComponent from './InterventionTaskCheckboxesComponent';
import InterventionTaskScaleComponent from './InterventionTaskScaleComponent';

class InterventionTaskGroupComponent extends React.Component {
    static propTypes = {
        tasks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        editMode: PropTypes.bool.isRequired,
        variant: PropTypes.string,
    };

    static defaultProps = {
        variant: null,
    };

    renderTask = task => {
        switch (task.type) {
            case 'text':
                return (
                    <InterventionTaskTextComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                    />
                );
            case 'string':
                return (
                    <InterventionTaskStringComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                    />
                );
            case 'checkboxes':
                return (
                    <InterventionTaskCheckboxesComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                    />
                );
            case 'scale':
                return (
                    <InterventionTaskScaleComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <Row>
                {this.props.tasks.map(task => (
                    <Col
                        xs={this.props.variant === 'healthCircle' ? 12 : ''}
                        key={task.id}
                    >
                        {this.renderTask(task)}
                    </Col>
                ))}
            </Row>
        );
    }
}

export default withLocale(InterventionTaskGroupComponent);
