import ShoppingCartDelivery from './ShoppingCartDelivery';

export default class ShoppingCartDeliveryFactory {
    static createFromState(state) {
        return new ShoppingCartDelivery(
            state.shoppingCart.delivery.name,
            state.shoppingCart.delivery.address,
            state.shoppingCart.delivery.postalCode,
            state.shoppingCart.delivery.city,
            state.shoppingCart.delivery.country,
            state.shoppingCart.delivery.phone
        );
    }
}
