import gql from 'graphql-tag';

const query = gql`
    query Settings {
        me {
            id
            email
            systemOfMeasures
            hasRegisteredDevices
            passwordIsSet
            hasExternalProviderSetUp
            isAbleToLoginWithFacebook
            isAbleToLoginWithGoogle
            isAbleToLoginWithApple
            agreements {
                dietCourse
                articles
                newsletter
            }
            diet {
                accessTo
                lifetimeAccess
            }
            videoWorkout {
                accessTo
                lifetimeAccess
            }
            activeSubscriptions {
                givesAccessToDiet
                givesAccessToVideoWorkouts
                renewalDate
                operator
            }
            hydrationSettings {
                key
                defaultDailyGoal {
                    value
                    unit
                }
                notifications {
                    key
                    enabled
                    hourInterval
                    fromHour
                    toHour
                    useSound
                }
            }
        }
    }
`;

export default query;
