import gql from 'graphql-tag';

const query = gql`
    query MealReplacement($date: Date!, $request: UserDietSetMealInputType!) {
        me {
            id
            diet {
                set(date: $date) {
                    ... on UserDietSet {
                        dietSetId
                        meal(request: $request) {
                            original {
                                id
                            }
                            replacements {
                                id
                                dishes {
                                    id
                                    name
                                    ingredients {
                                        name
                                    }
                                }
                                preparationImageUrl
                                publicName
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default query;
