import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';
import Price from 'view/Price/Price';
import ShoppingCartDeliveryOption from 'view/ShoppingCart/Delivery/Option/ShoppingCartDeliveryOption';
import {
    SALE_PRODUCT_LOAD_SEND_REQUEST,
    SALE_PRODUCT_LOAD_SUCCESS_RECEIVE_RESPONSE,
    SALE_PRODUCT_LOAD_FAILED_RECEIVE_RESPONSE,
} from 'view/Sale/Product/Load/SaleProductLoadActionType';
import {
    SHOPPING_CART_DELIVERY_OPTION_LOAD_RECEIVE_RESPONSE,
    SHOPPING_CART_DELIVERY_OPTION_LOAD_FAILED,
} from 'view/ShoppingCart/Delivery/Option/ShoppingCartDeliveryOptionActionType';
import { SHOPPING_CART_SET_DELIVERY } from 'view/ShoppingCart/Delivery/ShoppingCartDeliveryActionType';
import ShoppingCartDelivery from 'view/ShoppingCart/Delivery/ShoppingCartDelivery';
import Country from 'view/Country/Country';
import { parseProducts } from 'view/Sale/Product/Load/SaleProductLoadAction';
import Query from './ShoppingCartIndexLoadQuery';

export const shoppingCartIndexLoadAction = (promoCode = null) => dispatch => {
    dispatch({ type: SALE_PRODUCT_LOAD_SEND_REQUEST });

    return GraphQLFetch.runQuery(Query, { promoCode }).then(res => {
        if (res.data) {
            if (res.data.sale && res.data.sale.productVariants) {
                const parsedProducts = parseProducts(
                    res.data.sale.productVariants
                );
                dispatch({
                    type: SALE_PRODUCT_LOAD_SUCCESS_RECEIVE_RESPONSE,
                    products: parsedProducts,
                });
            }

            if (res.data.sale && res.data.sale.shippingOptions) {
                let isValid = true;

                res.data.sale.shippingOptions.forEach(shippingOption => {
                    if (
                        shippingOption.id === undefined ||
                        shippingOption.name === undefined ||
                        shippingOption.requiresAddress === undefined ||
                        shippingOption.onlyVirtualProducts === undefined ||
                        shippingOption.price === undefined ||
                        shippingOption.price.amount === undefined ||
                        shippingOption.price.currency === undefined ||
                        shippingOption.countries === undefined
                    ) {
                        isValid = false;
                    } else {
                        shippingOption.countries.forEach(country => {
                            if (
                                country.code === undefined ||
                                country.name === undefined
                            ) {
                                isValid = false;
                            }
                        });
                    }
                });

                if (isValid) {
                    const shippingOptions = res.data.sale.shippingOptions.map(
                        shippingOption =>
                            new ShoppingCartDeliveryOption(
                                shippingOption.id,
                                shippingOption.name,
                                shippingOption.requiresAddress,
                                shippingOption.onlyVirtualProducts,
                                new Price(
                                    shippingOption.price.amount,
                                    shippingOption.price.currency
                                ),
                                shippingOption.countries.reduce(
                                    (previous, current) => {
                                        previous.push(
                                            new Country(
                                                current.code,
                                                current.name
                                            )
                                        );
                                        return previous;
                                    },
                                    []
                                ),
                                shippingOption.freeFromCartValue
                                    ? new Price(
                                          shippingOption.freeFromCartValue.amount,
                                          shippingOption.freeFromCartValue.currency
                                      )
                                    : null,
                                shippingOption.isCod
                            )
                    );

                    dispatch({
                        type: SHOPPING_CART_DELIVERY_OPTION_LOAD_RECEIVE_RESPONSE,
                        shippingOptions,
                    });
                } else {
                    dispatch({
                        type: SHOPPING_CART_DELIVERY_OPTION_LOAD_FAILED,
                    });
                }
            }

            if (res.data.me && res.data.me.shipping) {
                dispatch({
                    type: SHOPPING_CART_SET_DELIVERY,
                    delivery: new ShoppingCartDelivery(
                        res.data.me.shipping.name,
                        res.data.me.shipping.address,
                        res.data.me.shipping.postalCode,
                        res.data.me.shipping.city,
                        res.data.me.shipping.country
                            ? new Country(
                                  res.data.me.shipping.country.code,
                                  res.data.me.shipping.country.name
                              )
                            : new Country(),
                        res.data.me.shipping.phone
                    ),
                });
            }

            return res.data;
        }

        return dispatch({ type: SALE_PRODUCT_LOAD_FAILED_RECEIVE_RESPONSE });
    });
};
