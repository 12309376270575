import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';

import withLoading from 'Layout/withLoading';
import { withLocale } from '@dietlabs/components';
import Select from 'components/FormElements/InputSelectControlled';
import DAILY_GOAL_VALUES_BY_UNIT from 'Hydration/DAILY_GOAL_VALUES_BY_UNIT';

import HydrationSettingsPlaceholder from './HydrationSettingsPlaceholder';

class HydrationSettings extends Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func.isRequired,
        defaultDailyGoal: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        save: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: null,
    };

    state = {
        errors: null,
        defaultDailyGoalValue: this.props.defaultDailyGoal.value,
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.defaultDailyGoal.value !==
            this.props.defaultDailyGoal.value
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                defaultDailyGoalValue: this.props.defaultDailyGoal.value,
            });
        }
    }

    handleGoalChange = async event => {
        event.preventDefault();
        const value = parseInt(event.target.value, 10);
        const {
            defaultDailyGoal: { unit },
        } = this.props;

        this.setState({ defaultDailyGoalValue: value });

        try {
            await this.props.save({
                defaultDailyGoal: { value, unit },
            });
            this.setState({ errors: null });
        } catch (errors) {
            this.setState({ errors });
        }
    };

    renderDailyGoalOptions() {
        const { defaultDailyGoalValue: value } = this.state;
        const {
            defaultDailyGoal: { unit },
        } = this.props;

        const options = DAILY_GOAL_VALUES_BY_UNIT[unit];
        if (options === undefined) {
            throw new Error(`Unknown unit: '${unit}'`);
        }

        const elements = options.map(v => (
            <option key={v} value={v}>
                {v} {unit}
            </option>
        ));

        if (!options.includes(value)) {
            elements.unshift(
                <option key={value} value={value}>
                    {value} {unit}
                </option>
            );
        }

        return elements;
    }

    render() {
        const { defaultDailyGoalValue } = this.state;
        const {
            defaultDailyGoal: { unit: defaultDailyGoalUnit },
        } = this.props;
        const { t } = this.props;

        return (
            <Container className={this.props.className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <Form>
                                <FormGroup tag="fieldset">
                                    <h2>{t('settings/hydration')}</h2>

                                    <Select
                                        label={t(
                                            'settings/hydration/daily-goal'
                                        )}
                                        name="defaultDailyGoal.value"
                                        value={String(defaultDailyGoalValue)}
                                        unit={defaultDailyGoalUnit}
                                        handleChange={this.handleGoalChange}
                                        errors={this.state.errors}
                                    >
                                        {this.renderDailyGoalOptions()}
                                    </Select>
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { HydrationSettings };
export default withLoading(
    withLocale(HydrationSettings),
    HydrationSettingsPlaceholder
);
