import React from 'react';
import PropTypes from 'prop-types';

import deepClone from 'utils/deepClone';
import { createDateObject } from 'utils/dateFormatter';

import Query from '@dietlabs/components/src/Apollo/Query';
import Settings from './Settings';

class SettingsConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Query query={Settings} fetchPolicy="network-only">
                {loadResult => {
                    const result = {
                        loading: loadResult.loading,
                        systemOfMeasures: null,
                        hasRegisteredDevices: null,
                        agreements: {
                            dietCourse: false,
                            articles: false,
                            newsletter: false,
                        },
                        dietAccessTo: undefined,
                        dietLifetimeAccess: undefined,
                        videoWorkoutAccessTo: undefined,
                        videoWorkoutLifetimeAccess: undefined,
                        subscriptions: [],
                        passwordIsSet: undefined,
                        hasExternalProviderSetUp: undefined,
                        isAbleToLoginWithFacebook: false,
                        isAbleToLoginWithGoogle: false,
                        isAbleToLoginWithApple: false,
                        hydrationSettings: {},
                        hydrationGoal: {},
                        rawData: {},
                    };

                    if (!loadResult.loading) {
                        result.rawData = deepClone(loadResult.data);

                        result.systemOfMeasures =
                            loadResult.data.me.systemOfMeasures;
                        result.agreements = loadResult.data.me.agreements;

                        const {
                            email,
                            systemOfMeasures,
                            hasRegisteredDevices,
                            agreements,
                            diet,
                            videoWorkout: {
                                accessTo: videoWorkoutAccessTo,
                                lifetimeAccess: videoWorkoutLifetimeAccess,
                            },
                            activeSubscriptions: subscriptions,
                            passwordIsSet,
                            hydrationSettings,
                            hydrationGoal,
                        } = result.rawData.me;

                        Object.assign(result, {
                            email,
                            systemOfMeasures,
                            hasRegisteredDevices,
                            agreements,
                            dietAccessTo: createDateObject(diet.accessTo),
                            dietLifetimeAccess: diet.lifetimeAccess,
                            videoWorkoutAccessTo: createDateObject(
                                videoWorkoutAccessTo
                            ),
                            videoWorkoutLifetimeAccess,
                            subscriptions: subscriptions.map(sub => ({
                                givesAccessToDiet: sub.givesAccessToDiet,
                                givesAccessToVideoWorkouts:
                                    sub.givesAccessToVideoWorkouts,
                                renewalDate: createDateObject(sub.renewalDate),
                                operator: sub.operator,
                            })),
                            passwordIsSet,
                            hydrationSettings,
                            hydrationGoal,
                            hasExternalProviderSetUp:
                                loadResult.data.me.hasExternalProviderSetUp,
                            isAbleToLoginWithFacebook:
                                loadResult.data.me.isAbleToLoginWithFacebook,
                            isAbleToLoginWithGoogle:
                                loadResult.data.me.isAbleToLoginWithGoogle,
                            isAbleToLoginWithApple:
                                loadResult.data.me.isAbleToLoginWithApple,
                        });
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default SettingsConnectorApollo;
