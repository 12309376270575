import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepDietType extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietType: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietType: null,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.dietType);
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.dietType);
    }

    render() {
        const { dietType, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t('diet-settings/diet-type/label')}
                        </Label>
                        <InputRadio
                            label={[
                                <strong
                                    key="fast_and_mixed"
                                    className="d-block"
                                >
                                    {this.props.t(
                                        'diet-settings/diet-type/fast-and-mixed/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/fast-and-mixed/description'
                                ),
                            ]}
                            name="dietType"
                            value="FAST_AND_MIXED"
                            handleChange={handleInputChange}
                            checked={dietType === 'FAST_AND_MIXED'}
                        />

                        <InputRadio
                            label={[
                                <span className="pill">
                                    {this.props.t(
                                        'alert/holiday-notification-chef-title'
                                    )}
                                </span>,
                                <strong
                                    key="engaging_and_mixed"
                                    className="d-block"
                                >
                                    {this.props.t(
                                        'diet-settings/diet-type/engaging-and-mixed/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/engaging-and-mixed/description'
                                ),
                            ]}
                            name="dietType"
                            value="ENGAGING_AND_MIXED"
                            handleChange={handleInputChange}
                            checked={dietType === 'ENGAGING_AND_MIXED'}
                        />

                        <InputRadio
                            label={[
                                <strong
                                    key="fast_and_traditional"
                                    className="d-block"
                                >
                                    {this.props.t(
                                        'diet-settings/diet-type/fast-and-traditional/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/fast-and-traditional/description'
                                ),
                            ]}
                            name="dietType"
                            value="FAST_AND_TRADITIONAL"
                            handleChange={handleInputChange}
                            checked={dietType === 'FAST_AND_TRADITIONAL'}
                        />

                        <InputRadio
                            label={[
                                <strong
                                    key="engaging_and_traditional"
                                    className="d-block"
                                >
                                    {this.props.t(
                                        'diet-settings/diet-type/engaging-and-traditional/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/engaging-and-traditional/description'
                                ),
                            ]}
                            name="dietType"
                            value="ENGAGING_AND_TRADITIONAL"
                            handleChange={handleInputChange}
                            checked={dietType === 'ENGAGING_AND_TRADITIONAL'}
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepDietType));
