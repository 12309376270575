export default class ShoppingCartProductOrder {
    constructor(token, firstSource, products, promoCode) {
        if (token) {
            this.token = token;
        }

        products = products || [];
        this.products = products.reduce(
            (prev, current) =>
                prev.concat({
                    id: current.id,
                    amount: current.amount,
                    lastSource: current.lastSource,
                }),
            []
        );

        this.firstSource = firstSource;
        this.promoCode = promoCode;
    }
}
