import gql from 'graphql-tag';

export default gql`
    mutation DietModeChangeMutation(
        $dietMode: DietModeType!
        $goalWeight: UserMeasurementWeightInputType!
        $startDate: Date!
    ) {
        me {
            dietModeChange(
                dietMode: $dietMode
                goalWeight: $goalWeight
                startDate: $startDate
            ) {
                __typename
                ... on BasicMutationSuccess {
                    code
                }
                ... on UnauthorizedException {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;
