import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Col,
    Container,
    Row,
    Form,
    FormGroup,
    InputGroup,
    Label,
} from 'reactstrap';

import { withLocale } from '@dietlabs/components';

class HydrationSettingsPlaceholder extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        const { t, className } = this.props;

        return (
            <Container className={className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <Form>
                                <FormGroup tag="fieldset">
                                    <h2>{t('settings/hydration')}</h2>
                                    <Label>
                                        {t('settings/hydration/daily-goal')}
                                    </Label>
                                    <FormGroup>
                                        <InputGroup className="form-control seelct">
                                            <div className="input-placeholder placeholder">
                                                &nbsp;
                                            </div>
                                        </InputGroup>
                                    </FormGroup>
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { HydrationSettingsPlaceholder };
export default withLocale(HydrationSettingsPlaceholder);
