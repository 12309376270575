import React from 'react';

import PsychometricQuestionsConnectorApollo from './Connector/Apollo/PsychometricQuestions/PsychometricQuestionsConnectorApollo';
import PsychometricQuestionsOnDemandConnectorApollo from './Connector/Apollo/PsychometricQuestions/PsychometricQuestionsOnDemandConnectorApollo';
import PsychometricSendAnswersConnectorApollo from './Connector/Apollo/PsychometricSendAnswers/PsychometricSendAnswersConnectorApollo';
import PsychometricTestContainer from './PsychometricTestContainer';

class PsychometricTestPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <PsychometricQuestionsConnectorApollo>
                {({ ...questions }) => (
                    <PsychometricQuestionsOnDemandConnectorApollo>
                        {({ ...questionsOnDemand }) => (
                            <PsychometricSendAnswersConnectorApollo>
                                {({ ...sendAnswers }) => (
                                    <PsychometricTestContainer
                                        {...questions}
                                        {...sendAnswers}
                                        {...questionsOnDemand}
                                    />
                                )}
                            </PsychometricSendAnswersConnectorApollo>
                        )}
                    </PsychometricQuestionsOnDemandConnectorApollo>
                )}
            </PsychometricQuestionsConnectorApollo>
        );
    }
}

export default PsychometricTestPage;
