import React from 'react';
import { Alert, Button, Col, Container, Form, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InputPassword from '../../components/FormElements/InputPassword';
import Loader from '../../Layout/Loader';

class ChangePasswordForm extends React.Component {
    state = {
        currentPassword: '',
        newPassword: '',
        preload: false,
        success: false,
        errors: undefined,
    };

    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
        changePassword: PropTypes.func.isRequired,
        username: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: null,
    };

    changeValue(value, name) {
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ preload: true });

        this.props
            .changePassword(this.state.currentPassword, this.state.newPassword)
            .then(response => {
                if (response.data.me.changePassword.code === 200) {
                    this.setState({
                        currentPassword: '',
                        newPassword: '',
                        preload: false,
                        success: true,
                        errors: undefined,
                    });
                } else {
                    this.setState({
                        success: false,
                        errors: response.data.me.changePassword,
                        preload: false,
                    });
                }
            });
    }

    render() {
        return (
            <Container className={this.props.className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <Form
                                onSubmit={event => this.handleSubmit(event)}
                                data-test="change-password-form"
                            >
                                <h2>
                                    {this.props.t('settings/change-password')}
                                </h2>
                                {this.state.errors ? (
                                    <Alert
                                        color="danger"
                                        data-test="change-password-error"
                                    >
                                        {this.props.t('error/message/generic')}
                                    </Alert>
                                ) : (
                                    ''
                                )}

                                {this.state.success ? (
                                    <Alert
                                        color="success"
                                        data-test="change-password-success"
                                    >
                                        {this.props.t(
                                            'settings/change-password/success'
                                        )}
                                        <br />
                                    </Alert>
                                ) : (
                                    ''
                                )}

                                <input
                                    name="email"
                                    autoComplete="username"
                                    value={this.props.username}
                                    hidden
                                    readOnly
                                />

                                <Label>
                                    {this.props.t(
                                        'settings/change-password/current-password'
                                    )}
                                </Label>
                                <InputPassword
                                    name="currentPassword"
                                    autoComplete="current-password"
                                    required
                                    value={this.state.currentPassword}
                                    setValue={(value, name) =>
                                        this.changeValue(value, name)
                                    }
                                    errors={this.state.errors}
                                    data-test="password-input"
                                />
                                <Label>
                                    {this.props.t(
                                        'settings/change-password/new-password'
                                    )}
                                </Label>
                                <InputPassword
                                    name="newPassword"
                                    autoComplete="new-password"
                                    required
                                    value={this.state.newPassword}
                                    setValue={(value, name) =>
                                        this.changeValue(value, name)
                                    }
                                    errors={this.state.errors}
                                    data-test="new-password-input"
                                />
                                <div className="text-center">
                                    <Button
                                        color="primary"
                                        data-test="save-button"
                                    >
                                        {this.props.t(
                                            'settings/change-password'
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
                {this.state.preload ? <Loader /> : ''}
            </Container>
        );
    }
}

export default withLocale(ChangePasswordForm);
