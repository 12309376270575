import React, { Component } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
    Card,
    CardBody,
    Label,
    Collapse,
    Alert,
} from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import InputDate from 'components/FormElements/InputDateControlled';
import PropTypes from 'prop-types';
import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';

import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';

import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';

import { formatIsoDate, createDateObject } from 'utils/dateFormatter';
import { PATH_DAY_PLAN_PRINT } from '../DayPlanPaths';

class DayPlanPrintForm extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        availableDays: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.string.isRequired,
                holiday: PropTypes.shape({
                    identifier: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                }),
            })
        ).isRequired,
        t: PropTypes.func.isRequired,
        trackEvent: PropTypes.func.isRequired,
    };

    endDate = new Date(
        new Date(this.props.date.valueOf()).setDate(
            this.props.date.getDate() + 2
        )
    );

    state = {
        collapsePrint: false,
        start: formatIsoDate(this.props.date),
        end: formatIsoDate(this.endDate),
        errors: new ValidationErrors(),
        periodError: false,
    };

    validationRules = {
        start: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
        end: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
    };

    togglePrint() {
        this.setState(prevState => ({
            collapsePrint: !prevState.collapsePrint,
        }));
    }

    track() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const start = createDateObject(this.state.start);
        const end = createDateObject(this.state.end);

        const startDelta = Math.ceil((start - today) / 1000 / 60 / 60 / 24) - 1;
        const startLabel = startDelta > 0 ? `+${startDelta}` : startDelta;
        const length = Math.ceil((end - start) / 1000 / 60 / 60 / 24) + 1;

        this.props.trackEvent(
            EVENT_CATEGORY_DIET_PLAN,
            'print',
            `from today${startLabel || ''}, for ${length} days`
        );
        this.props.trackEvent(
            EVENT_CATEGORY_DIET_PLAN,
            'print:from',
            startLabel
        );
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'print:length', length);
    }

    handleSubmit = event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        const diff = this.dateDiffInDays(this.state.start, this.state.end);
        if (diff > 14) {
            this.setState({ periodError: true });
        }

        if (frontEndErrors.length === 0 && diff <= 14) {
            this.track();
            this.setState({ periodError: false });
            window.open(
                PATH_DAY_PLAN_PRINT.replace(
                    ':start([0-9]{4}-[0-9]{2}-[0-9]{2})',
                    this.state.start
                ).replace(':end([0-9]{4}-[0-9]{2}-[0-9]{2})', this.state.end),
                '_blank'
            );
        }
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    dateDiffInDays = (date1, date2) => {
        const dt1 = new Date(date1);
        const dt2 = new Date(date2);
        const diff = Math.floor(
            (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
                (1000 * 60 * 60 * 24)
        );
        return Math.abs(diff);
    };

    getMinDateString = () => this.props.availableDays[0].date;

    getMaxDateString = () =>
        this.props.availableDays[this.props.availableDays.length - 1].date;

    render() {
        return (
            <Row className="d-none d-lg-block">
                <Col>
                    <Collapse isOpen={this.state.collapsePrint}>
                        <Card>
                            <CardBody className="text-left">
                                {this.state.periodError ? (
                                    <Alert color="danger">
                                        {this.props.t(
                                            'meal/print-period-alert'
                                        )}
                                    </Alert>
                                ) : (
                                    ''
                                )}
                                <Label>
                                    {this.props.t('shopping-list/date-range')}{' '}
                                    <sup>*</sup>
                                </Label>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    noValidate
                                    data-test="print-form"
                                >
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <InputDate
                                                name="start"
                                                value={this.state.start}
                                                min={this.getMinDateString()}
                                                max={this.getMaxDateString()}
                                                errors={this.state.errors}
                                                handleChange={
                                                    this.handleInputChange
                                                }
                                                validationRules={
                                                    this.validationRules.start
                                                }
                                                data-test="start-input"
                                            />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <InputDate
                                                name="end"
                                                value={this.state.end}
                                                min={this.getMinDateString()}
                                                max={this.getMaxDateString()}
                                                errors={this.state.errors}
                                                handleChange={
                                                    this.handleInputChange
                                                }
                                                validationRules={
                                                    this.validationRules.end
                                                }
                                                data-test="end-input"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" className="text-right">
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() =>
                                                    this.togglePrint()
                                                }
                                            >
                                                {this.props.t('back')}
                                            </Button>
                                        </Col>
                                        <Col xs="6" className="text-left">
                                            <Button color="primary">
                                                {this.props.t('print')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Collapse>
                    {this.state.collapsePrint ? (
                        ''
                    ) : (
                        <Button
                            color="primary"
                            onClick={() => this.togglePrint()}
                            className="mt-3 no-arrow"
                            data-test="show-print-form"
                            style={{ width: '15rem' }}
                        >
                            {this.props.t('print')}
                        </Button>
                    )}
                </Col>
            </Row>
        );
    }
}

export { DayPlanPrintForm };
export default withGTM(withLocale(DayPlanPrintForm));
