import React from 'react';
import withLoading from 'Layout/withLoading';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Layout from 'Layout/Layout';
import { Container } from 'reactstrap';
import HpbaMarkdown from 'Content/utils/HpbaMarkdown';
import { APP_VERSION } from 'consts';
import ContentPlaceholder from './ContentPlaceholder';

class Content extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                content: PropTypes.string,
            }).isRequired,
        }).isRequired,
        content: PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.shape({
                html: PropTypes.string,
                markdown: PropTypes.string,
            }),
        }).isRequired,
    };

    render() {
        let pageTitle;

        if (this.props.match.params.content === 'how-to') {
            pageTitle = 'Dostęp do serwisu ✓ Dieta OXY';
        } else if (this.props.match.params.content === 'terms-of-service') {
            pageTitle = 'Regulamin ✓ Dieta dr OXY';
        } else if (this.props.match.params.content === 'privacy-policy') {
            pageTitle = 'Polityka prywatności ✓ Dieta OXY';
        } else {
            pageTitle = this.props.content.title;
        }

        return (
            <Layout page="cms-article">
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content="" />
                </Helmet>

                <Container>
                    <header>
                        <h1>{this.props.content.title}</h1>
                    </header>
                    <section className="pt-0">
                        <div className="cms-content">
                            <HpbaMarkdown
                                content={this.props.content.content.markdown}
                            />
                        </div>
                        {this.props.match.params.content === 'info' ? (
                            <p>React app version: {APP_VERSION}</p>
                        ) : (
                            ''
                        )}
                    </section>
                </Container>
            </Layout>
        );
    }
}

export default withLoading(withRouter(Content), ContentPlaceholder);
