import gql from 'graphql-tag';

const query = gql`
    query MotivationGetUserResult {
        me {
            motivationalTestResult {
                key
                coping
                motivation
                profile
                willpower
            }
        }
    }
`;

export default query;
