import DietSettingsIndexPage from './Index/DietSettingsIndexPage';

const PATH_DIET_SETTINGS_INDEX = '/diet-settings';

const routes = [
    {
        path: PATH_DIET_SETTINGS_INDEX,
        component: DietSettingsIndexPage,
        private: true,
        exact: true,
        title: 'route-title/diet-settings',
        mobileAppTabIdentifier: 'diet',
    },
];

export { routes };
