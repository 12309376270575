export default {
    me: {
        __typename: 'MeMutation',
        shoppingListMarkProductAsBought: {
            code: 200,
            isBought: true,
            __typename: 'UserShoppingListMarkProductAsBoughtSuccess',
        },
    },
};
