import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { formatIsoDate } from 'utils/dateFormatter';
import DayPlanMealReaplacementsQuery from './DayPlanMealReplacementsQuery';

class DayPlanMealReplacementsConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    fetch = async request => {
        const result = await this.props.client.query({
            query: DayPlanMealReaplacementsQuery,
            variables: {
                date: formatIsoDate(this.props.date),
                request,
            },
            fetchPolicy: 'network-only',
        });

        return result;
    };

    render() {
        const { children } = this.props;

        return children({
            loadDayPlanMealReplacements: request => this.fetch(request),
        });
    }
}

export default withApollo(DayPlanMealReplacementsConnectorApollo);
