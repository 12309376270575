import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import remindPassword from 'assets/images/thankyou/remind_password.png';
import { PATHS } from '../../../config/paths';

const ShoppingCartThankYouContentComponent = () => (
    <section className="py-5 bg-white">
        <Container>
            <Row>
                <Col md={{ size: 8, offset: 2 }}>
                    <>
                        <p className="text-center bold black">
                            Przed Tobą 3 proste kroki:
                        </p>
                        <ol>
                            <li>
                                Zaloguj się
                                <p>
                                    Użyj opcji{' '}
                                    <strong>Mam już konto, loguje się</strong>{' '}
                                    widocznej na dole ekranu <br /> startowego
                                    aplikacji.
                                </p>
                                <Row>
                                    <Col xs="12" md="6">
                                        <img
                                            src={remindPassword}
                                            alt="login"
                                            className="img-fluid"
                                        />
                                    </Col>
                                </Row>
                            </li>
                            <li>
                                Nie pamiętasz hasła?
                                <p>
                                    Żaden problem! Skorzystaj z opcji
                                    przypomnienia:
                                </p>
                                <Button
                                    color="primary"
                                    className="text-left"
                                    tag={Link}
                                    to={PATHS.AUTH.REMIND_PASSWORD}
                                >
                                    NIE PAMIĘTAM HASŁA
                                </Button>
                            </li>
                            <li>
                                Odkryj pełnię możliwości!
                                <p>
                                    Spersonalizowane plany diety, opcja wymiany
                                    składników i przepisów, stały kontakt z
                                    dietetykiem, proste plany treningowe. Zdrowy
                                    styl życia w końcu stał się prosty i
                                    wygodny!
                                </p>
                            </li>
                        </ol>
                    </>
                </Col>
            </Row>
        </Container>
    </section>
);

export default withLocale(ShoppingCartThankYouContentComponent);
