import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import ArticleListFetchQuery from './ArticleListFetchQuery';

class ArticleFetchListConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Query
                query={ArticleListFetchQuery}
                variables={{
                    pageNumber: 1,
                    articlesPerPage: 9,
                }}
                fetchPolicy="no-cache"
            >
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.articleList = deepClone(data.article);
                        result.articlePagination = deepClone(
                            data.articlePagination
                        );
                    }
                    return children(result);
                }}
            </Query>
        );
    }
}

export default ArticleFetchListConnectorApollo;
