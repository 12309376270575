import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { withLocale } from '@dietlabs/components';
import { reportError } from '@dietlabs/utils';

import { formatIsoDate } from 'utils/dateFormatter';
import CachedDataMessage from '../../../CachedDataMessage/CachedDataMessage';
import DayPlan from '../../components/DayPlan';
import DayPlanSummary from '../../components/DayPlanSummary';
import Meal from '../../components/DayPlanMeal';
// eslint-disable-next-line import/extensions
import MealDetails from '../../components/DayPlanMealDetails.jsx';
import Training from '../../components/DayPlanTraining';
import Dish from '../../components/DayPlanDish';
import IngredientList from '../../components/DayPlanIngredientList';
import Ingredient from '../../components/DayPlanIngredient';
import IngredientReplacementList from '../../components/DayPlanIngredientReplacementList';
import IngredientReplacement from '../../components/DayPlanIngredientReplacement';
import PrintForm from '../../components/DayPlanPrintForm';

import MealReplacementList from '../../components/DayPlanMealReplacementList';
import MealReplacement from '../../components/DayPlanMealReplacement';

import DayPlanChangeDay from '../../components/DayPlanChangeDay';
import DayReplacement from '../../components/DayPlanDayReplacement';

import MealNavigation from '../../components/DayPlanMealNavigation';

class DayPlanContainer extends Component {
    static propTypes = {
        renderTimeline: PropTypes.func.isRequired,
        dayPlan: PropTypes.shape({
            canBeCopiedToDate: PropTypes.bool.isRequired,
            date: PropTypes.instanceOf(Date).isRequired,
            diet: PropTypes.shape({
                id: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                macro: PropTypes.shape({
                    animalProtein: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    carbohydrates: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    fat: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                    protein: PropTypes.shape({
                        grams: PropTypes.number.isRequired,
                        kcal: PropTypes.number.isRequired,
                        percentage: PropTypes.number.isRequired,
                    }).isRequired,
                }).isRequired,
                phase: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    identifier: PropTypes.string.isRequired,
                }).isRequired,
            }),
            events: PropTypes.arrayOf(PropTypes.shape()),
            showMacros: PropTypes.bool.isRequired,
        }).isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object).isRequired,
        categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
        copyDayPlan: PropTypes.func.isRequired,
        alreadyCopied: PropTypes.bool.isRequired,
        print: PropTypes.bool,
        replaceProduct: PropTypes.func.isRequired,
        loadDayPlanMealReplacements: PropTypes.func.isRequired,
        replaceMeal: PropTypes.func.isRequired,
        loadDayPlanDayReplacements: PropTypes.func.isRequired,
        replaceDay: PropTypes.func.isRequired,
        prefetchSurroundingDays: PropTypes.func,
        loadDayPlanForTomorrow: PropTypes.func.isRequired,
        fastingStatistics: PropTypes.shape({
            dietsCount: PropTypes.number,
            currentDietLength: PropTypes.number,
            currentDietProgress: PropTypes.number,
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string.isRequired,
        }).isRequired,
        setMealEaten: PropTypes.func.isRequired,
        setId: PropTypes.number.isRequired,
        mealsEaten: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        dietId: PropTypes.number.isRequired,
        isVegetarian: PropTypes.bool.isRequired,
        isPescoVegetarian: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        print: false,
        prefetchSurroundingDays: undefined,
    };

    state = {
        dayPlanForTomorrow: undefined,
        showModal: false,
        showModalWithReplacements: false,
        showModalWithDayReplacements: false,
        mealId: undefined,
        replacements: null,
        originalId: 0,
        event: {
            id: undefined,
            key: undefined,
            original: undefined,
            name: undefined,
        },
    };

    componentDidMount = async () => {
        const dayPlanForTomorrow = await this.props.loadDayPlanForTomorrow();
        this.setState({ dayPlanForTomorrow });

        if (!this.props.print) {
            this.props.prefetchSurroundingDays();
        }

        const urlParams = new URLSearchParams(this.props.location.search);
        if (urlParams.has('showReplacements')) {
            this.toggleModalWithDayReplacements();
        }
    };

    componentDidUpdate = async prevProps => {
        const dayPlanForTomorrow = await this.props.loadDayPlanForTomorrow();
        if (
            JSON.stringify(this.state.dayPlanForTomorrow) !==
            JSON.stringify(dayPlanForTomorrow)
        ) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ dayPlanForTomorrow });
        }

        if (!this.props.print) {
            this.props.prefetchSurroundingDays();
        }

        if (
            formatIsoDate(prevProps.dayPlan.date) !==
            formatIsoDate(this.props.dayPlan.date)
        ) {
            const urlParams = new URLSearchParams(this.props.location.search);
            if (urlParams.has('showReplacements')) {
                this.toggleModalWithDayReplacements();
            }
        }
    };

    toggleModal(mealId) {
        if (mealId) {
            this.setState(prevState => ({
                showModal: !prevState.showModal,
                mealId,
            }));
        } else {
            this.setState(prevState => ({
                showModal: !prevState.showModal,
            }));
            setTimeout(() => {
                this.setState({ mealId });
            }, 500);
        }
    }

    toggleModalWithReplacements(replacements, originalId, event) {
        if (replacements) {
            this.setState(prevState => ({
                showModalWithReplacements: !prevState.showModalWithReplacements,
                replacements,
                originalId,
                event,
            }));
        } else {
            this.setState(prevState => ({
                showModalWithReplacements: !prevState.showModalWithReplacements,
            }));
        }
    }

    toggleModalWithDayReplacements() {
        this.setState(prevState => ({
            showModalWithDayReplacements: !prevState.showModalWithDayReplacements,
        }));
    }

    changeMeal(mealId) {
        this.setState({ mealId });
    }

    renderIngredientReplacements = (
        replacements,
        productAmountId,
        productId,
        productKey,
        clickAction
    ) =>
        replacements.map(replacement => (
            <IngredientReplacement
                key={replacement.productId}
                replacementId={replacement.productId}
                name={replacement.name}
                measurements={replacement.measurements}
                replaceProduct={this.props.replaceProduct}
                productId={productId}
                productKey={productKey}
                productAmountId={productAmountId}
                closeAndScroll={clickAction.closeAndScroll}
                replaceProductSuccess={() => ''}
            />
        ));

    renderIngredientReplacementsList = (
        replacements,
        originalProductId,
        productId,
        productKey,
        original,
        productAmountId,
        clickAction
    ) => (
        <IngredientReplacementList
            shouldRenderOryginalProduct={originalProductId !== productId}
            renderOriginalProduct={() =>
                this.renderIngredientReplacements(
                    [original],
                    productAmountId,
                    productId,
                    productKey,
                    clickAction
                )
            }
            hasReplacements={replacements.length > 0}
        >
            {() =>
                this.renderIngredientReplacements(
                    replacements,
                    productAmountId,
                    productId,
                    productKey,
                    clickAction
                )
            }
        </IngredientReplacementList>
    );

    renderIngredients = ingredients =>
        ingredients.map(ingredient => (
            <Ingredient
                key={ingredient.productId}
                name={ingredient.name}
                measurements={ingredient.measurements}
                saleProductVariant={ingredient.saleProductVariant}
                hasReplacement={ingredient.hasReplacement}
                originalProductId={ingredient.original.productId}
                productId={ingredient.productId}
                productKey={ingredient.key}
                print={this.props.print}
            >
                {({ closeAndScroll }) =>
                    this.renderIngredientReplacementsList(
                        ingredient.replacements,
                        ingredient.original.productId,
                        ingredient.productId,
                        ingredient.key,
                        ingredient.original,
                        ingredient.productAmountId,
                        { closeAndScroll }
                    )
                }
            </Ingredient>
        ));

    renderIngredientList = ingredients => {
        const filteredIngredients = ingredients.filter(
            ingredient =>
                !ingredient.category.isSeasoning &&
                !ingredient.category.isOptional
        );

        const onlySeasoningsIngredients = ingredients.filter(
            ingredient => ingredient.category.isSeasoning
        );

        const optionalIngredients = ingredients.filter(
            ingredient => ingredient.category.isOptional
        );

        return (
            <IngredientList
                renderIngredients={() =>
                    this.renderIngredients(filteredIngredients)
                }
                renderOnlySeasoningsIngredients={() =>
                    this.renderIngredients(onlySeasoningsIngredients)
                }
                renderOptionalIngredients={() =>
                    this.renderIngredients(optionalIngredients)
                }
            />
        );
    };

    renderDishes = (dishes, name, preparationVideoUrl) =>
        dishes.map(dish => (
            <Dish
                key={dish.key}
                name={dish.name}
                mealName={name}
                recipe={dish.recipe}
                recipeNote={dish.recipeNote}
                triangleOfPower={dish.triangleOfPower}
                isFirstOccurance={dish.isFirstOccurance}
                isLastOccurance={dish.isLastOccurance}
                isPortioned={dish.isPortioned}
                portions={dish.portions}
                portionsTotal={dish.portionsTotal}
                data-test="dish-component"
                print={this.props.print}
                preparationVideoUrl={preparationVideoUrl}
                ingredients={dish.ingredients}
            >
                {() => this.renderIngredientList(dish.ingredients)}
            </Dish>
        ));

    renderMealReplacements = (
        originalMealId,
        mealKey,
        replacements,
        mealName
    ) =>
        replacements.map(mealReplacement => (
            <MealReplacement
                key={mealReplacement.id}
                originalMealId={originalMealId}
                mealKey={mealKey}
                mealReplacement={mealReplacement}
                replaceMeal={this.props.replaceMeal}
                showModal={this.state.showModal}
                toggleModalWithReplacements={() =>
                    this.toggleModalWithReplacements()
                }
                toggleModal={() => this.toggleModal()}
                changeMeal={mealId => this.changeMeal(mealId)}
                mealName={mealName}
            />
        ));

    renderMealReplacementsList = (
        replacements,
        originalMealId,
        mealId,
        mealKey,
        original,
        mealName
    ) => (
        <MealReplacementList
            originalMealId={originalMealId}
            mealId={mealId}
            renderOriginalMeal={() =>
                this.renderMealReplacements(originalMealId, mealKey, [original])
            }
            replacements={replacements}
            mealName={mealName}
            toggleModalWithReplacements={() =>
                this.toggleModalWithReplacements()
            }
            toggleModal={() => this.toggleModal()}
            date={this.props.dayPlan.date}
        >
            {() =>
                this.renderMealReplacements(
                    originalMealId,
                    mealKey,
                    replacements,
                    mealName
                )
            }
        </MealReplacementList>
    );

    renderMealDetails = events =>
        events.map(event => {
            if (event.__typename === 'Meal' && event.id === this.state.mealId) {
                return (
                    <MealDetails
                        key={event.key}
                        mealId={event.id}
                        dietPhase={this.props.dayPlan.diet.phase.identifier.toLowerCase()}
                        preparationTime={event.preparationTime}
                        name={event.name}
                        kcal={event.kcal}
                        macro={event.macro}
                        showMacros={this.props.dayPlan.showMacros}
                        originalId={event.original.id}
                        data-test="meal-component"
                        loadDayPlanMealReplacements={
                            this.props.loadDayPlanMealReplacements
                        }
                        renderMealReplacementsList={(
                            replacements,
                            originalMealId
                        ) =>
                            this.renderMealReplacementsList(
                                replacements,
                                originalMealId,
                                event.id,
                                event.key,
                                event.original,
                                event.name
                            )
                        }
                        print={this.props.print}
                        toggleModalWithReplacements={(
                            replacements,
                            originalId
                        ) =>
                            this.toggleModalWithReplacements(
                                replacements,
                                originalId,
                                event
                            )
                        }
                        toggleModal={() => this.toggleModal()}
                        setMealEaten={this.props.setMealEaten}
                        setId={this.props.setId}
                        mealsEaten={this.props.mealsEaten}
                        preparationVideoUrl={event.preparationVideoUrl}
                        preparationImageUrl={event.preparationImageUrl}
                        publicName={event.publicName}
                        date={this.props.dayPlan.date}
                    >
                        {() =>
                            this.renderDishes(
                                event.dishes,
                                event.name,
                                event.preparationVideoUrl
                            )
                        }
                    </MealDetails>
                );
            }
            return '';
        });

    renderEvents = events =>
        events.map(event => {
            if (event.__typename === 'Meal') {
                return (
                    <Meal
                        key={event.key}
                        mealId={event.id}
                        dietPhase={this.props.dayPlan.diet.phase.identifier.toLowerCase()}
                        preparationTime={event.preparationTime}
                        name={event.name}
                        kcal={event.kcal}
                        macro={event.macro}
                        showMacros={this.props.dayPlan.showMacros}
                        originalId={event.original.id}
                        data-test="meal-component"
                        loadDayPlanMealReplacements={
                            this.props.loadDayPlanMealReplacements
                        }
                        renderMealReplacementsList={(
                            replacements,
                            originalMealId
                        ) =>
                            this.renderMealReplacementsList(
                                replacements,
                                originalMealId,
                                event.id,
                                event.key,
                                event.original,
                                event.name,
                                event.name
                            )
                        }
                        print={this.props.print}
                        toggleModal={mealId => this.toggleModal(mealId)}
                        toggleModalWithReplacements={(
                            replacements,
                            originalId
                        ) =>
                            this.toggleModalWithReplacements(
                                replacements,
                                originalId,
                                event
                            )
                        }
                        dishes={event.dishes}
                        setMealEaten={this.props.setMealEaten}
                        setId={this.props.setId}
                        mealsEaten={this.props.mealsEaten}
                        preparationImageUrl={event.preparationImageUrl}
                        publicName={event.publicName}
                    >
                        {() =>
                            this.renderDishes(
                                event.dishes,
                                event.name,
                                event.preparationVideoUrl
                            )
                        }
                    </Meal>
                );
            }
            if (event.__typename === 'Training') {
                return (
                    <Training
                        key={event.__typename + event.id}
                        activities={event.activities}
                        burnedCalories={event.burnedCalories}
                        duration={event.duration}
                        data-test="training-component"
                    />
                );
            }
            reportError(
                new Error(`Unknown event typename: ${event.__typename}`)
            );
            return '';
        });

    renderMealLinks = () => (
        <MealNavigation
            events={this.props.dayPlan.events}
            changeMeal={mealId => this.changeMeal(mealId)}
            activeMeal={this.state.mealId}
            dietPhase={this.props.dayPlan.diet.phase.identifier.toLowerCase()}
            date={this.props.dayPlan.date}
            kcal={this.props.dayPlan.diet.kcal}
        />
    );

    renderSummary = (canBeCopiedToDate, alreadyCopied) => (
        <DayPlanSummary
            protein={this.props.dayPlan.diet.macro.protein.percentage}
            fat={this.props.dayPlan.diet.macro.fat.percentage}
            carbohydrates={
                this.props.dayPlan.diet.macro.carbohydrates.percentage
            }
            canBeCopiedToDate={canBeCopiedToDate}
            copyDayPlan={this.props.copyDayPlan}
            alreadyCopied={alreadyCopied}
            print={this.props.print}
            data-test="summary-component"
            toggleModalWithDayReplacements={() =>
                this.toggleModalWithDayReplacements()
            }
        />
    );

    renderDayReplacements = (replacements, search) =>
        replacements.map(replacement => (
            <DayReplacement
                key={replacement.dietSetId}
                dayReplacement={replacement}
                search={search}
                replaceDay={this.props.replaceDay}
                toggleModalWithDayReplacements={() =>
                    this.toggleModalWithDayReplacements()
                }
            />
        ));

    renderChangeDay = () => (
        <DayPlanChangeDay
            loadDayPlanDayReplacements={this.props.loadDayPlanDayReplacements}
            renderOriginalDay={(original, search) =>
                this.renderDayReplacements([original], search)
            }
            date={this.props.dayPlan.date}
            currentDayPlan={this.props.dayPlan.events}
            toggleModalWithDayReplacements={() =>
                this.toggleModalWithDayReplacements()
            }
        >
            {(replacements, search) =>
                this.renderDayReplacements(replacements, search)
            }
        </DayPlanChangeDay>
    );

    renderPrintForm = () => (
        <PrintForm
            date={this.props.dayPlan.date}
            availableDays={this.props.availableDays}
        />
    );

    render() {
        const {
            dayPlan,
            availableDays,
            categories,
            goal,
            isHolidayMenu,
            isTimeToNagForCurrentMeasurement,
            cacheHit,
            hasNetworkError,
            alreadyCopied,
            print,
            fastingStatistics,
        } = this.props;

        return (
            <React.Fragment>
                <CachedDataMessage {...{ cacheHit, hasNetworkError }} />
                <DayPlan
                    kcal={dayPlan.diet.kcal}
                    date={dayPlan.date}
                    phase={dayPlan.diet.phase}
                    goal={goal}
                    isHolidayMenu={isHolidayMenu}
                    isTimeToNagForCurrentMeasurement={
                        isTimeToNagForCurrentMeasurement
                    }
                    availableDays={availableDays}
                    categories={categories}
                    renderTimeline={position =>
                        this.props.renderTimeline(position)
                    }
                    print={print}
                    dayPlanForTomorrow={this.state.dayPlanForTomorrow}
                    showMacros={this.props.dayPlan.showMacros}
                    renderMealLinks={() => this.renderMealLinks()}
                    fastingStatistics={fastingStatistics}
                    showModal={this.state.showModal}
                    dietId={this.props.dietId}
                    isVegetarian={this.props.isVegetarian}
                    isPescoVegetarian={this.props.isPescoVegetarian}
                    renderMealDetails={() =>
                        this.renderMealDetails(dayPlan.events)
                    }
                    showModalWithReplacements={
                        this.state.showModalWithReplacements
                    }
                    toggleModalWithReplacements={() =>
                        this.toggleModalWithReplacements()
                    }
                    renderMealReplacementsList={() =>
                        this.renderMealReplacementsList(
                            this.state.replacements,
                            this.state.originalId,
                            this.state.event.id,
                            this.state.event.key,
                            this.state.event.original,
                            this.state.event.name
                        )
                    }
                    showModalWithDayReplacements={
                        this.state.showModalWithDayReplacements
                    }
                    toggleModalWithDayReplacements={() =>
                        this.toggleModalWithDayReplacements()
                    }
                    renderChangeDay={() => this.renderChangeDay()}
                >
                    {() => (
                        <React.Fragment>
                            {this.renderEvents(dayPlan.events)}
                            {this.renderSummary(
                                dayPlan.canBeCopiedToDate,
                                alreadyCopied
                            )}
                            {this.renderPrintForm()}
                        </React.Fragment>
                    )}
                </DayPlan>
            </React.Fragment>
        );
    }
}

export { DayPlanContainer };
export default withRouter(withLocale(DayPlanContainer));
