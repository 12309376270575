import { formatIsoDate } from 'utils/dateFormatter';
import DayPlanPrintPage from 'DayPlan/Index/DayPlanPrintPage';
import DayPlanIndexPage from './Index/DayPlanIndexPage';
import DayPlanIntroPage from './Index/DayPlanIntroPage';
import * as PATHS from './DayPlanPaths';

const dayPlanPath = `${PATHS.PATH_DAY_PLAN_INDEX}/${formatIsoDate(new Date())}`;

const routes = [
    {
        path: '/',
        redirectTo: dayPlanPath,
        exact: true,
    },
    {
        path: PATHS.PATH_DAY_PLAN_INDEX,
        redirectTo: dayPlanPath,
        exact: true,
    },
    {
        path: PATHS.PATH_DAY_PLAN_INDEX_WITH_DATE,
        component: DayPlanIndexPage,
        private: true,
        exact: true,
        title: 'route-title/day-plan',
        mobileAppTabIdentifier: 'diet',
    },
    {
        path: PATHS.PATH_DAY_PLAN_PRINT,
        component: DayPlanPrintPage,
        private: true,
        exact: true,
        title: 'route-title/day-plan',
        mobileAppTabIdentifier: 'diet',
    },
    {
        path: PATHS.PATH_DIET_INTRO,
        component: DayPlanIntroPage,
        private: true,
        exact: true,
        title: 'route-title/diet-intro',
        mobileAppTabIdentifier: 'diet',
    },
];

export { routes };
