import React from 'react';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import PropTypes from 'prop-types';
import ChangeSettingsMutation from './ChangeSettingsMutation';
import Settings from '../../../Connector/Apollo/Settings';

class ChangeSettingsConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    changeSettings = changeSettingsApi => async systemOfMeasures => {
        const response = await changeSettingsApi({
            variables: {
                request: {
                    systemOfMeasures,
                },
            },
            update: proxy => {
                const data = proxy.readQuery({ query: Settings });

                proxy.writeQuery({
                    query: Settings,
                    data: {
                        me: {
                            ...data.me,
                            systemOfMeasures,
                        },
                    },
                });
            },
            optimisticResponse: {
                me: {
                    setting: {
                        code: 200,
                        __typename: 'BasicMutationSuccess',
                    },
                    __typename: 'MeMutation',
                },
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        let result = {};

        return (
            <Mutation
                mutation={ChangeSettingsMutation}
                refetchQueries={() => [
                    {
                        query: Settings,
                    },
                ]}
            >
                {(changeSettings, changeSettingsResult) => {
                    result = {
                        ...result,
                        changeSettings: this.changeSettings(changeSettings),
                        loading: changeSettingsResult.loading,
                    };
                    return children(result);
                }}
            </Mutation>
        );
    }
}

export default ChangeSettingsConnectorApollo;
