import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import MeasurementUnits from 'components/Helpers/MeasurementUnits';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

import changeImg from 'assets/icons/change_icon_off.svg';
import changeChangedImg from 'assets/icons/change_icon_on.svg';
import oxyTea from 'assets/images/oxy-tea.png';
import { ReactComponent as Basket } from 'assets/icons/basket.svg';

class DayPlanIngredient extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
        saleProductVariant: PropTypes.shape({
            link: PropTypes.string.isRequired,
        }),
        hasReplacement: PropTypes.bool.isRequired,
        originalProductId: PropTypes.number.isRequired,
        productId: PropTypes.number.isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        saleProductVariant: null,
    };

    state = {
        collapse: false,
    };

    handleClick() {
        this.setState(prevState => ({
            collapse: !prevState.collapse,
        }));
    }

    render() {
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li data-test="ingredient" onClick={() => this.handleClick()}>
                {this.props.hasReplacement ? (
                    <button
                        type="button"
                        className={
                            this.state.collapse
                                ? 'btn-change animated rotateLeft'
                                : 'btn-change animated rotateRight'
                        }
                        data-test="exchange-button"
                    >
                        <img
                            className="img-fluid"
                            src={
                                this.props.originalProductId !==
                                this.props.productId
                                    ? changeChangedImg
                                    : changeImg
                            }
                            alt={this.props.t('meal/exchange-ingredient')}
                        />
                    </button>
                ) : (
                    ''
                )}

                <MeasurementUnits measurements={this.props.measurements} />
                {this.props.name}

                <div className="clearfix" />
                {this.props.saleProductVariant && !this.props.print ? (
                    <div className="mt-4">
                        <p className="mb-0">
                            <strong>Herbata OXY Tea</strong>
                            <br />
                            kompozycja naturalnych składników
                        </p>
                        <div className="d-flex align-items-center justify-content-between">
                            <img src={oxyTea} alt="" />
                            <a
                                className="btn btn-primary no-arrow"
                                href={
                                    this.props.saleProductVariant.link !== ''
                                        ? this.props.saleProductVariant.link
                                        : '/cart/add/23'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                data-test="sale-product-variant-button"
                            >
                                <span className="SVGInline pr-1">
                                    <Basket />
                                </span>{' '}
                                {this.props.t('button/buy')}
                            </a>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                <Collapse isOpen={this.state.collapse}>
                    {this.props.hasReplacement ? this.props.children() : ''}
                </Collapse>
            </li>
        );
    }
}

export { DayPlanIngredient };
export default withLocale(DayPlanIngredient);
