import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import DayPlanReplaceDayMutation from './DayPlanReplaceDayMutation';
import DayPlanQuery from '../Fetch/DayPlanQuery';

class DayPlanReplaceDayConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
    };

    createReplaceDay = mutate => async ({ replacementId }) => {
        const variables = {
            request: {
                date: formatIsoDate(this.props.date),
                replacementId,
            },
        };

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children, date } = this.props;

        return (
            <Mutation
                mutation={DayPlanReplaceDayMutation}
                awaitRefetchQueries
                refetchQueries={() => [
                    {
                        query: DayPlanQuery,
                        variables: {
                            date: formatIsoDate(date),
                            canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
                        },
                    },
                ]}
            >
                {mutate =>
                    children({
                        replaceDay: this.createReplaceDay(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default DayPlanReplaceDayConnectorApollo;
