import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import goalReached from 'assets/images/goal_reached.jpg';
import { PATHS } from 'config/paths';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

class GoalReached extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func,
    };

    static defaultProps = {
        renderTimeline: undefined,
    };

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline && this.props.renderTimeline('top')}
                <section className="bg-white">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img
                                    className="mb-4 img-fluid"
                                    src={goalReached}
                                    role="presentation"
                                    alt=""
                                />
                                <h1>
                                    {this.props.t(
                                        'info-pages/goal-reached/title'
                                    )}
                                </h1>
                                <Button
                                    color="primary"
                                    className="mb-4"
                                    tag={Link}
                                    to={PATHS.MAKE_DECISION}
                                >
                                    {this.props.t(
                                        'info-pages/goal-reached/button'
                                    )}
                                </Button>
                                <p>
                                    {this.props.t(
                                        'info-pages/goal-reached/content'
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { GoalReached };
export default withLocale(GoalReached);
