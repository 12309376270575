import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InterventionTaskTextComponent from './InterventionTaskTextComponent';
import InterventionTaskStringComponent from './InterventionTaskStringComponent';
import InterventionTaskCheckboxesComponent from './InterventionTaskCheckboxesComponent';
import InterventionTaskScaleComponent from './InterventionTaskScaleComponent';
import InterventionTaskGroupComponent from './InterventionTaskGroupComponent';
import InterventionTaskHealthCircleComponent from './InterventionTaskHealthCircleComponent';
import InterventionTaskHungermeterComponent from './InterventionTaskHungermeterComponent';

class InterventionTaskComponent extends React.Component {
    static propTypes = {
        tasks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        editMode: PropTypes.bool.isRequired,
    };

    renderTask = task => {
        switch (task.type) {
            case 'text':
                return (
                    <InterventionTaskTextComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                    />
                );
            case 'string':
                return (
                    <InterventionTaskStringComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                    />
                );
            case 'checkboxes':
                return (
                    <InterventionTaskCheckboxesComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                    />
                );
            case 'scale':
                return (
                    <InterventionTaskScaleComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                    />
                );
            case 'group': {
                if (task.variant === 'healthCircle' && !this.props.editMode) {
                    return (
                        <InterventionTaskHealthCircleComponent
                            key={task.id}
                            task={task}
                            tasks={task.subtasks}
                            setTaskAnswers={this.props.setTaskAnswers}
                            editMode={this.props.editMode}
                        />
                    );
                }

                if (task.variant === 'hungermeter') {
                    return (
                        <InterventionTaskHungermeterComponent
                            key={task.id}
                            tasks={task.subtasks}
                            setTaskAnswers={this.props.setTaskAnswers}
                            editMode={this.props.editMode}
                        />
                    );
                }

                return (
                    <InterventionTaskGroupComponent
                        key={task.id}
                        tasks={task.subtasks}
                        setTaskAnswers={this.props.setTaskAnswers}
                        editMode={this.props.editMode}
                        variant={task.variant}
                    />
                );
            }
            default:
                return null;
        }
    };

    render() {
        return this.props.tasks.map(task => this.renderTask(task));
    }
}

export default withLocale(InterventionTaskComponent);
