import gql from 'graphql-tag';

const query = gql`
    query Operator {
        me {
            id
            email
            diet {
                accessTo
            }
            activeSubscriptions {
                operator
            }
        }
    }
`;

export default query;
