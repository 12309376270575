import React, { Component } from 'react';
import { Container, Alert, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Odometer from 'react-odometerjs';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { reportWarning } from '@dietlabs/utils';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { formatIsoDate } from 'utils/dateFormatter';
import { getHolidayIconName } from 'DayPlan/utils/getHolidayIconName';
import MessageWithButton from 'Layout/Messages/MessageWithButton';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import { holidayIcons } from 'consts';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { PATH_DAY_PLAN_INDEX } from '../DayPlanPaths';

class DayPlan extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        kcal: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        phase: PropTypes.shape({
            identifier: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object.isRequired)
            .isRequired,
        // categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        trackEvent: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
        triggerLoadDayReplacements: PropTypes.func.isRequired,
        dayPlanForTomorrow: PropTypes.shape({
            __typename: PropTypes.string.isRequired,
            events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        }),

        showMacros: PropTypes.bool.isRequired,
        renderMealLinks: PropTypes.func.isRequired,
        fastingStatistics: PropTypes.shape({
            dietsCount: PropTypes.number,
            currentDietLength: PropTypes.number,
            currentDietProgress: PropTypes.number,
        }).isRequired,
        mobileApp: PropTypes.shape({
            active: PropTypes.bool,
            os: PropTypes.string,
            version: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {
        dayPlanForTomorrow: undefined,
    };

    state = {
        odometer: 0,
        showModal: false,
    };

    toggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    };

    componentDidMount() {
        this.setState({ odometer: this.props.kcal });
        this.trackView();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.kcal !== this.props.kcal) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                odometer: this.props.kcal,
            });
        }

        if (formatIsoDate(prevProps.date) !== formatIsoDate(this.props.date)) {
            this.trackView();
        }
    }

    trackView() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );

        const dayDelta = Math.floor(
            (this.props.date - today) / 1000 / 60 / 60 / 24
        );

        const label = dayDelta > 0 ? `+${dayDelta}` : dayDelta;
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'view', label);
    }

    renderGoalAlert() {
        if (this.props.goal) {
            if (
                this.props.goal.lostBecauseOfLoseWeight ||
                this.props.goal.lostBecauseOfPutOnWeight
            ) {
                return (
                    <MessageWithButton
                        color="danger"
                        renderButton={() => (
                            <Button
                                color="primary"
                                tag={Link}
                                to={PATHS.MAKE_DECISION}
                            >
                                {this.props.t('info-pages/goal-lost/button')}
                            </Button>
                        )}
                        data-test="goal-lost"
                    >
                        <h2 className="alert-heading">
                            {this.props.t('info-pages/goal-lost/title')}
                        </h2>
                        <p>{this.props.t('info-pages/goal-lost/content')}</p>
                    </MessageWithButton>
                );
            }

            if (
                this.props.goal.reachedBecauseOfLoseWeight ||
                this.props.goal.reachedBecauseOfPutOnWeight
            ) {
                return (
                    <MessageWithButton
                        color="success"
                        renderButton={() => (
                            <Button
                                color="secondary"
                                tag={Link}
                                to={PATHS.MAKE_DECISION}
                            >
                                {this.props.t('info-pages/goal-reached/button')}
                            </Button>
                        )}
                        data-test="goal-reached"
                    >
                        <h2 className="alert-heading">
                            {this.props.t('info-pages/goal-reached/title')}
                        </h2>
                        <p>{this.props.t('info-pages/goal-reached/content')}</p>
                    </MessageWithButton>
                );
            }
        }

        return '';
    }

    renderMeasurementAlert() {
        if (this.props.isTimeToNagForCurrentMeasurement) {
            return (
                <MessageWithButton
                    color="danger"
                    renderButton={() => (
                        <Button
                            color="primary"
                            tag={Link}
                            to={{
                                pathname: PATH_MEASUREMENT_ADD,
                                state: {
                                    from: { pathname: PATH_DAY_PLAN_INDEX },
                                },
                            }}
                        >
                            {this.props.t(
                                'info-pages/current-weight-needed/button'
                            )}
                        </Button>
                    )}
                    data-test="measurement"
                >
                    <p>
                        {this.props.t('alert/periodic-measurements-1')}
                        <br />
                        {this.props.t('alert/periodic-measurements-2')}
                    </p>
                </MessageWithButton>
            );
        }

        return '';
    }

    renderHolidayAlert() {
        const isThisHoliday = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(this.props.date)
        );
        if (isThisHoliday && !this.props.isHolidayMenu) {
            return (
                <Alert
                    color="danger"
                    className="mb-3 text-left clickable"
                    onClick={() => this.props.triggerLoadDayReplacements()}
                    data-test="holiday"
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('holiday-notification'),
                        }}
                    />
                </Alert>
            );
        }

        return '';
    }

    renderHolidayIcon(date) {
        const iconName = getHolidayIconName(
            this.props.availableDays,
            formatIsoDate(date)
        );
        if (iconName) {
            const Icon = holidayIcons[iconName];
            if (Icon) {
                return (
                    <span className="SVGInline ml-4" data-test="holiday-icon">
                        <Icon />
                    </span>
                );
            }

            reportWarning(new Error(`Unknown holiday icon: ${iconName}`));
        }

        return '';
    }

    renderTomorrowAlert() {
        const tomorrowAlert = [];
        if (
            this.props.dayPlanForTomorrow &&
            Object.keys(this.props.dayPlanForTomorrow).length !== 0
        ) {
            if (
                this.props.dayPlanForTomorrow.__typename ===
                'UserDayPlanSuccess'
            ) {
                this.props.dayPlanForTomorrow.events.forEach(event => {
                    if (event.__typename === 'Meal') {
                        event.dishes.forEach(dish => {
                            if (dish.recipeNoteForPreviousDay) {
                                tomorrowAlert.push(
                                    dish.recipeNoteForPreviousDay
                                );
                            }
                        });
                    }
                });
            }
        }

        const uniqTomorrowAlert = [...new Set(tomorrowAlert)];

        if (uniqTomorrowAlert.length > 0) {
            return (
                <Alert
                    color="info"
                    className="mb-4 text-left"
                    data-test="tomorrow-alert"
                >
                    <h4>{this.props.t('next-day-notification')}</h4>
                    <ul className="list">
                        {uniqTomorrowAlert.map((alert, idx, arr) => (
                            <li key={arr.indexOf(alert)}>{alert}</li>
                        ))}
                    </ul>
                </Alert>
            );
        }

        return '';
    }

    renderDietPhase() {
        return (
            <div
                className={`diet-phase ${this.props.phase.identifier.toLowerCase()}`}
            >
                {this.props.phase.name}
            </div>
        );
    }

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline('top')}

                {this.props.renderMealLinks()}

                <header>
                    <Container className="text-center">
                        {!this.props.print && (
                            <React.Fragment>
                                {this.renderGoalAlert()}
                                {this.renderMeasurementAlert()}
                            </React.Fragment>
                        )}

                        {this.renderTomorrowAlert()}

                        <h1>
                            <strong>
                                {this.props.t(
                                    `meal/weekday${this.props.date.getDay()}`
                                )}
                            </strong>

                            {this.props.showMacros ? (
                                <React.Fragment>
                                    ,{' '}
                                    <span className="text-nowrap">
                                        {this.props.print ? (
                                            this.props.kcal
                                        ) : (
                                            <Odometer
                                                value={this.state.odometer}
                                                format="d"
                                            />
                                        )}{' '}
                                        kcal
                                    </span>
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                            {this.renderHolidayIcon(this.props.date)}
                        </h1>

                        {this.renderDietPhase()}

                        {!this.props.print && (
                            <React.Fragment>
                                {this.renderHolidayAlert()}
                            </React.Fragment>
                        )}
                    </Container>
                </header>
                {this.props.children()}
            </Layout>
        );
    }
}
export { DayPlan };
export default withMobileAppMode(withGTM(withLocale(DayPlan)));
