import gql from 'graphql-tag';

export default gql`
    mutation ReportPayment($token: String!, $operatorData: String) {
        sale {
            reportPayment(token: $token, operatorData: $operatorData) {
                __typename
                ... on Payment {
                    containsDiet
                    containsTraining
                    containsPhysicalProduct
                    user {
                        email
                        hasDiet
                        isActive
                        authToken
                        created
                    }
                    isCod
                    price {
                        amount
                        currency
                    }
                }
                ... on NotReportedPayment {
                    containsDiet
                    containsTraining
                    containsPhysicalProduct
                    user {
                        email
                        hasDiet
                        isActive
                        authToken
                        created
                    }
                    isCod
                    reportData
                    price {
                        amount
                        currency
                    }
                }
                ... on CanceledPayment {
                    reason
                }
            }
        }
    }
`;
