class UrlBuilder {
    shoppingListName;

    products = [];

    constructor(shoppingListName) {
        this.shoppingListName = shoppingListName;
    }

    addProduct = (friscoId, itemCount, productName, productDescription) =>
        this.products.push({
            friscoId,
            itemCount,
            productName: productName.replace(/,/g, ' '),
            productDescription: productDescription.replace(/,/g, ' '),
        });

    buildUrl = () => {
        const productString = this.products
            .reduce((carry, product) => {
                const {
                    friscoId,
                    itemCount,
                    productName,
                    productDescription,
                } = product;

                carry.push(
                    `${encodeURIComponent(friscoId)}x${encodeURIComponent(
                        itemCount
                    )}x${encodeURIComponent(productName)}=${encodeURIComponent(
                        productDescription
                    )}`
                );
                return carry;
            }, [])
            .map(s => s.replace(/,/g, ' '))
            .join(',');

        return `https://www.frisco.pl/stn,skladniki-do-zestawu/skladniki,${productString}/zestaw,${encodeURIComponent(
            this.shoppingListName
        )}?utm_source=refferal&utm_medium=banner&utm_campaign=diet_by_ann`;
    };
}

export default UrlBuilder;
