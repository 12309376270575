import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Button } from 'reactstrap';
import { ReactComponent as Alert } from 'assets/icons/removeAccount/alert.svg';

const RemoveAccountError = ({ t, handleConfirmRemoveAccount }) => {
    return (
        <div className="text-center">
            <Alert className="mt-3" />
            <p className="black bold mt-3 mb-2">{t('remove-account/error')}</p>
            <p className="mb-2">{t('remove-account/try-in-moment')}</p>
            <Button
                color="primary"
                className="w-100"
                onClick={handleConfirmRemoveAccount}
            >
                {t('button/retry')}
            </Button>
        </div>
    );
};

RemoveAccountError.propTypes = {
    t: PropTypes.func.isRequired,
    handleConfirmRemoveAccount: PropTypes.func.isRequired,
};

export default withLocale(RemoveAccountError);
