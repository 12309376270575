import { dietSettingsGetSchemaForParameters } from '../SchemaForParameters/DietSettingsSchemaForParametersAction';

export const dietSettingsGetCurrentStepPositon = (
    schema,
    parameters,
    currentStepIdentifier
) => {
    if (schema === undefined || parseInt(schema.length, 10) === 0) {
        const exception = '`schema` must contain at least one step!';
        throw exception;
    }

    if (parameters === undefined) {
        // eslint-disable-next-line no-param-reassign
        parameters = {};
    }

    if (currentStepIdentifier === undefined) {
        const exception = '`currentStepIdentifier` must be set!';
        throw exception;
    }

    const schemaForParameters = dietSettingsGetSchemaForParameters(
        schema,
        parameters
    );
    const position = schemaForParameters.findIndex(
        step => step.id === currentStepIdentifier
    );

    // it means is not in schema
    if (position === -1) {
        const exception = `\`currentStepIdentifier\` is not in schema! Schema: ${schema}, Parameters: ${parameters}, CurrentStepIdentifier: ${currentStepIdentifier}`;
        throw exception;
    }

    return position + 1;
};
