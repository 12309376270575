import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Label, Row } from 'reactstrap';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import { withLocale } from '@dietlabs/components';
import ExternalProviderContainer from '@dietlabs/components/src/Auth/Login/ByExternalProvider/ExternalProviderContainer';
import { RETURN_URL_LOGIN } from '@dietlabs/components/src/Auth/Login/ByExternalProvider/LoginReturnUrls';
import ChangeSettingsConnectorApollo from './SystemOfMeasurements/Connector/Apollo/ChangeSettingsConnectorApollo';
import Layout from '../Layout/Layout';
import SystemOfMeasurementsForm from './SystemOfMeasurements/SystemOfMeasurementsForm';
import SettingsConnectorApollo from './Connector/Apollo/SettingsConnectorApollo';
import NotificationsSettings from './Notifications/NotificationsSettingsForm';
import UserAgreementToNotifyAboutDietCourseConnector from './Notifications/Connector/Apollo/UserAgreementToNotifyAboutDietCourseConnector';
import UserAgreementToReceiveNewsletterConnector from './Notifications/Connector/Apollo/UserAgreementToReceiveNewsletterConnector';
import ChangePasswordForm from './ChangePassword/ChangePasswordForm';
import ChangePasswordConnector from './ChangePassword/Connector/Apollo/ChangePasswordConnector';
import Placeholder from '../Settings/ProductsAndSubscriptions/SettingsProductsAndSubscriptionsPlaceholder';
import SettingsProductsAndSubscriptionsComponent from '../Settings/ProductsAndSubscriptions/SettingsProductsAndSubscriptionsComponent';
import HydrationSettingsConnector from '../Settings/Hydration/Connector/Apollo/HydrationSettingsConnectorApollo';
import HydrationSettings from '../Settings/Hydration/HydrationSettings';
import UserEmailForm from './UserEmail/UserEmailForm';
import RemoveAccountButton from './RemoveAccount/Components/RemoveAccountButton';

class UserSettings extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    componentDidMount() {
        scrollToTop();
    }

    renderActiveSubscriptions = result => {
        if (result.loading) {
            return <Placeholder />;
        }
        // Filter out undefined values
        const filteredProps = Object.entries(result)
            .filter(([, v]) => v !== undefined)
            .reduce((c, [k, v]) => ({ ...c, [k]: v }), {});
        return <SettingsProductsAndSubscriptionsComponent {...filteredProps} />;
    };

    renderSocialMediaLogin = (
        isAbleToLoginWithGoogle,
        isAbleToLoginWithFacebook,
        isAbleToLoginWithApple
    ) => (
        <Container>
            <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                    <div className="white-box">
                        <h2>
                            <Label className="not-label">
                                {this.props.t(
                                    'auth/login/social-media-login-header'
                                )}
                            </Label>
                        </h2>
                        <div className="text-center">
                            {isAbleToLoginWithGoogle &&
                            isAbleToLoginWithFacebook &&
                            isAbleToLoginWithApple ? (
                                this.props.t('auth/login/social-media-set-up')
                            ) : (
                                <ExternalProviderContainer
                                    returnUrl={RETURN_URL_LOGIN}
                                    displayGoogleButton={
                                        !isAbleToLoginWithGoogle
                                    }
                                    displayFacebookButton={
                                        !isAbleToLoginWithFacebook
                                    }
                                    displayAppleButton={!isAbleToLoginWithApple}
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );

    render() {
        return (
            <Layout page="settings">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('settings/title')}
                    </h1>
                </header>
                <section className="pt-0">
                    <SettingsConnectorApollo>
                        {result => (
                            <React.Fragment>
                                <UserEmailForm
                                    email={result.email}
                                    loading={result.loading}
                                />
                                <ChangeSettingsConnectorApollo>
                                    {systemOfMeasures => (
                                        <SystemOfMeasurementsForm
                                            setSystemOfMeasures={
                                                systemOfMeasures
                                            }
                                            value={result.systemOfMeasures}
                                            loading={result.loading}
                                        />
                                    )}
                                </ChangeSettingsConnectorApollo>

                                <HydrationSettingsConnector
                                    rawQueryResult={result.rawData}
                                >
                                    {save => (
                                        <HydrationSettings
                                            loading={result.loading}
                                            defaultDailyGoal={
                                                result.hydrationSettings
                                                    .defaultDailyGoal
                                            }
                                            save={save}
                                        />
                                    )}
                                </HydrationSettingsConnector>

                                <UserAgreementToNotifyAboutDietCourseConnector>
                                    {notifyAboutDietCourse => (
                                        <UserAgreementToReceiveNewsletterConnector>
                                            {receiveNewsletter => (
                                                <NotificationsSettings
                                                    agreements={
                                                        result.agreements
                                                    }
                                                    onDietCourseChange={
                                                        notifyAboutDietCourse.changeAgreement
                                                    }
                                                    onNewsletterChange={
                                                        receiveNewsletter.changeAgreement
                                                    }
                                                    loading={result.loading}
                                                />
                                            )}
                                        </UserAgreementToReceiveNewsletterConnector>
                                    )}
                                </UserAgreementToNotifyAboutDietCourseConnector>

                                {result.passwordIsSet ? (
                                    <ChangePasswordConnector>
                                        {changePassword => (
                                            <ChangePasswordForm
                                                changePassword={
                                                    changePassword.changePassword
                                                }
                                                username={result.email}
                                            />
                                        )}
                                    </ChangePasswordConnector>
                                ) : null}

                                {this.renderActiveSubscriptions(result)}

                                {this.renderSocialMediaLogin(
                                    result.isAbleToLoginWithGoogle,
                                    result.isAbleToLoginWithFacebook,
                                    result.isAbleToLoginWithApple
                                )}
                                <RemoveAccountButton loading={result.loading} />
                            </React.Fragment>
                        )}
                    </SettingsConnectorApollo>
                </section>
            </Layout>
        );
    }
}

export default withLocale(UserSettings);
