import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Button, Alert } from 'reactstrap';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import InterventionTaskComponent from '../Task/InterventionTaskComponent';

class InterventionBasicComponent extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        actionLabel: PropTypes.string.isRequired,
        concluded: PropTypes.bool.isRequired,
        content: PropTypes.string.isRequired,
        videoUrlBase: PropTypes.string,
        imageUrl: PropTypes.string,
        tasks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        onAction: PropTypes.func.isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        errors: PropTypes.arrayOf(PropTypes.shape()),
        editMode: PropTypes.bool.isRequired,
        setEditMode: PropTypes.func.isRequired,
        animationClass: PropTypes.string.isRequired,
    };

    static defaultProps = {
        videoUrlBase: null,
        imageUrl: null,
        errors: null,
    };

    componentDidMount() {
        if (!this.props.concluded) {
            this.props.setEditMode();
        }
    }

    renderContent = () => {
        if (
            this.props.tasks &&
            this.props.tasks.length > 0 &&
            this.props.tasks[0].variant === 'healthCircle' &&
            !this.props.editMode
        ) {
            return null;
        }

        return (
            <React.Fragment>
                <div
                    dangerouslySetInnerHTML={{ __html: this.props.content }}
                    className="MotivationIntervention__content"
                />
                {this.renderIllustration()}
            </React.Fragment>
        );
    };

    renderIllustration = () => {
        if (this.props.videoUrlBase) {
            return this.renderVideo();
        }

        if (this.props.imageUrl) {
            return this.renderImage();
        }

        return null;
    };

    renderVideo = () => {
        if (!this.props.videoUrlBase) {
            return null;
        }

        return (
            <video
                aria-hidden="true"
                poster={this.props.imageUrl}
                muted
                controls={false}
                autoPlay
                playsInline
                loop
                className="MotivationIntervention__illustration"
            >
                <source
                    src={`${this.props.videoUrlBase}.webm`}
                    type="video/webm"
                />
                <source
                    src={`${this.props.videoUrlBase}.mp4`}
                    type="video/mp4"
                />
                {this.renderImage()}
            </video>
        );
    };

    renderImage = () => {
        if (!this.props.imageUrl) {
            return null;
        }

        return (
            <img
                src={this.props.imageUrl}
                alt=""
                aria-hidden="true"
                className="img-fluid"
            />
        );
    };

    renderActions = () => {
        if (this.props.concluded && !this.props.tasks.length) {
            return null;
        }

        return (
            <div className="action">
                <Button
                    color="primary"
                    className="no-arrow"
                    onClick={this.props.onAction}
                >
                    {this.props.tasks &&
                    this.props.tasks.length > 0 &&
                    this.props.tasks[0].variant === 'healthCircle' &&
                    this.props.editMode
                        ? this.props.t('save')
                        : this.props.actionLabel}

                    <span className="SVGInline">
                        <ArrowRight className="ml-2" />
                    </span>
                </Button>
            </div>
        );
    };

    render() {
        const classNames = [
            'MotivationIntervention',
            `MotivationIntervention--${this.props.type}`,
            'MotivationIntervention--revealed',
            this.props.animationClass,
        ];
        if (this.props.concluded) {
            classNames.push('MotivationIntervention--concluded');
        }

        return (
            <section className={classNames.join(' ')}>
                {this.renderContent()}

                <InterventionTaskComponent
                    tasks={this.props.tasks}
                    setTaskAnswers={this.props.setTaskAnswers}
                    editMode={this.props.editMode}
                />

                {this.props.errors ? (
                    <Alert color="danger" className="mt-3">
                        {this.props.errors.map(error => error.message)}
                    </Alert>
                ) : (
                    ' '
                )}

                {this.renderActions()}
            </section>
        );
    }
}

export default withLocale(InterventionBasicComponent);
