import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { formatIsoDate } from 'utils/dateFormatter';
import DayPlanForTomorrowQuery from './DayPlanForTomorrowQuery';

class DayPlanForTomorrowConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    fetch = async () => {
        let tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        tomorrowDate = formatIsoDate(tomorrowDate);

        const result = await this.props.client.query({
            query: DayPlanForTomorrowQuery,
            variables: {
                date: tomorrowDate,
            },
            fetchPolicy: 'no-cache',
        });

        const dayPlanForTomorrow = result.data.me.dayPlanForTomorrow;

        if (dayPlanForTomorrow.__typename === 'UserDayPlanSuccess') {
            return dayPlanForTomorrow;
        }
        return undefined;
    };

    render() {
        const { children } = this.props;

        return children({
            loadDayPlanForTomorrow: () => this.fetch(),
        });
    }
}

export default withApollo(DayPlanForTomorrowConnectorApollo);
