import gql from 'graphql-tag';

const query = gql`
    query PsychotestResult($email: String, $token: String) {
        psychotest {
            result(email: $email, token: $token) {
                id
                email
                token
                personality
            }
        }
    }
`;

export default query;
