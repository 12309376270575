import { dietSettingsGetSchemaForParameters } from '../SchemaForParameters/DietSettingsSchemaForParametersAction';
import { dietSettingsGetCurrentStepPositon } from '../CurrentStepPosition/DietSettingsGetCurrentStepPositionAction';

export const dietSettingsGetPreviousStep = (
    schema,
    parameters,
    currentStepIdentifier
) => {
    const schemaForParameters = dietSettingsGetSchemaForParameters(
        schema,
        parameters
    );
    const currentStepPositon = dietSettingsGetCurrentStepPositon(
        schema,
        parameters,
        currentStepIdentifier
    );

    let result;
    if (currentStepPositon === 1) {
        result = null;
    } else if (currentStepPositon > 1) {
        // -2 because:
        // * -1 array is numerate from 0
        // * -1 previous step
        result = schemaForParameters[currentStepPositon - 2].id;
    }

    return result;
};
