import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepDietType extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietId: PropTypes.number,
        isVegetarian: PropTypes.bool.isRequired,
        isPescoVegetarian: PropTypes.bool.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietId: null,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.dietId);
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.dietId);
    }

    render() {
        const {
            dietId,
            isVegetarian,
            isPescoVegetarian,
            handleInputChange,
        } = this.props;

        if (dietId === 1 || dietId === 2) {
            return (
                <div className="full-screen3">
                    <div className="mb-auto">
                        <FormGroup tag="fieldset">
                            <Label>
                                {this.props.t('diet-settings/diet-type/label')}
                            </Label>
                            <InputRadio
                                label={[
                                    <strong key="full" className="d-block">
                                        {this.props.t(
                                            'diet-settings/diet-type/oxy/title'
                                        )}
                                    </strong>,
                                    this.props.t(
                                        'diet-settings/diet-type/oxy/description'
                                    ),
                                ]}
                                name="dietType1"
                                value="oxy"
                                handleChange={handleInputChange}
                                checked={
                                    (dietId === 1 || dietId === 2) &&
                                    !isVegetarian &&
                                    !isPescoVegetarian
                                }
                            />
                            <InputRadio
                                label={[
                                    <strong
                                        key="onlyFastPreparation"
                                        className="d-block"
                                    >
                                        {this.props.t(
                                            'diet-settings/diet-type/oxy-vege/title'
                                        )}
                                    </strong>,
                                    this.props.t(
                                        'diet-settings/diet-type/oxy-vege/description'
                                    ),
                                ]}
                                name="dietType1"
                                value="isVegetarian"
                                handleChange={handleInputChange}
                                checked={
                                    (dietId === 1 || dietId === 2) &&
                                    isVegetarian &&
                                    !isPescoVegetarian
                                }
                            />
                            <InputRadio
                                label={[
                                    <strong
                                        key="onlyFastPreparation"
                                        className="d-block"
                                    >
                                        {this.props.t(
                                            'diet-settings/diet-type/oxy-vege-fish/title'
                                        )}
                                    </strong>,
                                    this.props.t(
                                        'diet-settings/diet-type/oxy-vege-fish/description'
                                    ),
                                ]}
                                name="dietType1"
                                value="isPescoVegetarian"
                                handleChange={handleInputChange}
                                checked={
                                    (dietId === 1 || dietId === 2) &&
                                    !isVegetarian &&
                                    isPescoVegetarian
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
            );
        }

        return (
            <div className="full-screen3">
                <div className="my-auto">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t('diet-settings/diet-type/label')}
                        </Label>
                        <InputRadio
                            label={[
                                <strong key="full" className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-type/oxy-simple/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/oxy-simple/description'
                                ),
                            ]}
                            name="dietType1"
                            value="oxy"
                            handleChange={handleInputChange}
                            checked={
                                (dietId === 3 || dietId === 4) && !isVegetarian
                            }
                        />

                        <InputRadio
                            label={[
                                <strong
                                    key="onlyFastPreparation"
                                    className="d-block"
                                >
                                    {this.props.t(
                                        'diet-settings/diet-type/oxy-simple-vege/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-type/oxy-simple-vege/description'
                                ),
                            ]}
                            name="dietType1"
                            value="isVegetarian"
                            handleChange={handleInputChange}
                            checked={
                                (dietId === 3 || dietId === 4) && isVegetarian
                            }
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepDietType));
