import PsychometricTestPage from './PsychometricTestPage';
import PsychometricResultPage from './PsychometricResultPage';
import PsychometricStartPage from './PsychometricStartPage';

import {
    PATH_PSYCHOMETRIC_TEST,
    PATH_PSYCHOMETRIC_RESULT,
    PATH_PSYCHOMETRIC_RESULT_WITH_TOKEN,
    PATH_PSYCHOMETRIC_START,
} from './paths';

const routes = [
    {
        path: PATH_PSYCHOMETRIC_TEST,
        component: PsychometricTestPage,
        private: false,
        exact: true,
        title: 'route-title/psychometric',
    },
    {
        path: PATH_PSYCHOMETRIC_RESULT,
        component: PsychometricResultPage,
        private: false,
        exact: true,
        title: 'route-title/psychometric',
    },
    {
        path: PATH_PSYCHOMETRIC_RESULT_WITH_TOKEN,
        component: PsychometricResultPage,
        private: false,
        exact: true,
        title: 'route-title/psychometric',
    },
    {
        path: PATH_PSYCHOMETRIC_START,
        component: PsychometricStartPage,
        private: false,
        exact: true,
        title: 'route-title/psychometric',
    },
];

export { routes };
