import gql from 'graphql-tag';

const mutation = gql`
    mutation SalePaymentClone($token: String!) {
        sale {
            clonePayment(token: $token) {
                ... on Payment {
                    token
                }
            }
        }
    }
`;

export default mutation;
