import gql from 'graphql-tag';

const query = gql`
    query GetSalePaymentMethods {
        sale {
            payment {
                methods {
                    ... on SaleMethod {
                        methodId
                        channelId
                        name
                        description
                        logoUrl
                        wideViewportLogoUrl
                    }
                }
            }
        }
    }
`;

export default query;
