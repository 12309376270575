import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import { withApollo } from 'react-apollo';
import InterventionRevealMutation from './InterventionRevealMutation';
import InterventionFetchQuery from '../Fetch/InterventionFetchQuery';

class InterventionRevealConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        period: PropTypes.shape().isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    createReveal = mutate => async request => {
        const variables = {
            date: request,
        };

        const response = await mutate({
            variables,
        });

        return response;
    };

    refetchQueries = async () => {
        await this.props.client.query({
            query: InterventionFetchQuery,
            variables: { period: this.props.period },
            fetchPolicy: 'no-cache',
        });
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation
                mutation={InterventionRevealMutation}
                onCompleted={this.refetchQueries}
            >
                {mutate =>
                    children({
                        reveal: this.createReveal(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default withApollo(InterventionRevealConnectorApollo);
