import gql from 'graphql-tag';

const TASK_FIELDS = `
    id
    type
    variant
    label
    min
    max
    answers
    variantOptions {
        key
        value
    }
    options {
        key
        value
    }
`;

export default gql`
    mutation concludeInterventionMutation($date: Date!, $answers: [UserInterventionAnswerInputType!]) {
        me {
            concludeIntervention(date: $date, answers: $answers) {
                __typename
                ... on UserInterventionVisible {
                    key
                    date
                    number
                    type
                    revealed
                    concluded
                    promptContent
                    revealLabel
                    content
                    imageUrl
                    videoUrlBase
                    concludeLabel
                    editLabel
                    successContent
                    tasks {
                        ${TASK_FIELDS}
                        subtasks {
                            ${TASK_FIELDS}
                            subtasks {
                                ${TASK_FIELDS}
                            }
                        }
                    }
                }
                ... on InvalidAnswerException {
                    errors {
                        taskId
                        message
                    }
                }
            }
        }
    }
`;
