import React from 'react';
import PropTypes from 'prop-types';
import Query from '@dietlabs/components/src/Apollo/Query';
import CurrentMeasurementsQuery from './CurrentMeasurementsQuery';

class CurrentMeasurementsConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    static getFormatedMeasurement = rawMeasurement => ({
        change: rawMeasurement.change,
        date: rawMeasurement.date,
        unit: rawMeasurement.unit,
        value: rawMeasurement.value,
    });

    render() {
        const { children } = this.props;
        return (
            <Query query={CurrentMeasurementsQuery}>
                {({ data, loading }) => {
                    const result = {
                        loading,
                    };

                    if (!loading) {
                        const measurements = data.me.currentMeasurements;

                        result.weight = measurements.weight[0]
                            ? this.constructor.getFormatedMeasurement(
                                  measurements.weight[0]
                              )
                            : undefined;
                        result.arm = measurements.arm[0]
                            ? this.constructor.getFormatedMeasurement(
                                  measurements.arm[0]
                              )
                            : undefined;
                        result.chest = measurements.chest[0]
                            ? this.constructor.getFormatedMeasurement(
                                  measurements.chest[0]
                              )
                            : undefined;
                        result.hip = measurements.hip[0]
                            ? this.constructor.getFormatedMeasurement(
                                  measurements.hip[0]
                              )
                            : undefined;
                        result.thigh = measurements.thigh[0]
                            ? this.constructor.getFormatedMeasurement(
                                  measurements.thigh[0]
                              )
                            : undefined;
                        result.waist = measurements.waist[0]
                            ? this.constructor.getFormatedMeasurement(
                                  measurements.waist[0]
                              )
                            : undefined;
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default CurrentMeasurementsConnectorApollo;
