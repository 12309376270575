import React from 'react';
import Layout from 'Layout/Layout';
import { Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import hero from 'assets/images/psychometric/psychometric_start_meals2.jpg';
import phone from 'assets/images/psychometric/psychometric_phone.jpg';
import Slider from 'react-slick';
import arrowLeft from 'assets/images/psychometric/arrow-left.svg';
import social1 from 'assets/images/psychometric/instagram/1/1.jpg';
import social2 from 'assets/images/psychometric/instagram/1/2.jpg';
import social3 from 'assets/images/psychometric/instagram/1/3.jpg';
import social4 from 'assets/images/psychometric/instagram/1/4.jpg';
import social5 from 'assets/images/psychometric/instagram/1/5.jpg';
import social6 from 'assets/images/psychometric/instagram/1/6.jpg';
import social7 from 'assets/images/psychometric/instagram/1/7.jpg';
import social8 from 'assets/images/psychometric/instagram/1/8.jpg';

import social9 from 'assets/images/psychometric/instagram/2/1.jpg';
import social10 from 'assets/images/psychometric/instagram/2/2.jpg';
import social11 from 'assets/images/psychometric/instagram/2/3.jpg';
import social12 from 'assets/images/psychometric/instagram/2/4.jpg';
import social13 from 'assets/images/psychometric/instagram/2/5.jpg';
import social14 from 'assets/images/psychometric/instagram/2/6.jpg';
import social15 from 'assets/images/psychometric/instagram/2/7.jpg';
import social16 from 'assets/images/psychometric/instagram/2/8.jpg';

import aniaDietetyk from 'assets/images/psychometric/ania-dietetyk.png';

import { PATH_PSYCHOMETRIC_TEST } from './paths';

class PsychometricStartPage extends React.Component {
    slider3 = React.createRef();

    handlePrev = () => {
        this.slider3.current.slickPrev();
    };

    handleNext = () => {
        this.slider3.current.slickNext();
    };

    render() {
        const settings3 = {
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            speed: 500,
            rows: 2,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        infinite: true,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: false,
                        speed: 500,
                        rows: 2,
                    },
                },
            ],
        };

        return (
            <Layout page="psychometric-test">
                <header>
                    <Container className="text-center">
                        <h1>Zrób test</h1>
                        <h2 className="mb-4">
                            Dowiedz się, jaka dieta jest dla Ciebie. Dopasuj do
                            Twojej osobowości.
                        </h2>
                        <Button
                            tag={Link}
                            to={PATH_PSYCHOMETRIC_TEST}
                            color="primary"
                        >
                            Rozpocznij test
                        </Button>
                        <img src={hero} className="img-fluid my-5" alt="" />
                    </Container>
                </header>
                <section>
                    <Container>
                        <p>Na przykład…</p>
                        <p>
                            <strong>JENNIFER ANISTON</strong> — w wywiadzie dla
                            magazynu „Bon Appetit” zdradziła, że na śniadanie
                            robi koktajl z owoców. Jak jadła jajecznicę, nagle
                            zaczęła tyć.
                        </p>
                        <p>
                            <strong>JULIA ROBERTS</strong> — odwrotnie! Od
                            koktajli na śniadanie w krótkim czasie tyje.
                            Jajecznica zaś pomaga jej chudnąć.
                        </p>
                        <p>
                            <strong>AKTOR JACEK KAWALEC</strong> — na śniadanie
                            unika i koktajli, i jajek. W trzymaniu chudej figury
                            pomaga mu za to pełnoziarnisty chleb z drobiową
                            wędliną.
                        </p>
                    </Container>
                </section>
                <hr />
                <section>
                    <Container>
                        <img src={phone} className="img-fluid mb-5" alt="" />
                        <h2 className="text-center">
                            Sprawdź, jak powinna wyglądać Twoja dieta. Co musisz
                            jeść, a czego stanowczo unikać.
                        </h2>
                        <p>
                            Widzisz, dietę gwiazd układają tak zwani konsultanci
                            dietetyczni — wysoko opłacani specjaliści od
                            żywienia. Ale jak oni to robią? Skąd wiedzą, co daną
                            osobę tuczy, a co odchudza?
                        </p>
                        <p className="text-center">
                            Wierz lub nie: dowiadują się tego między innymi na
                            podstawie
                            <br />
                            <strong>TESTU PSYCHOMETRYCZNEGO.</strong>
                        </p>
                    </Container>
                </section>
                <section>
                    <Container>
                        <div className="promoQuote">
                            <img src={aniaDietetyk} alt="" />
                            <p>
                                Nazywam się Anna Janiszewska, jestem
                                dietetykiem. Moim największym odkryciem
                                dietetycznym jest fakt, że dieta musi być
                                dopasowana do tego, jacy naprawdę jesteśmy — do
                                naszej osobowości. To tak zwany czynnik
                                psychologiczny
                                <br /> — I JEST ON DECYDUJĄCY…
                                <br />
                                Anna Janiszewska – dietetyk – współtwórczyni
                                Diety OXY
                            </p>
                        </div>
                        <div className="text-center">
                            <Button
                                tag={Link}
                                to={PATH_PSYCHOMETRIC_TEST}
                                color="primary"
                                className="mt-4 mb-5"
                            >
                                Rozpocznij test
                            </Button>
                        </div>
                        <p>
                            <strong>UWAGA!</strong> — dziś test jest darmowy,
                            ale nie gwarantuję, że tak będzie zawsze. Dlatego
                            aby uniknąć rozczarowania, zrób go teraz.
                        </p>
                        <p>
                            Pamiętaj, za podobny test konsultantom dietetycznym
                            znane osoby płacą po kilka tysięcy. Dzisiaj możesz
                            go zrobić za darmo.
                        </p>
                    </Container>
                </section>
                <section>
                    <Container>
                        <h1 className="text-center">
                            ONE JUŻ BEZPŁATNIE SPRAWDZIŁY
                        </h1>
                        <h2 className="text-center">Czas na Ciebie!</h2>

                        <Slider ref={this.slider3} {...settings3}>
                            <img src={social1} alt="" />
                            <img src={social2} alt="" />
                            <img src={social3} alt="" />
                            <img src={social4} alt="" />
                            <img src={social5} alt="" />
                            <img src={social6} alt="" />
                            <img src={social7} alt="" />
                            <img src={social8} alt="" />
                            <img src={social9} alt="" />
                            <img src={social10} alt="" />
                            <img src={social11} alt="" />
                            <img src={social12} alt="" />
                            <img src={social13} alt="" />
                            <img src={social14} alt="" />
                            <img src={social15} alt="" />
                            <img src={social16} alt="" />
                        </Slider>

                        <div className="text-center py-4">
                            <Button
                                id="prev"
                                color="primary mx-2"
                                className="no-arrow"
                                onClick={this.handlePrev}
                            >
                                <img src={arrowLeft} alt="" />
                            </Button>
                            <Button
                                id="next"
                                color="primary mx-2"
                                className="no-arrow"
                                onClick={this.handleNext}
                            >
                                <img
                                    src={arrowLeft}
                                    className="rotate180deg"
                                    alt=""
                                />
                            </Button>
                        </div>

                        <div className="text-center">
                            <Button
                                tag={Link}
                                to={PATH_PSYCHOMETRIC_TEST}
                                color="primary"
                                className="mt-5"
                            >
                                Rozpocznij test
                            </Button>
                        </div>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default PsychometricStartPage;
