import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import { Row, Col } from 'reactstrap';
import convenrsation from 'assets/icons/interventions/conversation.svg';
import eye from 'assets/icons/interventions/eye.svg';
import flag from 'assets/icons/interventions/flag.svg';
import pin from 'assets/icons/interventions/pin.svg';
import { ReactComponent as DoneIcon } from 'assets/icons/interventions/done.svg';
import { formatShortDate } from 'utils/dateFormatter';

import { PATH_SINGLE_INTERVENTION } from './paths';

class InterventionItem extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        date: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        concluded: PropTypes.bool,
        typename: PropTypes.string.isRequired,
    };

    static defaultProps = {
        concluded: undefined,
    };

    renderIcon = icon => {
        switch (icon) {
            case 'quote':
                return <img src={pin} className="icon mr-3" alt="" />;
            case 'question':
                return <img src={convenrsation} className="icon mr-3" alt="" />;
            case 'behavioral':
                return <img src={flag} className="icon mr-3" alt="" />;
            case 'psychological':
                return <img src={eye} className="icon mr-3" alt="" />;

            default:
                return undefined;
        }
    };

    renderDoneIcon = () => {
        if (this.props.typename === 'UserInterventionVisible') {
            if (this.props.concluded) {
                return (
                    <span className="SVGInline done">
                        <DoneIcon />
                    </span>
                );
            }
            return (
                <span className="SVGInline">
                    <DoneIcon />
                </span>
            );
        }

        return null;
    };

    render() {
        return (
            <li className={this.props.typename}>
                <Link
                    to={
                        PATH_SINGLE_INTERVENTION.split(':')[0] + this.props.date
                    }
                >
                    <Row className="align-items-center">
                        <Col xs="3" sm="2" className="border-right text-center">
                            <span className="intervention-date">
                                {formatShortDate(this.props.date)}
                            </span>
                        </Col>

                        <Col xs="7" sm="8">
                            <div className="d-flex align-items-center">
                                {this.renderIcon(this.props.type)}

                                {this.props.t(
                                    `intervention-type/${this.props.type}`
                                )}
                            </div>
                        </Col>
                        <Col xs="2" className="text-center">
                            {this.renderDoneIcon()}
                        </Col>
                    </Row>
                </Link>
            </li>
        );
    }
}

export default withLocale(InterventionItem);
