import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Row,
    Col,
    Button,
    ButtonGroup,
    Form,
    FormGroup,
    Label,
    InputGroup,
} from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';

class MakeDecisionPlaceholder extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="diet">
                <Container>
                    <header>
                        <h1 className="text-center">
                            {this.props.t('make-decision/title')}
                        </h1>
                    </header>
                    <section className="pt-0">
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                <Row>
                                    <Col xs="4" className="text-center summary">
                                        <h3 className="placeholder">
                                            <span className="big-number">
                                                &nbsp;
                                            </span>
                                            &nbsp;
                                            <br />
                                            &nbsp;
                                        </h3>
                                    </Col>
                                    <Col xs="4" className="text-center summary">
                                        <h3 className="placeholder">
                                            <span className="big-number">
                                                &nbsp;
                                            </span>
                                            &nbsp;
                                            <br />
                                            &nbsp;
                                        </h3>
                                    </Col>
                                    <Col xs="4" className="text-center summary">
                                        <h3 className="placeholder">
                                            <span className="big-number">
                                                &nbsp;
                                            </span>
                                            &nbsp;
                                            <br />
                                            &nbsp;
                                        </h3>
                                    </Col>
                                </Row>

                                <br />
                                <Row>
                                    <Col className="text-center">
                                        <ButtonGroup>
                                            <Button color="placeholder" active>
                                                &nbsp;
                                            </Button>
                                            <Button color="placeholder">
                                                &nbsp;
                                            </Button>
                                        </ButtonGroup>

                                        <br />
                                        <br />

                                        <Form onSubmit={this.handleSubmit}>
                                            <p className="text-left placeholder">
                                                <br />
                                                &nbsp;
                                            </p>
                                            <FormGroup>
                                                <Label className="placeholder">
                                                    &nbsp;
                                                </Label>
                                                <InputGroup>
                                                    <div className="input-placeholder placeholder">
                                                        &nbsp;
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                            <Button color="placeholder">
                                                &nbsp;
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>
                </Container>
            </Layout>
        );
    }
}

export default withLocale(MakeDecisionPlaceholder);
