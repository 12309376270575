import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withLocale } from '@dietlabs/components';
import BackLink from 'Layout/BackLink';

import { PATH_HYDRATION } from '../paths';

class HydrationHistoryActions extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <BackLink to={PATH_HYDRATION}>
                {this.props.t('hydration/back-to-hydration')}
            </BackLink>
        );
    }
}

export { HydrationHistoryActions };
export default withLocale(HydrationHistoryActions);
