import React from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    Card,
} from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { ReactComponent as IconClose } from 'assets/icons/icon_close.svg';
import { ReactComponent as Basket } from 'assets/icons/basket.svg';

export class ShoppingCartUpSellProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    }

    addAndToggle(id) {
        this.props.add(id);
        this.toggle();
    }

    render() {
        const product = this.props.product;

        return (
            <React.Fragment>
                <Button
                    color="link"
                    data-test="about-product-button"
                    onClick={this.toggle}
                >
                    {this.props.t('cart/upsell/about')}
                </Button>
                <Modal
                    isOpen={this.state.showModal}
                    toggle={this.toggle}
                    centered
                    size="lg"
                    key={product.id}
                >
                    <ModalBody>
                        <Container>
                            <Card className="product product-horizontal text-center text-md-left">
                                <Row className="align-items-center">
                                    <Col md="2" lg="3">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: product.name,
                                            }}
                                            className="product-name d-md-none"
                                        />
                                        <img
                                            src={product.thumbnailUrl}
                                            className="img-fluid mb-3"
                                            alt=""
                                        />
                                    </Col>
                                    <Col md="6" lg="6">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: product.name,
                                            }}
                                            className="product-name d-none d-md-block"
                                        />
                                        <p
                                            data-test="product-description"
                                            className="product-description mt-2"
                                        >
                                            {product.shortDescription}
                                        </p>
                                    </Col>
                                    <Col md="4" lg="3" className="mt-4 mt-md-0">
                                        <span
                                            data-test="product-description"
                                            className="product-description"
                                        >
                                            {product.lengthLabel}
                                        </span>

                                        {product.price.promo.amount !==
                                        product.price.regular.amount ? (
                                            <React.Fragment>
                                                <span className="product-save">
                                                    {this.props.t(
                                                        'cart/upsell/you-save'
                                                    )}{' '}
                                                    {`${product.diffPrice}`}
                                                </span>
                                                <span
                                                    data-test="product-price"
                                                    className="product-price"
                                                >
                                                    {`${product.price.promo}`}
                                                </span>
                                                <span
                                                    data-test="product-old-price"
                                                    className="product-old-price"
                                                >
                                                    {`${product.price.regular}`}
                                                </span>
                                            </React.Fragment>
                                        ) : (
                                            <span
                                                data-test="product-price"
                                                className="product-price"
                                            >
                                                {`${product.price.regular}`}
                                            </span>
                                        )}

                                        {this.props.productIsInCart(
                                            product.id,
                                            this.props.cartProducts
                                        ) ? (
                                            <Button
                                                color="primary"
                                                data-test="product-added"
                                                className="w-100 mt-4 no-arrow"
                                                disabled
                                            >
                                                <span className="SVGInline pr-1">
                                                    <Basket />
                                                </span>{' '}
                                                {this.props.t(
                                                    'cart/upsell/added'
                                                )}
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                data-test="add-product-button"
                                                className="w-100 mt-4 no-arrow"
                                                onClick={() =>
                                                    this.addAndToggle(
                                                        product.id
                                                    )
                                                }
                                            >
                                                <span className="SVGInline pr-1">
                                                    <Basket />
                                                </span>{' '}
                                                {this.props.t(
                                                    'cart/upsell/add-product'
                                                )}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </Container>
                        <Button
                            color="link"
                            className="close"
                            onClick={() => this.toggle()}
                            data-test="close-button"
                        >
                            <IconClose />
                        </Button>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

ShoppingCartUpSellProductDetails.propTypes = {
    t: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    productIsInCart: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartUpSellProductDetails);
