export default {
    menu: 'Menu',
    'menu/home': 'Strona glówna',
    'menu/diet': 'Dieta',
    'menu/diet-plan': 'Plan diety',
    'menu/shop': 'Sklep',
    'menu/interventions': 'Motywacja',
    'menu/knowledge': 'Oxy365',
    'menu/blog': 'Blog',
    'menu/contact': 'Kontakt',

    'menu/activities': 'Aktywności',
    'menu/shopping-list': 'Lista zakupów',
    'menu/measurements': 'Pomiary',
    'menu/diet/settings': 'Ustawienia diety',
    'menu/faq': 'Pytania',

    'menu/logout': 'Wyloguj się',
    'menu/login': 'Zaloguj się',
    'menu/settings': 'Ustawienia',
    'menu/guides': 'Poradniki',
    'menu/account': 'Konto',
    'menu/psychometric-test': 'Zrób test',

    logout: 'Wyloguj',
    save: 'Zapisz',
    cancel: 'Anuluj',
    ok: 'OK',
    delete: 'Usuń',
    add: 'Dodaj',
    next: 'Dalej',
    prev: 'Wstecz',
    back: 'Wróć',
    print: 'Drukuj',
    'print-header': 'Dieta OXY',
    'print-copyright1':
        'Plan diety przygotowany dla {{email}}. Plan diety chroniony prawami autorskimi.',
    'print-copyright2':
        'Rozpowszechnianie utworu bez zgody tworcy lub osoby posiadajacej prawa autorskie do utworu jest karane na podstawie art. 116 ust. o prawie autorskim i prawach pokrewnych.',
    close: 'zamknij',
    buy: 'Kup',
    'add-field': 'Dodaj pole',
    'data-saved': 'Zapisano',
    'error/message/generic': 'Coś poszło nie tak',
    'error/message/network': 'Wystąpił błąd sieci',
    'error/message/offline': 'Brak połączenia z siecią',
    'error/message/no-account': 'Nie masz jeszcze konta?',
    'error/additional-message/generic':
        'Incydent został zgłoszony, będziemy pracować nad jego rozwiązaniem.',
    'error/additional-message/not-found':
        'Strona o podanym adresie nie istnieje.',
    'error/additional-message/user-not-found':
        'Nie znaleźliśmy twojego konta wśród zarejestrowanych użytkowników.',
    'button/refresh': 'Odśwież',
    'button/try-again': 'Spróbuj ponownie',
    'button/go-back': 'Powrót',
    'button/get-access': 'Uzyskaj dostęp',
    'button/send-email': 'Wyślij e-mail',
    'button/retry': 'Ponów',

    week: 'tydzień',
    week_0: 'tydzień',
    week_1: '{{count}} tygodnie',
    week_2: '{{count}} tygodni',
    week_3: '{{count}} tygodnia',

    month: 'miesiąc',
    month_0: 'miesiąc',
    month_1: '{{count}} miesiące',
    month_2: '{{count}} miesięcy',
    month_3: '{{count}} miesiąca',

    year: 'rok',
    year_0: 'rok',
    year_1: '{{count}} lata',
    year_2: '{{count}} lat',
    year_3: '{{count}} roku',

    'diet-intro/title': 'Przeczytaj zanim zaczniesz',

    'diet-intro/subtitle1':
        'Gratulujemy! [Zrobiłaś|Zrobiłeś] już pierwszy krok i jesteś coraz bliżej swojej wymarzonej sylwetki. [Wyznaczyłaś|Wyznaczyłeś] sobie cel, a my pomożemy Tobie w jego realizacji.',
    'diet-intro/subtitle2':
        'Zbilansowana i dopasowana do Ciebie Dieta OXY, to doskonały wybór i prosty sposób na osiągnięcie sukcesu!',

    'diet-intro/p1/title': 'Jedz regularnie',
    'diet-intro/p1/content':
        'Twój dzień to 5 zbilansowanych posiłków. Śniadanie jedz najpóźniej godzinę po przebudzeniu, kolejne posiłki zjadaj co 2-3 godziny, a kolację zaplanuj nie później niż 2 godziny przed snem!',
    'diet-intro/p1/content2':
        'Nie pomijaj żadnego posiłku - każdy jest ważny w Twojej zdrowej diecie. Pamiętając o tych kilku prostych wskazówkach z łatwością możesz dopasować dietę do rytmu Twojego dnia.',

    'diet-intro/p2/title': 'Uzupełniaj płyny',
    'diet-intro/p2/content':
        'Przy poszczególnych posiłkach w diecie pojawiają się propozycje napojów, które możesz spożyć w ciągu dnia. Należy wypijać łącznie około 1,5–2L płynów dziennie, głównie w postaci wody i herbat ziołowych. Uzupełniaj płyny małymi łykami w przerwach między posiłkami. Pamiętaj o dodatkowej porcji wody w przypadku zwiększonej aktywności fizycznej oraz w czasie upałów.',

    'diet-intro/p3/title': 'Wymieniaj posiłki, których nie lubisz',
    'diet-intro/p3/content':
        'Jeżeli w danym dniu zaproponowany przez nas jadłospis nie będzie Tobie smakował, możesz go wymienić na alternatywny zestaw, który Tobie zaproponujemy.',
    'diet-intro/p3/content2':
        'Wszystkie posiłki, które możesz wymienić są zbilansowane pod kątem kaloryczności oraz zawartości składników odżywczych, co zapewnia zachowanie wszystkich założeń Twojej diety.',

    'diet-intro/p4/title': 'Dopasuj kolejność posiłków od rytmu Twojego dnia!',
    'diet-intro/p4/content':
        'Dzięki temu, że masz dokładnie rozpisany plan diety - możesz z wyprzedzeniem zaplanować sobie posiłki, przygotować je wcześniej i zabrać ze sobą do pracy lub szkoły.',
    'diet-intro/p4/content2':
        'Unikniesz kupowania przypadkowych, często niezdrowych posiłków na mieście. Zaoszczędzisz pieniądze, zyskasz zgrabną sylwetkę i zdrowie. Idealne rozwiązanie! Dopasuj również kolejność posiłków do swojego dnia - możesz na przykład zjeść danie z obiadu w porze przekąski.',

    'diet-intro/p5/title': 'Zamieniaj składniki w swoich posiłkach',
    'diet-intro/p5/content':
        'Jeżeli masz problem z zakupem składnika swojego posiłku, możesz wymienić go na inny, znajdujący się na przygotowanej przez naszego dietetyka liście zamienników. Wszystkie zamienniki mają dokładnie przeliczoną gramaturę oraz składniki odżywcze. Listę zamienników znajdziesz przy każdym produkcie po naciśnięciu tej ikonki: ',
    'diet-intro/p5/content2':
        'Wszystkie zamienniki mają dokładnie przeliczoną gramaturę oraz składniki odżywcze.',

    'diet-intro/p6/title': 'Rób pomiary i kontroluj swoje wyniki',
    'diet-intro/p6/content':
        'Kontroluj swoję postępy w diecie - każdy, nawet najmniejszy sukces w procesie odchudzania motywuje Cię do dalszego działania!',
    'diet-intro/p6/content2':
        'Nasz system pozwala na wygodne zapisywanie Twoich pomiarów. Przy każdym pomiarze możesz dodatkowo zrobić sobie zdjęcie - będziesz zaskoczona tym jak szybko zmienia się Twoje ciało.',

    'diet-intro/p7/title': 'Korzystaj z listy zakupów',
    'diet-intro/p7/content':
        'Możesz generować listę zakupów na wybrane przez ciebie dni w kalendarzu na Twoim koncie - wystarczy, że wybierzesz z niego dni wyznaczające ramy czasowe, na które chcesz zrobić zakupy.',
    'diet-intro/p7/content2':
        'Zdecydowanie ułatwia to planowanie diety i przyspiesza zakupy. Listę możesz mieć przy sobie na swoim telefonie lub możesz ją wydrukować z Twojego komputera.',

    'diet-intro/p8/title': 'Masz dodatkowe pytania?',
    'diet-intro/p8/content': 'Zobacz listę ',
    'diet-intro/p8/content2': 'najczęściej zadawanych pytań',
    'diet-intro/p8/content3':
        '. Jeżeli masz jakiekolwiek wątpliwości dotyczące stosowania diety, skontaktuj się z naszym dietetykiem za pośrednictwem ',
    'diet-intro/p8/content4': 'formularza kontaktowego.',

    'diet-intro/start-diet': 'Rozpocznij dietę',

    share: 'Udostępnij',
    OK: 'OK',
    'share/title': 'Udostępnij swoje postępy',
    'share/description':
        'Udostępnij grafikę na Facebooku i/lub Instagramie, aby pokazać znajomym jak dobrze idzie Ci post!',
    'share/how-to-read': 'Jak czytać status?',
    'share/current-diet-progress': 'dzień postu',
    'share/current-diet-length': 'zaplanowana ilość dni postu',
    'share/diets-count': 'który to Twój post',

    'meal/preparation-time': 'Czas',
    'diet/meal/seasoning/header': 'Przyprawy',
    'diet/meal/optionalIngredients/header': 'Do potrawy możesz dodać również',
    'meal/min': 'min.',
    'meal/how-to-prepare': 'Sposób przygotowania',
    'meal/proteins-short': 'B',
    'meal/fat-short': 'T',
    'meal/carbohydrates-short': 'W',
    'meal/proteins': 'Białka',
    'meal/fat': 'Tłuszcze',
    'meal/carbohydrates': 'Węglowodany',
    'meal/weekday0': 'Niedziela',
    'meal/weekday1': 'Poniedziałek',
    'meal/weekday2': 'Wtorek',
    'meal/weekday3': 'Środa',
    'meal/weekday4': 'Czwartek',
    'meal/weekday5': 'Piątek',
    'meal/weekday6': 'Sobota',
    'meal/weekday0-short': 'Nd.',
    'meal/weekday1-short': 'Pn.',
    'meal/weekday2-short': 'Wt.',
    'meal/weekday3-short': 'Śr.',
    'meal/weekday4-short': 'Cz.',
    'meal/weekday5-short': 'Pt.',
    'meal/weekday6-short': 'Sb.',
    'meal/ingredients': 'Składniki',
    'meal/exchange-meal-button': 'Wymień posiłek',
    'meal/exchange-meal': 'Wymiana posiłku',
    'meal/exchange-dish-button': 'Wymień',
    'meal/exchange-collapse': 'Zwiń',
    'meal/exchange-day-button': 'Wymień dzień',
    'meal/exchange-ingredient': 'Wymień składnik',
    'meal/exchange-for': 'Możesz zamienić na',
    'meal/original-product': 'Oryginalny produkt',
    'meal/original-meal': 'Oryginalny posiłek',
    'meal/original-day': 'Oryginalny jadłospis',
    'meal/eaten-button': 'Zjedzone',
    'meal/summary-title': 'Rozkład kaloryczności',
    'meal/print-button': 'Drukuj',
    'meal/print-period-alert':
        'Wydruk dostępny dla okresu krótszego ni 2 tygodnie.',
    'meal/show-menus-contain': 'Pokaż jadłospisy zawierające:',
    'meal/menus-contain': 'zawiera:',
    'meal/search': 'Szukaj',
    'meal/name-of-dish-or-ingredient': 'nazwa dania lub składnika',
    'meal/there-are-no-replacements': 'Brak odpowiednich zamienników.',
    'meal/supports': 'Wspiera:',
    'next-day-notification': 'Na jutro przygotuj:',
    'meal/copy-day-button': 'Kopiuj na jutro',
    'meal/copy-day-button-done': 'Skopiowany',
    'meal/replacements': 'Zamienniki',
    'meal/current-dayplan': 'Obecny jadłospis',
    'holiday-notification': 'Pokaż <u>specjalny jadłospis</u> dla tego dnia.',
    fast: 'Post',
    'fast/exchange-day/alert':
        'Dana alternatywa postu dostępna jest tylko przez 3 dni, aby przez pozostały czas zachować możliwie zróżnicowany jadłospis umożliwiający odżywianie wewnątrzkomórkowe.',

    'dish/portion/you-should-have-this-dish-last':
        '[Powinnaś|Powinieneś] mieć przygotowaną tą potrawę już wcześniej. Jeśli coś Ci zostało, nie będzie to już uwzględnione w Twoim planie diety.',
    'dish/portion/you-should-have-this-dish':
        '[Powinnaś|Powinieneś] mieć przygotowaną tą potrawę już wcześniej.',

    'dish/portion/rest': 'Reszta nie zostanie użyta w Twoim planie diety.',

    'dish/portion/eat-x-of': 'Zjedz jedną porcję z {{total}}.',
    'dish/portion/eat-x-of_0': 'Zjedz {{fraction}}&nbsp;porcję z {{total}}.',
    'dish/portion/eat-x-of_1': 'Zjedz {{fraction}}&nbsp;porcje z {{total}}.',
    'dish/portion/eat-x-of_2': 'Zjedz {{fraction}}&nbsp;porcji z {{total}}.',
    'dish/portion/eat-x-of_3': 'Zjedz {{fraction}}&nbsp;porcji z {{total}}.',

    'dish/portion/eat-x-portion': 'Zjedz jedną porcję.',
    'dish/portion/eat-x-portion_0': 'Zjedz {{fraction}}&nbsp;porcję.',
    'dish/portion/eat-x-portion_1': 'Zjedz {{fraction}}&nbsp;porcje.',
    'dish/portion/eat-x-portion_2': 'Zjedz {{fraction}}&nbsp;porcji.',
    'dish/portion/eat-x-portion_3': 'Zjedz {{fraction}}&nbsp;porcji.',

    'dish/portion/x-portion-of': ' - jedna porcja z {{total}}',
    'dish/portion/x-portion-of_0': ' - {{fraction}}&nbsp;porcja z {{total}}',
    'dish/portion/x-portion-of_1': ' - {{fraction}}&nbsp;porcje z {{total}}',
    'dish/portion/x-portion-of_2': ' - {{fraction}}&nbsp;porcji z {{total}}',
    'dish/portion/x-portion-of_3': ' - {{fraction}}&nbsp;porcji z {{total}}',

    'recipe-for-x-portion': 'Przepis na jedną porcję',
    'recipe-for-x-portion_0': 'Przepis na {{count}} porcję',
    'recipe-for-x-portion_1': 'Przepis na {{count}} porcje',
    'recipe-for-x-portion_2': 'Przepis na {{count}} porcji',
    'recipe-for-x-portion_3': 'Przepis na {{count}} porcji',

    'dish/portion/dish-used-in-following-days':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na jedną porcję.',
    'dish/portion/dish-used-in-following-days_0':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcję.',
    'dish/portion/dish-used-in-following-days_1':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcje.',
    'dish/portion/dish-used-in-following-days_2':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcji.',
    'dish/portion/dish-used-in-following-days_3':
        'To danie zostanie użyte w kolejnych dniach. Przepis jest na {{count}} porcji.',

    'chef-advice/title': 'Rada Szefa Kuchni Wojtka',

    'shopping-list/title': 'Lista zakupów',
    'shopping-list/export-to-frisco': 'Zapisz jako listę zakupów Frisco.pl',
    'shopping-list/date-range': 'Zakres dat',
    'shopping-list/show-list-button': 'Przygotuj listę zakupów',
    'shopping-list/alert/no-full-covered-by-diet-in-period':
        'Nie masz ułożonej diety na cały zakres dat. Lista zakupów zawiera produkty tylko z dni w których dieta została ułożona.',
    'shopping-list/alert/no-diet-in-period':
        'Brak ułożonej diety w podanym zakresie dat.',
    'shopping-list/bought': 'Kupione',
    'shopping-list/no-products': 'Brak produktów',

    'measurments/title': 'Twoje pomiary',
    'measurments/history': 'Historia',
    'measurments/current-measurments-title': 'Twoje aktualne pomiary',
    'measurments/edit-measurments-title': 'Edytuj pomiary',
    'measurments/add-measurments-button': 'Dodaj pomiary',
    'measurments/delete-measurment-button': 'Usuń aktualny pomiar',
    'measurments/date': 'Data',
    'measurments/alert-no-measurments-in-period':
        'Brak pomiarów danego typu w danym okresie czasu',
    'measurments/param/body-weight': 'Masa ciała',
    'measurments/param/hips': 'Biodra',
    'measurments/param/hip': 'Biodro',
    'measurments/param/waist': 'Talia',
    'measurments/param/thighs': 'Uda',
    'measurments/param/thigh': 'Udo',
    'measurments/param/chest': 'Klatka',
    'measurments/param/arm': 'Ramię',
    'measurments/state/no-measurement': 'brak pomiaru',
    'measurments/state/change': 'zmiana',
    'measurments/state/no-change': 'bez zmian',

    'no-data': 'brak danych',
    'no-change': 'bez zmian',

    'redirect/diet-settings/title': 'Plan diety',
    'redirect/diet-settings/message':
        'Nie masz jeszcze rozpoczętego planu diety.',
    'redirect/shopping-list/message':
        'Rozpocznij dietę, aby zobaczyć listę zakupów.',
    'redirect/diet-settings/button': 'Rozpocznij dietę',

    'diet-settings/title': 'Ustawienia diety',
    'diet-settings/sex': 'Płeć',
    'diet-settings/sex/male': 'Mężczyzna',
    'diet-settings/sex/female': 'Kobieta',
    'diet-settings/name': 'Imię',
    'diet-settings/current-body-weight': 'Aktualna masa ciała:',
    'diet-settings/height': 'Wzrost:',
    'diet-settings/birth-date': 'Data urodzenia:',
    'diet-settings/your-goal': 'Twój cel',
    'diet-settings/goal/slimming': 'Zmniejszenie masy ciała',
    'diet-settings/goal/stabilization': 'Utrzymanie masy ciała',
    'diet-settings/goal/put-on-weight': 'Zwiększenie masy ciała',
    'diet-settings/goal/gain-muscle-mass': 'Budowanie masy mięśniowej',
    'diet-settings/goal-weight': 'Docelowa masa ciała',
    'diet-settings/diet-type/vege': 'Dieta Wege',
    'diet-settings/diet-type/vege/description':
        'Wyklucza mięso, ryby i owoce morza, pszenicę i jej pochodne oraz mleko krowie. Uwzględnia natomiast sery, produkty mleczne fermentowane, jaja i nasiona roślin strączkowych.',
    'diet-settings/activity/question': 'Ile średnio ćwiczysz w tygodniu?',
    'diet-settings/activity/none':
        'ćwiczę sporadycznie lub w ogóle — praca biurowa',
    'diet-settings/activity/low': 'ćwiczę 1-3 godziny tygodniowo',
    'diet-settings/activity/medium':
        'ćwiczę 3-5 godzin w tygodniu (ćwiczenia siłowe)',
    'diet-settings/activity/high':
        'ćwiczę 3-5 godzin tygodniowo (ćwiczenia aerobowe, biegi, spining, itp.)',
    'diet-settings/final/text':
        'Czym kierować się przy rozpoczęciu lub zmianie planu diety?',
    'diet-settings/final/list/1':
        'zaplanuj czas na dokonanie zakupów potrzebnych do diety',
    'diet-settings/final/list/2':
        'jeżeli planujesz wyjazd, który uniemożliwi Tobie zastosowanie się do jadłospisu, przełóż start diety na dogodniejszy termin.',
    'diet-settings/final/list/3':
        'nie odwlekaj terminu rozpoczęcia diety — skoro zrobiłeś już pierwszy krok, idź śmiało dalej',
    'diet-settings/final/list/4': 'masz 15 dni na rozpoczęcie diety',
    'diet-settings/final/start-date-question':
        'Od kiedy chcesz rozpocząć dietę lub dokonać w niej zmian?',
    'diet-settings/welcome-user': 'Witaj',
    'diet-settings/welcome-message':
        'Odpowiedz na kilka pytań, które pomogą nam przygotować plan diety odpowiedni dla Ciebie.',
    'diet-settings/cta': 'Rozpocznij',
    'diet-settings/acceptance/title': 'Stosowanie diety',
    'diet-settings/acceptance/msg1':
        'Diety nie należy stosować w przypadku występowania lęku przed ograniczeniem jedzenia, stosowania hormonów sterydowych i cytostatyków, a także niewydolności nadnerczy, ciężkiej depresji, stanów po przeszczepach narządów, odbywania chemioterapii, krańcowej marskości wątroby, dializy nerek, nadczynności tarczycy, cukrzycy typu I, ostrej porfirii, ciąży, osób nieletnich oraz podczas karmienia piersią.',
    'diet-settings/acceptance/msg2':
        'W przypadku zażywania leków, cukrzycy typu 2, choroby nerek, choroby wątroby, choroby tarczycy, wrzodów żołądka i dwunastnicy, kamieni nerkowych, podwyższonych wartości wyników laboratoryjnych: prób wątrobowych (ALAT, AspAT), kreatyniny, niedoborów pokarmowych, niedokrwistości, chorób dietozależnych oraz innych chorób przewlekłych i ostrych dietę należy stosować za zgodą i pod nadzorem lekarza.',
    'diet-settings/acceptance/msg3':
        'Zalecenia zawarte w aplikacji mają wyłącznie charakter informacyjny i nie stanowią porady lekarskiej, a stosowanie ich w praktyce nie zastąpi indywidualnej wizyty lekarskiej z lekarzem specjalistą lub też specjalistą-dietetykiem.',
    'diet-settings/acceptance/acceptance':
        'Oświadczam, że zapoznałem się z przeciwwskazaniami i nie choruję na wyżej wymienione jednostki chorobowe lub będę stosować dietę za zgodą i pod nadzorem lekarza',
    'diet-settings/acceptance/confirm': 'Potwierdzam',
    'diet-settings/diet-programme/label': 'Wybierz zakres diety:',
    'diet-settings/diet-programme/full/title': 'Pełny program żywieniowy',
    'diet-settings/diet-programme/full/description':
        'Obejmuje: Przygotowanie do postu, Post, Wychodzenie z postu oraz Zdrowe odżywianie',
    'diet-settings/diet-programme/fast-preparation/title':
        'Przygotowanie do postu',
    'diet-settings/diet-programme/fast-preparation/description':
        'Pojedynczy etap',
    'diet-settings/diet-programme/fast/title': 'Post',
    'diet-settings/diet-programme/fast/description': 'Pojedynczy etap',
    'diet-settings/diet-programme/fast-exit/title': 'Wychodzenie z postu',
    'diet-settings/diet-programme/fast-exit/description': 'Pojedynczy etap',
    'diet-settings/diet-programme/healthy-eating/title': 'Zdrowe odżywianie',
    'diet-settings/diet-programme/healthy-eating/description':
        'Pojedynczy etap',
    'diet-settings/fast-days/title': 'Ustaw czas trwania etapu:',
    'diet-settings/fast-days/fast-preparation/title': 'Przygotowanie do postu',
    'diet-settings/fast-days/fast-preparation/info':
        'Aby dobrze przygotować się do postu, okres ten powinien trwać minimum 3 dni',
    'diet-settings/fast-days/fast/title': 'Post',
    'diet-settings/fast-days/fast/info':
        'Sugerowany okres postu to minimum 7 dni',
    'diet-settings/fast-days/days': 'dni',
    'diet-settings/diet-type/label': 'Jaki rodzaj diety chcesz stosować?',
    'diet-settings/diet-type/label/stage': 'Zdrowe odżywianie',
    'diet-settings/diet-type/mediterranean-diet/title':
        'Dieta śródziemnomorska',
    'diet-settings/diet-type/mediterranean-diet/description':
        'Oparta na warzywach i owocach, zawierająca produkty zbożowe pełnoziarniste i zdrowe tłuszcze, ograniczająca spożycie mięsa i produktów mlecznych.',
    'diet-settings/diet-type/elimination-diet/title': 'Dieta eliminacyjna',
    'diet-settings/diet-type/elimination-diet/description':
        'Dieta z wykluczeniem glutenu, mleka i nabiału oraz jaj.',
    'diet-settings/fast-day-of-the-week/label':
        'Wybierz dzień tygodnia, w którym chcesz stosować 1-dniowy post na etapie:',
    'diet-settings/fast-day-of-the-week/mon': 'Pn',
    'diet-settings/fast-day-of-the-week/tue': 'Wt',
    'diet-settings/fast-day-of-the-week/wed': 'Śr',
    'diet-settings/fast-day-of-the-week/thur': 'Czw',
    'diet-settings/fast-day-of-the-week/fri': 'Pt',
    'diet-settings/fast-day-of-the-week/sat': 'Sb',
    'diet-settings/fast-day-of-the-week/sun': 'Nd',
    'diet-settings/fast-day-of-the-week/info':
        'Wybór możesz później zmienić w ustawieniach.',
    'diet-settings/sex/label': 'Twoja płeć:',
    'diet-settings/goal/label': 'Cel stosowania diety',
    'diet-settings/work-type/label':
        'Jak określisz poziom swojego ruchu w ciągu dnia? (nie dotyczy treningów)',
    'diet-settings/work-type/low/title': 'Mało',
    'diet-settings/work-type/low/description':
        'praca w domu, podróżowanie wyłącznie samochodem',
    'diet-settings/work-type/medium/title': 'Średnio',
    'diet-settings/work-type/medium/description':
        'praca siedząca, różne środki transportu',
    'diet-settings/work-type/high/title': 'Dużo',
    'diet-settings/work-type/high/description':
        'praca fizyczna, więcej chodzę, niż jeżdżę',
    'diet-settings/activity-level/label': 'Przez ile dni w tygodniu ćwiczysz?',
    'diet-settings/activity-level/none':
        'Ćwiczę rzadziej, niż 1 dzień w tygodniu',
    'diet-settings/activity-level/low': 'Ćwiczę 1-3 dni w tygodniu',
    'diet-settings/activity-level/medium': 'Ćwiczę 4-5 dni w tygodniu',
    'diet-settings/activity-level/high': 'Ćwiczę 6-7 dni w tygodniu',
    'diet-settings/finish':
        'Dziękujemy. Nasi dietetycy układają Twój osobisty plan diety.',
    'diet-settings/diet-id/label': 'Rodzaj diety',
    'diet-settings/diet-type/oxy-simple/title': 'Dieta OXY Simple',
    'diet-settings/diet-type/oxy-protein/title': 'Dieta OXY Białkowa',
    'diet-settings/diet-id/oxy-simple/description':
        'Wyjątkowo szybki i uproszczony jadłospis bazujący na ogólnodostępnych produktach oraz bardzo prostych w przygotowaniu przepisach. 4 posiłki dziennie, maksymalnie 5 składników na posiłek, codzienne gotowanie zajmuje mniej niż 20 minut.',
    'diet-settings/diet-id/oxy-protein/description':
        'Złożona jest z czterech tygodniowych faz: Detox, Fat Burning, Step i Balance. Dla przyspieszenia metabolizmu, na poszczególnych etapach diety podwyższona jest zawartość białka. Jadłospis obejmuje 5 posiłków dziennie.',
    'diet-settings/diet-type/oxy-simple/description':
        'Białko pochodzi z mięsa, ryb, nabiału, jaj, produktów roślinnych.',
    'diet-settings/diet-type/oxy-simple-vege/title': 'Dieta OXY Simple Vege',
    'diet-settings/diet-type/oxy-simple-vege/description':
        'Wyklucza mięso. Białko pochodzi z nabiału, jaj, produktów roślinnych.',
    'diet-settings/diet-type/oxy/title': 'Dieta OXY',
    'diet-settings/diet-type/oxy/description':
        'Białko pochodzi z mięsa, ryb, nabiału, jaj, produktów roślinnych.',
    'diet-settings/diet-type/oxy-vege/title': 'Dieta OXY Vege',
    'diet-settings/diet-type/oxy-vege/description':
        'Wyklucza mięso. Białko pochodzi z nabiału, jaj, produktów roślinnych.',
    'diet-settings/diet-type/oxy-vege-fish/title': 'Dieta OXY Vege + Fish',
    'diet-settings/diet-type/oxy-vege-fish/description':
        'Uwzględnia ryby i owoce morza. Białko pochodzi również z nabiału, jaj, produktów roślinnych.',

    'diet-settings/diet-type/fast-and-traditional/title':
        'Szybki i smaki tradycyjne',
    'diet-settings/diet-type/fast-and-traditional/description':
        'posiłki są szybkie i proste, przygotujesz je ze znanych, ogólnodostępnych składników, które kupisz w swoich ulubionych sklepach',

    'diet-settings/diet-type/fast-and-mixed/title': 'Szybki i smaki świata',
    'diet-settings/diet-type/fast-and-mixed/description':
        'posiłki przygotujesz szybko, propozycje dań są bardziej oryginalne i urozmaicone, pojawia się więcej nowych smaków z różnych stron świata',

    'diet-settings/diet-type/engaging-and-traditional/title':
        'Angażujący i smaki tradycyjne',
    'diet-settings/diet-type/engaging-and-traditional/description':
        'posiłki bazują na znanych i sprawdzonych składnikach, ale wymagają dłuższego czasu przygotowania',

    'diet-settings/diet-type/engaging-and-mixed/title':
        'Angażujący i smaki świata',
    'diet-settings/diet-type/engaging-and-mixed/description':
        'posiłki uwzględniające bardziej wyszukane i oryginalne posiłki, na ich przygotowanie potrzebujesz trochę więcej czasu',

    'settings/title': 'Ustawienia',
    'settings/profile': 'Profil użytkownika',
    'settings/email': 'Adres e-mail',
    'settings/metric-system': 'System metryczny',
    'settings/si': 'System SI (kg, cm)',
    'settings/imperial': 'System imperialny (cale, funty)',
    'settings/notifications': 'Powiadomienia',
    'settings/agreements/diet-course': 'Dotyczące przebiegu diety',
    'settings/agreements/articles': 'Motywacja i artykuły',
    'settings/agreements/newsletter': 'Newsletter',
    'settings/agreements/newsletter-info':
        'Wypisanie się z newslettera może zająć do 2 godzin.',
    'settings/change-password': 'Zmień hasło',
    'settings/change-password/current-password': 'Obecne hasło',
    'settings/change-password/new-password': 'Nowe hasło',
    'settings/change-password/success': 'Hasło zostało zmienione',
    'settings/products/access/end-date {date}': 'Dostęp opłacony do {date}.',
    'settings/products/access/past-end-date {date}': 'Dostęp wygasł {date}.',
    'settings/products/access/from-subscription':
        'Dostęp na podstawie subskrypcji.',
    'settings/products/access/lifetime': 'Dostęp dożywotni.',
    'settings/products/access/none': 'Brak.',
    'settings/products/diet/heading': 'Dieta',
    'settings/products/heading': 'Aktywne produkty',
    'settings/subscriptions/cancellation-instructions': 'Instrukcja anulowania',
    'settings/subscriptions/heading': 'Aktywne subskrypcje',
    'settings/subscriptions/diet+video-workouts/heading': 'Dieta i treningi',
    'settings/subscriptions/diet/heading': 'Dieta',
    'settings/subscriptions/none': 'Brak.',
    'settings/subscriptions/renewal-date {date}':
        'Następne odnowienie: {date}.',
    'settings/subscriptions/renewal-date/in-past':
        'Odnowienie w trakcie przetwarzania.',
    'settings/subscriptions/type/diets': 'Dostęp do diety.',
    'settings/hydration': 'Nawodnienie',
    'settings/hydration/daily-goal': 'Dzienny cel',
    'settings/hydration/success': 'Cel zmieniony',
    'settings/hydration/notifications': 'Przypomnienia o piciu wody',
    'settings/hydration/notifications/hour-interval': 'Co ile godzin',
    'settings/hydration/notifications/from-hour': 'Od godziny',
    'settings/hydration/notifications/to-hour': 'Do godziny',
    'settings/hydration/notifications/use-sound': 'Z dźwiękiem',
    'settings/remove-account': 'Usuwanie konta',

    'remove-account/title': 'Usuwanie konta',
    'remove-account/app-store/disclaimer':
        '<b>Uwaga! Usunięcie konta nie powoduje anulowania subskrypcji.</b> Aktywnymi subskrypcjami możesz zarządzać w ustawieniach konta Apple ID na swoim iPhonie.',
    'remove-account/google-play/disclaimer':
        '<b>Uwaga! Usunięcie konta nie powoduje anulowania subskrypcji.</b> Aktywnymi subskrypcjami możesz zarządzać w ustawieniach aplikacji Google Play.',
    'remove-account/email-confirm-info':
        'Proces usuwania konta wymaga weryfikacji Twojego konta. W tym celu wyślemy do Ciebie e-mail na adres:  {{userEmail}}',
    'remove-account/message-sent': 'Wiadomość wysłana',
    'remove-account/link-expire-info': 'Link wygaśnie po 24 godzinach.',
    'remove-account/check-your-mailbox':
        'Sprawdź swoją skrzynkę e-mail. Kliknij link w wiadomości, którą do Ciebie wysłaliśmy. <br /> <b>Twoje konto nie zostanie usunięte, dopóki tego nie zrobisz.</b>',
    'remove-account/confirmation':
        'Potwierdzam, chcę usunąć swoje konto z aplikacji.',
    'remove-account/info-title': 'Po usunięciu konta:',
    'remove-account/info_1':
        'nie zalogujesz się na swoje konto, przez co stracisz dostęp do aplikacji',
    'remove-account/info_2':
        'utracisz wszystkie swoje postepy, historię, nagrody itd. dotyczączące treningów, diety i pozostałych aktywności w aplikacji',
    'remove-account/info_3':
        'dane dotyczące twojego konta zostaną bezpowrotnie usunięte',
    'remove-account/error': 'Wystąpił błąd',
    'remove-account/try-in-moment': 'Spróbuj ponownie za chwilę.',
    'remove-account/link-expired': 'Ważność linka wygasła.',
    'remove-account/link-expired-info':
        'Ponów w aplikacji proces usuwania konta, aby otrzymać nową wiadomość e-mail.',
    'remove-account/success': 'Twoje konto zostało usunięte',
    'remove-account/success-info':
        'Za moment zostaniesz automatycznie wylogowany z aplikacji.',

    'footer/oxy365': 'Blog Dieta OXY',
    'footer/service-access': 'Dostęp do usługi i płatności',
    'footer/tos': 'Regulamin',
    'footer/privacy-policy': 'Polityka prywatności',
    'footer/contact': 'Kontakt',
    'footer/faq': 'Pytania i odpowiedzi',
    'footer/secure-payments': 'Bezpieczne płatności zapewnia',
    'footer/follow-us': 'Śledź nas na',
    'button/buy-diet': 'Kup dostęp do diety',
    'button/buy-subscribtion': 'Kup subskrypcję',
    'button/buy': 'Kup',
    'button/remove-account': 'Usuń konto',

    'error/unauthorized/subscription/expired/title':
        'Twoja subskrypcja wygasła\n{{accessTo}}',
    'error/unauthorized/subscription/expired/content':
        'Kontynuuj swoją dietę korzystając z oferty dla stałych klientów.',
    'error/unauthorized/subscription/never-had/title':
        'Rozpocznij swoją dietę już teraz!',
    'error/unauthorized/subscription/never-had/content':
        'Tylko jedna decyzja dzieli Cię od rozpoczęcia Diety OXY. Nie trać czasu, gdyż cel jest w zasięgu Twojej ręki!',
    'error/unauthorized/subscription/refresh': 'Sprawdź ponownie',
    'error/unauthorized/subscription/restore-purchuases':
        'Przypisz zakupioną subskrypcję',

    'info-pages/goal-reached/title': 'Brawo! [Osiągnęłaś|Osiągnąłeś] swój cel',
    'info-pages/goal-reached/content':
        'Twój cel został osiągnięty! Czas na decyzję, co chcesz zrobić dalej.',
    'info-pages/goal-reached/button': 'Dostosuj plan diety',

    'info-pages/goal-lost/title': 'Twój cel został utracony',
    'info-pages/goal-lost/content':
        'Aby osiągnąć założony cel, przejdź do ustawień i dopasuj odpowiednio swój plan diety. ',
    'info-pages/goal-lost/button': 'Zmień plan diety',

    'info-pages/current-weight-needed/title': 'Dodaj aktualny pomiar',
    'info-pages/current-weight-needed/content':
        'W celu przygotowania dalszego planu diety musisz dodać aktualny pomiar masy ciała.',
    'info-pages/current-weight-needed/button': 'Dodaj pomiary',

    'info-pages/diet-available-in-future/title':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_0':
        'Twoja dieta będzie dostępna za {{count}} dzień',
    'info-pages/diet-available-in-future/title_1':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_2':
        'Twoja dieta będzie dostępna za {{count}} dni',
    'info-pages/diet-available-in-future/title_3':
        'Twoja dieta będzie dostępna za {{count}} dnia',
    'info-pages/diet-available-in-future/content':
        'Nowy plan diety może być przygotowany najwcześniej na 7 dni przed rozpoczęciem. Pamiętaj, aby w tym czasie, dokonać aktualnego pomiaru masy ciała.',

    'info-pages/diet-outside-access/title':
        'Twoja subskrypcja kończy się za jeden dzień, {{date}}',
    'info-pages/diet-outside-access/title_0':
        'Twoja subskrypcja kończy się za {{count}} dzień, {{date}}',
    'info-pages/diet-outside-access/title_1':
        'Twoja subskrypcja kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/title_2':
        'Twoja subskrypcja kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/title_3':
        'Twoja subskrypcja kończy się za {{count}} dnia, {{date}}',
    'info-pages/diet-outside-access/unknown-number': 'nie wiadomo ile',

    'info-pages/diet-outside-access/web-title':
        'Twój abonament kończy się za jeden dzień, {{date}}',
    'info-pages/diet-outside-access/web-title_0':
        'Twój abonament kończy się za {{count}} dzień, {{date}}',
    'info-pages/diet-outside-access/web-title_1':
        'Twój abonament kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/web-title_2':
        'Twój abonament kończy się za {{count}} dni, {{date}}',
    'info-pages/diet-outside-access/web-title_3':
        'Twój abonament kończy się za {{count}} dnia, {{date}}',

    'info-pages/diet-completed/now':
        '[Zakończyłaś|Zakończyłeś] {{phase}}. Wybierz, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future':
        '{{phase}} kończy się za {{count}} dzień. Już teraz możesz wybrać, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future_0':
        '{{phase}} kończy się za {{count}} dzień. Już teraz możesz wybrać, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future_1':
        '{{phase}} kończy się za {{count}} dni. Już teraz możesz wybrać, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future_2':
        '{{phase}} kończy się za {{count}} dni. Już teraz możesz wybrać, co chcesz robić dalej.',
    'info-pages/diet-completed/in-future_3':
        '{{phase}} kończy się za {{count}} dnia. Już teraz możesz wybrać, co chcesz robić dalej.',

    'info-pages/after-long-break':
        'Dawno Cię z nami nie było. [Zakończyłaś|Zakończyłeś] {{phase}}. Wybierz, co chcesz robić dalej.',

    'contact/title': 'Kontakt',
    'contact/alert':
        'Zanim się z nami skontaktujesz, upewnij się, że odpowiedź na Twoje pytanie nie znajduje się w FAQ.',
    'contact/faq': 'Najczęściej zadawane pytania',
    'contact/contact-form': 'Formularz kontaktowy',
    'contact/name-surname': 'Imię i nazwisko',
    'contact/email-address': 'Twój adres e-mail',
    'contact/topic': 'Temat wiadomości',
    'contact/topic/topic1': 'Problem z zakupem planu diety',
    'contact/topic/topic2': 'Pytanie do dietetyka',
    'contact/topic/topic4': 'Problem z dostępem do serwisu',
    'contact/topic/topic5': 'Plan diety - reklamacje i zwroty',
    'contact/topic/topic6': 'Sklep internetowy - reklamacje i zwroty',
    'contact/topic/topic7': 'Współpraca',
    'contact/message': 'Wiadomość',
    'contact/send-message': 'Wyślij wiadomość',
    'contact/send-success': 'Wiadomość została wysłana. Dziękujemy.',

    'knowledge/title': 'Oxy365',
    'knowledge/show': 'Pokaż:',
    'knowledge/all': 'Wszystko',
    'knowledge/chefs-advice': 'Rada Szefa Kuchni',
    'knowledge/diet': 'Dieta',
    'knowledge/excercise': 'Ćwiczenia',
    'knowledge/motivation': 'Motywacja',
    'knowledge/psychodietetics': 'Psychodietetyka',
    'knowledge/tips': 'Porady',
    'knowledge/back-to-article-list': 'Powrót do listy artykułów',
    'knowledge/no-access':
        'Pełna treść artykułu dostępna tylko dla użytkowników z aktywnym planem diety.',
    'knowledge/author': 'Autor',
    'knowledge/about-author-male': 'O autorze',
    'knowledge/about-author-female': 'O autorce',
    'me/account/activate/message': 'Twoje konto zostało aktywowane.',
    'knowledge/load-more': 'Załaduj więcej',

    'make-decision/title': 'Podejmij decyzję',
    'make-decision/start': 'Start',
    'make-decision/now': 'Obecnie',
    'make-decision/goal': 'Cel',
    'make-decision/left': 'Zostało',
    'make-decision/put-on-weight': 'Przytycie',
    'make-decision/gain-muscle-mass': 'Budowanie mięśni',
    'make-decision/weight-loose': 'Odchudzanie',
    'make-decision/stabilization': 'Stabilizacja',
    'make-decision/continue-slimming':
        'Jeśli zależy Ci na dalszej utracie kilogramów, wpisz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-slimming-to-stabilization':
        'Udało Ci się osiągnąć docelową masę ciała. Żeby utrzymać te efekty, wybierz datę rozpoczęcia etapu stabilizującego.',
    'make-decision/continue-stabilization':
        'Jeżeli pomimo zmiany masy ciała nadal chcesz stosować dietę stabilizującą, kliknij w poniższy przycisk. Pamiętaj że wybranie tej opcji spowoduje, że ostatnia wartość dodanego pomiaru masy ciała zostanie ustawiona, jako Twoja obecna masa docelowa.',
    'make-decision/change-diet-mode-from-stabilization-to-slimming':
        'Ponieważ Twoja masa ciała wzrosła, rekomendujemy przejście do diety odchudzającej. Wpisz nową masę docelową oraz wybierz termin rozpoczęcia diety.',
    'make-decision/change-diet-mode-from-stabilization-to-put-on-weight':
        'Ponieważ Twoja masa ciała zmalała, rekomendujemy przejście do diety na przytycie. Wpisz nową masę docelową oraz wybierz termin rozpoczęcia diety.',
    'make-decision/continue-put-on-weight':
        'Jeśli chcesz kontynuować dietę na przytycie wybierz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-put-on-weight-to-stabilization':
        'Udało Ci się osiągnąć docelową masę ciała. Żeby utrzymać te efekty, wybierz datę rozpoczęcia etapu stabilizującego. ',
    'make-decision/continue-gain-muscle-mass':
        'Jeśli chcesz kontynuować budowanie masy mięśniowej wybierz nową docelową masę ciała.',
    'make-decision/change-diet-mode-from-gain-muscle-mass-to-stabilization':
        'Udało Ci się osiągnąć docelową masę ciała. Żeby utrzymać te efekty, wybierz datę rozpoczęcia etapu stabilizującego. ',

    'route-title/default': 'Dieta OXY',
    'route-title/day-plan': 'Dieta',
    'route-title/diet-intro': 'Wstęp',
    'route-title/day-plan-with-date': '',
    'route-title/diet-will-be-available-in-future':
        'Dieta będzie dostępna w przyszłości',
    'route-title/current-weight-needed': 'Dodaj aktualny pomiar',
    'route-title/goal-reached': 'Cel osiągnięty',
    'route-title/goal-lost': 'Cel utracony',
    'route-title/make-decision': 'Podejmij decyzję',
    'route-title/shopping-list': 'Lista zakupów',
    'route-title/measurements': 'Pomiary',
    'route-title/measurements-add': 'Dodaj pomiary',
    'route-title/measurements-edit': 'Edytuj pomiary',
    'route-title/diet-settings': 'Ustawienia diety',
    'route-title/settings': 'Ustawienia',
    'route-title/login-by-token': 'Login by Token',
    'route-title/logout': 'Logout',
    'route-title/error-404': 'Błąd 404',
    'route-title/unauthorized-exception': 'Brak dostępu',
    'route-title/content': '',
    'route-title/contact': 'Kontakt',
    'route-title/knowledge-category': 'Wiedza',
    'route-title/knowledge': 'Wiedza',
    'route-title/knowledge-article': '',
    'route-title/quiz': 'Quiz',
    'route-title/diet-outside-access': 'Brak dostępu',
    'route-title/block-cookies': '',
    'route-title/error-network': 'Wystąpił błąd sieci!',
    'route-title/activities': 'Moje aktywności',
    'route-title/activities-index': 'Aktywności',
    'route-title/activities-add': 'Dodaj aktywność',
    'route-title/activities-edit': 'Edytuj aktywność',
    'route-title/promo-code': 'Kupon rabatowy',
    'route-title/unsubscribe': 'Przykro nam, że odchodzisz',
    'route-title/error-application': 'Błąd aplikacji',
    'route-title/update': 'Zaktualizuj aplikację',
    'mobile-title/knowledge': 'Wiedza',
    'route-title/guides': 'Poradniki',
    'route-title/psychometric': 'Test psychometryczny',

    'alert/periodic-measurements':
        'Przypominamy o <b>konieczności zważenia się</b>, aby śledzić postępy w realizacji celu',
    'alert/periodic-measurements-1':
        'Okresowe pomiary masy ciała są konieczne, aby śledzić postępy w realizacji celu.',
    'alert/periodic-measurements-2':
        'Przypominamy o regularnym uzupełnianiu swojej aktualnej masy ciała. Najlepiej zważ się jeszcze dziś!',
    'alert/holiday-notification-chef-title': 'Szef Kuchni Wojtek poleca',
    'alert/holiday-notification-chef':
        'Wypróbuj jadłospis specjalny przygotowany przez Szefa Kuchni Wojtka.',
    'alert/holiday-notification': 'Pokaż jadłospis specjalny dla tego dnia',
    'alert/holiday-notification/button': 'Pokaż jadłospis',

    'auth/confirm/header': 'Dziękujemy',
    'auth/confirm/content':
        'Twoje konto zostało aktywowane. Możesz zacząć korzystać z aplikacji mobilnej Dieta OXY.',

    'auth/confirm/mobileApps':
        'Nie masz aplikacji mobilnej? Ściągnij ją teraz!',

    'auth/login/title': 'Zaloguj się',
    'auth/login/with-google': 'Kontynuuj używając konta Google',
    'auth/login/with-facebook': 'Kontynuuj używając konta Facebook',
    'auth/login/with-apple': 'Kontynuuj używając konta Apple',
    'auth/login/user-exists': 'Konto już istnieje. Użyj funkcji logowania.',
    'auth/login/user-not-exists':
        'Konto nie istnieje. Użyj funkcji rejestracji.',
    'auth/login/agreement':
        'Logowanie oznacza akceptację aktualnego regulaminu serwisu. Ostatnich zmian w regulaminie dokonano 20 grudnia 2019.',
    'auth/login/back-link-text': 'Powrót do logowania',
    'auth/login/menu-link-text': 'Zaloguj się',
    'auth/login/error-message': 'Logowanie nie powiodło się.',
    'auth/login/social-media-login-header':
        'Logowanie przez serwisy społecznościowe',
    'auth/login/social-media-set-up':
        'Twoje konto jest powiązane z serwisem społecznościowym.',

    'auth/remind-password/title': 'Odzyskaj dostęp',
    'auth/remind-password/description':
        'Wpisz e-mail, aby otrzymać wiadomość z instrukcją do zmiany hasła.',
    'auth/remind-password/link-text': 'Nie pamiętam swojego hasła',
    'auth/remind-password/error-message':
        'Nie znaleziono użytkownika o podanym adresie e-mail.',
    'auth/remind-password/success-message':
        'E-mail z instrukcją do zmiany hasła został wysłany.',

    'auth/reset-password/title': 'Ustaw nowe hasło',
    'auth/reset-password/token-is-not-valid-error-message':
        'Twój link do ustawiania hasła wygasł, jeśli nadal chcesz go użyć, skorzystaj ponownie z opcji "Nie pamiętam swojego hasła"',
    'auth/reset-password/error-message':
        'Hasło powinno mieć co najmniej 4 znaki.',
    'auth/reset-password/success-message':
        'Hasło do Twojego konta zostało ustawione.',

    'auth/password-is-not-set':
        'Nie masz jeszcze ustawionego hasła. Kliknij w przycisk poniżej aby ustawić hasło.',
    'auth/set-password': 'Ustaw hasło',
    'auth/set-password/title': 'Ustaw hasło',

    email: 'Adres email',
    password: 'Hasło',
    'password-to-login': 'Hasło do logowania',
    'new-password': 'Nowe hasło',
    name: 'Imię',

    'form/send': 'Wyślij',
    'validation/required': 'Pole jest wymagane.',
    'validation/date-format': 'Format daty jest niepoprawny.',
    'validation/email-format': 'Nieprawidłowy adres email.',

    monday: 'Poniedziałek',
    tuesday: 'Wtorek',
    wednesday: 'Środa',
    thursday: 'Czwartek',
    friday: 'Piątek',
    saturday: 'Sobota',
    sunday: 'Niedziela',

    'promo-code/page-title': 'Wpisz kupon rabatowy',
    'promo-code/input-label': 'Kupon rabatowy',
    'promo-code/missing-value': 'Wpisz kupon rabatowy',
    'promo-code/invalid-value': 'Niepoprawny kupon',
    'promo-code/submit': 'Weryfikuj kupon',
    'promo-code/not-exists': 'Kod promocyjny jest nie prawidłowy',
    'promo-code/has-been-activated': 'Kod promocyjny został aktywowany',

    'Something went wrong': 'Coś poszło nie tak',
    'Incident has been reported and we will work to solve it':
        'Incydent został zgłoszony, będziemy pracować nad jego rozwiązaniem.',
    'Go back': 'Wróć',
    'Try again': 'Spróbuj ponownie',

    'diet-faq/title': 'Pytania i odpowiedzi',

    'account/activate/title': 'Aktywacja konta',
    'account/activate/error': 'Aktywacja konta nie powiodła się.',

    'coupon/title': 'Aktywacja',
    'coupon/continue': 'Kontynuuj z kontem:',
    'coupon/register-user/title': 'Stwórz konto',
    'coupon/access-code': 'Kod dostępu',
    'coupon/login/title': 'Zaloguj się',
    'coupon/activate/title': 'Podaj swój kod dostępu do serwisu',
    'coupon/activated-successfully': 'Kupon został aktywowany',
    'coupon/activate-card/title': 'Aktywuj kod dostępu',
    'coupon/card-instruction': 'Aby skorzystać z karty dostępu:',
    'coupon/card-instruction/1': 'wypełnij wszystkie pola formularza',
    'coupon/card-instruction/2': 'przepisz kod ze swojej karty',
    'coupon/card-instruction/3':
        'jeśli wszystkie dane są poprawne, kliknij w przycisk.',
    'coupon/send': 'Aktywuj dostęp',

    'agreements/terms-and-conditions/text': 'Akceptuję ',
    'agreements/terms-and-conditions/text2': 'Regulamin',
    'agreements/terms-and-conditions/text3': ' oraz ',
    'agreements/terms-and-conditions/text4': 'Politykę prywatności',
    'agreements/terms-and-conditions/text5':
        ' i wyrażam zgodę na przetwarzanie moich danych osobowych.',
    'agreements/processing-personal-data/text':
        'Wyrażam zgodę na przetwarzanie moich danych osobowych',
    'agreements/processing-personal-data/text-more':
        'przez DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako administratora danych osobowych, w celu realizacji usług dostępnych w serwisie oraz w celach marketingowych, w tym na otrzymywanie na podany adres e-mail informacji handlowej wysłanej przez DietLabs sp. z. o.o. sp. k. w imieniu własnym lub na zlecenie jej partnerów biznesowych. Masz prawo wglądu do Twoich danych oraz ich poprawienia lub usunięcia.',

    'newsletter/unsubscribe/title': 'Przykro nam, że odchodzisz',
    'newsletter/unsubscribe/success':
        'Adres email został wypisany z newslettera.',
    'newsletter/unsubscribe/send': 'Wypisz mnie',
    'read-more': 'więcej',

    'cart/title': 'Koszyk',
    'cart/products': 'Produkty',
    'cart/payment-method': 'Forma płatności',
    'cart/free-delivery-info':
        'Dodaj produkty o łącznej wartości {{ price }}, aby mieć darmową wysyłkę.',
    'cart/delivery/heading': 'Adres dostawy',
    'cart/delivery/name': 'Imię i nazwisko lub nazwa firmy',
    'cart/delivery/street': 'Ulica, nr domu i lokalu',
    'cart/delivery/postal-code': 'Kod pocztowy',
    'cart/delivery/city': 'Miasto',
    'cart/delivery/phone': 'Numer telefonu',
    'cart/delivery/country': 'Kraj',
    'cart/summary': 'Podsumowanie',
    'cart/summary/products': 'Produkty łącznie',
    'cart/summary/delivery': 'Dostawa',
    'cart/summary/total': 'Razem do zapłaty',
    'cart/pay': 'Zapłać',
    'cart/register/login': 'Rejestracja / Logowanie',
    'cart/continue-with-email': 'Kontynuuj z adresem e-mail',
    'cart/accept-condition/text':
        'Akceptuję Regulamin oraz politykę prywatności i wyrażam zgodę na przetwarzanie moich danych osobowych.',
    'cart/accept-condition/text-more':
        'przez DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, jako administratora danych osobowych, w celu realizacji usług dostępnych w serwisie oraz w celach marketingowych, w tym na otrzymywanie na podany adres e-mail informacji handlowej wysłanej przez DietLabs sp. z. o.o. sp. k. w imieniu własnym lub na zlecenie jej partnerów biznesowych. Masz prawo wglądu do Twoich danych oraz ich poprawienia lub usunięcia.',
    'cart/accept-condition/validation/required': 'Zgoda jest wymagana',
    'cart/upsell': 'Klienci kupili również',
    'cart/upsell/you-save': 'oszczędzasz',
    'cart/upsell/add-product': 'Dodaj do koszyka',
    'cart/upsell/added': 'Dodany',
    'cart/upsell/about': 'Więcej o produkcie',
    'cart/next': 'Przejdź dalej',
    'cart/back-to-cart': 'Wróć do koszyka',
    'cart/confirm-and-order': 'Potwierdzam i zamawiam',
    'cart/change': 'zmień',
    'cart/order-summary': 'Podsumowanie zamówienia',
    'cart/order-confirm': 'Potwierdzenie zamówienia',
    'cart/address-of-delivery': 'Adres dostawy',
    'cart/payment': 'Płatność',
    'cart/payment/error-occured':
        'Przepraszamy, ale nie mogliśmy rozpocząć Twojej płatności. Spróbuj ponownie i daj nam znać jeśli problem będzie się powtarzał.',
    'cart/payment-cod/error-occured':
        'Przepraszamy, ale nie mogliśmy zrealizować Twojego zamówienia. Spróbuj ponownie i daj nam znać jeśli problem będzie się powtarzał.',
    'cart/order-is-pending': 'Zamówienie zostało przyjęte.',
    'cart/add/invalid-product-id':
        'Wybrany produkt nie jest dostępny w sprzedaży.',
    'cart/login-or': 'lub',

    'thank-you/title': 'Dziękujemy za zaufanie! Co dalej z Twoim zamówieniem?',
    'thank-you/title/48hours': 'Twoje konto w aplikacji jest już aktywne.',
    'thank-you/title2/48hours': 'Co zrobić dalej?',
    'thank-you/how-to-activate-the-service': 'Aktywacja usługi',
    'thank-you/step1':
        'Na Twojego maila wysłaliśmy wiadomość z podsumowaniem i potwierdzeniem zamówienia.',
    'thank-you/step2':
        'Zaraz potem przystąpiliśmy do przygotowania Twojego zamówienia do realizacji. Jak tylko paczka będzie gotowa do wysyłki, poinformujemy Cię o tym mailowo!',
    'thank-you/step3':
        'Chcesz śledzić drogę swojej przesyłki? Kiedy paczka wyruszy w drogę, otrzymasz od nas wiadomość z kodem śledzenia oraz dokładną instrukcją, jak monitorować zamówienie. Średni czas oczekiwania na przesyłkę to tylko 2 dni robocze!',
    'thank-you/activation-code-and-instruction':
        'W przesyłce znajdziesz swój kod aktywacyjny oraz informacje dotyczące jego użycia.',
    'thank-you/flyer-header': 'Dieta OXY - kod dostępu',
    'thank-you/flyer-content1':
        'Droga do szczupłej sylwetki może być jednocześnie prosta, szybka, smaczna i tania. Już niedługo się o tym przekonasz!',
    'thank-you/flyer-content2':
        'Z naszym jadłospisem jesz tylko to, na co masz ochotę i z radością obserwujesz, jak ubywa Ci zbędnych kilogramów.',
    'thank-you/flyer-content3': 'Zespół dietetyków OXY',
    'thank-you/flyer-footer1': 'Wejdź na stronę ',
    'thank-you/flyer-footer2': ', wpisz poniższy kod i aktywuj usługę.',
    'thank-you/go-to-diet-plan': 'Przejdź do planu diety',
    'thank-you/go-to-diet-plan/content1': 'Twój plan diety jest już gotowy.',
    'thank-you/go-to-diet-plan/content2':
        'Jedz to, co lubisz i ciesz się szczupłą sylwetką!',
    'thank-you/start-training': 'Zacznij treningi',
    'thank-you/start-training/content':
        'Treningi są dostępne wyłącznie w aplikacji Dieta OXY dla telefonów z systemem iOS oraz Android. Kliknij odpowiednią ikonę, pobierz aplikację i rozpocznij ćwiczenia!',
    'thank-you/start-configuring-your-diet':
        'Rozpocznij konfigurowanie Twojej diety',
    'thank-you/start-configuring-your-diet/content':
        'Już zaraz będziesz mógł rozpoczać swoją Dietę! Zanim się to stanie, potrzebujemy od Ciebie jeszcze kilku dodatkowych informacji.',
    'thank-you/start-configuring-your-diet/button': 'Konfiguruj dietę',
    'thank-you/warning':
        'UWAGA! Aby w pełni korzystać z zasobów serwisu aktywuj swoje konto. Link aktywacyjny wysłaliśmy Tobie na adres: {{email}}. Jeżeli nie [otrzymałaś|otrzymałeś] wiadomości sprawdź folder spam.',
    'thank-you/order-is-processed':
        'Twoje zamówienie jest w trakcie realizacji. Zrobimy co w naszej mocy, żeby dotarło do Ciebie najszybciej, jak to możliwe!',
    'thank-you/go-to-home': 'Przejdź do strony głównej',
    'loading/message': 'Ładowanie…',
    'et-al/abbrev': 'itp',
    'et-al/full': 'i tym podobne',

    'payment/pending/title': '[Zamówiłaś|Zamówiłeś] pakiet: {{ products }}',
    'payment/pending/description':
        'Dostęp do Twojej usługi otrzymasz, gdy zaksięgujemy Twoją wpłatę. Czas oczekiwania zależy od wybranego przez Ciebie sposobu płatności.',
    'payment/pending/make-payment-description':
        'Jeżeli nie [dokonałaś|dokonałeś] jeszcze płatności, możesz to zrobić teraz.',
    'payment/pending/make-payment': 'Zapłać teraz',

    'message/showing-cached-data': 'Ładujemy najnowsze dane.',
    'message/showing-cached-data/network-error':
        'Nie udało się pobrać danych — pokazane dane mogą być nieaktualne.',
    'message/showing-cached-data/network-error-offline':
        'Brak połączenia z Internetem — pokazane dane mogą być nieaktualne.',

    'units/mililiter/full': 'mililitr(ów)',
    'units/mililiter/full_0': 'mililitr',
    'units/mililiter/full_1': 'mililitry',
    'units/mililiter/full_2': 'mililitrów',
    'units/mililiter/full_3': 'mililitra',
    'units/mililiter/abbr': 'ml',

    'units/ounce/full': 'uncji',
    'units/ounce/full_0': 'uncja',
    'units/ounce/full_1': 'uncje',
    'units/ounce/full_2': 'uncji',
    'units/ounce/full_3': 'uncji',
    'units/ounce/abbr': 'oz',

    'route-title/hydration-day': 'Nawodnienie',
    'menu/hydration': 'Nawodnienie',
    'menu/your-hydration': 'Nwodnienie',
    'hydration/add-custom': 'Dodaj własną porcję wody',
    'hydration/goal-label': 'Dzienny cel:',
    'hydration/goal-changer-label': 'Twój dzienny cel',
    'hydration/goal-reached-label': 'Brawo!',
    'hydration/goal-reached':
        'Osiągnąłeś swój dzienny cel nawodnienia. Pamiętaj, dobre nawodnienie przyczynia się do prawidłowego funkcjonowania organizmu.',
    'hydration/goal-left/past/pre-value': 'Do wypicia pozostało Ci jeszcze',
    'hydration/goal-left/past/post-value': 'wody',
    'hydration/goal-left/present/pre-value':
        'Dzisiaj [powinnaś|powinieneś] wypić jeszcze',
    'hydration/goal-left/present/post-value': 'wody',
    'hydration/goal-left/future/pre-value':
        '{{date}} [powinnaś|powinieneś] wypić',
    'hydration/goal-left/future/post-value': 'wody',
    'hydration/goal-header/reached': 'Brawo! Dzienny cel został osiągnięty.',
    'hydration/goal-reached1':
        'Małymi krokami do celu - a ten związany z piciem wody udało Ci się dziś osiągnąć.',
    'hydration/goal-reached2':
        'To niezwykle istotne dla prawidłowego funkcjonowania całego organizmu!',
    'hydration/progress/label': 'wypite',
    'hydration/remove-last-portion': 'Usuń ostatnią porcję wody',
    'route-title/hydration-add': 'Dodaj porcję wody',
    'hydration/add-portion': 'Dodaj porcję',
    'hydration/add/heading': 'Ile wody [wypiłaś|wypiłeś]?',
    'hydration/add/submit': 'Dodaj',
    'hydration/add/cancel': 'Anuluj',
    'hydration/add/custom': 'własne',
    'hydration/full-glass': 'Pełna szklanka',
    'hydration/full-bottle': 'Pełna butelka',
    'hydration/add-global-action': 'Dodaj wypitą dziś porcję wody',
    'route-title/hydration-history': 'Historia nawodniania',
    'menu/hydration-history': 'Historia',
    'hydration/go-to-history': 'Historia',
    'hydration/back-to-hydration': 'Wróć',
    'hydration/recommended-value': '{{value}} (rekomendowane)',
    'hydration/glass-value': '{{value}} (szklanka)',
    'hydration/glass': 'szklanka',
    'hydration/half-bottle-value': '{{value}} (pół butelki)',
    'hydration/bottle-value': '{{value}} (butelka)',
    'hydration/bottle': 'butelka',
    'default-value': 'Wartość domyślna',
    'individual-goal': 'Indywidualny cel',

    'route-title/interventions': 'Motywacja',
    'interventions/title': 'Motywacja',
    'interventions/prev': 'Poprzednie',
    'interventions/next': 'Następne',
    'interventions/back-to-intro': 'Wróć do wprowadzenia',
    'interventions/go-to-motivation': 'Przejdź do moytywacji',
    'interventions/back-to-motivation': 'Powrót do listy',
    'intervention-type/quote': 'Zainspiruj się',
    'intervention-type/question': 'Pytanie od Coacha',
    'intervention-type/behavioral': 'Twoje zadanie na dziś',
    'intervention-type/psychological': 'Poznaj siebie',

    'motivation-test/question': 'Pytanie',
    'motivation-test/agree': 'CAŁKOWICIE SIĘ ZGADZAM',
    'motivation-test/disagree': 'ZUPEŁNIE SIĘ NIE ZGADZAM',
    'motivation-test/next': 'Następne',
    'motivation-test/prev': 'Poprzednie',
    'motivation-test/send': 'Wyślij',

    'product/you-save': 'oszczędzasz',
    'product/buy-now': 'Kup teraz',

    'guide/title': 'PORADNIKI DIETETYCZNE OXY',
    'guide/intro':
        'Specjalnie dla Ciebie przygotowałam poradniki, które są doskonałym uzupełnieniem Diety OXY. Znajdziesz tu dodatkowe przepisy dietetyczne, masaże, ćwiczenia, przepisy na domowe kosmetyki, motywację, inspirację i wiele innych cennych porad.',
    'guide/featured/title': 'Przekąski OXY w podróży',
    'guide/featured/description':
        ' Nadszedł czas wyjazdów, wakacji i urlopów. Zanim będziesz się cieszyć zasłużonym wypoczynkiem, czeka Cię jeszcze podróż do miejsca docelowego, niejednokrotnie długa i męcząca. Co wcale nie znaczy, że jesteś skazana na ratowanie się niezdrowymi i wysokokalorycznymi przekąskami ze stacji benzynowej! Z naszym specjalnym poradnikiem przekonasz się (który to już raz? 😉) że zdrowe odżywianie możliwe jest w każdych, nawet pozornie niesprzyjających warunkach. Udanych wakacji!',
    'guide/download': 'Pobierz poradnik',

    'psychometric/email-address': 'Podaj e-mail i poznaj wyniki testu',
    'psychometric/show-test-results': 'Poznaj wynik testu',
    'psychometric/bubble': 'A Ty jakim typem osobowości jesteś?',
    'psychometric/email-title': 'Jesteś gotowy poznać wyniki TESTU?',
    'psychometric/did-you-knpw':
        'Według naszych analiz aż 93% wyników w pełni odzwierciedla osobowość. Pozostał już Tobie tylko 1 krok, abyś poznał swój wynik testu.',
    'psychometric/why': 'Dlaczego test osobowości jest taki ważny? To proste',
    'psychometric/why/answer1':
        'Otrzymasz dietę dopasowaną do swojego charakteru',
    'psychometric/why/answer2': 'Osiągniesz założony cel',
    'psychometric/why/answer3': 'Jadłospis trafi w Twoje kulinarne gusta',
    'psychometric/guide/title':
        'Razem z wynikami testu otrzymasz poradnik\n„Smak odchudzania” - ZA DARMO',
    'psychometric/show-result': 'Poznaj wynik',
    'psychometric/how-your-diet-should-look-like':
        'Jak powinna wyglądać Twoja dieta?',

    'psychometric/result/who-you-are': '[Jaka|Jaki] jesteś?',

    'diet-type/simple/classic': 'OXY Simple Classic',
    'diet-type/simple/vege': 'OXY Simple Vege',

    'diet-type/classic/fat_burning': 'OXY Classic: Fat Burning',
    'diet-type/classic/balance': 'OXY Classic: Balance',
    'diet-type/classic/detox': 'OXY Classic: Detox',
    'diet-type/classic/step': 'OXY Classic: Step',
    'diet-type/classic/stabilisation': 'OXY Classic: Stabilizacja',

    'diet-type/vege/fat_burning': 'OXY Vege: Fat Burning',
    'diet-type/vege/balance': 'OXY Vege: Balance',
    'diet-type/vege/detox': 'OXY Vege: Detox',
    'diet-type/vege/step': 'OXY Vege: Step',
    'diet-type/vege/stabilisation': 'OXY Vege: Stabilizacja',

    'diet-type/vege/fish/fat_burning': 'OXY Vege + Fish: Fat Burning',
    'diet-type/vege/fish/balance': 'OXY Vege + Fish: Balance',
    'diet-type/vege/fish/detox': 'OXY Vege + Fish: Detox',
    'diet-type/vege/fish/step': 'OXY Vege + Fish: Step',
    'diet-type/vege/fish/stabilisation': 'OXY Vege + Fish: Stabilizacja',

    PERSONALITY_1_IMG: 'Lubisz sprawdzone, klasyczne posiłki',
    PERSONALITY_1_PROMO_SECTION:
        'Twoja dieta powinna składać się ze smacznych i klasycznych dań, których przygotowanie nie będzie czasochłonne! Możemy ją dla Ciebie skomponować!',
    PERSONALITY_1_PROMO_TITLE:
        'Wiemy już, że jesteś [indywidualistką|indywidualistą] i nie lubisz zmian!',
    PERSONALITY_1_SECTION:
        'Należysz do osób wrażliwych na stres. Na pojawiające się problemy z reguły reagujesz bardzo emocjonalnie. Dlatego też starasz się unikać konfliktów lub odwracać od nich uwagę.Jesteś typem samotnika. Zamiast hucznych imprez z udziałem wielu ludzi wybierasz towarzystwo najbliższych lub partnera. Bezpiecznie czujesz się w znanym sobie środowisku oraz preferujesz rzeczy wypróbowane i sprawdzone. Do wszelkich zmian i innowacji podchodzisz z dystansem.',
    PERSONALITY_1_SECTION_2:
        'Twoja natura sprawia, że do wszelkich nowości podchodzisz niechętnie, a czasem nawet się ich obawiasz. Trudno jest Tobie podjąć wysiłek potrzebny do wdrożenia zmian. Najczęściej wybierasz dobrze sobie znane dania, a nie takie, których smak może Cię za bardzo zaskoczyć. Najlepsza dieta dla Ciebie to właśnie taka, która nie będzie wymagała z Twojej strony dużego zaangażowania w przygotowanie potraw, a potrzebne produkty kupisz w swoich ulubionych sklepach.',
    PERSONALITY_2_IMG: 'Lubisz sprawdzone, proste dania',
    PERSONALITY_2_PROMO_SECTION:
        'Twoja dieta powinna składać się z lubianych przez Ciebie dań, które będą odpowiadać Twojemu gustowi i smakowi. Twój jadłospis musi być dopracowany w każdym szczególe. Możemy go dla Ciebie skomponować!',
    PERSONALITY_2_PROMO_TITLE:
        'Wiemy, że jesteś [perfekcjonistką|perfekcjonistą] i nie lubisz zmian!',
    PERSONALITY_2_SECTION:
        'Jesteś osobą wrażliwą na stres i krytykę, dlatego też wszelkie sytuacje konfliktowe przeżywasz bardzo osobiście. Często unikasz sytuacji mogących wywołać nieprzyjemne reakcje ze strony otoczenia. Jednocześnie jesteś typem [ndywidualistki|indywidualisty]. [Sama|Sam] wybierasz sobie drogę! Gdy chcesz osiągnąć jakiś cel, wytrwale do niego dążysz i jesteś skłonna do wyrzeczeń i poświęcenia. Odznaczasz się rzetelnością i skrupulatnością. [Jaka|Jaki] jesteś w kontaktach z innymi osobami? Jeśli chcesz spędzić z kimś czas, najczęściej są to Twoi najbliżsi lub [partner|partnerka].',
    PERSONALITY_2_SECTION_2:
        'Do zmian i innowacji w swoim życiu i otoczeniu podchodzisz z dużym dystansem. Jeśli już musisz coś zmienić, wybierasz rozwiązania pewne i sprawdzone. Tak również jest z jedzeniem. W kuchni jesteś [wierna|wierny] swoim przyzwyczajeniom i smakom. Wybierasz potrawy sprawdzone i znane, nawet jeśli wymagają dłuższego czasu przyrządzania. Dlatego też idealna dieta dla Ciebie to taka, która smakiem i składnikami podobna będzie do Twoich gustów.',
    PERSONALITY_3_IMG: 'Lubisz oryginalne smaki z różnych stron świata',
    PERSONALITY_3_PROMO_SECTION:
        'Twoja idealna dieta to taka, która będzie bogata w nowe smaki i nietuzinkowe dania, którymi się po prostu zachwycisz! Czy mamy ją dla Ciebie skomponować?',
    PERSONALITY_3_PROMO_TITLE:
        'Wiemy, że jesteś [żywiołowa|żywiołowy] i [towarzyska|towarzyski]!',
    PERSONALITY_3_SECTION:
        'Jesteś osobą impulsywną i żywiołową. Zdarza się, że w sytuacjach konfliktowych dajesz się ponieść emocjom. Jednocześnie dobrze czujesz się w towarzystwie innych. Nie masz problemu z nawiązywaniem nowych kontaktów i znajomości. Lubisz próbować nowych rzeczy i chętnie angażujesz się w proponowane przez innych aktywności. Jesteś osobą kreatywną i masz bardzo szerokie spektrum zainteresowań. Jest też druga strona medalu: Twoja natura nie pozwala na dość długie trwanie w jednym postanowieniu. Lubisz próbować nowych rzeczy, jednak szybko się do nich zniechęcasz i chwytasz za następne.',
    PERSONALITY_3_SECTION_2:
        'Lubisz nowe zestawienia i eksperymenty w kuchni. Dzięki temu spodoba się Tobie dieta zawierająca oryginalne smaki z różnych stron świata. Nie jesteś jednak typem, który poświęca dużo czasu w przygotowanie posiłku. Dlatego też najlepszym rozwiązaniem dla Ciebie będzie ciekawa i oryginalna dieta, która powinna składać się z szybkich do przygotowania posiłków.',
    PERSONALITY_4_IMG: 'Lubisz oryginalne i ciekawe przepisy',
    PERSONALITY_4_PROMO_SECTION:
        'Idealnie dopasowana do Ciebie dieta to taka, która będzie pełna oryginalnych i ciekawych przepisów oraz zupełnie nowych smaków! Czy mamy ją dla Ciebie skomponować?',
    PERSONALITY_4_PROMO_TITLE:
        'Wiemy, że jesteś [towarzyska|towarzyski] i [konsekwentna|konsekwentny]!',
    PERSONALITY_4_SECTION:
        'Jesteś osobą podchodzącą do życia bardzo emocjonalnie. Będąc w sytuacji konfliktowej trudno jest Tobie zachowywać opanowaną i zdystansowaną postawę. Słowa krytyki i dezaprobaty bardzo mocno bierzesz do siebie. Jesteś jednak duszą towarzystwa – praktycznie nie wyobrażasz sobie samotnego spędzania czasu. Twoim atutem jest m.in. to, że nie masz problemów z odnalezieniem się w obcym środowisku. Chętnie próbujesz nowych rzeczy i rozwiązań. Przy całej swojej żywiołowości jesteś konsekwentna i gdy bardzo Ci na czymś zależy, potrafisz zrobić niemal wszystko, by to osiągnąć!',
    PERSONALITY_4_SECTION_2:
        'Uwielbiasz kulinarne eksperymenty i jesteś [otwarta|otwarty] na nowe doznania smakowe. Najlepszym dla Ciebie rozwiązaniem dietetycznym będzie zestawianie oryginalnych i ciekawych przepisów, które nie pozwolą Ci się nudzić i wpaść w rutynę. Kreatywne i twórcze dania to właśnie to, co w jedzeniu lubisz najbardziej. Dodatkowe wiadomości i ciekawostki dotyczące odżywiania wzbogacą Twoją wiedzę i obudzą jeszcze większą motywację do działania.',
    PERSONALITY_5_IMG: 'Lubisz sprawdzone, proste dania',
    PERSONALITY_5_PROMO_SECTION:
        'Stworzona specjalnie dla Ciebie dieta musi być nieangażująca i składająca się z klasycznych posiłków, które lubisz! Możemy ją dla Ciebie skomponować!',
    PERSONALITY_5_PROMO_TITLE:
        'Wiemy, że jesteś [zrównoważona|zrównoważony] i nie przepadasz za zmianami!',
    PERSONALITY_5_SECTION:
        'Jesteś osobą stabilną i zrównoważoną. Nie masz większych problemów w radzeniu sobie ze stresem. Jeśli w Twoim życiu pojawiają się problemy, koncentrujesz się na ich rozwiązaniu. Starasz się sama nie generować konfliktów, często nawet za cenę własnego komfortu. Zbyt duża chęć dogodzenia wszystkim wokoło sprawia, że możesz zaniedbywać swoje własne potrzeby. W kontaktach towarzyskich wykazujesz dużą dozę rezerwy i dystansu. Jeśli już nazwiesz kogoś przyjacielem, musi to być naprawdę bliska Tobie osoba. Zmiany nie leżą w Twojej naturze, przyjmujesz je niechętnie. Do rzeczy nowych i nieznanych podchodzisz z dużą rezerwą. Niechętnie również podejmujesz się nowych wyzwań.',
    PERSONALITY_5_SECTION_2:
        'Najczęściej wybierasz dania dobrze sobie znane, a nie takie, których pochodzenie i smak mogą Cię za bardzo zaskoczyć. Najlepsza dieta dla Ciebie to właśnie taka, która nie będzie wymagała z Twojej strony dużego zaangażowania w przygotowanie potraw, a potrzebne do ich przygotowania produkty kupisz w swoich ulubionych sklepach.',
    PERSONALITY_6_IMG: 'Lubisz sprawdzone, proste posiłki',
    PERSONALITY_6_PROMO_SECTION:
        'Twoja dieta musi być zgodna z Twoimi przyzwyczajeniami i ulubionymi smakami. Powinna zatem składać się z dań, które uwzględniają Twoje przyzwyczajenia i codzienny rytm Twojego dnia! Możemy ją dla Ciebie skomponować!',
    PERSONALITY_6_PROMO_TITLE:
        'Wiemy, że jesteś [zrównoważona|zrównoważony] i nie lubisz zmian!',
    PERSONALITY_6_SECTION:
        'Należysz do osób nie mających trudności w radzeniu sobie ze stresem. Umiesz skutecznie rozwiązywać konflikty. Jesteś w stanie spojrzeć chłodno na problem i kiedy to konieczne pójść na kompromis. Niestety takie podejście powoduje, że chcąc robić dobre wrażenie na innych możesz odwracać uwagę od swoich problemów związanych ze sferą osobistą czy zdrowiem. W kontaktach towarzyskich jesteś raczej [ostrożna|ostrożny]. Aby ktoś mógł się do Ciebie zbliżyć, musi najpierw zdobyć Twoje zaufanie. Poza tym nie masz przekonania do nowych rozwiązań i nieznanych rzeczy. Dobrze czujesz się w sytuacjach znanych, w których to właśnie Ty rozdajesz karty. Masz jasno sprecyzowane, ustalone przez siebie cele, które skrupulatnie i konsekwentnie realizujesz.',
    PERSONALITY_6_SECTION_2:
        'W kuchni jesteś [wierna|wierny] swoim przyzwyczajeniom i smakom. Przygotowujesz potrawy sprawdzone i znane, nawet jeśli wymagają dłuższego czasu przygotowania. Dlatego też idealna dieta dla Ciebie to taka, która smakiem i składnikami podobna będzie do Twoich gustów.',
    PERSONALITY_7_IMG: 'Lubisz oryginalne i zarazem proste dania',
    PERSONALITY_7_PROMO_SECTION:
        'Dieta dla Ciebie powinna być oryginalna, zawierać smaki z całego świata! Przygotowanie posiłków podczas jej stosowania nie może być jednak czasochłonne. Możemy ją dla Ciebie skomponować!',
    PERSONALITY_7_PROMO_TITLE:
        'Wiemy, że jesteś [towarzyska|towarzyski] i lubisz zmiany!',
    PERSONALITY_7_SECTION:
        'Dobrze umiesz radzić sobie z napięciem i stresem. W sytuacjach konfliktu potrafisz dostosować się do otoczenia, jednak gdy sytuacja jest bardzo napięta i trudna dla Ciebie, pod maską opanowania kryją się niezwykle silne emocje. Jednocześnie jesteś osobą wesołą i pogodną. Bardzo lubisz towarzystwo innych ludzi. Masz dużo energii, chętnie angażujesz się w wiele nowych aktywności. Szczególnie dobrze czujesz się w zadaniach wymagających nieszablonowego i twórczego myślenia. Ma to również swój „minus” – trudno Tobie wytrwać w postanowieniach, powtarzające się czynności po jakimś czasie stają się dla Ciebie nudne i nieatrakcyjne.',
    PERSONALITY_7_SECTION_2:
        'Lubisz nowe zestawienia i eksperymenty w kuchni. Dzięki temu spodoba się Tobie każda dieta zawierająca oryginalne smaki z różnych stron świata.</p><p>Nie jesteś jednak typem, który poświęca dużo czasu w przygotowanie posiłku. Dlatego też najlepszym rozwiązaniem dla Ciebie będzie ciekawa i oryginalna, a zarazem szybka i łatwa dieta.',
    PERSONALITY_8_IMG: 'Lubisz oryginalne, ciekawe przepisy',
    PERSONALITY_8_PROMO_SECTION:
        'Twoja dieta powinna być oryginalna, niezwykle ciekawa i składać się z nietuzinkowych posiłków. Lubisz korzystać z efektywnych rozwiązań – i Twoja dieta również taka będzie! Możemy ją dla Ciebie skomponować!',
    PERSONALITY_8_PROMO_TITLE:
        'Wiemy, że jesteś [opanowana|opanowany] i lubisz niekonwencjonalne rozwiązania!',
    PERSONALITY_8_SECTION:
        'Jesteś osobą opanowaną, która bardzo dobrze funkcjonuje w sytuacjach nawet silnego stresu. Umiesz rozwiązywać konflikty i problemy nawet jeśli okazują się bardzo skomplikowane. Twoje opanowanie i rozsądek umożliwiają zarządzanie nawet dużą liczbą osób. Bardzo dobrze sprawdzisz się jako lider lub kierownik zespołu, przecież potrafisz konsekwentnie realizować swoje cele. Dobrze czujesz się w grupie, lubisz towarzystwo innych osób. Nie masz najmniejszych problemu z nawiązywaniem nowych znajomości i kontaktów, nie tylko dla celów osobistych, ale również zawodowych. Poza tym lubisz innowacyjne, twórcze rozwiązania i nie boisz się wyzwań!',
    PERSONALITY_8_SECTION_2:
        'Uwielbiasz kulinarne eksperymenty i jesteś [otwarta|otwarty] na nowe doznania smakowe. Najlepszym dla Ciebie rozwiązaniem dietetycznym będzie zestawianie oryginalnych i ciekawych przepisów, które nie pozwolą Ci się nudzić i wpaść w rutynę. Kreatywne i twórcze dania to właśnie to, co w jedzeniu lubisz najbardziej!',

    'terms-of-service/title': 'Regulaminy serwisu i aplikacji',
    'terms-of-service/android': 'Zakup w Google Play',
    'terms-of-service/ios': 'Zakup w App Store',
    'terms-of-service/web': 'Zakup za pośrednictwem strony internetowej',
};
