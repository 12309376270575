import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import { UNIT_TYPES } from 'consts';
import { formatIsoDate, createDateObject } from 'utils/dateFormatter';
import { getUnitForType } from 'utils/unit';

import HistoryQuery from './HydrationHistoryQuery';

class HydrationHistoryConnectorApollo extends Component {
    static propTypes = {
        dateFrom: PropTypes.instanceOf(Date).isRequired,
        dateTo: PropTypes.instanceOf(Date).isRequired,
        children: PropTypes.func.isRequired,
    };

    render() {
        const variables = {
            period: {
                start: formatIsoDate(this.props.dateFrom),
                end: formatIsoDate(this.props.dateTo),
            },
        };
        return (
            <Query query={HistoryQuery} variables={variables}>
                {({ loading, cacheHit, hasNetworkError, data }) => {
                    let unit;
                    let defaultDailyGoal;
                    let days = [];
                    if (!loading) {
                        unit = getUnitForType(
                            UNIT_TYPES.VOLUME,
                            data.me.systemOfMeasures
                        );

                        defaultDailyGoal =
                            data.me.hydrationSettings.defaultDailyGoal.value;

                        const rawDays = data.me.hydrationHistory;
                        if (rawDays.length) {
                            days = rawDays.map(day => ({
                                date: createDateObject(day.date),
                                goalValue: day.goal.value,
                                valueValue: day.value.value,
                            }));
                        }
                    }
                    return this.props.children({
                        loading,
                        cacheHit,
                        hasNetworkError,
                        unit,
                        defaultDailyGoal,
                        days,
                    });
                }}
            </Query>
        );
    }
}

export { HydrationHistoryConnectorApollo };
export default HydrationHistoryConnectorApollo;
