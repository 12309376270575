import React, { Component } from 'react';
import glass from 'assets/icons/hydration/glass.svg';
import bottle from 'assets/icons/hydration/bottle.svg';
import custom from 'assets/icons/hydration/custom.svg';
import AddPlaceholder from './HydrationAddButtonPlaceholder';

class HydrationAddPlaceholder extends Component {
    render() {
        return (
            <div className="text-center">
                <AddPlaceholder value={250} unit="ml" icon={glass} />

                <AddPlaceholder value={1000} unit="ml" icon={bottle} />

                <AddPlaceholder value={null} unit="ml" icon={custom} />
            </div>
        );
    }
}

export default HydrationAddPlaceholder;
