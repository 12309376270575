import SaleProductPrice from './Price/SaleProductPrice';

class SaleProduct {
    constructor(
        id,
        name,
        thumbnailUrl,
        price,
        isPhysical,
        containsDiet,
        containsWorkout,
        tags,
        isBestseller,
        shortDescription,
        longDescription,
        lengthLabel,
        vendorIdentifier
    ) {
        this.id = id;
        this.name = name;
        this.thumbnailUrl = thumbnailUrl;

        if (price instanceof SaleProductPrice) {
            this.price = price;
        } else {
            throw new Error('price has to be instance of SaleProductPrice');
        }

        this.isPhysical = isPhysical;
        this.containsDiet = containsDiet;
        this.containsWorkout = containsWorkout;
        this.tags = tags || [];
        this.isBestseller = isBestseller;
        this.shortDescription = shortDescription;
        this.longDescription = longDescription;
        this.lengthLabel = lengthLabel;
        this.vendorIdentifier = vendorIdentifier;
    }

    get diffPrice() {
        return this.price.regular.subtract(this.price.promo);
    }
}

export default SaleProduct;
