import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import { Container, Button } from 'reactstrap';
import { ReactComponent as ArrowLeft } from 'assets/icons/chevron-left.svg';
import { formatMediumDate } from 'utils/dateFormatter';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import InterventionPlaceholder from './InterventionPlaceholder';
import InterventionPromptComponent from './Prompt/InterventionPromptComponent';
import InterventionHiddenComponent from './Hidden/InterventionHiddenComponent';
import InterventionSuccessComponent from './Success/InterventionSuccessComponent';
import InterventionBasicComponent from './Basic/InterventionBasicComponent';
import { PATH_INTERVENTIONS } from './paths';

class InterventionContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        intervention: PropTypes.arrayOf(
            PropTypes.shape({
                __typename: PropTypes.string,
                date: PropTypes.string.isRequired,
                revealed: PropTypes.bool,
                revealLabel: PropTypes.string,
                promptContent: PropTypes.string,
                type: PropTypes.string.isRequired,
                message: PropTypes.string,
                number: PropTypes.number.isRequired,

                content: PropTypes.string.isRequired,
                imageUrl: PropTypes.string,
                videoUrlBase: PropTypes.string,
                tasks: PropTypes.arrayOf(PropTypes.shape()),
                concluded: PropTypes.bool.isRequired,
                concludeLabel: PropTypes.string.isRequired,
                editLabel: PropTypes.string.isRequired,
                successContent: PropTypes.string.isRequired,
            }).isRequired
        ).isRequired,
        reveal: PropTypes.func.isRequired,
        conclude: PropTypes.func.isRequired,
    };

    state = {
        revealed: this.props.intervention[0].revealed,
        editMode: false,
        success: false,
        answers: [],
        errors: null,
        animationClass: 'fadeInRight animated',
    };

    componentDidMount() {
        if (this.props.intervention[0].tasks) {
            const answers = this.buildAnswersArray();
            this.setState({ answers });
        }
    }

    buildAnswersArray = () => {
        const answers1 = this.props.intervention[0].tasks.map(task => {
            if (task.subtasks.length > 0) {
                return task.subtasks.map(subtask => {
                    return subtask.answers.map(answer => ({
                        taskId: parseInt(subtask.id, 10),
                        value: answer,
                    }));
                });
            }
            return [];
        });

        const answers2 = this.props.intervention[0].tasks.map(task => {
            return task.answers.map(answer => ({
                taskId: parseInt(task.id, 10),
                value: answer,
            }));
        });

        const answers = [...answers1, ...answers2];

        return answers.flat(3);
    };

    handleReveal = async () => {
        await this.props.reveal(this.props.intervention[0].date);
        this.setState({ revealed: true });
    };

    setTaskAnswers = (taskId, values) => {
        this.setState(prevState => {
            const oldAnswers = prevState.answers.filter(
                item => item.taskId !== taskId
            );
            const newAnswers = values.map(el => ({
                taskId: parseInt(taskId, 10),
                value: el,
            }));
            this.setState({ answers: [...oldAnswers, ...newAnswers] });
        });
    };

    handleAction = async () => {
        if (this.state.editMode) {
            const response = await this.props.conclude(
                this.props.intervention[0].date,
                this.state.answers
            );

            if (response.data.me.concludeIntervention.errors) {
                this.setState({
                    errors: response.data.me.concludeIntervention.errors,
                });
            } else if (
                // health circle hack
                this.props.intervention[0].tasks &&
                this.props.intervention[0].tasks.length > 0 &&
                this.props.intervention[0].tasks[0].variant === 'healthCircle'
            ) {
                this.setState({
                    editMode: false,
                });
            } else {
                this.setState({
                    editMode: false,
                    animationClass: 'fadeOutLeft animated',
                });

                setTimeout(() => this.setState({ success: true }), 1000);
            }
        } else {
            this.toggleEditMode();
        }
    };

    toggleEditMode = () => {
        this.setState(prevState => ({
            editMode: !prevState.editMode,
        }));
    };

    renderIntervention = () => {
        // hidden
        if (
            this.props.intervention[0].__typename === 'UserInterventionHidden'
        ) {
            return (
                <InterventionHiddenComponent
                    type={this.props.intervention[0].type}
                    message={this.props.intervention[0].message}
                />
            );
        }

        // prompt
        if (!this.state.revealed) {
            return (
                <InterventionPromptComponent
                    number={this.props.intervention[0].number}
                    promptContent={this.props.intervention[0].promptContent}
                    actionLabel={this.props.intervention[0].revealLabel}
                    type={this.props.intervention[0].type}
                    onReveal={() => this.handleReveal()}
                    animationClass={this.state.animationClass}
                />
            );
        }

        // success
        if (this.state.success) {
            return (
                <InterventionSuccessComponent
                    successContent={this.props.intervention[0].successContent}
                    type={this.props.intervention[0].type}
                />
            );
        }

        // basic
        return (
            <InterventionBasicComponent
                type={this.props.intervention[0].type}
                content={this.props.intervention[0].content}
                imageUrl={this.props.intervention[0].imageUrl}
                videoUrlBase={this.props.intervention[0].videoUrlBase}
                tasks={this.props.intervention[0].tasks}
                concluded={this.props.intervention[0].concluded}
                errors={this.state.errors}
                actionLabel={
                    this.props.intervention[0].concluded &&
                    this.props.intervention[0].tasks.length &&
                    !this.state.editMode
                        ? this.props.intervention[0].editLabel
                        : this.props.intervention[0].concludeLabel
                }
                onAction={() => this.handleAction()}
                setTaskAnswers={(taskId, values) =>
                    this.setTaskAnswers(taskId, values)
                }
                editMode={this.state.editMode}
                setEditMode={() => this.toggleEditMode()}
                animationClass={this.state.animationClass}
            />
        );
    };

    render() {
        return (
            <Layout page="interventions">
                <header>
                    <Container>
                        <h1 className="text-center">
                            {formatMediumDate(this.props.intervention[0].date)}
                        </h1>
                    </Container>
                </header>
                <section className="pt-0">
                    <Container>{this.renderIntervention()}</Container>
                    <div className="text-center mt-2">
                        <Button
                            color="link"
                            tag={Link}
                            to={PATH_INTERVENTIONS}
                            onClick={() => {
                                callNativeAppRemoveLastRoute('motivation');
                                callNativeAppRemoveLastRoute('motivation');
                            }}
                        >
                            <span className="SVGInline">
                                <ArrowLeft className="mr-2" />
                            </span>
                            {this.props.t('interventions/back-to-motivation')}
                        </Button>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default withLoading(
    withLocale(InterventionContainer),
    InterventionPlaceholder
);
