import React from 'react';
import Layout from 'Layout/Layout';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Container, Row, Col, Button, Progress } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { ReactComponent as ArrowLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { PATH_MOTIVATION_RESULT } from './paths';
import MotivationTestPlaceholder from './MotivationTestPlaceholder';
import MotivationQuestion from './MotivationQuestion';

class MotivationTestContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        motivation: PropTypes.shape().isRequired,
        sendAnswers: PropTypes.func.isRequired,
    };

    questionArray = this.props.motivation
        .map(item =>
            item.section.map(item2 => item2.question.map(question => question))
        )
        .flat(2);

    answersArray = this.questionArray.map(item => ({
        questionId: item.identifier,
        // answer: item.position,
        answer: 4,
    }));

    state = {
        answers: this.answersArray,
        currentQuestion: 0,
        showResult: false,
    };

    setAnswer = (id, answer) => {
        this.setState(prevState => {
            const newAnswers = prevState.answers.map(item => {
                if (item.questionId === id) {
                    return { questionId: id, answer };
                }

                return item;
            });

            return { answers: newAnswers };
        });
    };

    handlePrev = () => {
        const wrapper = document.getElementById('wrapper');
        wrapper.classList.add('animated', 'fadeOutRight');

        setTimeout(() => {
            wrapper.classList.remove(...wrapper.classList);
            wrapper.classList.add('hide');
            this.setState(prevState => ({
                currentQuestion: prevState.currentQuestion - 1,
            }));
            wrapper.classList.add('animated', 'fadeInLeft');
        }, 200);
    };

    handleNext = () => {
        const wrapper = document.getElementById('wrapper');
        wrapper.classList.add('animated', 'fadeOutLeft');

        setTimeout(async () => {
            wrapper.classList.remove(...wrapper.classList);
            wrapper.classList.add('hide');

            if (this.state.currentQuestion < this.questionArray.length - 1) {
                this.setState(prevState => ({
                    currentQuestion: prevState.currentQuestion + 1,
                }));
            } else {
                const response = await this.props.sendAnswers(
                    '',
                    this.state.answers
                );
                if (response.data.motivation.motivationSend.code === 200) {
                    this.setState({ showResult: true });
                }
            }

            wrapper.classList.add('animated', 'fadeInRight');
        }, 200);
    };

    render() {
        if (this.state.showResult) {
            return <Redirect to={PATH_MOTIVATION_RESULT} />;
        }
        const progress =
            ((this.state.currentQuestion + 1) / this.questionArray.length) *
            100;
        const question = this.questionArray[this.state.currentQuestion];

        const position = this.state.answers[this.state.currentQuestion].answer;

        return (
            <Layout page="motivation-test">
                <header>
                    <Container>
                        <h1 className="text-center">
                            {this.props.t('motivation-test/question')}{' '}
                            {this.state.currentQuestion + 1}/
                            {this.questionArray.length}
                        </h1>
                    </Container>
                </header>
                <section className="pt-0">
                    <Container>
                        <Row>
                            <Col xs="12" md={{ size: 8, offset: 2 }}>
                                <Progress value={progress} className="mb-5" />
                                <div id="wrapper" className="wrapper">
                                    <MotivationQuestion
                                        key={question.identifier}
                                        identifier={question.identifier}
                                        content={question.content}
                                        position={position}
                                        setAnswer={(id, answer) =>
                                            this.setAnswer(id, answer)
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md={{ size: 8, offset: 2 }}>
                                <Row className="mt-5">
                                    <Col xs="6" className="pr-2">
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => this.handlePrev()}
                                            disabled={
                                                this.state.currentQuestion <= 0
                                            }
                                        >
                                            <span className="SVGInline">
                                                <ArrowLeft className="mr-2" />
                                            </span>
                                            {this.props.t(
                                                'motivation-test/prev'
                                            )}
                                        </Button>
                                    </Col>
                                    <Col xs="6" className="text-right pl-2">
                                        <Button
                                            color="primary"
                                            className="no-arrow"
                                            onClick={() => this.handleNext()}
                                        >
                                            {this.state.currentQuestion ===
                                            this.questionArray.length - 1
                                                ? this.props.t(
                                                      'motivation-test/send'
                                                  )
                                                : this.props.t(
                                                      'motivation-test/next'
                                                  )}
                                            <span className="SVGInline">
                                                <ArrowRight className="ml-2" />
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLoading(
    withLocale(MotivationTestContainer),
    MotivationTestPlaceholder
);
