import { PATH_DAY_PLAN_INDEX } from 'DayPlanNew/DayPlanPaths';
import { PATH_KNOWLEDGE_ALL_ARTICLES } from 'Knowledge/paths';
import { PATH_INTERVENTIONS } from 'Interventions/paths';
import { PATH_HYDRATION } from 'Hydration/paths';
import { PATHS } from '../paths';

export default [
    {
        path: PATHS.EXTERNAL.HOME,
        externalUrl: PATHS.EXTERNAL.HOME,
        translationKey: 'menu/home',
    },
    {
        path: PATH_DAY_PLAN_INDEX,
        translationKey: 'menu/diet',
    },
    {
        path: PATHS.SHOPPING_LIST,
        translationKey: 'menu/shopping-list',
    },
    {
        path: PATH_INTERVENTIONS,
        translationKey: 'menu/interventions',
    },
    {
        path: PATH_HYDRATION,
        translationKey: 'menu/hydration',
        // children: [
        //     {
        //         path: PATH_HYDRATION,
        //         translationKey: 'menu/your-hydration',
        //         exact: true,
        //     },
        //     {
        //         path: PATH_HYDRATION_HISTORY.replace(/\/:from[^/]*/, ''),
        //         translationKey: 'menu/hydration-history',
        //     },
        // ],
    },
    {
        path: PATH_KNOWLEDGE_ALL_ARTICLES,
        translationKey: 'menu/knowledge',
    },
    {
        path: PATHS.CONTACT,
        translationKey: 'menu/contact',
    },
];
