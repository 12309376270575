import Price from 'view/Price/Price';

export default class SaleProductPrice {
    constructor(regular, promo) {
        if (regular instanceof Price) {
            this.regular = regular;
        } else {
            throw new Error('regular has to be instance of Price');
        }

        if (promo) {
            if (promo instanceof Price) {
                if (promo.amount !== 0) {
                    this.promo = promo;
                } else {
                    this.promo = regular;
                }
            } else {
                throw new Error('promo has to be instance of Price');
            }
        } else {
            this.promo = regular;
        }
    }
}
