import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import ContentQuery from './ContentQuery';

class ContentOnDemandConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    fetch = async identifier => {
        const result = await this.props.client.query({
            query: ContentQuery,
            variables: identifier,
            fetchPolicy: 'no-cache',
        });

        return result;
    };

    render() {
        const { children } = this.props;

        return children({
            loadContent: identifier => this.fetch(identifier),
        });
    }
}

export default withApollo(ContentOnDemandConnectorApollo);
