import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import SaleProductConnectorApollo from 'SaleProduct/Connector/Apollo/Fetch/SaleProductConnectorApollo';
import PsychometricResultConnectorApollo from './Connector/Apollo/PsychometricResult/PsychometricResultConnectorApollo';
import PsychometricResultContainer from './PsychometricResultContainer';

class PsychometricResultPage extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                token: PropTypes.string,
            }).isRequired,
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                email: PropTypes.string,
            }),
        }).isRequired,
    };

    render() {
        const { token } = this.props.match.params;
        let email = '';
        if (this.props.location.state) {
            email = this.props.location.state.email;
        }

        return (
            <PsychometricResultConnectorApollo token={token} email={email}>
                {({ ...result }) => (
                    <SaleProductConnectorApollo>
                        {({ ...loadSaleProduct }) => (
                            <PsychometricResultContainer
                                {...loadSaleProduct}
                                {...result}
                            />
                        )}
                    </SaleProductConnectorApollo>
                )}
            </PsychometricResultConnectorApollo>
        );
    }
}

export default withRouter(PsychometricResultPage);
