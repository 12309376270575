import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import InputDate from 'components/FormElements/InputDateControlled';
import PropTypes from 'prop-types';
import { DateType } from 'types/CommonTypes';
import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';

import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';

import { createDateObject, formatIsoDate } from 'utils/dateFormatter';

class ShoppingListSearch extends Component {
    static propTypes = {
        period: PropTypes.shape({
            start: DateType.isRequired,
            end: DateType.isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        handlePeriodChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            start: this.props.period.start.substring(0, 10),
            end: this.props.period.end.substring(0, 10),
            errors: new ValidationErrors(),
        };

        this.validationRules = {
            start: [
                new RequiredRule({ translator: props.t }),
                new DateFormatRule({ translator: props.t }),
            ],
            end: [
                new RequiredRule({ translator: props.t }),
                new DateFormatRule({ translator: props.t }),
            ],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        if (frontEndErrors.length === 0) {
            const period = {
                start: formatIsoDate(createDateObject(this.state.start)),
                end: formatIsoDate(createDateObject(this.state.end)),
            };
            this.props.handlePeriodChange(period);
        }
    }

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    render() {
        const { t } = this.props;

        return (
            <Row>
                <Col xs="12" sm={{ size: 8, offset: 2 }}>
                    <Form
                        onSubmit={this.handleSubmit}
                        noValidate
                        data-test="search-form"
                    >
                        <FormGroup>
                            <h3 className="text-center">
                                {t('shopping-list/date-range')}:
                            </h3>
                            <Row>
                                <Col xs="6" className="pr-2">
                                    <InputDate
                                        name="start"
                                        value={this.state.start}
                                        errors={this.state.errors}
                                        handleChange={this.handleInputChange}
                                        validationRules={
                                            this.validationRules.start
                                        }
                                        data-test="start-input"
                                    />
                                </Col>
                                <Col xs="6" className="pl-2">
                                    <InputDate
                                        name="end"
                                        value={this.state.end}
                                        errors={this.state.errors}
                                        handleChange={this.handleInputChange}
                                        validationRules={
                                            this.validationRules.end
                                        }
                                        data-test="end-input"
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <div className="text-center d-print-none">
                            <Button color="primary">
                                {t('shopping-list/show-list-button')}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        );
    }
}

export default withLocale(ShoppingListSearch);
