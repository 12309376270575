import React from 'react';
import { flags } from 'config/config';
import { Redirect } from 'react-router';

import { PATHS } from 'config/paths';
import { MENU } from 'consts';

import { routes as measurementRoutes } from 'Measurement/routes';
import { routes as userSettingsRoutes } from 'UserSettings/routes';
import { routes as shoppingListRoutes } from 'ShoppingList/routes';
import { routes as dayPlanNewRoutes } from 'DayPlanNew/routes';
import { routes as dietSettingsRoutes } from 'DietSettings/routes';
import { routes as authRoutes } from '@dietlabs/components/src/Auth/routes';
import { routes as updateAppRoutes } from 'UpdateApp/routes';
import { routes as newsletterUnsubscribeRoutes } from 'Newsletter/routes';
import { routes as contactRoutes } from 'Contact/routes';
import { routes as contentRoutes } from 'Content/routes';
import { routes as makeDecisionRoutes } from 'MakeDecision/routes';
import { routes as knowledgeRoutes } from 'Knowledge/routes';
import { routes as interventionsRoutes } from 'Interventions/routes';
import { routes as motivationTestRoutes } from 'MotivationTest/routes';
import { routes as psychometricTestRoutes } from 'PsychometricTest/routes';
import { routes as guidesRoutes } from 'Guides/routes';
import { routes as couponRoutes } from '@dietlabs/components/src/Coupon/routes';
import { routes as hydrationRoutes } from 'Hydration/routes';
import { routes as deleteAccountRoutes } from 'UserSettings/RemoveAccount/routes';

import GoalReached from 'DayPlan/Index/GoalReached';
import GoalLost from 'DayPlan/Index/GoalLost';
import Error404 from 'view/Error/Error404';
import ErrorApplication from 'view/Error/ErrorApplication';
import ErrorNetwork from 'view/Error/ErrorNetwork';
import PromoCodeContainer from 'view/PromoCode/PromoCodeContainer';
import TrackingStopContainer from 'view/Tracking/Stop/TrackingStopContainer';
import ShoppingCartIndexContainer from 'view/ShoppingCart/Index/ShoppingCartIndexContainer';
import ShoppingCartProductAddContainer from 'view/ShoppingCart/Product/Add/ShoppingCartProductAddContainer';
import ShoppingCartUpSellContainer from 'view/ShoppingCart/UpSell/ShoppingCartUpSellContainer';
import ShoppingCartConfirmContainer from 'view/ShoppingCart/Confirm/ShoppingCartConfirmContainer';
import ShoppingCartThankYouContainer from 'view/ShoppingCart/ThankYou/ShoppingCartThankYouContainer';
import ShoppingCartBankTransferContainer from 'view/ShoppingCart/BankTransfer/ShoppingCartBankTransferContainer';

const routes = [
    {
        path: PATHS.GOAL_REACHED,
        component: GoalReached,
        private: true,
        exact: false,
        title: 'route-title/goal-reached',
        mobileAppTabIdentifier: 'measure',
    },
    {
        path: PATHS.GOAL_LOST,
        component: GoalLost,
        private: true,
        exact: false,
        title: 'route-title/goal-lost',
        mobileAppTabIdentifier: 'measure',
    },
    {
        path: PATHS.ERROR_404,
        component: Error404,
        private: false,
        exact: false,
        title: 'route-title/error-404',
        exitPopup: false,
    },
    {
        path: PATHS.ERROR_APPLICATION,
        component: ErrorApplication,
        private: false,
        exact: false,
        title: 'route-title/error-application',
        exitPopup: false,
    },
    {
        path: PATHS.ERROR_NETWORK,
        component: ErrorNetwork,
        private: false,
        exact: false,
        title: 'route-title/error-network',
    },
    {
        path: PATHS.PROMO_CODE.EN,
        component: PromoCodeContainer,
        private: false,
        exact: true,
        title: 'route-title/promo-code',
    },
    {
        path: PATHS.PROMO_CODE.PL,
        component: PromoCodeContainer,
        private: false,
        exact: true,
        title: 'route-title/promo-code',
    },
    {
        path: PATHS.BLOCK_COOKIES,
        component: TrackingStopContainer,
        private: false,
        exact: true,
        title: 'route-title/block-cookies',
    },
    {
        path: PATHS.REDIRECT_HELPER,
        title: 'route-title/default',
        // eslint-disable-next-line react/prop-types
        render: props => <Redirect to={props.location.state.redirectTo} />,
    },
    ...measurementRoutes,
    ...userSettingsRoutes,
    ...shoppingListRoutes,
    ...dayPlanNewRoutes,
    ...dietSettingsRoutes,
    ...authRoutes,
    ...updateAppRoutes,
    ...newsletterUnsubscribeRoutes,
    ...contactRoutes,
    ...contentRoutes,
    ...makeDecisionRoutes,
    ...knowledgeRoutes,
    ...interventionsRoutes,
    ...motivationTestRoutes,
    ...guidesRoutes,
    ...couponRoutes,
    ...psychometricTestRoutes,
    ...hydrationRoutes,
    ...deleteAccountRoutes,
];

const cartRoutes = [
    {
        path: PATHS.CART.INDEX,
        component: ShoppingCartIndexContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
        menuType: MENU.SIMPLE,
    },
    {
        path: PATHS.CART.ADD,
        component: ShoppingCartProductAddContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
        menuType: MENU.SIMPLE,
    },
    {
        path: PATHS.CART.UPSELL,
        component: ShoppingCartUpSellContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
        menuType: MENU.SIMPLE,
    },
    {
        path: PATHS.CART.CONFIRM,
        component: ShoppingCartConfirmContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
        menuType: MENU.SIMPLE,
    },
    {
        path: PATHS.CART.THANK_YOU,
        component: ShoppingCartThankYouContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
    },
    {
        path: PATHS.CART.BANK_TRANSFER,
        component: ShoppingCartBankTransferContainer,
        private: false,
        exact: true,
        title: 'route-title/default',
        exitPopup: false,
    },
];

export default routes.concat(flags.cart ? cartRoutes : []);
