/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import MeasurementUnits from 'components/Helpers/MeasurementUnits';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

import iconChange from 'assets/images/dayplan/icon-change-ingredient.svg';
import iconChangeDone from 'assets/images/dayplan/icon-change-ingredient-done.svg';

import withMobileAppMode from 'Layout/withMobileAppMode';

// import oxyTea from 'assets/images/oxy-tea.png';
import { ReactComponent as Basket } from 'assets/icons/basket.svg';

class DayPlanIngredient extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
        saleProductVariant: PropTypes.shape({
            thumbnailUrl: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            shortPromo: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
        hasReplacement: PropTypes.bool.isRequired,
        originalProductId: PropTypes.number.isRequired,
        productId: PropTypes.number.isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        saleProductVariant: null,
    };

    state = {
        collapse: false,
    };

    closeAndScroll() {
        this.setState({ collapse: false });
    }

    handleClick() {
        this.setState(prevState => ({
            collapse: !prevState.collapse,
        }));
    }

    render() {
        return (
            <React.Fragment>
                <li onClick={() => this.handleClick()} data-test="ingredient">
                    <MeasurementUnits measurements={this.props.measurements} />
                    <span className="stay-together">
                        {this.props.hasReplacement ? (
                            <button
                                type="button"
                                className={
                                    this.state.collapse
                                        ? 'btn-change animated rotateLeft'
                                        : 'btn-change animated rotateRight'
                                }
                                data-test="exchange-button"
                            >
                                <img
                                    className="img-fluid"
                                    src={
                                        this.props.originalProductId !==
                                        this.props.productId
                                            ? iconChangeDone
                                            : iconChange
                                    }
                                    alt={this.props.t(
                                        'meal/exchange-ingredient'
                                    )}
                                />
                            </button>
                        ) : (
                            ''
                        )}
                    </span>
                    {this.props.name}

                    <Collapse isOpen={this.state.collapse}>
                        {this.props.hasReplacement
                            ? this.props.children({
                                  closeAndScroll: () => this.closeAndScroll(),
                              })
                            : ''}
                    </Collapse>
                </li>

                {this.props.saleProductVariant && !this.props.mobileAppMode ? (
                    <div className="product-box">
                        <img
                            src={this.props.saleProductVariant.thumbnailUrl}
                            alt=""
                            style={{ width: '100px', height: 'auto' }}
                        />
                        <div className="ml-3">
                            <p className="mb-0">
                                <strong
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.saleProductVariant
                                            .name,
                                    }}
                                />{' '}
                                - {this.props.saleProductVariant.shortPromo}
                            </p>
                            <a
                                className="btn btn-primary no-arrow mt-3"
                                href={`/cart/add/${this.props.saleProductVariant.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-test="sale-product-variant-button"
                            >
                                <span className="SVGInline pr-1">
                                    <Basket />
                                </span>{' '}
                                {this.props.t('button/buy')}
                            </a>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    }
}

export { DayPlanIngredient };
export default withMobileAppMode(withLocale(DayPlanIngredient));
