import gql from 'graphql-tag';

const query = gql`
    query UserData {
        me {
            id
            name
            email
            sex
            systemOfMeasures
            diet {
                accessTo
            }
            status
            passwordIsSet
            hasExternalProviderSetUp
        }
    }
`;

export default query;
