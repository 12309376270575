import React from 'react';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import MakeDecision from './MakeDecision';
import MakeDecisionPlaceholder from './MakeDecisionPlaceholder';
import MakeDecisionCurrentMeasurements from './MakeDecisionCurrentMeasurements';
import MakeDecisionButtons from './MakeDecisionButtons';
import MakeDecisionForm from './MakeDecisionForm';

class MakeDecisionContainer extends React.Component {
    state = {
        dietMode: this.props.dietSettings.diet.mode || undefined,
    };

    static propTypes = {
        dietSettings: PropTypes.shape().isRequired,
        goalChange: PropTypes.func.isRequired,
        dietModeChange: PropTypes.func.isRequired,
    };

    changeDietMode = dietMode => {
        this.setState({ dietMode });
    };

    render() {
        return (
            <MakeDecision>
                {() => (
                    <React.Fragment>
                        <MakeDecisionCurrentMeasurements
                            firstMeasurement={
                                this.props.dietSettings.firstMeasurement
                                    .weight[0]
                            }
                            lastMeasurement={
                                this.props.dietSettings.lastMeasurement
                                    .weight[0]
                            }
                            goalWeight={this.props.dietSettings.goalWeight}
                            dietMode={this.props.dietSettings.diet.mode}
                        />

                        <MakeDecisionButtons
                            lastMeasurement={
                                this.props.dietSettings.lastMeasurement
                                    .weight[0]
                            }
                            goalWeight={this.props.dietSettings.goalWeight}
                            dietMode={this.props.dietSettings.diet.mode}
                            activeDietMode={this.state.dietMode}
                            changeDietMode={this.changeDietMode}
                        />

                        <MakeDecisionForm
                            dietSettings={this.props.dietSettings}
                            dietMode={this.state.dietMode}
                            systemOfMeasures={
                                this.props.dietSettings.systemOfMeasures
                            }
                            weight={
                                this.props.dietSettings.lastMeasurement
                                    .weight[0]
                            }
                            goalChange={this.props.goalChange}
                            dietModeChange={this.props.dietModeChange}
                        />
                    </React.Fragment>
                )}
            </MakeDecision>
        );
    }
}

export default withLoading(
    withLocale(MakeDecisionContainer),
    MakeDecisionPlaceholder
);
