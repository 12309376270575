import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import deepClone from 'utils/deepClone';
import { formatIsoDate } from 'utils/dateFormatter';

import mutation from './HydrationAddMutation';

class HydrationAddConnectorApollo extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        queryResult: PropTypes.object.isRequired,
    };

    createAdd = mutate => (value, unit) => {
        const variables = {
            date: formatIsoDate(this.props.date),
            value,
            unit,
        };

        const { hydrationDay: hydrationDayResult, ...meResult } = deepClone(
            this.props.queryResult.me
        );
        const optimisticResponse = {
            me: {
                ...meResult,
                hydrationDayAddPortion: {
                    ...hydrationDayResult,
                    portions: [
                        ...hydrationDayResult.portions,
                        {
                            __typename: 'UserHydration',
                            id: -1 * Date.now(),
                            value: {
                                __typename: 'Volume',
                                value,
                                unit,
                            },
                        },
                    ],
                },
            },
        };

        return mutate({
            variables,
            optimisticResponse,
        });
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={mutation}>
                {(mutate, { data: rawData }) =>
                    children(this.createAdd(mutate), {
                        data: rawData
                            ? rawData.me.hydrationDayAddPortion
                            : null,
                    })
                }
            </Mutation>
        );
    }
}

export { HydrationAddConnectorApollo };
export default HydrationAddConnectorApollo;
