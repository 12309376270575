export default class ShoppingCartDeliveryOptions {
    constructor(
        availableShoppingCartDeliveryOptions,
        saleShippingId,
        onlyDietlabsProducts
    ) {
        this.availableShoppingCartDeliveryOptions = availableShoppingCartDeliveryOptions;
        this.saleShippingId = saleShippingId || null;
        this.onlyDietlabsProducts = onlyDietlabsProducts;
    }

    getAvailableForProductsInCart(
        isPhysicalProduct,
        codIsAvailable,
        disableCodForProducts
    ) {
        return Object.values(this.availableShoppingCartDeliveryOptions).filter(
            method =>
                ((method.onlyVirtual && !isPhysicalProduct) ||
                    (method.onlyPhysical && isPhysicalProduct)) &&
                (!method.isCod || (method.isCod && codIsAvailable)) &&
                (!method.isCod || !disableCodForProducts)
        );
    }

    get areShippingDataNeeded() {
        return this.chosen ? this.chosen.doesRequireAddress : false;
    }

    get chosen() {
        let choosen = null;

        if (this.saleShippingId && this.availableShoppingCartDeliveryOptions) {
            choosen = Object.values(
                this.availableShoppingCartDeliveryOptions
            ).filter(method => method.id === this.saleShippingId)[0];

            // delivery outside of Poland is available only for dietlabs products
            if (choosen && !this.onlyDietlabsProducts) {
                choosen.countries = choosen.countries.filter(
                    country => country.code === 'PL'
                );
            }
        }

        return choosen;
    }
}
