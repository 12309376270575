import React, { Component } from 'react';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import playBtn from 'assets/images/dayplan/play-button.svg';

class DayPlanAdvice extends Component {
    static propTypes = {
        advice: PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            media: PropTypes.arrayOf(),
        }).isRequired,
    };

    videoRef = React.createRef();

    state = {
        visiblePoster: true,
    };

    playVideo = () => {
        this.setState({ visiblePoster: false });
        this.videoRef.current.play();
    };

    renderAdviceMedia = media => {
        if (media[0].__typename === 'IngredientNoteMediaImage') {
            const mealImg = media[0].imageUrl
                .replace('(', '%28')
                .replace(')', '%29');

            return (
                <div className="embed-responsive embed-responsive-1by1">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item"
                    />
                </div>
            );
        }

        return (
            <div className="embed-responsive embed-responsive-1by1">
                <video
                    ref={this.videoRef}
                    src={media[0].videoUrl}
                    controls
                    className="embed-responsive-item"
                    playsInline
                    controlsList="nodownload"
                    disablePictureInPicture
                >
                    <track kind="captions" />
                </video>

                {this.state.visiblePoster && (
                    <div
                        style={{
                            backgroundImage: `url(${media[0].posterUrl
                                .replace('(', '%28')
                                .replace(')', '%29')})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                        onClick={this.playVideo}
                        role="button"
                        tabIndex="0"
                    >
                        <img src={playBtn} alt="" />
                    </div>
                )}
            </div>
        );
    };

    render() {
        const description = this.props.advice.description
            .trim()
            .split('\n')
            .reduce((carry, line) => {
                if (carry.length) {
                    carry.push(<br key={line} />);
                }
                carry.push(line);
                return carry;
            }, []);

        return (
            <div>
                <h4>{this.props.advice.title}</h4>
                <p>{description}</p>
                {this.props.advice.media &&
                    this.renderAdviceMedia(this.props.advice.media)}
            </div>
        );
    }
}

export default withLocale(DayPlanAdvice);
