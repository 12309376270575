import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withLocale } from '@dietlabs/components';
import { ReactComponent as WaterIcon } from 'assets/icons/hydration/water_icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/hydration/hydration_success_icon.svg';

class HydrationDayGoalHeader extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
        goal: PropTypes.number.isRequired,
    };

    render() {
        const { goal, value } = this.props;
        const limit = Math.round(goal - value);

        if (value >= goal) {
            return (
                <div className="goal-header success">
                    <SuccessIcon className="mr-2" />
                    {this.props.t('hydration/goal-header/reached')}
                </div>
            );
        }

        return (
            <div className="goal-header">
                <WaterIcon className="mr-2" />
                {this.props.t('hydration/goal-left/past/pre-value')} {limit}{' '}
                {this.props.unit}{' '}
                {this.props.t('hydration/goal-left/past/post-value')}
            </div>
        );
    }
}

export default withLocale(HydrationDayGoalHeader);
