export default [
    { id: 'start' },
    { id: 'sex-birthday-height' },
    { id: 'weight' },
    { id: 'goal' },
    { id: 'activity-level' },
    {
        id: 'diet-id',
        fork: {
            chooseChild: state => state.dietId,
            children: {
                1: [
                    { id: 'diet-type-1' },
                    { id: 'diet-type-2' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
                2: [
                    { id: 'diet-type-1' },
                    { id: 'diet-type-2' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
                3: [
                    { id: 'diet-type-1' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
                4: [
                    { id: 'diet-type-1' },
                    { id: 'start-date' },
                    { id: 'finish' },
                ],
            },
        },
    },
];
