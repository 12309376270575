import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_CONVERSION } from 'view/GoogleTagManager/EventCategories';
import ShoppingCartThankYouServiceActivationComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouServiceActivationComponent';
import ShoppingCartThankYouStartUsingComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouStartUsingComponent';
import ShoppingCartThankYouComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouComponent';
import ShoppingCartThankYouPlaceholderComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouPlaceholderComponent';
import ShoppingCartThankYouContentComponent from 'components/ShoppingCart/ThankYou/ShoppingCartThankYouContentComponent';
import { PATHS } from 'config/paths';

class ShoppingCartThankYouContainer extends Component {
    static propTypes = {
        reportPayment: PropTypes.func.isRequired,
        authLoginByToken: PropTypes.func.isRequired,
        token: PropTypes.string.isRequired,
        GTM: PropTypes.shape({
            api: PropTypes.shape({
                trigger: PropTypes.func.isRequired,
                gtm: PropTypes.shape({
                    push: PropTypes.func.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    };

    state = {
        paymentData: null,
        redirectTo: null,
    };

    report = () => {
        const paymentOperatorData =
            global && global.location && global.location.search
                ? global.location.search
                : '';

        this.props
            .reportPayment(this.props.token, paymentOperatorData)
            .then(paymentData => {
                if (paymentData === null) {
                    window.location = PATHS.EXTERNAL.HOME;
                    this.setState({ paymentData });
                } else if (paymentData.redirectToPaymentCancelPage) {
                    const redirectTo =
                        PATHS.CART.CONFIRM.split(':')[0] + this.props.token;
                    this.setState({ redirectTo });
                } else if (paymentData.user.authToken) {
                    this.props.authLoginByToken(paymentData.user.authToken);
                    this.setState({ paymentData });
                } else {
                    this.setState({ paymentData });
                }
            });
    };

    componentDidMount() {
        this.report();
    }

    componentDidUpdate(prevProps, prevState) {
        const { reportData: prevReportData } = prevState.paymentData || {};
        const { reportData } = this.state.paymentData || {};
        if (
            reportData &&
            reportData.length &&
            JSON.stringify(reportData) !== JSON.stringify(prevReportData)
        ) {
            reportData.forEach(data => this.props.GTM.api.gtm.push(data));
            this.props.GTM.api.trigger({
                event: EVENT_CATEGORY_CONVERSION,
            });
        }
    }

    pushDataLayer = () => {
        if (window) {
            window.dataLayer.push({ visitorType: 'hasAccount' });
        }
    };

    render() {
        let isPast48Hours;
        if (this.state.paymentData) {
            const now = new Date();
            const userCreatedDate = new Date(
                this.state.paymentData.user.created
            );
            const timeDifference = now - userCreatedDate;
            const hoursDifference = timeDifference / (1000 * 60 * 60);
            isPast48Hours = hoursDifference >= 48;
        }

        if (isPast48Hours) {
            this.pushDataLayer();
        }

        if (this.state.paymentData === null) {
            if (this.state.redirectTo) {
                return <Redirect to={this.state.redirectTo} />;
            }
            return <ShoppingCartThankYouPlaceholderComponent />;
        }
        return (
            <ShoppingCartThankYouComponent
                userEmail={this.state.paymentData.user.email}
                isPast48Hours={isPast48Hours}
                paymentPrice={this.state.paymentData.price}
            >
                <ShoppingCartThankYouServiceActivationComponent
                    isDietInPayment={this.state.paymentData.containsDiet}
                    isTrainingInPayment={
                        this.state.paymentData.containsTraining
                    }
                    isCod={this.state.paymentData.isCod}
                    containsPhysicalProduct={
                        this.state.paymentData.containsPhysicalProduct
                    }
                />

                <ShoppingCartThankYouContentComponent
                    isPast48Hours={isPast48Hours}
                />

                <ShoppingCartThankYouStartUsingComponent
                    userEmail={this.state.paymentData.user.email}
                    isUserActive={this.state.paymentData.user.isActive}
                    userHasDiet={this.state.paymentData.user.hasDiet}
                    isDietInPayment={this.state.paymentData.containsDiet}
                />
            </ShoppingCartThankYouComponent>
        );
    }
}

export { ShoppingCartThankYouContainer };
export default withGTM(ShoppingCartThankYouContainer);
