import React from 'react';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col } from 'reactstrap';

import autumnSoups from 'assets/images/guides/autumn-soups.jpeg';
import celluliteStop from 'assets/images/guides/cellulite-stop.jpeg';
import childsDiet from 'assets/images/guides/childs-diet.jpeg';
import cocktails from 'assets/images/guides/cocktails.jpeg';
import healthysweets from 'assets/images/guides/healthysweets.jpeg';
import lodyOxy from 'assets/images/guides/lody-oxy.jpeg';
import oxyGrill from 'assets/images/guides/oxy-grill.jpeg';
import oxySnacks from 'assets/images/guides/oxy-snacks.jpeg';
import summerSalads from 'assets/images/guides/summer-salads.jpeg';
import sweets from 'assets/images/guides/sweets.jpeg';
import tasteOfWeightLoss from 'assets/images/guides/taste-of-weight-loss.png';
import vegeFishBreakfasts from 'assets/images/guides/vege-fish-breakfasts.png';

import guideAutumnSoups from 'assets/pdf/autumn-soups.pdf';
import guideCelluliteStop from 'assets/pdf/cellulite-stop.pdf';
import guideChildsDiet from 'assets/pdf/childs-diet.pdf';
import guideCocktails from 'assets/pdf/cocktails.pdf';
import guideHealthysweets from 'assets/pdf/oxy-froot.pdf';
import guideLodyOxy from 'assets/pdf/lody-oxy.pdf';
import guideOxyGrill from 'assets/pdf/oxy-grill.pdf';
import guideOxySnacks from 'assets/pdf/oxy-snacks.pdf';
import guideSummerSalads from 'assets/pdf/summer-salads.pdf';
import guideSweets from 'assets/pdf/sweets.pdf';
import guideTasteOfWeightLoss from 'assets/pdf/taste-of-weight-loss.pdf';
import guideVegeFishBreakfasts from 'assets/pdf/vege-fish-breakfasts.pdf';

const guideArray = [
    { cover: autumnSoups, file: guideAutumnSoups },
    { cover: celluliteStop, file: guideCelluliteStop },
    { cover: childsDiet, file: guideChildsDiet },
    { cover: cocktails, file: guideCocktails },
    { cover: healthysweets, file: guideHealthysweets },
    { cover: lodyOxy, file: guideLodyOxy },
    { cover: oxyGrill, file: guideOxyGrill },
    { cover: oxySnacks, file: guideOxySnacks },
    { cover: summerSalads, file: guideSummerSalads },
    { cover: sweets, file: guideSweets },
    { cover: tasteOfWeightLoss, file: guideTasteOfWeightLoss },
    { cover: vegeFishBreakfasts, file: guideVegeFishBreakfasts },
];

class GuidesIndexPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="guides">
                <header>
                    <Container>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <h1 className="text-center">
                                    {this.props.t('guide/title')}
                                </h1>
                                <p>{this.props.t('guide/intro')}</p>
                            </Col>
                        </Row>
                    </Container>
                </header>
                <section className="pt-0">
                    <Container>
                        <Row className="align-items-center mb-5">
                            <Col md="4">
                                <a href={guideOxySnacks}>
                                    <img
                                        src={oxySnacks}
                                        className="img-fluid guide"
                                        alt=""
                                    />
                                </a>
                            </Col>
                            <Col md={{ size: 6, offset: 1 }}>
                                <h2 className="text-center">
                                    {this.props.t('guide/featured/title')}
                                </h2>
                                <p>
                                    {this.props.t('guide/featured/description')}
                                </p>
                                <div className="text-center">
                                    <a
                                        href={guideOxySnacks}
                                        className="btn btn-primary"
                                    >
                                        {this.props.t('guide/download')}
                                    </a>
                                </div>
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-5">
                            <Col md={{ size: 10, offset: 1 }}>
                                <Row>
                                    {guideArray.map(guide => (
                                        <Col
                                            xs={{ size: 10, offset: 1 }}
                                            md={{ size: 4, offset: 0 }}
                                            className="text-center mb-5"
                                            key={guide.file}
                                        >
                                            <a href={guide.file}>
                                                <img
                                                    src={guide.cover}
                                                    className="img-fluid guide"
                                                    alt=""
                                                />
                                            </a>
                                            <a
                                                href={guide.file}
                                                className="btn btn-primary"
                                            >
                                                {this.props.t('guide/download')}
                                            </a>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(GuidesIndexPage);
