import gql from 'graphql-tag';

const TASK_FIELDS = `
    id
    type
    variant
    label
    min
    max
    answers
    variantOptions {
        keyalias: key
        value
    }
    options {
        keyalias: key
        value
    }
`;

const query = gql`
    query Intervention($period: DatePeriodInputType!) {
        me {
            intervention(period: $period) {
                __typename
                ... on UserInterventionVisible {
                    key
                    date
                    number
                    type
                    revealed
                    concluded
                    promptContent
                    revealLabel
                    content
                    imageUrl
                    videoUrlBase
                    concludeLabel
                    editLabel
                    successContent
                    tasks {
                        ${TASK_FIELDS}
                        subtasks {
                            ${TASK_FIELDS}
                            subtasks {
                                ${TASK_FIELDS}
                            }
                        }
                    }
                }
                ... on UserInterventionHidden {
                    key
                    type
                    date
                    message
                }
            }
        }
    }
`;

export default query;
