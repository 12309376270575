import React from 'react';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import PropTypes from 'prop-types';
import RemoveUserAccountMutation from './RemoveUserAccountMutation';

class DeleteUserAccountConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation mutation={RemoveUserAccountMutation}>
                {(removeUserAccount, { loading }) => {
                    const result = {
                        removeUserAccount,
                        loading,
                    };
                    return children(result);
                }}
            </Mutation>
        );
    }
}

export default DeleteUserAccountConnectorApollo;
