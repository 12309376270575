import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import personality1 from 'assets/images/psychometric/personality1.jpeg';
import personality2 from 'assets/images/psychometric/personality2.jpeg';
import personality3 from 'assets/images/psychometric/personality3.jpeg';
import personality4 from 'assets/images/psychometric/personality4.jpeg';
import personality5 from 'assets/images/psychometric/personality5.jpeg';
import personality6 from 'assets/images/psychometric/personality6.jpeg';
import personality7 from 'assets/images/psychometric/personality7.jpeg';
import personality8 from 'assets/images/psychometric/personality8.jpeg';
import personalityMan from 'assets/images/psychometric/personalityMan.jpeg';

class PsychometricResult extends React.Component {
    static propTypes = {
        personality: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
    };

    render() {
        const sex = global.localStorage.getItem('sex');

        const personalityImage = {
            1: personality1,
            2: personality2,
            3: personality3,
            4: personality4,
            5: personality5,
            6: personality6,
            7: personality7,
            8: personality8,
        };

        return (
            <section>
                <Container>
                    <Row>
                        <Col md="6">
                            <img
                                src={
                                    sex === 'female'
                                        ? personalityImage[
                                              this.props.personality
                                          ]
                                        : personalityMan
                                }
                                className="img-fluid rounded-circle personality mb-4"
                                alt=""
                            />
                        </Col>
                        <Col md="6">
                            <h2 className="text-center">
                                {this.props.t(
                                    'psychometric/result/who-you-are'
                                )}
                            </h2>
                            <p>
                                {this.props.t(
                                    `PERSONALITY_${this.props.personality}_SECTION`
                                )}
                            </p>
                        </Col>
                    </Row>
                    <Row className="my-5 align-items-center">
                        <Col md="6">
                            <h2 className="text-center">
                                {this.props.t(
                                    `PERSONALITY_${this.props.personality}_PROMO_TITLE`
                                )}
                            </h2>
                            <p>
                                {this.props.t(
                                    `PERSONALITY_${this.props.personality}_PROMO_SECTION`
                                )}
                            </p>
                        </Col>
                        <Col md="6">
                            <div className="primary-box">
                                <h2>
                                    {this.props.t(
                                        `PERSONALITY_${this.props.personality}_IMG`
                                    )}
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <h2 className="text-center">
                        {this.props.t(
                            'psychometric/how-your-diet-should-look-like'
                        )}
                    </h2>
                    <p>
                        {this.props.t(
                            `PERSONALITY_${this.props.personality}_SECTION_2`
                        )}
                    </p>
                </Container>
            </section>
        );
    }
}

export default withLocale(PsychometricResult);
