import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import DayPlanSetMealEatenMutation from './DayPlanSetMealEatenMutation';
import DayPlanQuery from '../Fetch/DayPlanQuery';

class DayPlanSetMealEatenConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
    };

    createSetMealEaten = mutate => async ({ setId, mealId, eaten }) => {
        const variables = {
            date: formatIsoDate(this.props.date),
            setId,
            mealId,
            eaten,
        };

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children, date } = this.props;

        return (
            <Mutation
                mutation={DayPlanSetMealEatenMutation}
                awaitRefetchQueries
                refetchQueries={() => [
                    {
                        query: DayPlanQuery,
                        variables: {
                            date: formatIsoDate(date),
                            canBeCopiedToDate: formatIsoDate(addDays(date, 1)),
                        },
                    },
                ]}
            >
                {mutate =>
                    children({
                        setMealEaten: this.createSetMealEaten(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default DayPlanSetMealEatenConnectorApollo;
