import gql from 'graphql-tag';

const query = gql`
    query DayReplacement($date: Date!, $text: String) {
        me {
            id
            diet {
                set(date: $date) {
                    ... on UserDietSet {
                        dietSetId
                        original {
                            dietSetId
                            events {
                                __typename
                                ... on Meal {
                                    id
                                    name
                                    dishes {
                                        id
                                        name
                                        ingredients {
                                            name
                                            productId
                                        }
                                    }
                                    preparationImageUrl
                                    publicName
                                }
                            }
                            categories {
                                id
                                name
                            }
                            active
                        }
                        replacements(text: $text) {
                            dietSetId
                            holiday {
                                identifier
                                name
                                description
                                icon
                            }
                            events {
                                __typename
                                ... on Meal {
                                    id
                                    name
                                    dishes {
                                        id
                                        name
                                        ingredients {
                                            name
                                            productId
                                        }
                                    }
                                    preparationImageUrl
                                    publicName
                                }
                            }
                            categories {
                                id
                                name
                            }
                            active
                        }
                    }
                }
            }
        }
    }
`;

export default query;
