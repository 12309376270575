import { dietSettingsGetSchemaForParameters } from '../SchemaForParameters/DietSettingsSchemaForParametersAction';
import { dietSettingsGetCurrentStepPositon } from '../CurrentStepPosition/DietSettingsGetCurrentStepPositionAction';

export const dietSettingsGetNextStep = (
    schema,
    parameters,
    currentStepIdentifier
) => {
    const schemaForParameters = dietSettingsGetSchemaForParameters(
        schema,
        parameters
    );
    const currentStepPositon = dietSettingsGetCurrentStepPositon(
        schema,
        parameters,
        currentStepIdentifier
    );

    let result;

    if (currentStepPositon === schemaForParameters.length) {
        result = null;
    } else if (currentStepPositon < schemaForParameters.length) {
        // because:
        // +1 next step
        // -1 array is numerated from 0
        result = schemaForParameters[currentStepPositon].id;
    }

    return result;
};
