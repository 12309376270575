import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

class SettingsProductsPlaceholder extends PureComponent {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        const { t, className } = this.props;
        return (
            <Container className={className}>
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <h2>{t('settings/products/heading')}</h2>
                            <h3>{t('settings/products/diet/heading')}</h3>
                            <p className="placeholder">&nbsp;</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { SettingsProductsPlaceholder };
export default withLocale(SettingsProductsPlaceholder);
