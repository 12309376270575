import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Form, Alert } from 'reactstrap';
import Loader from 'Layout/Loader';
import { Redirect, withRouter } from 'react-router';
import { PATHS } from 'config/paths';
import withMobileAppMode from 'Layout/withMobileAppMode';
import Layout from 'Layout/Layout';
import { FLASH_MESSAGES } from 'consts';

import InputPassword from 'components/FormElements/InputPasswordControlled';

import { validateField } from 'view/Validation/validateField';
import { validateFields } from 'view/Validation/validateFields';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { withLocale } from '@dietlabs/components';
import withFlashMessage from '@dietlabs/components/src/FlashMessage/withFlashMessage';
import {
    FlashMessageSuccess,
    FlashMessageError,
} from '@dietlabs/components/src/FlashMessage/FlashMessage';

export class AuthResetPasswordComponent extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                token: PropTypes.string,
            }).isRequired,
        }).isRequired,
        resetPassword: PropTypes.func.isRequired,
        validateToken: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        addMessage: PropTypes.func.isRequired,
    };

    state = {
        password: '',
        preload: false,
        tokenError: false,
        error: false,
        success: false,
        errors: new ValidationErrors(),
    };

    validationRules = {
        password: [new RequiredRule({ translator: this.props.t })],
    };

    componentDidMount = async () => {
        const token = this.props.match.params.token;
        const response = await this.props.validateToken({ token });
        const { validateResetPasswordToken } = response.data.auth;

        try {
            if (!validateResetPasswordToken) {
                this.props.addMessage(
                    new FlashMessageError(
                        this.props.t(
                            'auth/reset-password/token-is-not-valid-error-message'
                        ),
                        FLASH_MESSAGES.AUTH.RESET_PASSWORD.FAILED
                    )
                );
                this.setState({ tokenError: true });
            }
        } catch (e) {
            throw new Error(
                `Failed to validate reset password token, got error: ${e}`
            );
        }
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        this.validateInput(event.target.name, event.target.value);
    };

    validateInput = (field, value) => {
        if (this.validationRules[field]) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            try {
                const response = await this.props.resetPassword({
                    token: this.props.match.params.token,
                    password: this.state.password,
                });
                const { code, details } = response.data.auth.resetPassword;

                if (code === 200) {
                    this.setState({
                        password: '',
                        error: false,
                        errors: new ValidationErrors(),
                        success: true,
                        preload: false,
                    });
                    this.props.addMessage(
                        new FlashMessageSuccess(
                            this.props.t('auth/reset-password/success-message'),
                            FLASH_MESSAGES.AUTH.RESET_PASSWORD.SUCCESS
                        )
                    );
                } else {
                    for (let i = 0; i < details.length; i += 1) {
                        const error = details[i];
                        if (error.fieldName in this.validationRules) {
                            this.setState({
                                errors: response.data.auth.resetPassword,
                            });
                            break;
                        }
                    }
                    this.setState({
                        error: true,
                        success: false,
                        preload: false,
                    });
                }
            } catch (e) {
                this.setState({
                    success: false,
                    preload: false,
                });
                throw new Error(`Failed to set new password, got error: ${e}`);
            }
        }
    };

    render() {
        if (!this.props.mobileAppMode && this.state.success) {
            return <Redirect to={PATHS.AUTH.LOGIN} />;
        }

        if (this.state.tokenError) {
            return <Redirect to={PATHS.AUTH.LOGIN} />;
        }

        return (
            <Layout page="login">
                <section className="bg-white">
                    <Container className="text-center">
                        <h1>{this.props.t('auth/reset-password/title')}</h1>
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                {this.state.error ? (
                                    <Alert color="danger">
                                        {this.props.t(
                                            'auth/reset-password/error-message'
                                        )}
                                    </Alert>
                                ) : (
                                    ''
                                )}
                                <Form
                                    onSubmit={this.handleSubmit}
                                    data-test="reset-password-form"
                                >
                                    <InputPassword
                                        label={this.props.t('password')}
                                        name="password"
                                        value={this.state.password}
                                        errors={this.state.errors}
                                        handleChange={this.handleInputChange}
                                        validationRules={
                                            this.validationRules.password
                                        }
                                        data-test="password-input"
                                    />
                                    <Button
                                        color="primary"
                                        data-test="reset-password-button"
                                    >
                                        {this.props.t('form/send')}
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.state.preload ? <Loader /> : ''}
            </Layout>
        );
    }
}

export default withMobileAppMode(
    withFlashMessage(withRouter(withLocale(AuthResetPasswordComponent)))
);
