import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import InterventionsListFetchQuery from './InterventionsListFetchQuery';

class InterventionsFetchListConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        period: PropTypes.shape().isRequired,
    };

    render() {
        const { children, period } = this.props;

        return (
            <Query
                query={InterventionsListFetchQuery}
                variables={{ period }}
                fetchPolicy="no-cache"
            >
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.interventionsList = deepClone(
                            data.me.intervention
                        );
                    }
                    return children(result);
                }}
            </Query>
        );
    }
}

export default InterventionsFetchListConnectorApollo;
