import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';

import PsychometricQuestionsQuery from './PsychometricQuestionsQuery';

class PsychotestQuestionsConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Query
                query={PsychometricQuestionsQuery}
                variables={{ isFemale: true }}
                fetchPolicy="no-cache"
            >
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.psychotest = data.psychotest.questions;
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default PsychotestQuestionsConnectorApollo;
