import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Timeline from 'Layout/Timeline';
import { Link } from 'react-router-dom';

import { holidayIcons } from 'consts';
import { createDateObject } from 'utils/dateFormatter';
import { reportWarning } from '@dietlabs/utils';
import { withLocale } from '@dietlabs/components';
import withLoading from 'Layout/withLoading';
import TimelinePlaceholder from 'Layout/Timeline/TimelinePlaceholder';
import { getHolidayIconName } from 'DayPlan/utils/getHolidayIconName';

import { PATH_DAY_PLAN_INDEX } from '../DayPlanPaths';

class DayPlanTimeline extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        availableDays: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.string.isRequired,
                holiday: PropTypes.shape({
                    identifier: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                }),
                dayPlan: PropTypes.shape({
                    diet: PropTypes.shape({
                        originalPgase: PropTypes.shape({
                            identifier: PropTypes.string.isRequired,
                            name: PropTypes.string.isRequired,
                        }),
                    }),
                }),
            })
        ).isRequired,
        additionalDatesCount: PropTypes.number,
    };

    static defaultProps = {
        additionalDatesCount: 14,
    };

    state = {
        scrollPos: 0,
        show: false,
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.setState(prevState => ({
            scrollPos: document.body.getBoundingClientRect().top,
            show:
                document.body.getBoundingClientRect().top > prevState.scrollPos,
        }));
    };

    getDates() {
        const values = this.props.availableDays.map(day =>
            createDateObject(day.date)
        );

        if (values.length !== 0) {
            const lastDateValue = values[values.length - 1].valueOf();

            for (
                let idx = 1;
                idx <= this.props.additionalDatesCount;
                idx += 1
            ) {
                const date = new Date(lastDateValue);
                date.setDate(date.getDate() + idx);
                values.push(date);
            }
        }

        return values;
    }

    renderHolidayIcon(isoDate) {
        const iconName = getHolidayIconName(this.props.availableDays, isoDate);
        if (iconName) {
            const Icon = holidayIcons[iconName];
            if (Icon) {
                return (
                    <span className="SVGInline ml-2">
                        <Icon />
                    </span>
                );
            }

            reportWarning(new Error(`Unknown holiday icon: ${iconName}`));
        }
        return '';
    }

    renderPhase(isodate) {
        const phaseDay = this.props.availableDays.find(
            day => day.date === isodate
        );
        if (phaseDay) {
            return phaseDay.dayPlan.diet.phase.identifier.toLowerCase();
        }

        return '';
    }

    render() {
        const { date, ...timelineProps } = this.props;

        return (
            <div
                className={
                    this.state.show
                        ? 'timeline-container active'
                        : 'timeline-container hidden'
                }
            >
                <Timeline
                    {...timelineProps}
                    activeDate={date}
                    dates={this.getDates()}
                >
                    {({ active, isoDate, formattedDate, linkClassName }) => (
                        <Link
                            {...(active ? { 'aria-current': 'page' } : {})}
                            to={`${PATH_DAY_PLAN_INDEX}/${isoDate}`}
                            className={`${linkClassName} ${this.renderPhase(
                                isoDate
                            )}`}
                            data-test="day-pill"
                        >
                            {formattedDate}
                            {this.renderHolidayIcon(isoDate)}
                        </Link>
                    )}
                </Timeline>
            </div>
        );
    }
}

export { DayPlanTimeline };
export default withLoading(withLocale(DayPlanTimeline), TimelinePlaceholder);
