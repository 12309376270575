import React from 'react';
import RemoveUserAccountConnectorApollo from './ConnectorOld/RemoveUserAccount/RemoveUserAccountConnectorApollo';
import RemoveAccountConfrimContainer from './RemoveAccountConfrimContainer';

const RemoveAccountConfirmPage = () => {
    return (
        <RemoveUserAccountConnectorApollo>
            {RemoveAccountData => {
                return <RemoveAccountConfrimContainer {...RemoveAccountData} />;
            }}
        </RemoveUserAccountConnectorApollo>
    );
};

export default RemoveAccountConfirmPage;
