import gql from 'graphql-tag';

export default gql`
    mutation DietSetMealEatenMutation(
        $date: Date!
        $setId: Int!
        $mealId: Int!
        $eaten: Boolean
    ) {
        me {
            dietSetMealEaten(
                date: $date
                setId: $setId
                mealId: $mealId
                eaten: $eaten
            ) {
                __typename
                ... on UnauthorizedException {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on BasicMutationSuccess {
                    code
                }
            }
        }
    }
`;
