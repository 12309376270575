import gql from 'graphql-tag';

const query = gql`
    query UserData {
        me {
            id
            name
            sex
            email
            passwordIsSet
            hasExternalProviderSetUp
        }
    }
`;

export default query;
