import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import { PATHS } from 'config/paths';
import ShoppingCartDelivery from 'view/ShoppingCart/Delivery/ShoppingCartDelivery';

export class ShoppingCartOrderDeliverySummaryComponent extends React.Component {
    render() {
        const {
            name,
            address,
            city,
            country,
            phone,
            postalCode,
        } = this.props.delivery;
        return (
            <div className="white-box h-100">
                <h2>{this.props.t('cart/address-of-delivery')}</h2>
                <p>
                    <span data-test="name">{name}</span>,<br />
                    <span data-test="address">{address}</span>
                    <br />
                    <span data-test="postalCode">{postalCode}</span>{' '}
                    <span data-test="city">{city}</span>
                    <br />
                    <span data-test="country">{country.name}</span>
                    <br />
                    <span data-test="phone">{phone}</span>
                </p>
                <p className="mb-0">
                    {this.props.isAbleToProcess ? (
                        <Link data-test="change" to={PATHS.CART.INDEX}>
                            {this.props.t('cart/change')}
                        </Link>
                    ) : (
                        ''
                    )}
                </p>
            </div>
        );
    }
}

ShoppingCartOrderDeliverySummaryComponent.propTypes = {
    t: PropTypes.func.isRequired,
    isAbleToProcess: PropTypes.bool.isRequired,
    delivery: PropTypes.instanceOf(ShoppingCartDelivery).isRequired,
};

export default withLocale(ShoppingCartOrderDeliverySummaryComponent);
