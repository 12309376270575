import React from 'react';
import PropTypes from 'prop-types';
import HpbaMarkdown from 'Content/utils/HpbaMarkdown';

import COPING_DODGING from './results/COPING_DODGING.md';
import COPING_MIXED_BOTH from './results/COPING_MIXED_BOTH.md';
import COPING_MIXED_DODGING from './results/COPING_MIXED_DODGING.md';
import COPING_MIXED_NEIGHTER from './results/COPING_MIXED_NEIGHTER.md';
import COPING_MIXED from './results/COPING_MIXED.md';
import COPING_TASK from './results/COPING_TASK.md';
import MOTIVATION_EXT_NEG from './results/MOTIVATION_EXT_NEG.md';
import MOTIVATION_EXT_POS_EXT_NEG from './results/MOTIVATION_EXT_POS_EXT_NEG.md';
import MOTIVATION_EXT_POS from './results/MOTIVATION_EXT_POS.md';
import MOTIVATION_INT_EXT_NEG from './results/MOTIVATION_INT_EXT_NEG.md';
import MOTIVATION_INT_EXT_POS from './results/MOTIVATION_INT_EXT_POS.md';
import MOTIVATION_INT from './results/MOTIVATION_INT.md';
import MOTIVATION_MIXED_HIGH from './results/MOTIVATION_MIXED_HIGH.md';
import MOTIVATION_MIXED_LOW from './results/MOTIVATION_MIXED_LOW.md';
import MOTIVATION_MIXED from './results/MOTIVATION_MIXED.md';
import PROFILE_MOTIVATED from './results/PROFILE_MOTIVATED.md';
import PROFILE_SELF_CONTROL_MASTER from './results/PROFILE_SELF_CONTROL_MASTER.md';
import PROFILE_WAITING_FOR_MIRACLE from './results/PROFILE_WAITING_FOR_MIRACLE.md';
import WILLPOWER_HIGH from './results/WILLPOWER_HIGH.md';
import WILLPOWER_LOW from './results/WILLPOWER_LOW.md';
import WILLPOWER_MEDIUM from './results/WILLPOWER_MEDIUM.md';

class MotivationResult extends React.Component {
    static propTypes = {
        content: PropTypes.string.isRequired,
    };

    state = {
        content: '',
    };

    componentDidMount() {
        let filename;

        switch (this.props.content) {
            case 'COPING_DODGING':
                filename = COPING_DODGING;
                break;
            case 'COPING_MIXED_BOTH':
                filename = COPING_MIXED_BOTH;
                break;
            case 'COPING_MIXED_DODGING':
                filename = COPING_MIXED_DODGING;
                break;
            case 'COPING_MIXED_NEIGHTER':
                filename = COPING_MIXED_NEIGHTER;
                break;
            case 'COPING_MIXED':
                filename = COPING_MIXED;
                break;
            case 'COPING_TASK':
                filename = COPING_TASK;
                break;
            case 'MOTIVATION_EXT_NEG':
                filename = MOTIVATION_EXT_NEG;
                break;
            case 'MOTIVATION_EXT_POS_EXT_NEG':
                filename = MOTIVATION_EXT_POS_EXT_NEG;
                break;
            case 'MOTIVATION_EXT_POS':
                filename = MOTIVATION_EXT_POS;
                break;
            case 'MOTIVATION_INT_EXT_NEG':
                filename = MOTIVATION_INT_EXT_NEG;
                break;
            case 'MOTIVATION_INT_EXT_POS':
                filename = MOTIVATION_INT_EXT_POS;
                break;
            case 'MOTIVATION_INT':
                filename = MOTIVATION_INT;
                break;
            case 'MOTIVATION_MIXED_HIGH':
                filename = MOTIVATION_MIXED_HIGH;
                break;
            case 'MOTIVATION_MIXED':
                filename = MOTIVATION_MIXED;
                break;
            case 'MOTIVATION_MIXED_LOW':
                filename = MOTIVATION_MIXED_LOW;
                break;
            case 'PROFILE_MOTIVATED':
                filename = PROFILE_MOTIVATED;
                break;
            case 'PROFILE_SELF_CONTROL_MASTER':
                filename = PROFILE_SELF_CONTROL_MASTER;
                break;
            case 'PROFILE_WAITING_FOR_MIRACLE':
                filename = PROFILE_WAITING_FOR_MIRACLE;
                break;
            case 'WILLPOWER_HIGH':
                filename = WILLPOWER_HIGH;
                break;
            case 'WILLPOWER_LOW':
                filename = WILLPOWER_LOW;
                break;
            case 'WILLPOWER_MEDIUM':
                filename = WILLPOWER_MEDIUM;
                break;
            default:
                filename = null;
        }

        fetch(filename)
            .then(response => response.text())
            .then(text => {
                this.setState({ content: text });
            });
    }

    render() {
        return <HpbaMarkdown content={this.state.content} />;
    }
}

export default MotivationResult;
