import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InputTextarea from 'components/FormElements/InputTextareaControlled';

class InterventionTaskTextComponent extends React.Component {
    static propTypes = {
        task: PropTypes.shape({
            id: PropTypes.string,
            answers: PropTypes.arrayOf(PropTypes.string),
            label: PropTypes.string,
        }).isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        editMode: PropTypes.bool.isRequired,
    };

    state = {
        messages:
            this.props.task.answers.length > 0 ? this.props.task.answers : [''],
    };

    handleInputChange = event => {
        const name = parseInt(event.target.name.replace('input', ''), 10);
        const value = event.target.value;

        this.setState(prevState => {
            let newMessages;
            if (prevState.messages.length > 0) {
                newMessages = prevState.messages.map((item, i) => {
                    if (i === name) {
                        return value;
                    }
                    return item;
                });
            } else {
                newMessages = [value];
            }

            this.props.setTaskAnswers(
                parseInt(this.props.task.id, 10),
                newMessages
            );

            return { messages: newMessages };
        });
    };

    renderForm = () => {
        if (this.state.messages.length > 0) {
            return this.state.messages.map((message, idx) => (
                <InputTextarea
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    label={this.props.task.label || ''}
                    name={`input${idx}`}
                    value={message}
                    handleChange={this.handleInputChange}
                    disabled={!this.props.editMode}
                />
            ));
        }

        return (
            <InputTextarea
                label={this.props.task.label || ''}
                name="input0"
                value=""
                handleChange={this.handleInputChange}
                disabled={!this.props.editMode}
            />
        );
    };

    render() {
        return <div className="mt-3">{this.renderForm()}</div>;
    }
}

export default withLocale(InterventionTaskTextComponent);
