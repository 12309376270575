import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import InterventionFetchQuery from './InterventionFetchQuery';

class InterventionFetchConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        period: PropTypes.shape().isRequired,
    };

    render() {
        const { children, period } = this.props;

        return (
            <Query
                query={InterventionFetchQuery}
                variables={{ period }}
                fetchPolicy="no-cache"
            >
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.intervention = deepClone(data.me.intervention);
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default InterventionFetchConnectorApollo;
