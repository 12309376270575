import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { subDays } from 'utils/date';
import { formatIsoDate } from 'utils/dateFormatter';
import MeasurementHistoryQuery from './MeasurementHistoryQuery';

export class MeasurementHistoryConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
        initialMeasurementType: PropTypes.string.isRequired,
        periodLength: PropTypes.number.isRequired,
        periodEnd: PropTypes.instanceOf(Date).isRequired,
    };

    state = {
        loading: true,
        history: null,
        goalWeight: null,
        error: null,
        measurementType: this.props.initialMeasurementType,
        periodLength: this.props.periodLength,
        periodEnd: this.props.periodEnd,
    };

    async componentDidMount() {
        const { measurementType, periodLength, periodEnd } = this.state;
        // can't use fetchPolicy cache-and-network because apollo...
        // has problems with proper caching query with variables using <Query /> https://github.com/apollographql/react-apollo/issues/2202
        // not supported if firing query manually https://github.com/apollographql/apollo-client/issues/3130 (but it will change soon - hopefully)
        await this.load(measurementType, periodLength, periodEnd);
    }

    load = (measurementType, periodLength, periodEnd) => {
        this.setState({
            loading: true,
            measurementType,
            periodLength,
            periodEnd,
        });
        const datePeriod = {
            start: formatIsoDate(subDays(periodEnd, periodLength - 1)),
            end: formatIsoDate(periodEnd),
        };
        this.props.client
            .query({
                query: MeasurementHistoryQuery,
                variables: {
                    datePeriod,
                    [measurementType]: true,
                },
                fetchPolicy: 'no-cache',
            })
            .then(response =>
                this.setState(
                    {
                        loading: false,
                        history: response.data.me.history[measurementType],
                        goalWeight: response.data.me.goalWeight,
                    },
                    () => response
                )
            )
            .catch(error => this.setState({ error }));
    };

    render() {
        const { children } = this.props;
        const {
            loading,
            history,
            goalWeight,
            error,
            measurementType,
            periodLength,
            periodEnd,
        } = this.state;
        if (error) {
            throw error;
        }

        if (loading) {
            return children({
                loading,
                measurementType,
                periodLength,
                periodEnd,
            });
        }

        return children({
            loading,
            load: this.load,
            history,
            goalWeight,
            measurementType,
            periodLength,
            periodEnd,
        });
    }
}

export default withApollo(MeasurementHistoryConnectorApollo);
