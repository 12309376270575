import React from 'react';

import ArticleListFetchConnectorApollo from '../Connector/Apollo/FetchList/ArticleListFetchConnectorApollo';
import ArticleListByCategoryConnectorApollo from '../Connector/Apollo/FetchList/ArticleListByCategoryConnectorApollo';
import ArticleListContainer from './ArticleListContainer';

class ArticleListPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <ArticleListFetchConnectorApollo>
                {({ ...articleList }) => (
                    <ArticleListByCategoryConnectorApollo>
                        {({ ...loadArticlesByCategory }) => (
                            <ArticleListContainer
                                {...articleList}
                                {...loadArticlesByCategory}
                            />
                        )}
                    </ArticleListByCategoryConnectorApollo>
                )}
            </ArticleListFetchConnectorApollo>
        );
    }
}

export default ArticleListPage;
