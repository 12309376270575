import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Events, scrollSpy, animateScroll } from 'react-scroll';

import scrollToElement from 'components/Helpers/scrollToElement';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

class DayPlanMealLink extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        mealId: PropTypes.number.isRequired,
        triggerOpenMeal: PropTypes.func.isRequired,
    };

    activeItemRef = React.createRef();

    componentDidMount() {
        Events.scrollEvent.register('begin', to => {
            if (to) {
                this.props.triggerOpenMeal(to);
                animateScroll.scrollMore(1);
            }
        });

        // hack to hide timeline
        Events.scrollEvent.register('end', to => {
            if (to) {
                animateScroll.scrollMore(1);
            }
        });

        scrollSpy.update();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    handleSetActive = () => {
        this.scrollToActive();
    };

    scrollToActive() {
        const activeItem = this.activeItemRef.current;
        if (!activeItem) {
            return;
        }
        // eslint-disable-next-line react/prop-types
        const list = this.props.listRef;
        scrollToElement(activeItem, {
            parent: list,
            align: 'center',
        });
    }

    render() {
        return (
            <li className="nav-item" ref={this.activeItemRef}>
                <Link
                    className="nav-link"
                    activeClass="active"
                    to={`meal${this.props.mealId}`}
                    spy
                    smooth
                    offset={-40}
                    onSetActive={this.handleSetActive}
                >
                    {this.props.name}
                </Link>
            </li>
        );
    }
}

export default DayPlanMealLink;
