import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';
import Query from './ShoppingCartPaymentLoadQuery';
import ShoppingCartPaymentMethod from '../ShoppingCartPaymentMethod';

const parseMethods = rawMethods => {
    const result = [];

    rawMethods.forEach(method => {
        const isValid =
            method.methodId !== undefined &&
            method.name !== undefined &&
            method.description !== undefined &&
            method.logoUrl !== undefined;

        if (isValid) {
            result.push(
                new ShoppingCartPaymentMethod(
                    method.methodId,
                    method.channelId,
                    method.name,
                    method.description,
                    method.logoUrl,
                    method.wideViewportLogoUrl
                )
            );
        }
    });

    if (!result.length) {
        throw new Error();
    }

    return result;
};

export const shoppingCartPaymentMethodsLoadAction = () =>
    GraphQLFetch.runQuery(Query, {}).then(res =>
        parseMethods(res.data.sale.payment.methods)
    );
