import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import deepClone from 'utils/deepClone';

import mutation from './HydrationDayPortionRemoveMutation';

class HydrationDayPortionRemoveConnectorApollo extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        queryResult: PropTypes.object.isRequired,
    };

    createPortionRemove = mutate => portionId => {
        const variables = {
            portionId,
        };

        const { hydrationDay: hydrationDayResult, ...meResult } = deepClone(
            this.props.queryResult.me
        );
        const optimisticResponse = {
            me: {
                ...meResult,
                hydrationDayRemovePortion: {
                    ...hydrationDayResult,
                    portions: hydrationDayResult.portions.filter(
                        ({ id }) => id !== portionId
                    ),
                },
            },
        };

        return mutate({
            variables,
            optimisticResponse,
        });
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={mutation}>
                {(mutate, { data: rawData }) =>
                    children(this.createPortionRemove(mutate), {
                        data: rawData
                            ? rawData.me.hydrationDayRemovePortion
                            : null,
                    })
                }
            </Mutation>
        );
    }
}

export { HydrationDayPortionRemoveConnectorApollo };
export default HydrationDayPortionRemoveConnectorApollo;
