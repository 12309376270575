import React, { Component } from 'react';
import { ReactComponent as WaterIcon } from 'assets/icons/hydration/water_icon.svg';

class HydrationDayGoalHeader extends Component {
    render() {
        return (
            <div className="goal-header placeholder">
                <WaterIcon className="mr-2" />
                goal header placeholder
            </div>
        );
    }
}

export default HydrationDayGoalHeader;
