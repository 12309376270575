import React from 'react';
import PropTypes from 'prop-types';
import { Label, FormGroup } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import InputRadio from 'components/FormElements/InputRadioControlled';

class PsychometricQuestion extends React.Component {
    static propTypes = {
        question: PropTypes.string.isRequired,
        questionAnswer: PropTypes.number.isRequired,
        identifier: PropTypes.number.isRequired,
        answers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        setAnswer: PropTypes.func.isRequired,
    };

    handleInputChange = event => {
        const value = parseInt(event.target.value, 10);
        this.props.setAnswer(this.props.identifier, value);
    };

    render() {
        return (
            <div className="question">
                <FormGroup tag="fieldset">
                    <Label>{this.props.question}</Label>
                    {this.props.answers.map(answer => (
                        <InputRadio
                            key={answer.id}
                            label={answer.text}
                            name="q"
                            value={answer.id}
                            handleChange={this.handleInputChange}
                            checked={this.props.questionAnswer === answer.id}
                        />
                    ))}
                </FormGroup>
            </div>
        );
    }
}

export default withLocale(PsychometricQuestion);
