/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import scrollToElement from 'components/Helpers/scrollToElement';
import smoothscroll from 'smoothscroll-polyfill';
import PropTypes from 'prop-types';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { withLocale } from '@dietlabs/components';
import Menu from 'Layout/Menu';
import iconBack from 'assets/images/dayplan/icon-back.svg';

smoothscroll.polyfill();

class DayPlanMealNavigation extends Component {
    static propTypes = {
        activeMeal: PropTypes.number,
        changeMeal: PropTypes.func.isRequired,
        dietPhase: PropTypes.string.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
    };

    static defaultProps = {
        activeMeal: undefined,
    };

    listRef = React.createRef();

    activeItemRef = React.createRef();

    componentDidMount() {
        this.scrollToActive();
    }

    scrollToActive() {
        const activeItem = this.activeItemRef.current;
        if (!activeItem) {
            return;
        }
        const list = this.listRef.current;

        scrollToElement(activeItem, {
            parent: list,
            align: 'center',
        });
    }

    handleMealNavClick(event, mealId) {
        event.preventDefault();
        this.props.changeMeal(mealId);
    }

    formatDate = (date, isShort) => {
        const { t } = this.props;

        const weekday = date.getDay();
        // const day = date.getDate();
        // const day0 = day < 10 ? `0${day}` : day;
        // const month = date.getMonth() + 1;
        // const month0 = month < 10 ? `0${month}` : month;
        let dayName = t(`meal/weekday${weekday}`);

        if (isShort) {
            dayName = t(`meal/weekday${weekday}-short`);
        }
        // return `${dayName} ${day0}.${month0}`;
        return `${dayName}`;
    };

    render() {
        return (
            <React.Fragment>
                {!this.props.mobileAppMode && (
                    <React.Fragment>
                        <Menu forceReloadLinks={false} />
                        <div className="top-bar border-bottom">
                            <Container className="px-3 px-md-0">
                                <h1 className="text-center py-3">
                                    <Button
                                        color="link"
                                        onClick={() => window.history.back()}
                                        className="mr-4 float-left p-0"
                                    >
                                        <img
                                            src={iconBack}
                                            className="mr-1"
                                            alt=""
                                        />
                                        {this.props.t('back')}
                                    </Button>
                                    <span className="d-none d-md-inline">
                                        {this.formatDate(
                                            this.props.date,
                                            false
                                        )}
                                    </span>
                                    <span className="d-md-none">
                                        {this.formatDate(this.props.date, true)}
                                    </span>
                                    , {this.props.kcal} kcal
                                </h1>
                            </Container>
                        </div>
                    </React.Fragment>
                )}
                <section className="meal-navigation">
                    <Container>
                        <ul ref={this.listRef}>
                            {this.props.events.map(event => {
                                if (event.__typename === 'Meal') {
                                    return (
                                        <li
                                            className="nav-item"
                                            ref={
                                                this.props.activeMeal ===
                                                event.id
                                                    ? this.activeItemRef
                                                    : ''
                                            }
                                            key={event.key}
                                        >
                                            <a
                                                href=" "
                                                className={
                                                    this.props.activeMeal ===
                                                    event.id
                                                        ? `nav-link active ${this.props.dietPhase}`
                                                        : 'nav-link'
                                                }
                                                onClick={e =>
                                                    this.handleMealNavClick(
                                                        e,
                                                        event.id
                                                    )
                                                }
                                            >
                                                {event.name}
                                            </a>
                                        </li>
                                    );
                                }
                                return '';
                            })}
                        </ul>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default withMobileAppMode(withLocale(DayPlanMealNavigation));
