import React from 'react';
import Layout from 'Layout/Layout';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col, Button, Form } from 'reactstrap';
import InputEmail from 'components/FormElements/InputEmailControlled';

import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import EmailFormatRule from 'view/Validation/ValidationRules/EmailFormatRule';

import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';

import tasteOfWeightLoss from 'assets/images/guides/taste-of-weight-loss.png';

import feature1 from 'assets/images/psychometric/grocery.png';
import feature2 from 'assets/images/psychometric/measure.png';
import feature3 from 'assets/images/psychometric/colored-circles.svg';

import { Link as ScrollLink } from 'react-scroll';

class PsychometricEmailForm extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        handleSubmitEmailForm: PropTypes.func.isRequired,
    };

    validationRules = {
        email: [
            new RequiredRule({ translator: this.props.t }),
            new EmailFormatRule({ translator: this.props.t }),
        ],
    };

    state = {
        email: undefined,
        errors: new ValidationErrors(),
        // success: false,
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.props.handleSubmitEmailForm(this.state.email);
        }
    };

    render() {
        return (
            <Layout page="psychometric-test">
                <section>
                    <Container className="text-center">
                        <Row className="align-items-center">
                            <Col xs="12" md="6">
                                <div className="bubble">
                                    {this.props.t('psychometric/bubble')}
                                </div>
                            </Col>
                            <Col xs="12" md="6" id="show-results">
                                <h2 className="mb-5">
                                    {this.props.t('psychometric/email-title')}
                                </h2>
                                <Form onSubmit={this.handleSubmit} noValidate>
                                    <InputEmail
                                        label={this.props.t(
                                            'psychometric/email-address'
                                        )}
                                        name="email"
                                        value={this.state.email}
                                        errors={this.state.errors}
                                        handleChange={this.handleInputChange}
                                        validationRules={
                                            this.validationRules.email
                                        }
                                    />

                                    <div className="text-center">
                                        <Button color="primary">
                                            {this.props.t(
                                                'psychometric/show-test-results'
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="py-0">
                    <Container>
                        <aside className="did-you-know">
                            <p>{this.props.t('psychometric/did-you-knpw')}</p>
                        </aside>
                    </Container>
                </section>

                <section className="pb-0">
                    <Container className="text-center">
                        <h2>{this.props.t('psychometric/why')}</h2>
                        <Row>
                            <Col xs="12" md="4">
                                <img
                                    className="feature"
                                    src={feature1}
                                    alt=""
                                />
                                <p>
                                    {this.props.t('psychometric/why/answer1')}
                                </p>
                            </Col>
                            <Col xs="12" md="4">
                                <img
                                    className="feature"
                                    src={feature2}
                                    alt=""
                                />
                                <p>
                                    {this.props.t('psychometric/why/answer2')}
                                </p>
                            </Col>
                            <Col xs="12" md="4">
                                <img
                                    className="feature"
                                    src={feature3}
                                    alt=""
                                />
                                <p>
                                    {this.props.t('psychometric/why/answer3')}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section>
                    <Container className="text-center">
                        <h2 className="heading-2 mb-4">
                            {this.props.t('psychometric/guide/title')}
                        </h2>

                        <Col
                            xs={{ size: 10, offset: 1 }}
                            md={{ size: 4, offset: 4 }}
                            className="text-center mb-5"
                        >
                            <ScrollLink
                                className="scroll-link"
                                to="show-results"
                                smooth
                                duration={500}
                            >
                                <img
                                    src={tasteOfWeightLoss}
                                    className="img-fluid guide"
                                    alt=""
                                />
                            </ScrollLink>

                            <ScrollLink
                                className="btn btn-primary scroll-link"
                                to="show-results"
                                smooth
                                duration={500}
                            >
                                {this.props.t('psychometric/show-result')}
                            </ScrollLink>
                        </Col>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(PsychometricEmailForm);
