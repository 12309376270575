import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { ApiDateType } from 'types/CommonTypes';
import { createDateObject, formatIsoDate } from 'utils/dateFormatter';

import DayPlanForTomorrowConnectorApollo from 'DayPlan/Index/Connector/Apollo/Fetch/DayPlanForTomorrowConnectorApollo';
import DayPlanConnectorApollo from './Connector/Apollo/Fetch/DayPlanConnectorApollo';
import DayPlanCacheConnectorApollo from './Connector/Apollo/Fetch/DayPlanCacheConnectorApollo';
import DayPlanCopyConnectorApollo from './Connector/Apollo/Copy/DayPlanCopyConnectorApollo';
import DayPlanReplaceProductConnectorApollo from './Connector/Apollo/ReplaceProduct/DayPlanReplaceProductConnectorApollo';
import DayPlanMealReplacementsConnectorApollo from './Connector/Apollo/ReplaceMeal/DayPlanMealReplacementsConnectorApollo';
import DayPlanReplaceMealConnectorApollo from './Connector/Apollo/ReplaceMeal/DayPlanReplaceMealConnectorApollo';
import DayPlanDayReplacementsConnectorApollo from './Connector/Apollo/ReplaceDay/DayPlanDayReplacementsConnectorApollo';
import DayPlanReplaceDayConnectorApollo from './Connector/Apollo/ReplaceDay/DayPlanReplaceDayConnectorApollo';
import DayPlanSetMealEatenConnectorApollo from './Connector/Apollo/EatenMeal/DayPlanSetMealEatenConnectorApollo';

import DayPlanIndexContainer from './DayPlanIndexContainer';

class DayPlanIndex extends Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                date: ApiDateType,
            }).isRequired,
            path: PropTypes.string,
        }).isRequired,
        history: PropTypes.shape({
            location: PropTypes.shape({
                state: PropTypes.shape({
                    forceRefreshData: PropTypes.bool,
                }),
            }).isRequired,
        }).isRequired,
        routeChildrenMounted: PropTypes.bool.isRequired,
    };

    render() {
        let date;

        if (this.props.match.params.date) {
            date = createDateObject(this.props.match.params.date);
        } else {
            date = createDateObject(formatIsoDate(new Date()));
        }

        let forceRefreshData = false;

        if (this.props.history.location.state) {
            forceRefreshData = this.props.history.location.state
                .forceRefreshData;
        }

        // hack - wait until routeChildren component did mount and send routeChanged post msg to native aplication
        if (this.props.routeChildrenMounted) {
            return (
                <DayPlanConnectorApollo
                    date={date}
                    forceRefreshData={forceRefreshData}
                >
                    {({ cacheHit, hasNetworkError, ...dayPlanData }) => (
                        <DayPlanCacheConnectorApollo date={date}>
                            {({ prefetchSurroundingDays }) => (
                                <DayPlanForTomorrowConnectorApollo>
                                    {({ loadDayPlanForTomorrow }) => (
                                        <DayPlanCopyConnectorApollo date={date}>
                                            {({ copyDayPlan }) => (
                                                <DayPlanReplaceProductConnectorApollo
                                                    date={date}
                                                >
                                                    {({ replaceProduct }) => (
                                                        <DayPlanMealReplacementsConnectorApollo
                                                            date={date}
                                                        >
                                                            {({
                                                                loadDayPlanMealReplacements,
                                                            }) => (
                                                                <DayPlanReplaceMealConnectorApollo
                                                                    date={date}
                                                                >
                                                                    {({
                                                                        replaceMeal,
                                                                    }) => (
                                                                        <DayPlanDayReplacementsConnectorApollo
                                                                            date={
                                                                                date
                                                                            }
                                                                        >
                                                                            {({
                                                                                loadDayPlanDayReplacements,
                                                                            }) => (
                                                                                <DayPlanReplaceDayConnectorApollo
                                                                                    date={
                                                                                        date
                                                                                    }
                                                                                >
                                                                                    {({
                                                                                        replaceDay,
                                                                                    }) => (
                                                                                        <DayPlanSetMealEatenConnectorApollo
                                                                                            date={
                                                                                                date
                                                                                            }
                                                                                        >
                                                                                            {({
                                                                                                setMealEaten,
                                                                                            }) => (
                                                                                                <DayPlanIndexContainer
                                                                                                    date={
                                                                                                        date
                                                                                                    }
                                                                                                    {...dayPlanData}
                                                                                                    {...{
                                                                                                        copyDayPlan,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        replaceProduct,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        replaceMeal,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        replaceDay,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        setMealEaten,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        loadDayPlanMealReplacements,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        loadDayPlanDayReplacements,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        cacheHit,
                                                                                                        hasNetworkError,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        loadDayPlanForTomorrow,
                                                                                                    }}
                                                                                                    {...{
                                                                                                        prefetchSurroundingDays,
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </DayPlanSetMealEatenConnectorApollo>
                                                                                    )}
                                                                                </DayPlanReplaceDayConnectorApollo>
                                                                            )}
                                                                        </DayPlanDayReplacementsConnectorApollo>
                                                                    )}
                                                                </DayPlanReplaceMealConnectorApollo>
                                                            )}
                                                        </DayPlanMealReplacementsConnectorApollo>
                                                    )}
                                                </DayPlanReplaceProductConnectorApollo>
                                            )}
                                        </DayPlanCopyConnectorApollo>
                                    )}
                                </DayPlanForTomorrowConnectorApollo>
                            )}
                        </DayPlanCacheConnectorApollo>
                    )}
                </DayPlanConnectorApollo>
            );
        }

        return null;
    }
}

export default withRouter(DayPlanIndex);
