import React from 'react';
import PropTypes from 'prop-types';
import TableRow, {
    TABLE_ROW_SIZE_BIG,
    TABLE_ROW_SIZE_NORMAL,
} from 'Layout/Table/TableRow';
import TableRowCell, {
    TABLE_ROW_CELL_ALIGN_RIGHT,
} from 'Layout/Table/TableRowCell';
import { Link } from 'react-router-dom';
import Change from 'Layout/Change';
import { formatMediumDate } from 'utils/dateFormatter';
import { PATH_MEASUREMENT_EDIT } from 'Measurement/routes';

class MeasurementHistoryTableRow extends React.Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
        change: PropTypes.number,
        date: PropTypes.string.isRequired,
    };

    static defaultProps = {
        change: undefined,
    };

    render() {
        const { date, value, unit, change } = this.props;

        return (
            <TableRow
                size={change ? TABLE_ROW_SIZE_BIG : TABLE_ROW_SIZE_NORMAL}
                redirectToOnClick={PATH_MEASUREMENT_EDIT.split(':')[0] + date}
            >
                <TableRowCell th>
                    <Link to={PATH_MEASUREMENT_EDIT.split(':')[0] + date}>
                        {formatMediumDate(date)}
                    </Link>
                </TableRowCell>
                <TableRowCell>
                    <Link to={PATH_MEASUREMENT_EDIT.split(':')[0] + date}>
                        {`${value} ${unit}`}
                    </Link>
                </TableRowCell>
                <TableRowCell aligment={TABLE_ROW_CELL_ALIGN_RIGHT}>
                    <Link to={PATH_MEASUREMENT_EDIT.split(':')[0] + date}>
                        <Change
                            label={`${change} ${unit}`}
                            difference={change}
                        />
                    </Link>
                </TableRowCell>
            </TableRow>
        );
    }
}

export default MeasurementHistoryTableRow;
