import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

export class ShoppingCartConfirmPaymentOnlinePlaceholder extends React.Component {
    render() {
        return (
            <section>
                <Container>
                    <h2>{this.props.t('cart/payment')}</h2>
                    <br />
                    <div className="payment-method d-flex align-items-center">
                        <Row className="align-items-center w-100">
                            <Col xs={4} className="text-center">
                                <div className="placeholder logo-placeholder" />
                            </Col>
                            <Col xs={8}>
                                <h3 className="placeholder">&nbsp;</h3>
                                <p className="placeholder">&nbsp;</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="payment-method d-flex align-items-center">
                        <Row className="align-items-center w-100">
                            <Col xs={4} className="text-center">
                                <div className="placeholder logo-placeholder" />
                            </Col>
                            <Col xs={8}>
                                <h3 className="placeholder">&nbsp;</h3>
                                <p className="placeholder">&nbsp;</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="payment-method d-flex align-items-center">
                        <Row className="align-items-center w-100">
                            <Col xs={4} className="text-center">
                                <div className="placeholder logo-placeholder" />
                            </Col>
                            <Col xs={8}>
                                <h3 className="placeholder">&nbsp;</h3>
                                <p className="placeholder">&nbsp;</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        );
    }
}

ShoppingCartConfirmPaymentOnlinePlaceholder.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withLocale(ShoppingCartConfirmPaymentOnlinePlaceholder);
