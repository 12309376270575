import gql from 'graphql-tag';

const query = gql`
    query CurrentMeasurementsQuery {
        me {
            id
            currentMeasurements: measurement {
                weight(request: { limit: 1 }) {
                    ...CurrentMeasurementsFragment
                }
                hip(request: { limit: 1 }) {
                    ...CurrentMeasurementsFragment
                }
                waist(request: { limit: 1 }) {
                    ...CurrentMeasurementsFragment
                }
                thigh(request: { limit: 1 }) {
                    ...CurrentMeasurementsFragment
                }
                chest(request: { limit: 1 }) {
                    ...CurrentMeasurementsFragment
                }
                arm(request: { limit: 1 }) {
                    ...CurrentMeasurementsFragment
                }
            }
        }
    }

    fragment CurrentMeasurementsFragment on UserMeasurementType {
        __typename
        value
        unit
        date
        change
    }
`;

export default query;
