import Country from 'view/Country/Country';

export default class ShoppingCartDelivery {
    constructor(name, address, postalCode, city, country, phone) {
        this.name = name || '';
        this.address = address || '';
        this.postalCode = postalCode || '';
        this.city = city || '';
        this.country = country || new Country();
        this.phone = phone || '';
    }
}
