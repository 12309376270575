import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { ApiDateType } from 'types/CommonTypes';
import { createDateObject } from 'utils/dateFormatter';

import DayPlanPrintConnectorApollo from './Connector/Apollo/DayPlanPrintConnectorApollo';
import DayPlanPrintContainer from './DayPlanPrintContainer';

class DayPlanPrint extends Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                start: ApiDateType,
                end: ApiDateType,
            }).isRequired,
        }).isRequired,
    };

    render() {
        const start = createDateObject(this.props.match.params.start);
        const end = createDateObject(this.props.match.params.end);

        return (
            <DayPlanPrintConnectorApollo start={start} end={end}>
                {dayPlanData => <DayPlanPrintContainer {...dayPlanData} />}
            </DayPlanPrintConnectorApollo>
        );
    }
}

export default withRouter(DayPlanPrint);
