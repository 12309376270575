import React from 'react';
import { APP_VERSION } from 'consts';
import Firebase from './Firebase';

function trackFirebaseEvent(eventName, eventParams = null) {
    const mobileApp = JSON.parse(global.localStorage.getItem('mobileApp'));
    const mobileAppMode = Boolean(mobileApp.active);
    const mobileAppOs = mobileApp.os;

    const userId = Number(global.localStorage.getItem('userId'));
    const newParams = {
        ...eventParams,
        app_info: APP_VERSION,
        app_brand: 'oxyPl',
        app_platform: mobileAppMode ? `webview-${mobileAppOs}` : 'web',
    };
    if (userId) {
        newParams.user_ID = `${userId}_pl`;
    }

    Firebase.analytics().logEvent(eventName, newParams);
}

const withFirebase = ChildComponent => props => (
    <ChildComponent {...props} trackFirebaseEvent={trackFirebaseEvent} />
);

export default withFirebase;
