import React from 'react';
import Layout from 'Layout/Layout';
import Loader from 'Layout/Loader';

class PsychometricTestPlaceholder extends React.Component {
    render() {
        return (
            <Layout page="psychometric-test">
                <div className="full-height">
                    <Loader />
                </div>
            </Layout>
        );
    }
}

export default PsychometricTestPlaceholder;
