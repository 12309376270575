import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import TableRowPlaceholder from 'Layout/Table/TableRowPlaceholder';
import { FullMeasurementType } from 'types/CommonTypes';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { Container, Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import iconHip from 'assets/icons/measurement/hip.svg';
import iconThigh from 'assets/icons/measurement/thigh.svg';
import iconWaist from 'assets/icons/measurement/waist.svg';
import iconWeight from 'assets/icons/measurement/weight.svg';
import iconManHip from 'assets/icons/measurement/man-hip.svg';
import iconManThigh from 'assets/icons/measurement/man-thigh.svg';
import iconManWaist from 'assets/icons/measurement/man-waist.svg';
import iconManWeight from 'assets/icons/measurement/man-weight.svg';
import CurrentMeasurementsBox from './CurrentMeasurementsBox';

export class CurrentMeasurementsContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        weight: FullMeasurementType,
        hip: FullMeasurementType,
        waist: FullMeasurementType,
        thigh: FullMeasurementType,
        arm: FullMeasurementType,
    };

    static defaultProps = {
        weight: undefined,
        hip: undefined,
        waist: undefined,
        thigh: undefined,
        arm: undefined,
    };

    render() {
        const { t, loading, weight, hip, waist, thigh } = this.props;
        const sex = localStorage.getItem('sex') || 'female';

        return (
            <Container>
                <Row>
                    <Col xs="12" sm={{ size: 8, offset: 2 }}>
                        {loading ? (
                            Array.from(Array(6)).map((data, key) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableRowPlaceholder key={key} />
                            ))
                        ) : (
                            <React.Fragment>
                                <CurrentMeasurementsBox
                                    {...weight}
                                    icon={
                                        sex === 'female'
                                            ? iconWeight
                                            : iconManWeight
                                    }
                                    label={t('measurments/param/body-weight')}
                                />
                                <CurrentMeasurementsBox
                                    {...hip}
                                    icon={
                                        sex === 'female' ? iconHip : iconManHip
                                    }
                                    label={t('measurments/param/hip')}
                                />
                                <CurrentMeasurementsBox
                                    {...waist}
                                    icon={
                                        sex === 'female'
                                            ? iconWaist
                                            : iconManWaist
                                    }
                                    label={t('measurments/param/waist')}
                                />
                                <CurrentMeasurementsBox
                                    {...thigh}
                                    icon={
                                        sex === 'female'
                                            ? iconThigh
                                            : iconManThigh
                                    }
                                    label={t('measurments/param/thigh')}
                                />
                            </React.Fragment>
                        )}

                        <div className="text-center">
                            <Button
                                color="primary"
                                tag={Link}
                                to={PATH_MEASUREMENT_ADD}
                            >
                                {t('measurments/add-measurments-button')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default withLocale(CurrentMeasurementsContainer);
