import React from 'react';
import PropTypes from 'prop-types';
import Query from '@dietlabs/components/src/Apollo/Query';
import { DateType } from 'types/CommonTypes';
import ShoppingListQuery from './ShoppingListQuery';

class ShoppingListConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        period: PropTypes.shape({
            start: DateType.isRequired,
            end: DateType.isRequired,
        }),
    };

    static defaultProps = {
        period: undefined,
    };

    render() {
        const { children, period } = this.props;

        return (
            <Query
                query={ShoppingListQuery}
                variables={period ? { period } : {}}
            >
                {({ data, loading, cacheHit, hasNetworkError, client }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (
                        !loading &&
                        data.me.shoppingList &&
                        data.me.shoppingList.__typename === 'UserShoppingList'
                    ) {
                        const categories = data.me.shoppingList.category.map(
                            singleCategory => {
                                const newCategory = {
                                    ...singleCategory,
                                    products: singleCategory.product,
                                };
                                delete newCategory.product;

                                return newCategory;
                            }
                        );

                        const shoppingList = {
                            ...data.me.shoppingList,
                            categories,
                        };

                        delete shoppingList.category;

                        result.shoppingList = shoppingList;
                        result.availableDays = data.me.diet.availableDays.map(
                            i => i.date
                        );

                        // When requesting shopping list with no variables
                        // given, backend returns last viewed list. To
                        // achieve this with caching, inject every response
                        // as a response to request with no vars.
                        client.writeQuery({
                            query: ShoppingListQuery,
                            variables: {},
                            data,
                        });
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default ShoppingListConnectorApollo;
