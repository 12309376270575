/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import withGTM from 'view/GoogleTagManager/withGTM';
import PropTypes from 'prop-types';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import Loader from 'Layout/Loader';

import breakfast from 'assets/images/dayplan/plug/breakfast1-1.jpg';
import morningSnack from 'assets/images/dayplan/plug/morning-snack1-1.jpg';
import dinner from 'assets/images/dayplan/plug/dinner1-1.jpg';
import eveningSnack from 'assets/images/dayplan/plug/evening-snack1-1.jpg';
import supper from 'assets/images/dayplan/plug/supper1-1.jpg';

import iconChange from 'assets/images/dayplan/icon-change-meal.svg';

import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';

class DayPlanMealReplacement extends Component {
    static propTypes = {
        mealReplacement: PropTypes.shape({
            id: PropTypes.number.isRequired,
            dishes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
            preparationImageUrl: PropTypes.string,
        }).isRequired,
        trackEvent: PropTypes.func.isRequired,
        replaceMeal: PropTypes.func.isRequired,
        originalMealId: PropTypes.number.isRequired,
        mealKey: PropTypes.string.isRequired,
        toggleModalWithReplacements: PropTypes.func.isRequired,
        // toggleModal: PropTypes.func.isRequired,
        changeMeal: PropTypes.func.isRequired,
        mealName: PropTypes.string.isRequired,
        showModal: PropTypes.bool.isRequired,
    };

    state = {
        preload: false,
    };

    changeMeal = async () => {
        this.setState({ preload: true });
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'exchange meal');

        const replacement = {
            originalMealId: this.props.originalMealId,
            replacementId: this.props.mealReplacement.id,
            key: this.props.mealKey,
            dishes: this.props.mealReplacement.dishes,
        };

        const response = await this.props.replaceMeal(replacement);

        if (
            response.data.me.dietSetReplaceMeal.__typename !==
            'BasicMutationSuccess'
        ) {
            throw new Error(
                `Replace meal failed: ${response.data.me.dietSetReplaceMeal.__typename}`
            );
        }
        this.setState({ preload: false });
        this.props.changeMeal(this.props.mealReplacement.id);

        this.props.toggleModalWithReplacements();

        // reset to default window on pop state and back to meal details view,
        // because when meal replace is occured new instance of meal details component is moutn
        // and there is history,pushState trigger
        window.onpopstate = () => {};
        if (this.props.showModal) {
            window.history.go(-2);
            callNativeAppRemoveLastRoute('diet');
            callNativeAppRemoveLastRoute('diet');
        } else {
            window.history.go(-1);
            callNativeAppRemoveLastRoute('diet');
        }
    };

    renderMealImage = () => {
        let mealImg;

        if (this.props.mealReplacement.preparationImageUrl) {
            mealImg = this.props.mealReplacement.preparationImageUrl
                .replace('(', '%28')
                .replace(')', '%29');
        } else {
            switch (this.props.mealName) {
                case 'Śniadanie':
                    mealImg = breakfast;
                    break;
                case 'Przekąska poranna':
                    mealImg = morningSnack;
                    break;
                case 'Obiad':
                    mealImg = dinner;
                    break;
                case 'Lunch':
                    mealImg = dinner;
                    break;
                case 'Przekąska wieczorna':
                    mealImg = eveningSnack;
                    break;
                case 'Przekąska':
                    mealImg = eveningSnack;
                    break;
                case 'Kolacja':
                    mealImg = supper;
                    break;
                case 'Obiadokolacja':
                    mealImg = supper;
                    break;
                default:
                    mealImg = breakfast;
            }
        }

        return (
            <div
                className="embed-responsive embed-responsive-16by9"
                role="button"
            >
                <div
                    style={{
                        backgroundImage: `url(${mealImg})`,
                    }}
                    className="embed-responsive-item"
                />
            </div>
        );
    };

    render() {
        const mealReplacement = this.props.mealReplacement;
        let i = 0;

        return (
            <div
                className="white-box meal-replacement"
                role="button"
                data-test="replace-meal"
            >
                <Row onClick={() => this.changeMeal()}>
                    <Col xs="4">{this.renderMealImage()}</Col>
                    <Col xs="8">
                        <Row
                            className="align-items-center"
                            style={{ height: '72px' }}
                        >
                            <Col xs="9" className="pr-0">
                                <div>
                                    {mealReplacement.dishes.map(dish => {
                                        i += 1;
                                        let j = 0;
                                        return (
                                            <React.Fragment key={dish.id}>
                                                <strong>
                                                    {i >= 2 ? ', ' : ''}
                                                    {dish.name}
                                                </strong>
                                                {dish.ingredients ? ' (' : ''}

                                                {dish.ingredients &&
                                                    dish.ingredients.map(
                                                        ingredient => {
                                                            j += 1;
                                                            // eslint-disable-next-line prettier/prettier
                                                            if(j <= 3){
                                                                return (
                                                                    <span
                                                                        key={
                                                                            ingredient.name
                                                                        }
                                                                    >
                                                                        {j >= 2
                                                                            ? ', '
                                                                            : ''}
                                                                        {
                                                                            ingredient.name
                                                                        }
                                                                    </span>
                                                                );
                                                            }
                                                            return null;
                                                        }
                                                    )}
                                                {dish.ingredients ? ')' : ''}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </Col>
                            <Col xs="3" className="text-right">
                                <img src={iconChange} alt="" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {this.state.preload ? <Loader /> : ''}
            </div>
        );
    }
}

export { DayPlanMealReplacement };
export default withGTM(withLocale(DayPlanMealReplacement));
