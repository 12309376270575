import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './StepContext';

class StepProvider extends Component {
    static propTypes = {
        prevStep: PropTypes.func.isRequired,
        nextStep: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    render() {
        const {
            prevStep,
            nextStep,
            handleInputChange,
            changeNextBtnState,
        } = this.props;

        const providedValue = {
            prevStep,
            nextStep,
            handleInputChange,
            changeNextBtnState,
        };
        return <Provider value={providedValue}>{this.props.children}</Provider>;
    }
}

export default StepProvider;
