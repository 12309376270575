import gql from 'graphql-tag';

export default gql`
    mutation hydrationDayAddPortion(
        $date: Date!
        $value: Int!
        $unit: String!
    ) {
        me {
            hydrationDayAddPortion(date: $date, value: $value, unit: $unit) {
                __typename
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on UserHydrationDay {
                    key
                    goal {
                        value
                        unit
                    }
                    portions {
                        id
                        value {
                            value
                            unit
                        }
                    }
                }
            }
        }
    }
`;
