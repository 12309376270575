import React, { Component } from 'react';
import { FormGroup, Label, Input, InputGroup, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { InputErrorType } from 'types/CommonTypes';
import withRequired from './withRequired';

class InputText extends Component {
    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        return (
            <FormGroup>
                {this.props.label ? (
                    <Label for={this.props.name}>
                        {this.props.required ? <sup>*</sup> : ''}{' '}
                        {this.props.label}
                    </Label>
                ) : (
                    ''
                )}
                <InputGroup
                    className={
                        errorMgs ? 'form-control is-invalid' : 'form-control'
                    }
                >
                    <Input
                        type="text"
                        name={this.props.name}
                        value={this.props.value || ''}
                        placeholder={this.props.placeholder}
                        onChange={this.props.handleChange}
                        required={this.props.required}
                        disabled={!!this.props.disabled}
                    />
                </InputGroup>
                <FormFeedback>
                    {errorMgs
                        ? errorMgs.map((message, k) => (
                              <li key={k}>{message}</li>
                          ))
                        : ''}
                </FormFeedback>
            </FormGroup>
        );
    }
}

InputText.propTypes = {
    errors: InputErrorType,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    validationErrors: PropTypes.object, //eslint-disable-line
};

InputText.defaultProps = {
    errors: undefined,
    label: null,
    required: false,
    disabled: false,
    placeholder: null,
    value: undefined,
    validationErrors: undefined,
};

export default withRequired(InputText);
