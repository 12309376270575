import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { convertUnit } from 'utils/unit';

import flattenValidationExceptionMessages from '../utils/flattenValidationExceptionMessages';
import DayConnector from './Connector/Apollo/HydrationDayConnectorApollo';
import DayMutationsConnector from './Connector/Apollo/HydrationDayMutationsConnectorApollo';
import PortionAddConnector from './Connector/Apollo/HydrationAddConnectorApollo';
import DayContainer from './HydrationDayContainer';

function getLastPortionId(portions) {
    const lastPortion =
        portions && portions.length && portions[portions.length - 1];
    return lastPortion ? lastPortion.id : null;
}

function sumPortionValues(portions, targetUnit) {
    return portions.reduce(
        (carry, { value: { value, unit } }) =>
            carry + convertUnit(value, unit, targetUnit),
        0
    );
}

class HydrationDayPage extends Component {
    createAddPortion = addPortion => async (unit, value) => {
        await addPortion(unit, value);
    };

    render() {
        const date = new Date();

        return (
            <DayConnector date={date}>
                {({
                    loading,
                    cacheHit,
                    hasNetworkError,
                    data: { goal, portions } = {},
                    // This should only be passed to mutation
                    // connectors
                    queryResult,
                }) => (
                    <DayMutationsConnector
                        date={date}
                        queryResult={queryResult}
                    >
                        {({
                            changeGoal,
                            changeGoalData,
                            removePortion,
                            removePortionData,
                        }) => {
                            let valueValue = 0;
                            if (portions) {
                                valueValue = sumPortionValues(
                                    portions,
                                    goal.unit
                                );
                            }
                            const lastPortionId = getLastPortionId(portions);
                            const removeLastPortion = lastPortionId
                                ? () => removePortion(lastPortionId)
                                : null;

                            const changeGoalMessages = flattenValidationExceptionMessages(
                                changeGoalData
                            );
                            const removePortionMessages = flattenValidationExceptionMessages(
                                removePortionData
                            );
                            return (
                                <PortionAddConnector
                                    date={date}
                                    queryResult={queryResult}
                                >
                                    {(mutate, mutationResult) => {
                                        const addPortionMessages = flattenValidationExceptionMessages(
                                            mutationResult
                                        );

                                        return (
                                            <DayContainer
                                                loading={loading}
                                                cacheHit={cacheHit}
                                                hasNetworkError={
                                                    hasNetworkError
                                                }
                                                date={date}
                                                changeGoal={changeGoal}
                                                changeGoalMessages={
                                                    changeGoalMessages
                                                }
                                                removeLastPortion={
                                                    removeLastPortion
                                                }
                                                removeLastPortionMessages={
                                                    removePortionMessages
                                                }
                                                goal={goal}
                                                value={valueValue}
                                                add={this.createAddPortion(
                                                    mutate
                                                )}
                                                messages={addPortionMessages}
                                            />
                                        );
                                    }}
                                </PortionAddConnector>
                            );
                        }}
                    </DayMutationsConnector>
                )}
            </DayConnector>
        );
    }
}

export { HydrationDayPage };
export default withRouter(HydrationDayPage);
