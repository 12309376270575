export default {
    me: {
        __typename: 'MeMutation',
        shoppingListMarkProductAsNotBought: {
            code: 200,
            isBought: false,
            __typename: 'UserShoppingListMarkProductAsNotBoughtSuccess',
        },
    },
};
