import React from 'react';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from 'consts';
import isMobileApplication from 'components/Helpers/isMobileApplication';

import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import { Container, Button } from 'reactstrap';

class UpdatePage extends React.PureComponent {
    render() {
        let storeUrl;

        if (isMobileApplication() === 'ios') {
            storeUrl = APP_STORE_URL;
        } else if (isMobileApplication() === 'android') {
            storeUrl = GOOGLE_PLAY_URL;
        }

        return (
            <Layout page="update" data-test="workouts">
                <Header>Zaktualizuj aplikację</Header>
                <section className="pt-0">
                    <Container className="text-center">
                        <p>
                            Kliknij w przycisk poniżej aby aktualizować
                            aplikację.
                        </p>
                        <Button to={storeUrl}>Zaktualizuj aplikację</Button>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default UpdatePage;
