import { getAllAvailablePaths } from '../AllAvailablePaths/DietSettingsGetAllAvailablePathsAction';

export const getTheLongestPath = schema => {
    let result = [];
    getAllAvailablePaths(schema).forEach(path => {
        if (path.length > result.length) {
            result = path;
        }
    });

    return result;
};
