import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col } from 'reactstrap';

class InterventionsPlaceholder extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="interventions">
                <Header>{this.props.t('interventions/title')}</Header>
                <section className="pt-0">
                    <Container>
                        <Row>
                            <Col
                                xs="12"
                                md={{ size: 8, offset: 2 }}
                                lg={{ size: 6, offset: 3 }}
                            >
                                <Row>
                                    <Col xs="12" md={{ size: 12, offset: 0 }}>
                                        <ul className="interventions-list">
                                            <li className="UserInterventionVisible">
                                                <div className="placeholder">
                                                    &nbsp;
                                                </div>
                                            </li>
                                            <li className="UserInterventionVisible">
                                                <div className="placeholder">
                                                    &nbsp;
                                                </div>
                                            </li>
                                            <li className="UserInterventionVisible">
                                                <div className="placeholder">
                                                    &nbsp;
                                                </div>
                                            </li>
                                            <li className="UserInterventionVisible">
                                                <div className="placeholder">
                                                    &nbsp;
                                                </div>
                                            </li>
                                            <li className="UserInterventionVisible">
                                                <div className="placeholder">
                                                    &nbsp;
                                                </div>
                                            </li>
                                            <li className="UserInterventionVisible">
                                                <div className="placeholder">
                                                    &nbsp;
                                                </div>
                                            </li>
                                            <li className="UserInterventionVisible">
                                                <div className="placeholder">
                                                    &nbsp;
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(InterventionsPlaceholder);
