import { SHOPPING_CART_INDEX_LOAD } from './ShoppingCartIndexLoadActionType';
import { shoppingCartIndexLoadAction } from './ShoppingCartIndexLoadAction';

export const shoppingCartIndexGet = (promoCode = null) => async (
    dispatch,
    getState
) => {
    if (
        getState().shoppingCart.shoppingCartIndexLoad.expirationDate >
        new Date()
    ) {
        return new Promise(resolve =>
            resolve(getState().shoppingCart.shoppingCartIndexLoad.data)
        );
    }

    const result = await dispatch(await shoppingCartIndexLoadAction(promoCode));
    dispatch({
        type: SHOPPING_CART_INDEX_LOAD,
        data: result,
    });
    return result;
};
