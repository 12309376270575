import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import ContentQuery from './ContentQuery';

class ContentConnectorApollo extends React.Component {
    static propTypes = {
        identifier: PropTypes.string.isRequired,
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children, identifier } = this.props;

        return (
            <Query
                query={ContentQuery}
                variables={{
                    identifier,
                }}
                fetchPolicy="no-cache"
            >
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.content = deepClone(data.content);
                    }
                    return children(result);
                }}
            </Query>
        );
    }
}

export default ContentConnectorApollo;
