import { connect } from 'react-redux';
import ShoppingCartDeliveryOptionsFactory from 'view/ShoppingCart/Delivery/Option/ShoppingCartDeliveryOptionsFactory';
import ShoppingCartProductsFactory from 'view/ShoppingCart/Product/ShoppingCartProductsFactory';
import ShoppingCartIndexDeliveryComponent from 'components/ShoppingCart/Index/ShoppingCartIndexDeliveryComponent';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { shoppingCartDeliveryOptionSetAction } from 'view/ShoppingCart/Delivery/Option/Set/ShoppingCartDeliveryOptionSetAction';
import ShoppingCartPriceFactory from 'view/ShoppingCart/Price/ShoppingCartPriceFactory';

const mapStateToProps = state => {
    const deliveryOptions = ShoppingCartDeliveryOptionsFactory.createFromState(
        state
    );
    const ShoppingCartProducts = ShoppingCartProductsFactory.createFromState(
        state
    );
    const disableCodForProducts = ShoppingCartProducts.disableCodForProducts;
    const isPhysicalProduct = ShoppingCartProducts.isPhysicalProduct;
    const areThereOnlyDietlabsProducts =
        ShoppingCartProducts.areThereOnlyDietlabsProducts;

    const availableShippingOptions = deliveryOptions.getAvailableForProductsInCart(
        isPhysicalProduct,
        areThereOnlyDietlabsProducts,
        disableCodForProducts
    );

    const prices = ShoppingCartPriceFactory.createFromState(state);
    const productPrice = prices.products;
    const isFreeDeliveryAvailable =
        (deliveryOptions.chosen &&
            deliveryOptions.chosen.freeFromCartValue &&
            deliveryOptions.chosen.freeFromCartValue.amount >
                productPrice.amount) ||
        false;
    const freeFromCartValue =
        deliveryOptions.chosen && deliveryOptions.chosen.freeFromCartValue
            ? deliveryOptions.chosen.freeFromCartValue
            : null;

    return {
        options: availableShippingOptions,
        saleShippingId: deliveryOptions.saleShippingId,
        validationError: ValidationErrors.createFromObject(
            state.shoppingCart.validationError
        ),
        areShippingDataNeeded: deliveryOptions.areShippingDataNeeded,
        isLoggedIn: !!localStorage.getItem('token'),
        isFreeDeliveryAvailable,
        freeFromCartValue,
    };
};

const mapDispatchToProps = dispatch => ({
    setDeliveryOption: saleShippingId =>
        dispatch(shoppingCartDeliveryOptionSetAction(saleShippingId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartIndexDeliveryComponent);
