import { connect } from 'react-redux';
import ShoppingCartDeliveryOptionsFactory from 'view/ShoppingCart/Delivery/Option/ShoppingCartDeliveryOptionsFactory';
import ShoppingCartIndexDeliveryDataComponent from 'components/ShoppingCart/Index/ShoppingCartIndexDeliveryDataComponent';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { shoppingCartSetDeliveryAction } from 'view/ShoppingCart/Delivery/ShoppingCartDeliveryAction';
import ShoppingCartDeliveryFactory from 'view/ShoppingCart/Delivery/ShoppingCartDeliveryFactory';

const mapStateToProps = state => {
    const deliveryOptions = ShoppingCartDeliveryOptionsFactory.createFromState(
        state
    );
    const delivery = ShoppingCartDeliveryFactory.createFromState(state);

    return {
        delivery,
        validationError: ValidationErrors.createFromObject(
            state.shoppingCart.validationError
        ),
        chosenDeliveryOption: deliveryOptions.chosen,
    };
};

const mapDispatchToProps = dispatch => ({
    setDeliveryData: delivery =>
        dispatch(shoppingCartSetDeliveryAction(delivery)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartIndexDeliveryDataComponent);
