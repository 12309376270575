import React from 'react';
import Layout from 'Layout/Layout';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import { Container, Row, Col, CardDeck } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import dietetitian from 'assets/images/psychometric/dietetitian.jpeg';
import { Link as ScrollLink } from 'react-scroll';
import Slider from 'react-slick';
import sylwia from 'assets/images/psychometric/metamorfozy/sylwia-olejniczak.png';
import renata from 'assets/images/psychometric/metamorfozy/renata-olejniczak.png';
import katarzyna from 'assets/images/psychometric/metamorfozy/katarzyna-tkocz.png';

import age from 'assets/images/psychometric/age.svg';
import gender from 'assets/images/psychometric/gender.svg';
import growth from 'assets/images/psychometric/growth.svg';
import physicalActivity from 'assets/images/psychometric/physical-activity.svg';
import weight from 'assets/images/psychometric/weight.svg';

import SaleProductItem from 'SaleProduct/SaleProductItem';
import PsychometricTestPlaceholder from './PsychometricTestPlaceholder';
import PsychometricResult from './PsychometricResult';

class PsychometricResultContainer extends React.Component {
    static propTypes = {
        result: PropTypes.shape({
            personality: PropTypes.string,
        }).isRequired,
        loadSaleProduct: PropTypes.func.isRequired,
    };

    state = {
        products: [],
    };

    renderProducts = () => {
        if (!this.state.products.length) {
            this.props.loadSaleProduct('main-diet').then(response => {
                this.setState({
                    products: response,
                });
            });
        }

        if (this.state.products.length > 0) {
            return (
                <CardDeck className="multi-products">
                    {this.state.products.map(saleProduct => (
                        <SaleProductItem
                            key={saleProduct.id}
                            saleProduct={saleProduct}
                            promoCode="abc"
                            source="cde"
                            showThumbnail
                            showShortDescription={false}
                        />
                    ))}
                </CardDeck>
            );
        }

        return 'loading...';
    };

    render() {
        const settings = {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            speed: 500,
            centerMode: true,
            centerPadding: '15px',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        arrows: false,
                    },
                },
            ],
        };

        return (
            <Layout page="psychometric-test">
                <PsychometricResult
                    personality={this.props.result.personality}
                />
                <section className="py-0">
                    <Container>
                        <h2 className="text-center">
                            Dzięki Twoim odpowiedziom już wiem, którego z tych
                            produktów
                            <br />
                            musisz unikać na śniadanie...
                        </h2>

                        <Row className="align-items-center">
                            <Col md="6">
                                <img
                                    src={dietetitian}
                                    className="img-fluid personality"
                                    alt=""
                                />
                            </Col>
                            <Col md="6">
                                <p>
                                    …żeby stopniowo, ale zdrowo zacząć chudnąć.
                                </p>
                                <p>
                                    Wiem już także, co głównie musisz jeść na
                                    obiad, żeby nie utrudniać swojemu
                                    organizmowi spalania tłuszczu na energię.
                                </p>
                                <p>
                                    I wiem, co powinno się znaleźć na Twoim
                                    talerzu na podwieczorek i kolację — żeby
                                    podczas snu nie blokować uwalniania energii
                                    z tłuszczu i zmaksymalizować jego spalanie.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container>
                        <h1 className="text-center">
                            Teraz chciałabym się tą wiedzą
                            <br /> z Tobą podzielić…
                        </h1>
                        <p>
                            Dieta dopasowana do Ciebie na podstawie testu
                            psychometrycznego, jest to dieta, na której — z
                            naukowego punktu widzenia —{' '}
                            <b>nie możesz nie schudnąć!</b> Chyba że jesteś
                            przypadkiem wyjątkowym. A takie zdarzają się raz na
                            10 000 osób.
                        </p>
                        <p>
                            <b>Powtarzam:</b> jeśli nie masz rzadkich chorób,
                            które dotykają 0,01% populacji, nie możesz nie
                            schudnąć na diecie dopasowanej ściśle pod Ciebie!
                        </p>
                        <p>
                            Schudniesz około 5, 8 lub 10 kilogramów — bo tyle
                            chudną inne osoby, które rozpoczęły dopasowaną pod
                            siebie dietę. Więc…
                        </p>

                        <div className="text-center">
                            <ScrollLink
                                className="btn btn-primary scroll-link mt-5"
                                to="buy"
                                smooth
                                duration={500}
                            >
                                Rozpocznij dietę
                            </ScrollLink>
                        </div>
                    </Container>
                </section>
                <section>
                    <Container className="text-center">
                        <h2>
                            Dołącz do <b>46 140</b> osób zadowolonych z efektów
                            <br />
                            Diety OXY
                        </h2>
                        <p>
                            Dieta OXY dopasowana będzie do Ciebie na podstawie:
                        </p>
                        <Row>
                            <Col>
                                <img src={age} alt="" />
                                <h4>Wiek</h4>
                            </Col>
                            <Col>
                                <img src={gender} alt="" />
                                <h4>Płeć</h4>
                            </Col>
                            <Col>
                                <img src={growth} alt="" />
                                <h4>Wzrost</h4>
                            </Col>
                            <Col>
                                <img src={weight} alt="" />
                                <h4>Masa ciała</h4>
                            </Col>
                            <Col>
                                <img src={physicalActivity} alt="" />
                                <h4>Aktywność fizyczna</h4>
                            </Col>
                        </Row>

                        <div className="text-center">
                            <ScrollLink
                                className="btn btn-primary scroll-link mt-5"
                                to="buy"
                                smooth
                                duration={500}
                            >
                                Rozpocznij dietę
                            </ScrollLink>
                        </div>
                    </Container>
                </section>

                <section className="social-proof">
                    <Container>
                        <h2 className="text-center">
                            ONE JUŻ SCHUDŁY Z DIETĄ OXY!
                        </h2>
                        <Slider className="mt-5" {...settings}>
                            <div className="slide">
                                <img
                                    src={sylwia}
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>
                                    Po tygodniu wiedziałam, że to jest dieta dla
                                    mnie!
                                    <br />
                                    Sylwia
                                </p>
                            </div>

                            <div className="slide">
                                <img
                                    src={katarzyna}
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>
                                    Czuję się bardziej pewna siebie. Jestem
                                    zupełnie nową osobą! <br />
                                    Katarzyna
                                </p>
                            </div>

                            <div className="slide">
                                <img
                                    src={renata}
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>
                                    Rzeczy inaczej na mnie leżą. Czuję się
                                    młodsza! <br />
                                    Renata
                                </p>
                            </div>

                            <div className="slide">
                                <img
                                    src={sylwia}
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>
                                    Po tygodniu wiedziałam, że to jest dieta dla
                                    mnie!
                                    <br />
                                    Sylwia
                                </p>
                            </div>

                            <div className="slide">
                                <img
                                    src={katarzyna}
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>
                                    Czuję się bardziej pewna siebie. Jestem
                                    zupełnie nową osobą! <br />
                                    Katarzyna
                                </p>
                            </div>

                            <div className="slide">
                                <img
                                    src={renata}
                                    className="img-fluid"
                                    alt=""
                                />
                                <p>
                                    Rzeczy inaczej na mnie leżą. Czuję się
                                    młodsza! <br />
                                    Renata
                                </p>
                            </div>
                        </Slider>
                    </Container>
                </section>

                <section id="buy">
                    <Container>
                        <h2 className="text-center">
                            KUP TYLKO DZIŚ W PROMOCJI
                        </h2>

                        {this.renderProducts()}
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLoading(
    withLocale(PsychometricResultContainer),
    PsychometricTestPlaceholder
);
