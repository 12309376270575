export default class ShoppingCartIndexSaveOrder {
    constructor(
        token,
        products,
        firstSource,
        saleShippingId,
        delivery,
        userData,
        promoCode
    ) {
        if (token) {
            this.token = token;
        }

        products = products || [];
        this.products = products.reduce(
            (prev, current) =>
                prev.concat({
                    id: current.id,
                    amount: current.amount,
                    lastSource: current.lastSource,
                }),
            []
        );
        this.firstSource = firstSource;
        this.saleShippingId = saleShippingId || null;

        if (delivery) {
            this.name = delivery.name || '';
            this.city = delivery.city || '';
            this.postalCode = delivery.postalCode || '';
            this.address = delivery.address || '';
            this.phone = delivery.phone || '';
            this.countryCode = delivery.country ? delivery.country.code : '';
        }

        if (userData) {
            this.email = userData.email || '';
            this.acceptConditions = Boolean(userData.acceptConditions);
        }

        this.promoCode = promoCode;
    }
}
