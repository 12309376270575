import gql from 'graphql-tag';

export default gql`
    mutation hydrationDayRemovePortion($portionId: Int!) {
        me {
            hydrationDayRemovePortion(id: $portionId) {
                __typename
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
                ... on UserHydrationDay {
                    key
                    goal {
                        value
                        unit
                    }
                    portions {
                        id
                        value {
                            value
                            unit
                        }
                    }
                }
            }
        }
    }
`;
