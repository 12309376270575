import React from 'react';
import { Container, Row, Col, Form, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

export class NotificationsSettingsFormPlaceholder extends React.Component {
    render() {
        return (
            <Container
                id="settings-agreements"
                className={this.props.className}
            >
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <Form>
                                <h2>
                                    {this.props.t('settings/notifications')}
                                </h2>

                                <div className="form-group">
                                    <div className="custom-switch custom-control">
                                        <Label className="custom-control-label">
                                            {this.props.t(
                                                'settings/agreements/diet-course'
                                            )}
                                        </Label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="custom-switch custom-control">
                                        <Label className="custom-control-label">
                                            {this.props.t(
                                                'settings/agreements/newsletter'
                                            )}
                                        </Label>
                                    </div>
                                </div>
                            </Form>
                            <p>
                                {this.props.t(
                                    'settings/agreements/newsletter-info'
                                )}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

NotificationsSettingsFormPlaceholder.propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
};

NotificationsSettingsFormPlaceholder.defaultProps = {
    className: null,
};

export default withLocale(NotificationsSettingsFormPlaceholder);
