import Price from 'view/Price/Price';

export default class ShoppingCartPrice {
    constructor(products, deliveryOption) {
        products = products || [];
        const currency = products[0] ? products[0].price.promo.currency : 'PLN';
        this.products = products.reduce(
            (prev, current) => prev.add(current.sumPrice),
            new Price(0, currency)
        );
        if (deliveryOption && products.length) {
            if (
                deliveryOption.freeFromCartValue &&
                this.products.amount >= deliveryOption.freeFromCartValue.amount
            ) {
                this.deliveryOption = new Price(0, currency);
            } else {
                this.deliveryOption = deliveryOption.price;
            }
        } else {
            this.deliveryOption = new Price(0, currency);
        }

        this.total = this.products.add(this.deliveryOption);
    }
}
