import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import { PATHS } from 'config/paths';
import withLoading from 'Layout/withLoading';
import UnauthorizedException from 'view/Error/UnauthorizedException';
import { formatIsoDate } from 'utils/dateFormatter';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import { PATH_DAY_PLAN_INDEX_WITH_DATE } from '../DayPlanPaths';

import * as types from '../DayPlanTypes';
import Timeline from '../components/DayPlanTimeline';
import CurrentWeightRequired from './CurrentWeightRequired';
import DayPlanContainer from './DayPlan/DayPlanContainer';
import DayPlanIndexPlaceholder from './DayPlanIndexPlaceholder';
import DietInFuture from './DietInFuture';
import GoalLost from './GoalLost';
import GoalReached from './GoalReached';
import OutsideAccess from './OutsideAccess';
import PaymentPending from './PaymentPending';

class DayPlanIndexContainer extends Component {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        accessTo: PropTypes.instanceOf(Date),
        dietAvailabilityDate: PropTypes.instanceOf(Date),
        hasActiveSubscription: PropTypes.bool.isRequired,
        dayPlan: PropTypes.shape({
            __typename: PropTypes.string.isRequired,
            date: PropTypes.instanceOf(Date),
            // for pending payments:
            token: PropTypes.string,
            description: PropTypes.string,
            dietId: PropTypes.number,
            dietEndDate: PropTypes.string,
        }).isRequired,
        availableDays: PropTypes.arrayOf(PropTypes.object).isRequired,
        categories: PropTypes.arrayOf(PropTypes.shape()),
        goal: PropTypes.shape({
            reachedBecauseOfLoseWeight: PropTypes.bool.isRequired,
            reachedBecauseOfPutOnWeight: PropTypes.bool.isRequired,
            lostBecauseOfLoseWeight: PropTypes.bool.isRequired,
            lostBecauseOfPutOnWeight: PropTypes.bool.isRequired,
        }).isRequired,
        isHolidayMenu: PropTypes.bool.isRequired,
        isTimeToNagForCurrentMeasurement: PropTypes.bool.isRequired,
        cacheHit: PropTypes.bool.isRequired,
        hasNetworkError: PropTypes.bool.isRequired,
        copyDayPlan: PropTypes.func.isRequired,
        alreadyCopied: PropTypes.bool.isRequired,
        replaceProduct: PropTypes.func.isRequired,
        loadDayPlanMealReplacements: PropTypes.func.isRequired,
        replaceMeal: PropTypes.func.isRequired,
        loadDayPlanDayReplacements: PropTypes.func.isRequired,
        replaceDay: PropTypes.func.isRequired,
        prefetchSurroundingDays: PropTypes.func.isRequired,
        loadDayPlanForTomorrow: PropTypes.func.isRequired,
        fastingStatistics: PropTypes.shape().isRequired,
        setMealEaten: PropTypes.func.isRequired,
        setId: PropTypes.number.isRequired,
        mealsEaten: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        dietId: PropTypes.number.isRequired,
        isVegetarian: PropTypes.bool.isRequired,
        isPescoVegetarian: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        accessTo: undefined,
        dietAvailabilityDate: undefined,
        categories: undefined,
    };

    renderTimeline = () => (
        <Timeline
            availableDays={this.props.availableDays}
            date={this.props.date}
            loading={false}
        />
    );

    render() {
        const type = this.props.dayPlan.__typename;

        if (type === types.CURRENT_WEIGHT_REQUIRED) {
            return (
                <CurrentWeightRequired
                    renderTimeline={() => this.renderTimeline()}
                />
            );
        }

        if (type === types.DAY_PLAN) {
            const { cacheHit, hasNetworkError } = this.props;
            return (
                <DayPlanContainer
                    availableDays={this.props.availableDays}
                    dayPlan={this.props.dayPlan}
                    categories={this.props.categories}
                    loadDayPlanForTomorrow={this.props.loadDayPlanForTomorrow}
                    renderTimeline={() => this.renderTimeline()}
                    goal={this.props.goal}
                    isHolidayMenu={this.props.isHolidayMenu}
                    isTimeToNagForCurrentMeasurement={
                        this.props.isTimeToNagForCurrentMeasurement
                    }
                    {...{ cacheHit, hasNetworkError }}
                    copyDayPlan={this.props.copyDayPlan}
                    replaceProduct={this.props.replaceProduct}
                    alreadyCopied={this.props.alreadyCopied}
                    loadDayPlanMealReplacements={
                        this.props.loadDayPlanMealReplacements
                    }
                    replaceMeal={this.props.replaceMeal}
                    loadDayPlanDayReplacements={
                        this.props.loadDayPlanDayReplacements
                    }
                    replaceDay={this.props.replaceDay}
                    prefetchSurroundingDays={this.props.prefetchSurroundingDays}
                    fastingStatistics={this.props.fastingStatistics}
                    setMealEaten={this.props.setMealEaten}
                    setId={this.props.setId}
                    mealsEaten={this.props.mealsEaten}
                    dietId={this.props.dietId}
                    isVegetarian={this.props.isVegetarian}
                    isPescoVegetarian={this.props.isPescoVegetarian}
                />
            );
        }

        if (type === types.DAY_REDIRECT) {
            return (
                <Redirect
                    to={PATH_DAY_PLAN_INDEX_WITH_DATE.replace(
                        ':date([0-9]{4}-[0-9]{2}-[0-9]{2})',
                        formatIsoDate(this.props.dayPlan.date)
                    )}
                    data-test="day-redirect"
                />
            );
        }

        if (type === types.DIET_IN_FUTURE) {
            return (
                <DietInFuture
                    date={this.props.dietAvailabilityDate}
                    renderTimeline={() => this.renderTimeline()}
                />
            );
        }

        if (type === types.DIET_INTRO) {
            callNativeAppRemoveLastRoute('diet');
            return (
                <Redirect
                    data-test="diet-intro"
                    to={{
                        pathname: PATHS.DIET_INTRO,
                        state: { date: this.props.date },
                    }}
                />
            );
        }

        if (type === types.DIET_SETTINGS) {
            callNativeAppRemoveLastRoute('diet');
            return (
                <Redirect to={PATHS.DIET_SETTINGS} data-test="diet-settings" />
            );
        }

        if (type === types.GOAL_LOST) {
            return <GoalLost renderTimeline={() => this.renderTimeline()} />;
        }

        if (type === types.GOAL_REACHED) {
            return <GoalReached renderTimeline={() => this.renderTimeline()} />;
        }

        if (type === types.OUTSIDE_ACCESS) {
            return (
                <OutsideAccess
                    accessTo={this.props.accessTo}
                    hasActiveSubscription={this.props.hasActiveSubscription}
                    renderTimeline={() => this.renderTimeline()}
                />
            );
        }

        if (type === types.PAYMENT_PENDING) {
            return (
                <PaymentPending
                    token={this.props.dayPlan.token}
                    description={this.props.dayPlan.description}
                />
            );
        }

        if (type === types.UNAUTHORIZED) {
            return <UnauthorizedException accessTo={this.props.dayPlan.date} />;
        }

        throw new Error(`Unknown day plan type: '${type}'`);
    }
}

export { DayPlanIndexContainer };
export default withLoading(DayPlanIndexContainer, DayPlanIndexPlaceholder);
