import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';

import MotivationQuestionsQuery from './MotivationQuestionsQuery';

class MotivationQuestionsConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Query query={MotivationQuestionsQuery} fetchPolicy="no-cache">
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.motivation = [
                            ...data.motivation.public,
                            ...data.motivation.dietSetup,
                        ];
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default MotivationQuestionsConnectorApollo;
