import { getTheLongestPath } from '../TheLongestPath/DietSettingsTheLongestPathAction';

const getStepsCount = (steps, parameters) => {
    let result = 0;
    steps.forEach(step => {
        result += 1;
        if (step.fork) {
            const subStepIdentifier = step.fork.chooseChild(parameters);
            if (subStepIdentifier === undefined) {
                result += getTheLongestPath([step]).length - 1;
            } else {
                const subSteps = step.fork.children[subStepIdentifier];
                result += getStepsCount(subSteps, parameters);
            }
        }
    });

    return result;
};

export const dietSettingsGetStepsCount = (schema, parameters) => {
    let result = 0;
    if (parameters === undefined) {
        // eslint-disable-next-line no-param-reassign
        parameters = {};
    }

    if (schema.length) {
        result = getStepsCount(schema, parameters);
    }

    return result;
};
