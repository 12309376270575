import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

class InterventionHiddenComponent extends React.Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    };

    render() {
        const classNames = [
            'MotivationIntervention',
            `MotivationIntervention--${this.props.type}`,
            'MotivationIntervention--revealed',
            'MotivationIntervention--hidden',
            'fadeInRight animated',
        ];

        return (
            <div className={classNames.join(' ')}>
                <p className="text-center">{this.props.message}</p>
            </div>
        );
    }
}

export default withLocale(InterventionHiddenComponent);
