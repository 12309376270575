import React from 'react';

import UserDataConnectorApollo from 'UserData/Connector/Apollo/Fetch/UserDataConnectorApollo';
import ContentConnectorApollo from 'Content/Index/Connector/Apollo/Fetch/ContentConnectorApollo';
import SendEmailConnectorApollo from './Connector/Apollo/SendEmail/SendEmailConnectorApollo';
import ContactContainer from './ContactContainer';

class ContactPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <ContentConnectorApollo identifier="faq:start">
                {({ ...contentData }) => (
                    <ContentConnectorApollo identifier="faq:dietitian">
                        {({ ...contentData2 }) => (
                            <UserDataConnectorApollo>
                                {({ ...userData }) => (
                                    <SendEmailConnectorApollo>
                                        {({ ...sendEmail }) => (
                                            <ContactContainer
                                                loading={
                                                    contentData.loading ||
                                                    contentData2.loading
                                                }
                                                content={contentData.content}
                                                content2={contentData2.content}
                                                {...userData}
                                                {...sendEmail}
                                            />
                                        )}
                                    </SendEmailConnectorApollo>
                                )}
                            </UserDataConnectorApollo>
                        )}
                    </ContentConnectorApollo>
                )}
            </ContentConnectorApollo>
        );
    }
}

export default ContactPage;
