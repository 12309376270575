import React from 'react';
import Layout from 'Layout/Layout';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Container, Row, Col, Button, Progress } from 'reactstrap';
import { withApollo } from 'react-apollo';
import UserDataQuery from '@dietlabs/components/src/UserData/Connector/Apollo/Fetch/UserDataQuery';
import { ReactComponent as ArrowLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { PATH_PSYCHOMETRIC_RESULT } from './paths';
import PsychometricTestPlaceholder from './PsychometricTestPlaceholder';
import PsychometricQuestion from './PsychometricQuestion';
import PsychomenticEmailForm from './PsychometricEmailForm';

class PsychometricTestContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        psychotest: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        sendAnswers: PropTypes.func.isRequired,
        questionsOnDemand: PropTypes.func.isRequired,
        client: PropTypes.shape({
            readQuery: PropTypes.func.isRequired,
        }).isRequired,
    };

    questionArray = [...this.props.psychotest];

    answersArray = this.questionArray.map(item => ({
        questionId: item.id,
        answer: item.answers[0].id,
    }));

    state = {
        questionArray: this.questionArray,
        answers: this.answersArray,
        currentQuestion: 0,
        showResult: false,
        showEmailForm: false,
        email: undefined,
    };

    setAnswer = async (id, answer) => {
        this.setState(prevState => {
            const newAnswers = prevState.answers.map(item => {
                if (item.questionId === id) {
                    return { questionId: id, answer };
                }

                return item;
            });

            return { answers: newAnswers };
        });

        // set sex for translator
        if (id === 21) {
            let isFemale;
            if (answer === 70) {
                localStorage.setItem('sex', 'female');
                isFemale = true;
            } else {
                localStorage.setItem('sex', 'male');
                isFemale = false;
            }

            // ask psychometric questions again to get correct sex form
            const response = await this.props.questionsOnDemand(isFemale);
            if (response.data.psychotest) {
                this.setState({
                    questionArray: response.data.psychotest.questions,
                });
            }
        }
    };

    handlePrev = () => {
        const wrapper = document.getElementById('wrapper');
        wrapper.classList.add('animated', 'fadeOutRight');

        setTimeout(() => {
            wrapper.classList.remove(...wrapper.classList);
            wrapper.classList.add('hide');
            this.setState(prevState => ({
                currentQuestion: prevState.currentQuestion - 1,
            }));
            wrapper.classList.add('animated', 'fadeInLeft');
        }, 200);
    };

    handleNext = () => {
        const wrapper = document.getElementById('wrapper');
        wrapper.classList.add('animated', 'fadeOutLeft');

        setTimeout(async () => {
            wrapper.classList.remove(...wrapper.classList);
            wrapper.classList.add('hide');

            if (this.state.currentQuestion < this.questionArray.length - 1) {
                this.setState(prevState => ({
                    currentQuestion: prevState.currentQuestion + 1,
                }));
            } else {
                let email = '';
                try {
                    const userData = this.props.client.readQuery({
                        query: UserDataQuery,
                    });
                    email = userData.me.email;

                    const response = await this.props.sendAnswers(
                        email,
                        '',
                        this.state.answers
                    );
                    if (
                        response.data.psychotest.psychometricTestSend.code ===
                        200
                    ) {
                        this.setState({ showResult: true, email });
                    }
                } catch {
                    this.setState({ showEmailForm: true });
                }
            }

            wrapper.classList.add('animated', 'fadeInRight');
        }, 200);
    };

    handleSubmitEmailForm = async email => {
        const response = await this.props.sendAnswers(
            email,
            '',
            this.state.answers
        );
        if (response.data.psychotest.psychometricTestSend.code === 200) {
            this.setState({ showResult: true, email });
        }
    };

    render() {
        if (this.state.showResult) {
            return (
                <Redirect
                    data-test="diet-intro"
                    to={{
                        pathname: PATH_PSYCHOMETRIC_RESULT,
                        state: { email: this.state.email },
                    }}
                />
            );
        }

        if (this.state.showEmailForm) {
            return (
                <PsychomenticEmailForm
                    handleSubmitEmailForm={email =>
                        this.handleSubmitEmailForm(email)
                    }
                />
            );
        }

        const progress =
            ((this.state.currentQuestion + 1) / this.questionArray.length) *
            100;
        const question = this.state.questionArray[this.state.currentQuestion];

        const questionAnswer = this.state.answers[this.state.currentQuestion]
            .answer;

        return (
            <Layout page="psychometric-test">
                <header>
                    <Container>
                        <h1 className="text-center">
                            {this.props.t('motivation-test/question')}{' '}
                            {this.state.currentQuestion + 1}/
                            {this.questionArray.length}
                        </h1>
                    </Container>
                </header>
                <section className="pt-0">
                    <Container>
                        <Row>
                            <Col xs="12" md={{ size: 8, offset: 2 }}>
                                <Progress value={progress} className="mb-5" />
                                <div id="wrapper" className="wrapper">
                                    <PsychometricQuestion
                                        key={question.id}
                                        identifier={question.id}
                                        question={question.text}
                                        questionAnswer={questionAnswer}
                                        answers={question.answers}
                                        setAnswer={(id, answer) =>
                                            this.setAnswer(id, answer)
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md={{ size: 8, offset: 2 }}>
                                <Row className="mt-5">
                                    <Col xs="6" className="pr-2">
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => this.handlePrev()}
                                            disabled={
                                                this.state.currentQuestion <= 0
                                            }
                                        >
                                            <span className="SVGInline">
                                                <ArrowLeft className="mr-2" />
                                            </span>
                                            {this.props.t(
                                                'motivation-test/prev'
                                            )}
                                        </Button>
                                    </Col>
                                    <Col xs="6" className="text-right pl-2">
                                        <Button
                                            color="primary"
                                            className="no-arrow"
                                            onClick={() => this.handleNext()}
                                        >
                                            {this.state.currentQuestion ===
                                            this.questionArray.length - 1
                                                ? this.props.t(
                                                      'motivation-test/send'
                                                  )
                                                : this.props.t(
                                                      'motivation-test/next'
                                                  )}

                                            <span className="SVGInline">
                                                <ArrowRight className="ml-2" />
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLoading(
    withApollo(PsychometricTestContainer),
    PsychometricTestPlaceholder
);
