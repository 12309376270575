import gql from 'graphql-tag';

const TYPENAME = 'UserShoppingListCategoryProduct';

const query = gql`
    fragment product on ${TYPENAME} {
        __typename
        key
        isBought
    }
`;

export { TYPENAME };
export default query;
