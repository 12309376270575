export default {
    apiKey: 'AIzaSyBeFW54J9LGQEGJLP3hiN2kbQFQebh5EC0',
    authDomain: 'oxy-new-v2.firebaseapp.com',
    databaseURL: 'https://oxy-new-v2.firebaseio.com',
    projectId: 'oxy-new-v2',
    storageBucket: 'oxy-new-v2.appspot.com',
    messagingSenderId: '532768192189',
    appId: '1:532768192189:web:82673625d418f6296829d4',
    measurementId: 'G-8GD8CYC65P',
};
