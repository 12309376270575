import React from 'react';

import InterventionsListFetchConnectorApollo from './Connector/Apollo/FetchList/InterventionsListFetchConnectorApollo';
import InternevtionsIndexContainer from './InterventionsIndexContainer';

class InterventionsIndexPage extends React.Component {
    static propTypes = {};

    render() {
        const period = { start: '2000-01-01', end: '3000-01-01' };
        return (
            <InterventionsListFetchConnectorApollo period={period}>
                {({ ...interventionsList }) => (
                    <InternevtionsIndexContainer {...interventionsList} />
                )}
            </InterventionsListFetchConnectorApollo>
        );
    }
}

export default InterventionsIndexPage;
