import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import withGTM from 'view/GoogleTagManager/withGTM';

import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon.svg';
import { ReactComponent as ChangeIcon } from 'assets/icons/change_icon.svg';

import Loader from 'Layout/Loader';

class DayPlanSummary extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        canBeCopiedToDate: PropTypes.bool.isRequired,
        copyDayPlan: PropTypes.func.isRequired,
        alreadyCopied: PropTypes.bool.isRequired,
        print: PropTypes.bool.isRequired,
        trackEvent: PropTypes.func.isRequired,
        protein: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        carbohydrates: PropTypes.number.isRequired,
        toggleModalWithDayReplacements: PropTypes.func.isRequired,
    };

    state = {
        alreadyClicked: this.props.alreadyCopied,
        preload: false,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.alreadyCopied !== this.props.alreadyCopied) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                alreadyClicked: this.props.alreadyCopied,
                preload: false,
            });
        }
    }

    copyDay = async () => {
        this.setState({
            alreadyClicked: true,
            preload: true,
        });
        const response = await this.props.copyDayPlan();
        if (
            response.data.me.dietSetCopy.__typename === 'BasicMutationSuccess'
        ) {
            this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'copy to next day');
        } else {
            throw new Error(
                `Copy day plan to next day failed: ${response.data.me.dietSetCopy.__typename}`
            );
        }
    };

    render() {
        const {
            protein,
            fat,
            carbohydrates,
            canBeCopiedToDate,
            print,
            t,
        } = this.props;

        return (
            <React.Fragment>
                <section className="summary py-0">
                    <Container className="text-center">
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <h3>Rozkład kaloryczności:</h3>
                                <Alert color="summary">
                                    <Row>
                                        <Col xs="4" className="border-right">
                                            <h3 className="mb-0 primary">
                                                {protein}%
                                            </h3>
                                            {t('meal/proteins')}
                                        </Col>
                                        <Col xs="4" className="border-right">
                                            <h3 className="mb-0 primary">
                                                {fat}%
                                            </h3>
                                            {t('meal/fat')}
                                        </Col>
                                        <Col xs="4">
                                            <h3 className="mb-0 primary">
                                                {carbohydrates}%
                                            </h3>
                                            {t('meal/carbohydrates')}
                                        </Col>
                                    </Row>
                                </Alert>

                                {canBeCopiedToDate && !print ? (
                                    <Row>
                                        <Col
                                            xs="12"
                                            sm={{ size: 8, offset: 2 }}
                                        >
                                            <Button
                                                color="primary"
                                                className={
                                                    this.state.alreadyClicked
                                                        ? 'done mb-2 no-arrow'
                                                        : 'mb-2 no-arrow'
                                                }
                                                disabled={
                                                    this.state.alreadyClicked
                                                }
                                                onClick={() => this.copyDay()}
                                                data-test="copy-button"
                                                style={{ width: '15rem' }}
                                            >
                                                <span className="SVGInline mr-2">
                                                    <CopyIcon />
                                                </span>
                                                {this.state.alreadyClicked
                                                    ? t(
                                                          'meal/copy-day-button-done'
                                                      )
                                                    : t('meal/copy-day-button')}
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : (
                                    ''
                                )}

                                <Button
                                    color="primary"
                                    className="mb-2 no-arrow"
                                    onClick={() =>
                                        this.props.toggleModalWithDayReplacements()
                                    }
                                    style={{ width: '15rem' }}
                                >
                                    <span className="SVGInline mr-2">
                                        <ChangeIcon />
                                    </span>
                                    {t('meal/exchange-day-button')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.state.preload ? <Loader /> : ''}
            </React.Fragment>
        );
    }
}

export { DayPlanSummary };
export default withGTM(withLocale(DayPlanSummary));
