import GraphQLFetch from '@dietlabs/components/src/GraphQL/GraphQLFetch';
import Mutation from './PaymentReportMutation';

const reportPayment = (token, operatorData) =>
    GraphQLFetch.runMutation(Mutation, {
        token,
        operatorData,
    }).then(res => {
        let result = null;
        if (
            res &&
            res.data &&
            res.data.sale &&
            res.data.sale.reportPayment &&
            res.data.sale.reportPayment.__typename
        ) {
            const type = res.data.sale.reportPayment.__typename;

            if (type === 'NotReportedPayment') {
                result = res.data.sale.reportPayment;

                if (result.reportData) {
                    result.reportData = result.reportData.map(JSON.parse);
                }
            } else if (type === 'Payment') {
                result = res.data.sale.reportPayment;
            } else if (type === 'CanceledPayment') {
                result = {
                    redirectToPaymentCancelPage: true,
                };
            }
        }

        return result;
    });

export default reportPayment;
