import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import PropTypes from 'prop-types';

class DayPlanMealNavigation extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    state = {
        scrollPos: 0,
        show: false,
    };

    listRef = React.createRef();

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.setState(prevState => ({
            scrollPos: document.body.getBoundingClientRect().top,
            show:
                document.body.getBoundingClientRect().top > prevState.scrollPos,
        }));
    };

    render() {
        return (
            <section
                className={
                    this.state.show
                        ? 'meal-navigation active'
                        : 'meal-navigation hidden'
                }
            >
                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <ul ref={this.listRef}>
                                {this.props.children(this.listRef.current)}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default DayPlanMealNavigation;
