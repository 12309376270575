import { formatPrice } from 'utils/currencyFormatter';

export default class Price {
    constructor(amount, currency) {
        this.amount = Number(amount)
            ? Math.round(Number(amount) * 100) / 100
            : 0;
        this.currency = currency;
    }

    add(price) {
        if (price.currency !== this.currency) {
            throw new Error('Currencies have to be the same');
        }

        return new Price(this.amount + price.amount, this.currency);
    }

    multiply(multiplier) {
        return new Price(this.amount * multiplier, this.currency);
    }

    subtract(promo) {
        return new Price(this.amount - promo.amount, this.currency);
    }

    toString() {
        return formatPrice(this);
    }
}
