import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import ArticleListFetchQuery from './ArticleListFetchQuery';

class ArticeListByCategoryConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    fetch = async request => {
        const result = await this.props.client.query({
            query: ArticleListFetchQuery,
            variables: request,
            fetchPolicy: 'network-only',
        });

        return result;
    };

    render() {
        const { children } = this.props;

        return children({
            loadArticlesByCategory: request => this.fetch(request),
        });
    }
}

export default withApollo(ArticeListByCategoryConnectorApollo);
