import React from 'react';
import PropTypes from 'prop-types';
import Query from '@dietlabs/components/src/Apollo/Query';
import ActiveSubscriptionQuery from './ActiveSubscriptionQuery';

class ActiveSubscriptionConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Query query={ActiveSubscriptionQuery}>
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        data,
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    return children(result);
                }}
            </Query>
        );
    }
}

export default ActiveSubscriptionConnectorApollo;
