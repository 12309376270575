import gql from 'graphql-tag';

export default gql`
    mutation motivationSendAnswersMutation(
        $source: String
        $answers: [MotivationQuestionAnswerInputType]!
    ) {
        motivation {
            motivationSend(source: $source, answers: $answers) {
                __typename
                ... on BasicMutationSuccess {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;
