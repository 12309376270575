import ShoppingCartProduct from 'view/ShoppingCart/Product/ShoppingCartProduct';
import { disableCodForProductsArray } from 'consts';

export default class ShoppingCartProducts {
    constructor(rawCartProducts = {}, availableProducts = {}) {
        this.rawCartProducts = rawCartProducts;
        this.availableProducts = availableProducts;

        this.initProducts();
    }

    initProducts() {
        this.products = Object.keys(this.rawCartProducts).reduce(
            (prev, productId) =>
                this.availableProducts[productId] !== undefined
                    ? prev.concat(
                          new ShoppingCartProduct(
                              this.availableProducts[productId],
                              this.rawCartProducts[productId].amount,
                              this.rawCartProducts[productId].lastSource
                          )
                      )
                    : prev,
            []
        );
    }

    get isPhysicalProduct() {
        return Boolean(
            this.products.filter(product => product.isPhysical).length
        );
    }

    get areThereOnlyDietlabsProducts() {
        return !this.products.filter(
            product => product.vendorIdentifier !== 'dietlabs'
        ).length;
    }

    get disableCodForProducts() {
        return this.products.some(product =>
            disableCodForProductsArray.includes(product.id)
        );
    }

    get areThereSomeProducts() {
        return Object.keys(this.availableProducts).length
            ? Boolean(this.products.length)
            : Boolean(Object.keys(this.rawCartProducts).length);
    }
}
