import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import ArticleFetchQuery from './ArticleFetchQuery';

class ArticleFetchConnectorApollo extends React.Component {
    static propTypes = {
        articleId: PropTypes.number.isRequired,
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children, articleId } = this.props;

        return (
            <Query
                query={ArticleFetchQuery}
                variables={{
                    articleId,
                }}
                fetchPolicy="no-cache"
            >
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.article = deepClone(data.article[0]);
                        if (data.me) {
                            result.accessTo = deepClone(data.me.diet.accessTo);
                        }
                    }
                    return children(result);
                }}
            </Query>
        );
    }
}

export default ArticleFetchConnectorApollo;
