import { connect } from 'react-redux';
import ShoppingCartUpSellProductDetailsComponent from 'components/ShoppingCart/UpSell/ShoppingCartUpSellProductDetailsComponent';
import { shoppingCartProductAddAction } from 'view/ShoppingCart/Product/Add/ShoppingCartProductAddAction';
import { shoppingCartProductIsInCartAction } from 'view/ShoppingCart/Product/IsInCart/ShoppingCartProductIsInCart';
import ShoppingCartProductsFactory from 'view/ShoppingCart/Product/ShoppingCartProductsFactory';

const mapStateToProps = state => ({
    cartProducts: ShoppingCartProductsFactory.createFromState(state).products,
});

const mapDispatchToProps = dispatch => ({
    add: productId => dispatch(shoppingCartProductAddAction(productId, 1)),
    productIsInCart: (productId, cartProducts) =>
        shoppingCartProductIsInCartAction(productId, cartProducts),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartUpSellProductDetailsComponent);
