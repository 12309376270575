import { PATH_PSYCHOMETRIC_TEST } from 'PsychometricTest/paths';
import { PATHS } from '../paths';

export default [
    {
        path: PATHS.EXTERNAL.HOME,
        externalUrl: PATHS.EXTERNAL.HOME,
        translationKey: 'menu/home',
    },
    {
        path: PATH_PSYCHOMETRIC_TEST,
        translationKey: 'menu/psychometric-test',
    },
    {
        path: PATHS.CONTACT,
        translationKey: 'menu/contact',
    },
    {
        path: PATHS.AUTH.LOGIN,
        translationKey: 'menu/login',
    },
];
