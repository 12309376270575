import React, { Component } from 'react';
import { Row, Col, Alert, Collapse } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';
import bulb from 'assets/images/dayplan/bulb.svg';
import DayPlanAdvice from './DayPlanAdvice';

class DayPlanDish extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        recipe: PropTypes.string.isRequired,
        recipeNote: PropTypes.string,
        isFirstOccurance: PropTypes.bool.isRequired,
        isLastOccurance: PropTypes.bool.isRequired,
        isPortioned: PropTypes.bool.isRequired,
        portions: PropTypes.number,
        portionsTotal: PropTypes.number,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        ingredients: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    };

    static defaultProps = {
        recipeNote: undefined,
        portions: undefined,
        portionsTotal: undefined,
    };

    state = {
        chefAdviceCollapse: false,
    };

    renderArrow() {
        if (this.state.chefAdviceCollapse) {
            return (
                <span className="collapse-arrow open">
                    <span className="SVGInline">
                        <DownArrow />
                    </span>
                </span>
            );
        }

        return (
            <span className="collapse-arrow">
                <span className="SVGInline">
                    <DownArrow />
                </span>
            </span>
        );
    }

    renderChefAdvice = () => {
        const adviceArray = [];

        this.props.ingredients.forEach(ingredient => {
            if (ingredient.note.title) {
                adviceArray.push({
                    title: ingredient.note.title,
                    description: ingredient.note.content,
                    media: ingredient.note.media,
                });
            }
        });

        if (adviceArray.length > 0) {
            return (
                <div className="chef-advice">
                    <div
                        role="button"
                        tabIndex="0"
                        onClick={() => this.handleClick()}
                    >
                        <img src={bulb} alt="" className="mr-2" />
                        {this.props.t('chef-advice/title')}
                        {this.renderArrow()}
                    </div>
                    <Collapse isOpen={this.state.chefAdviceCollapse}>
                        {adviceArray.map((advice, idx) => {
                            return (
                                <>
                                    {idx > 0 && <hr />}
                                    <DayPlanAdvice
                                        key={advice.title}
                                        advice={advice}
                                    />
                                </>
                            );
                        })}
                    </Collapse>
                </div>
            );
        }

        return null;
    };

    handleClick() {
        this.setState(prevState => ({
            chefAdviceCollapse: !prevState.chefAdviceCollapse,
        }));
    }

    renderPortionsInfoBeforeRecipe() {
        if (this.props.portions && !this.props.isFirstOccurance) {
            return (
                <Alert color="info" data-test="portions-info-before-recipe">
                    {this.props.isLastOccurance
                        ? this.props.t(
                              'dish/portion/you-should-have-this-dish-last'
                          )
                        : this.props.t(
                              'dish/portion/you-should-have-this-dish'
                          )}{' '}
                    <div
                        className="d-inline"
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('dish/portion/eat-x-of', {
                                count: this.props.portions,
                                total: this.props.portionsTotal,
                            }),
                        }}
                    />
                </Alert>
            );
        }
        return '';
    }

    renderPortionsInfoAfterRecipe() {
        if (this.props.portions && this.props.isFirstOccurance) {
            return (
                <Alert color="info" data-test="portions-info-after-recipe">
                    {!this.props.isLastOccurance ? (
                        <div
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                                __html: this.props.t(
                                    'dish/portion/dish-used-in-following-days',
                                    { count: this.props.portionsTotal }
                                ),
                            }}
                        />
                    ) : (
                        ''
                    )}{' '}
                    <div
                        className="d-inline"
                        dangerouslySetInnerHTML={{
                            __html: this.props.t('dish/portion/eat-x-portion', {
                                count: this.props.portions,
                            }),
                        }}
                    />{' '}
                    {this.props.isLastOccurance
                        ? this.props.t('dish/portion/rest')
                        : ''}
                </Alert>
            );
        }
        return '';
    }

    renderRecipeNote() {
        if (this.props.recipeNote) {
            return (
                <Alert color="info" data-test="recipe-note">
                    {this.props.recipeNote}
                </Alert>
            );
        }
        return '';
    }

    render() {
        const replaceSpanToOl = ({ children }) => (
            <ol>
                <li>{children.slice(3)}</li>
            </ol>
        );

        return (
            <div className="white-box">
                <h2 className="dishName">
                    {this.props.name}
                    {this.props.isPortioned ? (
                        <div
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                                __html: this.props.t(
                                    'dish/portion/x-portion-of',
                                    {
                                        count: this.props.portions,
                                        total: this.props.portionsTotal,
                                    }
                                ),
                            }}
                            data-test="portion-info"
                        />
                    ) : (
                        ''
                    )}
                </h2>
                <Row>
                    <Col xs="12" sm="6">
                        {this.props.recipe !== '' && (
                            <h2 className="d-none d-md-block">
                                {this.props.t('meal/ingredients')}:
                            </h2>
                        )}

                        {this.props.children()}
                    </Col>

                    <Col xs="12" sm="6" className="pl-md-5 mt-4 mt-md-0">
                        {this.renderChefAdvice()}

                        {this.props.recipe !== '' && (
                            <>
                                {this.renderPortionsInfoBeforeRecipe()}

                                <div className="recipe">
                                    <h2>
                                        {this.props.portions
                                            ? this.props.t(
                                                  'recipe-for-x-portion',
                                                  {
                                                      count: this.props
                                                          .portionsTotal,
                                                  }
                                              )
                                            : this.props.t(
                                                  'meal/how-to-prepare'
                                              )}
                                        :
                                    </h2>
                                    {this.props.recipe && (
                                        <Markdown
                                            start="1"
                                            options={{
                                                overrides: {
                                                    span: {
                                                        component: replaceSpanToOl,
                                                    },
                                                },
                                            }}
                                        >
                                            {this.props.recipe}
                                        </Markdown>
                                    )}
                                </div>

                                {this.renderPortionsInfoAfterRecipe()}
                                {this.renderRecipeNote()}
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export { DayPlanDish };
export default withLocale(DayPlanDish);
