import React, { Component } from 'react';
import { withLocale } from '@dietlabs/components';
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { callNativeAppRouteChanged } from 'components/Helpers/callNativeAppRouteChanged';
import withMobileAppMode from 'Layout/withMobileAppMode';
import Menu from 'Layout/Menu';
import iconBack from 'assets/images/dayplan/icon-back.svg';
import { withShouldScroll } from '@dietlabs/components/src/Layout/ScrollToTop';

class DayPlanMealReplacementList extends Component {
    static propTypes = {
        originalMealId: PropTypes.number.isRequired,
        mealId: PropTypes.number.isRequired,
        children: PropTypes.func.isRequired,
        renderOriginalMeal: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        replacements: PropTypes.arrayOf(PropTypes.shape()),
        toggleModalWithReplacements: PropTypes.func.isRequired,
        toggleModal: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        handleShouldScroll: PropTypes.func.isRequired,
    };

    static defaultProps = {
        replacements: null,
    };

    componentDidMount() {
        document.body.style.overflow = 'hidden';

        this.props.handleShouldScroll(false);

        // disable browser back button
        window.history.pushState(
            null,
            null,
            `${window.location.pathname}?wymien-posilek`
        );
        window.onpopstate = () => {
            this.props.toggleModalWithReplacements();
        };

        // routeChanged post msg
        callNativeAppRouteChanged(
            window.location.href,
            'diet',
            'Wymiana posiłku'
        );
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        window.onpopstate = () => {
            this.props.toggleModal();
        };
    }

    formatDate = (date, isShort) => {
        const { t } = this.props;

        const weekday = date.getDay();
        const day = date.getDate();
        const day0 = day < 10 ? `0${day}` : day;
        const month = date.getMonth() + 1;
        const month0 = month < 10 ? `0${month}` : month;
        let dayName = t(`meal/weekday${weekday}`);

        if (isShort) {
            dayName = t(`meal/weekday${weekday}-short`);
        }
        return `${dayName} ${day0}.${month0}`;
    };

    render() {
        const { originalMealId, mealId, replacements } = this.props;

        return (
            <React.Fragment>
                {!this.props.mobileAppMode && (
                    <React.Fragment>
                        <Menu forceReloadLinks={false} />
                        <div className="top-bar border-bottom">
                            <Container className="px-3 px-md-0">
                                <h1 className="text-center py-3">
                                    <Button
                                        color="link"
                                        onClick={() => window.history.back()}
                                        className="mr-4 float-left p-0"
                                    >
                                        <img
                                            src={iconBack}
                                            className="mr-1"
                                            alt=""
                                        />
                                        {this.props.t('back')}
                                    </Button>
                                    {this.props.t('meal/exchange-meal-button')}
                                    <span className="d-none d-md-inline">
                                        &nbsp;(
                                        {this.formatDate(
                                            this.props.date,
                                            false
                                        )}
                                        )
                                    </span>
                                </h1>
                            </Container>
                        </div>
                    </React.Fragment>
                )}
                <Container>
                    <div className="meal">
                        <Row className="mt-3 change-meal">
                            <Col md={{ size: 8, offset: 2 }}>
                                {originalMealId !== mealId ? (
                                    <React.Fragment>
                                        {this.props.renderOriginalMeal()}
                                    </React.Fragment>
                                ) : (
                                    ''
                                )}
                                {replacements && replacements.length > 0 ? (
                                    <React.Fragment>
                                        {this.props.children()}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {this.props.t(
                                            'meal/there-are-no-replacements'
                                        )}
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export { DayPlanMealReplacementList };
export default withShouldScroll(
    withMobileAppMode(withLocale(DayPlanMealReplacementList))
);
