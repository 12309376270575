import GuidesIndexPage from './GuidesIndexPage';
import { PATH_GUIDES } from './paths';

const routes = [
    {
        path: PATH_GUIDES,
        component: GuidesIndexPage,
        private: true,
        exact: true,
        title: 'route-title/guides',
        mobileAppTabIdentifier: 'more',
    },
];

export { routes };
