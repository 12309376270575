import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import logo from 'assets/icons/logo.svg';
import withStep from './StepContext/withStep';

class StepStart extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        t: PropTypes.func.isRequired,
    };

    static defaultProps = {
        name: '',
    };

    render() {
        return (
            <div className="full-screen2">
                <div className="text-center my-auto">
                    <img className="mb-5 logo" src={logo} alt="logo" />
                    <p>
                        {this.props.t('diet-settings/welcome-user')}{' '}
                        {this.props.name}!
                        <br />
                        {this.props.t('diet-settings/welcome-message')}
                    </p>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepStart));
