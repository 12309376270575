export default class ShoppingCartPaymentMethod {
    constructor(
        methodId,
        channelId,
        name,
        description,
        logoUrl,
        wideViewportLogoUrl
    ) {
        this.methodId = methodId;
        this.channelId = channelId;
        this.name = name;
        this.description = description;
        this.logoUrl = logoUrl;
        this.wideViewportLogoUrl = wideViewportLogoUrl;
    }
}
