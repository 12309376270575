import gql from 'graphql-tag';

const query = gql`
    query PsychotestQuestions($isFemale: Boolean) {
        psychotest {
            questions(isFemale: $isFemale) {
                id
                text
                answers {
                    id
                    text
                    points
                }
            }
        }
    }
`;

export default query;
