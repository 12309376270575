import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

class FormGroupPlaceholder extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
    };

    render() {
        const { label } = this.props;
        return (
            <FormGroup>
                <Label>{label}</Label>
                <div className="input-placeholder placeholder">&nbsp;</div>
            </FormGroup>
        );
    }
}

export default FormGroupPlaceholder;
