import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
// import { CustomInput } from 'reactstrap';

// Canvas size
const SIZE = 480;
const HALF = SIZE / 2;
const RINGS_COUNT = 6;
const PADDING = (HALF / (RINGS_COUNT + 1)) * 2;
const RAD_OFFSET = 1.5 * Math.PI;

function getVariantOption(task, key) {
    const option = task.variantOptions.find(o => o.keyalias === key);
    if (!option) {
        throw new Error(
            `Failed to get “${key}” variant option from task#${task.id}`
        );
    }
    return option.value;
}

class InterventionTaskHealthCircleComponent extends React.Component {
    static propTypes = {
        task: PropTypes.shape({
            id: PropTypes.string,
            answers: PropTypes.arrayOf(PropTypes.string),
            label: PropTypes.string,
            min: PropTypes.number,
            max: PropTypes.number,
            variant: PropTypes.string,
            variantOptions: PropTypes.arrayOf(PropTypes.shape()),
            subtasks: PropTypes.arrayOf(PropTypes.shape()),
        }).isRequired,
        tasks: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                answers: PropTypes.arrayOf(PropTypes.string),
                label: PropTypes.string,
                min: PropTypes.number,
                max: PropTypes.number,
                variant: PropTypes.string,
                variantOptions: PropTypes.arrayOf(PropTypes.shape()),
                subtasks: PropTypes.arrayOf(PropTypes.shape()),
            })
        ).isRequired,
    };

    getResultContent() {
        return getVariantOption(this.props.task, 'resultContent');
    }

    getResultQuestions() {
        return getVariantOption(this.props.task, 'resultQuestions');
    }

    renderCircle() {
        const tasks = this.props.tasks;
        const rings = [];
        const labels = [];
        const answerMin = tasks.reduce(
            (carry, { min }) => Math.max(carry, min),
            0
        );
        const answerMax = tasks.reduce(
            (carry, { max }) => Math.max(carry, max),
            0
        );
        for (let i = 1; i <= RINGS_COUNT; i += 1) {
            const r = ((HALF - PADDING) / RINGS_COUNT) * i;
            rings.push(
                <circle
                    key={i}
                    cx={HALF}
                    cy={HALF}
                    r={r}
                    className="MotivationInterventionHealthCircle__ring"
                />
            );
            const label = answerMin + (answerMax / (RINGS_COUNT - 1)) * (i - 1);
            labels.push(
                <text
                    key={i}
                    x={HALF + r}
                    y={HALF * 1.035}
                    className="MotivationInterventionHealthCircle__label"
                    aria-hidden
                >
                    {label}
                </text>
            );
        }

        const answersPath = [];
        const areaBorders = [];
        const areaLabels = [];
        const areaLabelsPaths = [];
        const maxR = HALF - PADDING;
        const minR = maxR / RINGS_COUNT;
        const outerMaxR = HALF - PADDING + minR;
        const offset = HALF;
        const radBase = (2 * Math.PI) / tasks.length;
        for (let idx = 0; idx < tasks.length; idx += 1) {
            const task = tasks[idx];
            const {
                answers: [answer],
            } = task;
            const rad1 = radBase * idx + RAD_OFFSET;
            const rad2 = radBase * (idx + 1) + RAD_OFFSET;
            const maxX1 = Math.cos(rad1) * maxR + offset;
            const maxY1 = Math.sin(rad1) * maxR + offset;

            areaBorders.push(
                <line
                    key={task.id}
                    x1={HALF}
                    y1={HALF}
                    x2={maxX1}
                    y2={maxY1}
                    className="MotivationInterventionHealthCircle__areaBorder"
                />
            );

            const pathId = `MotivationInterventionHealthCircle__areaLabelPath--${task.id}`;
            const outerMaxX1 = Math.cos(rad1) * outerMaxR + offset;
            const outerMaxY1 = Math.sin(rad1) * outerMaxR + offset;
            const outerMaxX2 = Math.cos(rad2) * outerMaxR + offset;
            const outerMaxY2 = Math.sin(rad2) * outerMaxR + offset;
            const outerMaxXY1 = `${outerMaxX1} ${outerMaxY1}`;
            const outerMaxXY2 = `${outerMaxX2} ${outerMaxY2}`;
            let d;
            // Bottom half has flipped labels
            if (rad1 < 2 * Math.PI || rad2 > 3 * Math.PI) {
                d = `M ${outerMaxXY1} A ${outerMaxR} ${outerMaxR} 0 0 1 ${outerMaxXY2}`;
            } else {
                d = `M ${outerMaxXY2} A ${outerMaxR} ${outerMaxR} 0 0 0 ${outerMaxXY1}`;
            }
            areaLabelsPaths.push(<path key={pathId} id={pathId} d={d} />);
            areaLabels.push(
                <text
                    key={task.id}
                    className="MotivationInterventionHealthCircle__areaLabel"
                    aria-hidden
                >
                    <textPath xlinkHref={`#${pathId}`} startOffset="50%">
                        {task.variantOptions[0].value}
                    </textPath>
                </text>
            );

            const r = minR + (answer / (answerMax - answerMin)) * (maxR - minR);
            const x1 = Math.cos(rad1) * r + offset;
            const y1 = Math.sin(rad1) * r + offset;
            const x2 = Math.cos(rad2) * r + offset;
            const y2 = Math.sin(rad2) * r + offset;
            const act = idx === 0 ? 'M' : 'L';
            answersPath.push(`${act} ${x1} ${y1}`);
            answersPath.push(`A ${r} ${r} 0 0 1 ${x2} ${y2}`);
        }
        answersPath.push('Z');

        const classNames = [
            'MotivationIntervention__illustration',
            'MotivationIntervention__illustration--mainContent',
            'MotivationInterventionHealthCircle',
        ];

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox={`0 0 ${SIZE} ${SIZE}`}
                preserveAspectRatio="xMidYMid meet"
                className={classNames.join(' ')}
            >
                <g>{rings}</g>
                <g>{areaBorders}</g>
                <defs>{areaLabelsPaths}</defs>
                <g>{areaLabels}</g>
                <g>{labels}</g>
                <path
                    d={answersPath.join('\n')}
                    className="MotivationInterventionHealthCircle__answers"
                />
            </svg>
        );
    }

    render() {
        return (
            <div className="mt-3">
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.getResultContent(),
                    }}
                    className="MotivationIntervention__content"
                />

                {this.renderCircle()}

                <div
                    dangerouslySetInnerHTML={{
                        __html: this.getResultQuestions(),
                    }}
                    className="MotivationIntervention__content"
                />
            </div>
        );
    }
}

export default withLocale(InterventionTaskHealthCircleComponent);
