import ShoppingListIndexPage from 'ShoppingList/Index/ShoppingListIndexPage';

const PATH_SHOPPING_LIST_INDEX = '/shopping-list';

const routes = [
    {
        path: PATH_SHOPPING_LIST_INDEX,
        component: ShoppingListIndexPage,
        private: true,
        exact: false,
        title: 'route-title/shopping-list',
        mobileAppTabIdentifier: 'shopping-list',
    },
];

export { PATH_SHOPPING_LIST_INDEX, routes };
