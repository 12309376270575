import DayPage from './Day/HydrationDayPage';
import HistoryPage from './History/HydrationHistoryPage';
import { PATH_HYDRATION, PATH_HYDRATION_HISTORY } from './paths';

const routes = [
    {
        path: PATH_HYDRATION,
        component: DayPage,
        private: true,
        exact: true,
        title: 'route-title/hydration-day',
        mobileAppTabIdentifier: 'more',
    },
    {
        path: PATH_HYDRATION_HISTORY,
        component: HistoryPage,
        private: true,
        exact: true,
        title: 'route-title/hydration-history',
        mobileAppTabIdentifier: 'more',
    },
];

export { routes };
