import gql from 'graphql-tag';

export default gql`
    mutation revealInterventionMutation($date: Date!) {
        me {
            revealIntervention(date: $date) {
                ... on UserInterventionVisible {
                    __typename
                    key
                    revealed
                }
            }
        }
    }
`;
