import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { ReactComponent as DoneIcon } from 'assets/icons/interventions/done.svg';

class InterventionSuccessComponent extends React.Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        successContent: PropTypes.string.isRequired,
    };

    render() {
        const classNames = [
            'MotivationIntervention',
            `MotivationIntervention--${this.props.type}`,
            'MotivationIntervention--succeed',
            'fadeInRight animated',
        ];
        return (
            <section className={classNames.join(' ')}>
                <div className="number">
                    <span className="SVGInline">
                        <DoneIcon />
                    </span>
                </div>
                <div
                    className="content text-center"
                    dangerouslySetInnerHTML={{
                        __html: this.props.successContent,
                    }}
                />
            </section>
        );
    }
}

export default withLocale(InterventionSuccessComponent);
