import { shoppingCartProductGetToSmartRemove } from 'view/ShoppingCart/Product/GetToSmartRemove/ShoppingCartProductGetToSmartRemove';
import ShoppingCartProductsFactory from 'view/ShoppingCart/Product/ShoppingCartProductsFactory';
import { SHOPPING_CART_PRODUCT_REMOVE } from 'view/ShoppingCart/Product/Remove/ShoppingCartProductRemoveActionType';
import { SHOPPING_CART_PRODUCT_ADD } from './ShoppingCartProductAddActionType';

export const shoppingCartProductAddAction = (
    productId,
    amount,
    firstSource,
    lastSource
) => (dispatch, getState) => {
    const { saleProducts } = getState();
    const saleProduct = saleProducts[productId];
    if (!saleProduct) {
        const productIds = Object.keys(saleProducts).join();
        throw new Error(
            `Failed to add product#${productId} to cart. Product ids in store: ${productIds}`
        );
    }
    const currentCartProducts = ShoppingCartProductsFactory.createFromState(
        getState()
    ).products;

    const productIdsToRemove = shoppingCartProductGetToSmartRemove(
        saleProduct,
        currentCartProducts
    );

    productIdsToRemove.forEach(productToRemove => {
        dispatch({
            type: SHOPPING_CART_PRODUCT_REMOVE,
            productId: productToRemove.id,
        });
    });

    if (amount === undefined) {
        amount = 1;
    }

    const action = dispatch({
        type: SHOPPING_CART_PRODUCT_ADD,
        productId,
        amount,
        firstSource,
        lastSource,
    });

    return action;
};
