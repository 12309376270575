import ShoppingCartProductsFactory from 'view/ShoppingCart/Product/ShoppingCartProductsFactory';
import ShoppingCartDeliveryOptions from './ShoppingCartDeliveryOptions';

export default class ShoppingCartDeliveryOptionsFactory {
    static createFromState(state) {
        const areThereOnlyDietlabsProducts = ShoppingCartProductsFactory.createFromState(
            state
        ).areThereOnlyDietlabsProducts;

        return new ShoppingCartDeliveryOptions(
            state.shoppingCart.shippingOptions,
            state.shoppingCart.saleShippingId,
            areThereOnlyDietlabsProducts
        );
    }
}
