import MotivationTestPage from './MotivationTestPage';
import MotivationResultPage from './MotivationResultPage';

import { PATH_MOTIVATION_TEST, PATH_MOTIVATION_RESULT } from './paths';

const routes = [
    {
        path: PATH_MOTIVATION_TEST,
        component: MotivationTestPage,
        private: true,
        exact: true,
        title: 'route-title/interventions',
        mobileAppTabIdentifier: 'motivation',
    },
    {
        path: PATH_MOTIVATION_RESULT,
        component: MotivationResultPage,
        private: true,
        exact: true,
        title: 'route-title/interventions',
        mobileAppTabIdentifier: 'motivation',
    },
];

export { routes };
