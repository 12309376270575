import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { reportError } from '@dietlabs/utils';
import { withLocale } from '@dietlabs/components';
import { formatLongDate } from '../../utils/dateFormatter';

class SettingsSubscriptionsComponent extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        subscriptions: PropTypes.arrayOf(
            PropTypes.shape({
                givesAccessToDiet: PropTypes.bool.isRequired,
                givesAccessToVideoWorkouts: PropTypes.bool.isRequired,
                renewalDate: PropTypes.instanceOf(Date),
                operator: PropTypes.string,
            })
        ).isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    renderSingleSubscription(subscription, key) {
        const { t } = this.props;
        let heading;
        let renewalMessage;

        if (
            subscription.givesAccessToDiet &&
            subscription.givesAccessToVideoWorkouts
        ) {
            heading = t('settings/subscriptions/diet+video-workouts/heading');
        } else if (subscription.givesAccessToDiet) {
            heading = t('settings/subscriptions/diet/heading');
        } else if (subscription.givesAccessToVideoWorkouts) {
            heading = t('settings/subscriptions/video-workouts/heading');
        } else {
            reportError(
                new Error(
                    'Encountered subscription that does not give access to neighter diet nor video workouts'
                )
            );
            return null;
        }

        if (subscription.renewalDate > new Date()) {
            renewalMessage = t(
                'settings/subscriptions/renewal-date {date}'
            ).replace('{date}', formatLongDate(subscription.renewalDate));
        } else {
            renewalMessage = t('settings/subscriptions/renewal-date/in-past');
        }

        return (
            <Fragment key={key}>
                <h3>{heading}</h3>
                <p>{renewalMessage}</p>
            </Fragment>
        );
    }

    renderSubscriptions() {
        if (this.props.subscriptions.length === 0) {
            const { t } = this.props;
            return <p>{t('settings/subscriptions/none')}</p>;
        }

        return this.props.subscriptions.map((sub, key) =>
            this.renderSingleSubscription(sub, key)
        );
    }

    render() {
        const { t, className } = this.props;
        return (
            <Container className={className}>
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <h2>{t('settings/subscriptions/heading')}</h2>
                            {this.renderSubscriptions()}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export { SettingsSubscriptionsComponent };
export default withLocale(SettingsSubscriptionsComponent);
