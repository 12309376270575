/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { scroller, Element } from 'react-scroll';
import { ReactComponent as DownArrow } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChangeIcon } from 'assets/icons/change_icon.svg';

const steps = [5, 15, 30, 45, 60];

class DayPlanMeal extends Component {
    static propTypes = {
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        name: PropTypes.string.isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        // showMacros: PropTypes.bool.isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        originalId: PropTypes.number.isRequired,
        loadDayPlanMealReplacements: PropTypes.func.isRequired,
        renderMealReplacementsList: PropTypes.func.isRequired,
        print: PropTypes.bool.isRequired,
        mealId: PropTypes.number.isRequired,
        dietPhase: PropTypes.string.isRequired,
        onRef2: PropTypes.func.isRequired,
    };

    state = {
        mealCollapse: true,
        collapse: false,
        replacements: null,
        originalId: 0,
        preloadInside: false,
    };

    changeMealButtonRef = React.createRef();

    scrollAnchorRef = React.createRef();

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `<${step}`;
            }
        }
        return `>${steps[steps.length - 1]}`;
    }

    componentDidMount() {
        this.props.onRef2(this, `meal${this.props.mealId}`);
    }

    closeAndScroll() {
        scroller.scrollTo(`meal${this.props.mealId}`, {
            duration: 1000,
            delay: 0,
            smooth: true,
            offset: -56,
        });

        this.setState({
            collapse: false,
            preloadInside: true,
        });
    }

    replaceMealSuccess() {
        this.setState({ preloadInside: false });
    }

    handleClick() {
        if (this.state.collapse) {
            this.setState({ collapse: false });
        } else {
            this.setState({ preloadInside: true });
            this.loadMealReplacements();
        }
    }

    openMeal() {
        this.setState({ mealCollapse: true });
    }

    handleMealClick() {
        this.setState(prevState => ({
            mealCollapse: !prevState.mealCollapse,
        }));
    }

    loadMealReplacements = async () => {
        const request = {
            originalMealId: this.props.originalId,
            limit: 10,
        };
        const response = await this.props.loadDayPlanMealReplacements(request);

        this.setState({
            collapse: true,
            replacements: response.data.me.diet.set.meal.replacements,
            originalId: response.data.me.diet.set.meal.original.id,
            preloadInside: false,
        });

        scroller.scrollTo(`changeMealButton${this.props.mealId}`, {
            duration: 500,
            delay: 0,
            smooth: true,
            offset: -56,
        });
    };

    render() {
        return (
            <section
                className="meal"
                ref={this.scrollAnchorRef}
                name={`meal${this.props.mealId}`}
            >
                <Container>
                    <header
                        role="button"
                        onClick={() => this.handleMealClick()}
                        className={this.props.dietPhase}
                    >
                        <h2>
                            <strong>{this.props.name}</strong>
                            <Button
                                className={
                                    this.state.mealCollapse
                                        ? 'open collapse-arrow outline btn-auto-width'
                                        : 'collapse-arrow outline btn-auto-width'
                                }
                                type="button"
                            >
                                <span className="SVGInline">
                                    <DownArrow />
                                </span>
                            </Button>
                        </h2>
                        <p className="BTW">
                            <span>
                                <strong>
                                    {this.props.t('meal/proteins-short')}
                                    {': '}
                                </strong>
                                {this.props.macro.protein.grams}g &nbsp;
                                <strong>
                                    {this.props.t('meal/fat-short')}
                                    {': '}
                                </strong>
                                {this.props.macro.fat.grams}g &nbsp;
                                <strong>
                                    {this.props.t('meal/carbohydrates-short')}
                                    {': '}
                                </strong>
                                {this.props.macro.carbohydrates.grams}g
                            </span>
                            <span>{this.props.kcal}&nbsp;kcal</span>
                        </p>
                    </header>

                    <Collapse isOpen={this.state.mealCollapse}>
                        {this.props.children()}

                        {!this.props.print && this.props.name !== 'Start' ? (
                            <Row className="mb-4">
                                <Col xs="12">
                                    <div className="text-center">
                                        <Element
                                            name={`changeMealButton${this.props.mealId}`}
                                        >
                                            <Button
                                                color="primary"
                                                innerRef={
                                                    this.changeMealButtonRef
                                                }
                                                onClick={() =>
                                                    this.handleClick()
                                                }
                                                data-test="change-meal-button"
                                                outline={this.state.collapse}
                                                className="no-arrow"
                                            >
                                                <span className="SVGInline mr-2">
                                                    <ChangeIcon />
                                                </span>
                                                {this.state.collapse
                                                    ? this.props.t(
                                                          'meal/exchange-collapse'
                                                      )
                                                    : this.props.t(
                                                          'meal/exchange-meal-button'
                                                      )}
                                            </Button>
                                        </Element>

                                        <Collapse isOpen={this.state.collapse}>
                                            {this.props.renderMealReplacementsList(
                                                this.state.replacements,
                                                this.state.originalId,
                                                {
                                                    closeAndScroll: () =>
                                                        this.closeAndScroll(),
                                                    replaceMealSuccess: () =>
                                                        this.replaceMealSuccess(),
                                                }
                                            )}
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            ''
                        )}
                    </Collapse>
                </Container>

                {this.state.preloadInside ? <Loader fixed="no" /> : ''}
            </section>
        );
    }
}

export { DayPlanMeal };
export default withLocale(DayPlanMeal);
