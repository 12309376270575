import React, { Component } from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { InputErrorType } from 'types/CommonTypes';
import eye from 'assets/icons/eye-solid.svg';
import eyeSlash from 'assets/icons/eye-slash-solid.svg';
import withRequired from './withRequired';

class InputPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        };
    }

    toggleVisibility() {
        this.setState(prevState => ({
            isVisible: !prevState.isVisible,
        }));
    }

    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        return (
            <FormGroup>
                {this.props.label ? (
                    <Label for={this.props.name}>
                        {this.props.required ? <sup>*</sup> : ''}{' '}
                        {this.props.label}
                    </Label>
                ) : (
                    ''
                )}
                <InputGroup
                    className={
                        errorMgs ? 'form-control is-invalid' : 'form-control'
                    }
                >
                    <Input
                        type={this.state.isVisible ? 'text' : 'password'}
                        name={this.props.name}
                        autoComplete={this.props.autoComplete}
                        value={this.props.value}
                        onChange={this.props.handleChange}
                        required={this.props.required}
                    />
                    <InputGroupAddon
                        addonType="append"
                        onClick={() => this.toggleVisibility()}
                    >
                        <span className="input-group-text">
                            {this.state.isVisible ? (
                                <img src={eyeSlash} className="svg-inline" />
                            ) : (
                                <img src={eye} className="svg-inline" />
                            )}
                        </span>
                    </InputGroupAddon>
                </InputGroup>
                <FormFeedback>
                    {errorMgs
                        ? errorMgs.map((message, k) => (
                              <li key={k}>{message}</li>
                          ))
                        : ''}
                </FormFeedback>
            </FormGroup>
        );
    }
}

InputPassword.propTypes = {
    errors: InputErrorType,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    autoComplete: PropTypes.string,
    required: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    validationErrors: PropTypes.object, //eslint-disable-line
};

InputPassword.defaultProps = {
    errors: undefined,
    label: null,
    autoComplete: 'current-password',
    required: false,
    value: undefined,
    validationErrors: undefined,
};

export default withRequired(InputPassword);
