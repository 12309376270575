import React, { Component } from 'react';
import { FormGroup, Label, Input, InputGroup, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { DateType, InputErrorType } from 'types/CommonTypes';
import { formatIsoDate } from 'utils/dateFormatter';
import withRequired from './withRequired';

class InputDate extends Component {
    render() {
        let errorMgs;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === this.props.name) {
                    errorMgs = error.messages;
                }
            });
        }

        return (
            <FormGroup className={errorMgs ? 'is-invalid' : ''}>
                {this.props.label ? (
                    <Label for={this.props.name}>
                        {this.props.required ? <sup>*</sup> : ''}{' '}
                        {this.props.label}
                    </Label>
                ) : (
                    ''
                )}
                <InputGroup
                    className={
                        errorMgs ? 'form-control is-invalid' : 'form-control'
                    }
                >
                    <Input
                        type="date"
                        name={this.props.name}
                        value={this.props.value}
                        min={this.props.min}
                        max={this.props.max}
                        onChange={this.props.handleChange}
                        onBlur={this.props.onBlur}
                        required={this.props.required}
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                        placeholder={formatIsoDate(new Date())}
                    />
                </InputGroup>
                <FormFeedback>
                    {errorMgs
                        ? errorMgs.map((message, k) => (
                              <li key={k}>{message}</li>
                          ))
                        : ''}
                </FormFeedback>
            </FormGroup>
        );
    }
}

InputDate.propTypes = {
    errors: InputErrorType,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    value: DateType,
    min: DateType,
    max: DateType,
    validationErrors: PropTypes.object, //eslint-disable-line
    onBlur: PropTypes.func,
};

InputDate.defaultProps = {
    errors: undefined,
    label: null,
    required: false,
    value: undefined,
    min: undefined,
    max: undefined,
    onBlur: undefined,
};

export default withRequired(InputDate);
