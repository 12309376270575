import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Button } from 'reactstrap';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';

class InterventionPromptComponent extends React.Component {
    static propTypes = {
        number: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        onReveal: PropTypes.func.isRequired,
        actionLabel: PropTypes.string.isRequired,
        promptContent: PropTypes.string.isRequired,
        animationClass: PropTypes.string.isRequired,
    };

    render() {
        const classNames = [
            'MotivationIntervention',
            `MotivationIntervention--${this.props.type}`,
            'MotivationIntervention--prompt',
            this.props.animationClass,
        ];

        return (
            <section className={classNames.join(' ')}>
                <div className="number">
                    {this.props.number.toLocaleString('pl-PL', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })}
                </div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.promptContent,
                    }}
                    className="content"
                />
                <div className="action">
                    <Button color="white" outline onClick={this.props.onReveal}>
                        {this.props.actionLabel}
                        <span className="SVGInline">
                            <ArrowRight className="ml-2" />
                        </span>
                    </Button>
                </div>
            </section>
        );
    }
}

export default withLocale(InterventionPromptComponent);
