import React from 'react';

// import PropTypes from 'prop-types';
import DietSettingsConnectorApollo from './Connector/Apollo/Fetch/DietSettingsConnectorApollo';
import DietSettingsValidateDataConnectorApollo from './Connector/Apollo/Validate/DietSettingsValidateDataConnectorApollo';
import DietSettingsGenerateDietConnectorApollo from './Connector/Apollo/GenerateDiet/DietSettingsGenerateDietConnectorApollo';
import DietSettingsIndexContainer from './DietSettingsIndexContainer';

class DietSettingsIndexPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <DietSettingsConnectorApollo>
                {({ ...dietSettingsData }) => (
                    <DietSettingsValidateDataConnectorApollo>
                        {({ validateData }) => (
                            <DietSettingsGenerateDietConnectorApollo>
                                {({ generateDiet }) => (
                                    <DietSettingsIndexContainer
                                        {...dietSettingsData}
                                        {...{ validateData }}
                                        {...{ generateDiet }}
                                    />
                                )}
                            </DietSettingsGenerateDietConnectorApollo>
                        )}
                    </DietSettingsValidateDataConnectorApollo>
                )}
            </DietSettingsConnectorApollo>
        );
    }
}

export default DietSettingsIndexPage;
