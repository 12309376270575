import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { PATH_DAY_PLAN_INDEX } from 'DayPlanNew/DayPlanPaths';
import { GOOGLE_PLAY_URL, APP_STORE_URL } from 'consts';
import appStore from 'assets/images/appstore.png';
import googlePlay from 'assets/images/googleplay.png';

export class ShoppingCartThankYouStartUsingComponent extends React.Component {
    render() {
        const { userEmail, isUserActive, isDietInPayment } = this.props;
        return (
            <React.Fragment>
                {isDietInPayment ? (
                    <section>
                        <Container>
                            <Row>
                                <Col md="6" data-test="thank-you/diet">
                                    <h2 data-test="thank-you/has-diet">
                                        {this.props.t(
                                            'thank-you/go-to-diet-plan'
                                        )}
                                    </h2>
                                    <p>
                                        {this.props.t(
                                            'thank-you/go-to-diet-plan/content1'
                                        )}
                                        <br />
                                        {this.props.t(
                                            'thank-you/go-to-diet-plan/content2'
                                        )}
                                    </p>
                                    <Button
                                        color="primary"
                                        tag={Link}
                                        to={PATH_DAY_PLAN_INDEX}
                                    >
                                        {this.props.t(
                                            'thank-you/go-to-diet-plan'
                                        )}
                                    </Button>
                                </Col>
                                <Col md="6">
                                    <h2>
                                        {this.props.t(
                                            'thank-you/start-training'
                                        )}
                                    </h2>
                                    <p>
                                        {this.props.t(
                                            'thank-you/start-training/content'
                                        )}
                                    </p>
                                    <a
                                        href={GOOGLE_PLAY_URL}
                                        className="social-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            srcSet={`${googlePlay} 100w`}
                                            sizes="50px"
                                            alt="Google Play"
                                        />
                                    </a>
                                    <a
                                        href={APP_STORE_URL}
                                        className="social-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            srcSet={`${appStore} 100w`}
                                            sizes="50px"
                                            alt="App Store"
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                ) : (
                    ''
                )}

                {!isUserActive ? (
                    <section>
                        <Container>
                            <Alert
                                color="danger"
                                className="mt-5"
                                data-test="thank-you/alert"
                            >
                                {this.props.t('thank-you/warning', {
                                    email: userEmail,
                                })}
                            </Alert>
                        </Container>
                    </section>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    }
}

ShoppingCartThankYouStartUsingComponent.propTypes = {
    t: PropTypes.func.isRequired,
    userEmail: PropTypes.string.isRequired,
    isUserActive: PropTypes.bool.isRequired,
    isDietInPayment: PropTypes.bool.isRequired,
};

export default withLocale(ShoppingCartThankYouStartUsingComponent);
