import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import Header from 'Layout/Header';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import { Row, Col, Container, Button } from 'reactstrap';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/chevron-left.svg';
import InterventionItem from './InterventionItem';

import { PATH_INTERVENTIONS_INTRO } from './paths';

function splitArrayIntoChunks(arr, chunkLen) {
    const chunkList = [];
    const chunkCount = Math.ceil(arr.length / chunkLen);
    for (let i = 0; i < chunkCount; i += 1) {
        chunkList.push(arr.splice(0, chunkLen).reverse());
    }
    return chunkList;
}

const PER_PAGE = 7;

class InterventionsIndex extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        interventionsList: PropTypes.arrayOf(
            PropTypes.shape({
                __typename: PropTypes.string,
                date: PropTypes.string,
                type: PropTypes.string,
                message: PropTypes.string, // if "UserInterventionHidden"
                revealed: PropTypes.bool, // if "UserInterventionVisible"
            })
        ).isRequired,
    };

    state = {
        currentPage: 1,
    };

    interventionsArray = Object.assign([], this.props.interventionsList);

    interventionsChunks = splitArrayIntoChunks(
        this.interventionsArray.reverse(),
        PER_PAGE
    );

    hasMorePrevTasks() {
        return !(
            this.props.interventionsList.length / PER_PAGE <=
            this.state.currentPage
        );
    }

    hasMoreNextTasks() {
        return this.state.currentPage > 1;
    }

    handleNavigationClick = direction => {
        if (direction === 'prev') {
            this.setState(prevState => ({
                currentPage: prevState.currentPage + 1,
            }));

            try {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            } catch (e) {
                window.scrollTo(0, 0);
            }
        } else {
            try {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            } catch (e) {
                window.scrollTo(0, 0);
            }

            this.setState(prevState => ({
                currentPage: prevState.currentPage - 1,
            }));
        }
    };

    renderNavigation() {
        let prevButton;
        // if no prev interventions are available - show link to diet intro
        if (this.hasMorePrevTasks()) {
            prevButton = (
                <Button
                    outline
                    color="primary"
                    onClick={() => this.handleNavigationClick('prev')}
                    className="w-100"
                >
                    <span className="SVGInline">
                        <ArrowLeft className="mr-2" />
                    </span>
                    {this.props.t('interventions/prev')}
                </Button>
            );
        } else {
            prevButton = (
                <Button
                    outline
                    color="primary"
                    className="w-100"
                    tag={Link}
                    to={PATH_INTERVENTIONS_INTRO}
                >
                    <span className="SVGInline">
                        <ArrowLeft className="mr-2" />
                    </span>
                    {this.props.t('interventions/back-to-intro')}
                </Button>
            );
        }
        return (
            <Row className="mt-5">
                <Col xs="6" className="pr-2">
                    {prevButton}
                </Col>
                <Col xs="6" className="pl-2">
                    <Button
                        color="primary"
                        disabled={!this.hasMoreNextTasks()}
                        onClick={() => this.handleNavigationClick('next')}
                        className="w-100 no-arrow"
                    >
                        {this.props.t('interventions/next')}
                        <span className="SVGInline">
                            <ArrowRight className="ml-2" />
                        </span>
                    </Button>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Layout page="interventions">
                <Header>{this.props.t('interventions/title')}</Header>
                <section className="pt-0">
                    <Container>
                        <Row>
                            <Col
                                xs="12"
                                md={{ size: 8, offset: 2 }}
                                lg={{ size: 6, offset: 3 }}
                            >
                                <Row>
                                    <Col xs="12" md={{ size: 12, offset: 0 }}>
                                        <ul className="interventions-list">
                                            {this.interventionsChunks[
                                                this.state.currentPage - 1
                                            ].map(intervention => (
                                                <InterventionItem
                                                    key={intervention.date}
                                                    date={intervention.date}
                                                    type={intervention.type}
                                                    concluded={
                                                        intervention.concluded
                                                    }
                                                    typename={
                                                        intervention.__typename
                                                    }
                                                />
                                            ))}
                                        </ul>
                                        {this.renderNavigation()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(InterventionsIndex);
