import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'Layout/Layout';
import { Link } from 'react-router-dom';
import { withLocale } from '@dietlabs/components';
import { withRouter } from 'react-router';
import { Container, Button } from 'reactstrap';
import { ReactComponent as ArrowLeft } from 'assets/icons/chevron-left.svg';
import { PATH_INTERVENTIONS } from './paths';

class InterventionPlaceholderContainer extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                date: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    };

    render() {
        const date = this.props.match.params.date;

        return (
            <Layout page="interventions">
                <header>
                    <Container>
                        <h1 className="text-center">{date}</h1>
                    </Container>
                </header>
                <section className="pt-0">
                    <Container>
                        <section className="MotivationIntervention--prompt placeholder" />
                    </Container>
                    <div className="text-center mt-2">
                        <Button color="link" tag={Link} to={PATH_INTERVENTIONS}>
                            <span className="SVGInline">
                                <ArrowLeft className="mr-2" />
                            </span>
                            {this.props.t('interventions/back-to-motivation')}
                        </Button>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default withRouter(withLocale(InterventionPlaceholderContainer));
