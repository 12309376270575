import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import GoalChangeMutation from './GoalChangeMutation';

class GoalChangeConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createGoalChange = mutate => async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={GoalChangeMutation}>
                {mutate =>
                    children({
                        goalChange: this.createGoalChange(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default GoalChangeConnectorApollo;
