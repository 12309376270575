const TOLERATION = 0.1;

/**
 * @param {string} friscoProductMeasure
 * @param {array} measurements
 * @param {string} measurements[].unit
 */
const transformMeasurementUnit = (friscoProductMeasure, measurements) =>
    measurements.find(elem => {
        if (elem.unit === 'g' && friscoProductMeasure === 'Kilogram') {
            return true;
        }

        if (elem.unit === 'ml' && friscoProductMeasure === 'Litre') {
            return true;
        }

        return false;
    });

/**
 * @param {float} friscoAmount
 * @param {float} productAmount
 */
const transformMeasurementValue = (friscoAmount, productAmount) => {
    const amount = productAmount / friscoAmount;

    let desiredAmount = Math.ceil(amount);

    const tolerantionAmount = productAmount * TOLERATION;

    if (
        productAmount > friscoAmount &&
        productAmount - friscoAmount < tolerantionAmount
    ) {
        desiredAmount -= 1;
    }

    return desiredAmount;
};

export { transformMeasurementUnit, transformMeasurementValue };
