import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import DayPlanCopyMutation from './DayPlanCopyMutation';
import DayPlanQuery from '../Fetch/DayPlanQuery';

class ShoppingListMarkProductAsBoughtConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
    };

    createCopyDayPlan = mutate => async () => {
        const variables = {
            sourceDate: formatIsoDate(this.props.date),
            targetDate: formatIsoDate(addDays(this.props.date, 1)),
        };

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children, date } = this.props;

        return (
            <Mutation
                mutation={DayPlanCopyMutation}
                refetchQueries={() => [
                    {
                        query: DayPlanQuery,
                        variables: {
                            date: formatIsoDate(addDays(date, 1)),
                            canBeCopiedToDate: formatIsoDate(addDays(date, 2)),
                        },
                    },
                ]}
            >
                {mutate =>
                    children({
                        copyDayPlan: this.createCopyDayPlan(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default ShoppingListMarkProductAsBoughtConnectorApollo;
