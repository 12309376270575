import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import { ApiDateType } from 'types/CommonTypes';
import ValidationErrors from 'view/Validation/ValidationErrors';
import MeasurementGroupDeleteMutation from './MeasurementGroupDeleteMutation';
import MeasurementFetchByDateQuery from './MeasurementFetchByDateQuery';

class MeasurementDeleteByDateConnectorApollo extends React.Component {
    static propTypes = {
        date: ApiDateType,
        children: PropTypes.func.isRequired,
    };

    static defaultProps = {
        date: undefined,
    };

    deleteMeasurements = deleteMeasurementsApi => async date => {
        const response = await deleteMeasurementsApi({
            variables: {
                request: {
                    date,
                },
            },
        });

        if (
            response.data.me.measurementGroupDelete.__typename ===
            'ValidationException'
        ) {
            throw new ValidationErrors(
                response.data.me.measurementGroupDelete.messages,
                response.data.me.measurementGroupDelete.details
            );
        }
    };

    render() {
        const { date, children } = this.props;
        let result = { date };

        return (
            <Mutation
                mutation={MeasurementGroupDeleteMutation}
                refetchQueries={() => [
                    {
                        query: MeasurementFetchByDateQuery,
                        variables: { date },
                    },
                ]}
            >
                {(deleteMeasurements, deleteResult) => {
                    result = {
                        ...result,
                        deleteMeasurements: this.deleteMeasurements(
                            deleteMeasurements
                        ),
                        deleting: deleteResult.loading,
                    };

                    return children(result);
                }}
            </Mutation>
        );
    }
}

export default MeasurementDeleteByDateConnectorApollo;
