import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { CustomInput, Label } from 'reactstrap';
import InterventionTaskTextComponent from './InterventionTaskTextComponent';
import InterventionTaskStringComponent from './InterventionTaskStringComponent';

class InterventionTaskScaleComponent extends React.Component {
    static propTypes = {
        // t: PropTypes.func.isRequired,
        task: PropTypes.shape({
            id: PropTypes.string,
            answers: PropTypes.arrayOf(PropTypes.string),
            label: PropTypes.string,
            min: PropTypes.number,
            max: PropTypes.number,
            variant: PropTypes.string,
            subtasks: PropTypes.arrayOf(PropTypes.shape()),
        }).isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        editMode: PropTypes.bool.isRequired,
    };

    state = {
        messages:
            this.props.task.answers.length > 0 ? this.props.task.answers : [5],
    };

    handleInputChange = event => {
        const name = parseInt(event.target.name.replace('input', ''), 10);
        const value = event.target.value;

        this.setState(prevState => {
            const newMessages = prevState.messages.map((item, i) => {
                if (i === name) {
                    return value;
                }
                return item;
            });

            this.props.setTaskAnswers(
                parseInt(this.props.task.id, 10),
                newMessages
            );

            return { messages: newMessages };
        });
    };

    handleAddInput = () => {
        this.setState(prevState => ({
            messages: [...prevState.messages, ''],
        }));
    };

    renderForm = () => {
        if (this.state.messages.length > 0) {
            return this.state.messages.map((message, idx) => (
                <CustomInput
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    type="range"
                    name={`input${idx}`}
                    id={`scale${this.props.task.id}`}
                    min={this.props.task.min}
                    max={this.props.task.max}
                    step="1"
                    style={{
                        '--value': `${(message / this.props.task.max) * 100}%`,
                    }}
                    value={message}
                    onChange={this.handleInputChange}
                    disabled={!this.props.editMode}
                />
            ));
        }

        return null;
    };

    renderSupportingTask = () => {
        if (this.props.task.variant === 'with-supporting-questions') {
            let task;
            const answer = parseInt(this.state.messages[0], 10);
            const { min, max } = this.props.task;

            if (answer === min) {
                task = this.props.task.subtasks.find(
                    t => t.variant === 'if-min'
                );
            } else if (answer === max) {
                task = this.props.task.subtasks.find(
                    t => t.variant === 'if-max'
                );
            } else {
                task = this.props.task.subtasks.find(
                    t => t.variant === 'if-not-min-max'
                );
            }

            switch (task.type) {
                case 'text':
                    return (
                        <InterventionTaskTextComponent
                            key={task.id}
                            task={task}
                            setTaskAnswers={this.props.setTaskAnswers}
                            editMode={this.props.editMode}
                        />
                    );
                case 'string':
                    return (
                        <InterventionTaskStringComponent
                            key={task.id}
                            task={task}
                            setTaskAnswers={this.props.setTaskAnswers}
                            editMode={this.props.editMode}
                        />
                    );
                default:
                    return null;
            }
        }

        return null;
    };

    render() {
        return (
            <div className="mt-3">
                <Label>{this.props.task.label}</Label>
                {this.renderForm()}
                {this.renderSupportingTask()}
            </div>
        );
    }
}

export default withLocale(InterventionTaskScaleComponent);
