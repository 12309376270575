import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import PsychometricResultQuery from './PsychometricResultQuery';

class PsychometricResultConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        email: PropTypes.string,
        token: PropTypes.string,
    };

    static defaultProps = {
        email: '',
        token: '',
    };

    render() {
        const { children, email, token } = this.props;

        return (
            <Query
                query={PsychometricResultQuery}
                variables={{
                    email,
                    token,
                }}
                fetchPolicy="no-cache"
            >
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.result = deepClone(data.psychotest.result);
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default PsychometricResultConnectorApollo;
