/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import withMobileAppMode from 'Layout/withMobileAppMode';

import iconChange from 'assets/images/dayplan/icon-change.svg';
import iconDoneOn from 'assets/images/dayplan/icon-done-on.svg';
import iconDoneOff from 'assets/images/dayplan/icon-done-off.svg';
import breakfast from 'assets/images/dayplan/plug/breakfast1-1.jpg';
import morningSnack from 'assets/images/dayplan/plug/morning-snack1-1.jpg';
import dinner from 'assets/images/dayplan/plug/dinner1-1.jpg';
import eveningSnack from 'assets/images/dayplan/plug/evening-snack1-1.jpg';
import supper from 'assets/images/dayplan/plug/supper1-1.jpg';

import playBtn from 'assets/images/dayplan/play-button.svg';

import { callNativeAppRouteChanged } from 'components/Helpers/callNativeAppRouteChanged';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';

import { isToday, isTomorrow, isYesterday } from 'utils/date';

import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import { withShouldScroll } from '@dietlabs/components/src/Layout/ScrollToTop';

const steps = [5, 15, 30, 45, 60];

class DayPlanMealDetails extends Component {
    static propTypes = {
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        originalId: PropTypes.number.isRequired,
        loadDayPlanMealReplacements: PropTypes.func.isRequired,
        toggleModalWithReplacements: PropTypes.func.isRequired,
        toggleModal: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        mealId: PropTypes.number.isRequired,
        setMealEaten: PropTypes.func.isRequired,
        setId: PropTypes.number.isRequired,
        mealsEaten: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        preparationVideoUrl: PropTypes.string,
        preparationImageUrl: PropTypes.string,
        publicName: PropTypes.string.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        handleShouldScroll: PropTypes.func.isRequired,
    };

    static defaultProps = {
        preparationVideoUrl: undefined,
        preparationImageUrl: undefined,
    };

    videoRef = React.createRef();

    state = {
        preloadInside: false,
        preloadInsideEaten: false,
        visiblePoster: true,
    };

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `<${step}`;
            }
        }
        return `>${steps[steps.length - 1]}`;
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';

        this.props.handleShouldScroll(false);

        // hack to hide large title on iOS
        setTimeout(() => {
            window.scrollTo(0, 100);
        }, 500);

        let title;

        if (isToday(this.props.date)) {
            title = 'Dzisiaj';
        } else if (isTomorrow(this.props.date)) {
            title = 'Jutro';
        } else if (isYesterday(this.props.date)) {
            title = 'Wczoraj';
        } else {
            title = this.formatDate(this.props.date);
        }

        if (window.location.href.includes('szczegoly-posilku')) {
            callNativeAppRemoveLastRoute('diet');
            // routeChanged post msg
            callNativeAppRouteChanged(window.location.href, 'diet', title);
        } else {
            // disable browser back button
            window.history.pushState(
                null,
                null,
                `${window.location.pathname}?szczegoly-posilku`
            );

            // routeChanged post msg
            callNativeAppRouteChanged(window.location.href, 'diet', title);
        }

        window.onpopstate = () => {
            this.props.toggleModal();
        };

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Meal_details',
        });
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        window.onpopstate = () => {};
    }

    formatDate = date => {
        const { t } = this.props;

        const weekday = date.getDay();
        const day = date.getDate();
        const day0 = day < 10 ? `0${day}` : day;
        const month = date.getMonth() + 1;
        const month0 = month < 10 ? `0${month}` : month;
        const dayName = t(`meal/weekday${weekday}-short`);

        return `${dayName} ${day0}.${month0}`;
    };

    handleMealReplace() {
        this.setState({ preloadInside: true });
        this.loadMealReplacements();

        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-change_meal',
            location: 'Meal_details',
            meal_id: this.props.mealId,
            meal_category: this.props.name,
        });
    }

    async handleSetMealEaten() {
        this.setState({ preloadInsideEaten: true });
        const isMealEaten = this.props.mealsEaten.find(
            meal => meal.mealId === this.props.mealId
        );

        try {
            const response = await this.props.setMealEaten({
                setId: this.props.setId,
                mealId: this.props.mealId,
                eaten: !isMealEaten,
            });

            if (response.data.me.dietSetMealEaten.code === 200) {
                this.setState({ preloadInsideEaten: false });
            }
        } catch (error) {
            throw new Error(`Failed to set meal eaten: ${error}`);
        }

        this.setState({ preloadInsideEaten: false });
    }

    loadMealReplacements = async () => {
        const request = {
            originalMealId: this.props.originalId,
            limit: 10,
        };
        const response = await this.props.loadDayPlanMealReplacements(request);

        const replacements = response.data.me.diet.set.meal.replacements;
        const originalId = response.data.me.diet.set.meal.original.id;

        this.props.toggleModalWithReplacements(replacements, originalId);
        this.setState({ preloadInside: false });
    };

    playVideo = async () => {
        this.setState({ visiblePoster: false });
        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-watch_video',
            location: 'Meal_details',
            meal_id: this.props.mealId,
            meal_category: this.props.name,
        });

        try {
            await this.videoRef.current.play();
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(err);
        }
    };

    renderMealVideo = () => {
        if (!this.props.preparationVideoUrl) {
            return this.renderMealImage();
        }

        return (
            <div className="embed-responsive embed-responsive-1by1">
                <video
                    ref={this.videoRef}
                    src={this.props.preparationVideoUrl}
                    controls
                    className="embed-responsive-item"
                    playsInline
                    controlsList="nodownload"
                    disablePictureInPicture
                />
                {this.state.visiblePoster ? (
                    <div
                        style={{
                            backgroundImage: `url(${this.props.preparationImageUrl
                                .replace('(', '%28')
                                .replace(')', '%29')})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                        onClick={this.playVideo}
                    >
                        <img src={playBtn} alt="" />
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    renderMealImage = () => {
        let mealImg;

        if (this.props.preparationImageUrl) {
            mealImg = this.props.preparationImageUrl
                .replace('(', '%28')
                .replace(')', '%29');
        } else {
            switch (this.props.name) {
                case 'Śniadanie':
                    mealImg = breakfast;
                    break;
                case 'Przekąska poranna':
                    mealImg = morningSnack;
                    break;
                case 'Obiad':
                    mealImg = dinner;
                    break;
                case 'Lunch':
                    mealImg = dinner;
                    break;
                case 'Przekąska wieczorna':
                    mealImg = eveningSnack;
                    break;
                case 'Przekąska':
                    mealImg = eveningSnack;
                    break;
                case 'Kolacja':
                    mealImg = supper;
                    break;
                case 'Obiadokolacja':
                    mealImg = supper;
                    break;
                default:
                    mealImg = breakfast;
            }
        }

        return (
            <div className="embed-responsive embed-responsive-1by1">
                <div
                    style={{
                        backgroundImage: `url(${mealImg})`,
                    }}
                    className="embed-responsive-item"
                />
            </div>
        );
    };

    render() {
        const isMealEaten = this.props.mealsEaten.find(
            meal => meal.mealId === this.props.mealId
        );
        return (
            <section className="meal-details">
                <div className="meal-info mb-3">
                    <Container>
                        <Row>
                            <Col md={{ size: 10, offset: 1 }}>
                                <div className="white-box">
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            {this.renderMealVideo()}
                                        </Col>
                                        <Col md={{ size: 6, offset: 1 }}>
                                            {!this.props.mobileAppMode && (
                                                <h1 className="d-none d-md-block">
                                                    {this.props.publicName}
                                                </h1>
                                            )}
                                            <Alert color="summary">
                                                <Row>
                                                    <Col
                                                        xs="3"
                                                        className="border-right"
                                                    >
                                                        <h3 className="mb-0 primary">
                                                            {this.props.kcal}
                                                        </h3>
                                                        kcal
                                                    </Col>
                                                    <Col
                                                        xs="3"
                                                        className="border-right"
                                                    >
                                                        <h3 className="mb-0 primary">
                                                            {
                                                                this.props.macro
                                                                    .protein
                                                                    .grams
                                                            }
                                                            &nbsp;g
                                                        </h3>
                                                        {this.props.t(
                                                            'meal/proteins'
                                                        )}
                                                    </Col>
                                                    <Col
                                                        xs="3"
                                                        className="border-right"
                                                    >
                                                        <h3 className="mb-0 primary">
                                                            {
                                                                this.props.macro
                                                                    .fat.grams
                                                            }
                                                            &nbsp;g
                                                        </h3>
                                                        {this.props.t(
                                                            'meal/fat'
                                                        )}
                                                    </Col>
                                                    <Col xs="3">
                                                        <h3 className="mb-0 primary">
                                                            {
                                                                this.props.macro
                                                                    .carbohydrates
                                                                    .grams
                                                            }
                                                            &nbsp;g
                                                        </h3>
                                                        {this.props.t(
                                                            'meal/carbohydrates'
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Alert>
                                            <div className="action-buttons border-top pt-4 d-none d-md-block">
                                                <Row>
                                                    {!isMealEaten &&
                                                        !this.state
                                                            .preloadInsideEaten && (
                                                            <Col className="border-right">
                                                                <Button
                                                                    color="link"
                                                                    onClick={() =>
                                                                        this.handleMealReplace()
                                                                    }
                                                                    data-test="change-meal-button"
                                                                    className="p-0 w-100"
                                                                    style={{
                                                                        height:
                                                                            '32px',
                                                                    }}
                                                                >
                                                                    {this.state
                                                                        .preloadInside ? (
                                                                        <span
                                                                            className="spinner-border spinner-border-sm mr-2"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                iconChange
                                                                            }
                                                                            className="mr-1"
                                                                            alt=""
                                                                        />
                                                                    )}
                                                                    {this.props.t(
                                                                        'meal/exchange-meal-button'
                                                                    )}
                                                                </Button>
                                                            </Col>
                                                        )}

                                                    <Col>
                                                        <Button
                                                            color="link"
                                                            onClick={() =>
                                                                this.handleSetMealEaten()
                                                            }
                                                            data-test="change-meal-button"
                                                            className="p-0 w-100"
                                                            style={{
                                                                height: '32px',
                                                            }}
                                                        >
                                                            {this.state
                                                                .preloadInsideEaten ? (
                                                                <span
                                                                    className="spinner-border spinner-border-sm mr-2"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        isMealEaten
                                                                            ? iconDoneOn
                                                                            : iconDoneOff
                                                                    }
                                                                    className="mr-1"
                                                                    alt=""
                                                                />
                                                            )}

                                                            {this.props.t(
                                                                'meal/eaten-button'
                                                            )}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            {this.props.children()}
                        </Col>
                    </Row>
                </Container>

                <footer className="d-md-none">
                    <Container className="p-0 d-flex justify-content-center">
                        {!isMealEaten && !this.state.preloadInsideEaten && (
                            <div className="border-right px-2 px-md-5">
                                <Button
                                    color="link"
                                    onClick={() => this.handleMealReplace()}
                                    data-test="change-meal-button"
                                    className="p-0 w-100"
                                    style={{
                                        height: '32px',
                                        lineHeight: '32px',
                                    }}
                                >
                                    {this.state.preloadInside ? (
                                        <span
                                            className="spinner-border spinner-border-sm mr-2"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <img
                                            src={iconChange}
                                            className="mr-1"
                                            alt=""
                                        />
                                    )}
                                    {this.props.t('meal/exchange-meal-button')}
                                </Button>
                            </div>
                        )}

                        <div className="px-2 px-md-5">
                            <Button
                                color="link"
                                onClick={() => this.handleSetMealEaten()}
                                data-test="change-meal-button"
                                className="p-0 w-100"
                                style={{
                                    height: '32px',
                                    lineHeight: '32px',
                                }}
                            >
                                {this.state.preloadInsideEaten ? (
                                    <span
                                        className="spinner-border spinner-border-sm mr-2"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <img
                                        src={
                                            isMealEaten
                                                ? iconDoneOn
                                                : iconDoneOff
                                        }
                                        className="mr-1"
                                        alt=""
                                    />
                                )}

                                {this.props.t('meal/eaten-button')}
                            </Button>
                        </div>
                    </Container>
                </footer>
            </section>
        );
    }
}

export { DayPlanMealDetails };
export default withShouldScroll(
    withFirebase(withMobileAppMode(withLocale(DayPlanMealDetails)))
);
