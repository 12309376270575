import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import InterventionFetchConnectorApollo from './Connector/Apollo/Fetch/InterventionFetchConnectorApollo';
import InterventionRevealConnectorApollo from './Connector/Apollo/Reveal/InterventionRevealConnectorApollo';
import InterventionConcludeConnectorApollo from './Connector/Apollo/Conclude/InterventionConcludeConnectorApollo';
import InternevtionContainer from './InterventionContainer';

class InterventionPage extends React.Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                date: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    };

    render() {
        const date = this.props.match.params.date;
        const period = { start: date, end: date };
        return (
            <InterventionFetchConnectorApollo period={period}>
                {({ ...intervention }) => (
                    <InterventionRevealConnectorApollo period={period}>
                        {({ ...reveal }) => (
                            <InterventionConcludeConnectorApollo
                                period={period}
                            >
                                {({ ...conclude }) => (
                                    <InternevtionContainer
                                        {...intervention}
                                        {...reveal}
                                        {...conclude}
                                    />
                                )}
                            </InterventionConcludeConnectorApollo>
                        )}
                    </InterventionRevealConnectorApollo>
                )}
            </InterventionFetchConnectorApollo>
        );
    }
}

export default withRouter(InterventionPage);
