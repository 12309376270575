/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

import breakfast from 'assets/images/dayplan/breakfast.png';
import morningSnack from 'assets/images/dayplan/morning-snack.png';
import dinner from 'assets/images/dayplan/dinner.png';
import eveningSnack from 'assets/images/dayplan/evening-snack.png';
import supper from 'assets/images/dayplan/supper.png';

import iconKcal from 'assets/images/dayplan/icon-kcal.svg';
import iconForward from 'assets/images/dayplan/icon-forward.svg';
import iconChange from 'assets/images/dayplan/icon-change.svg';
import iconDoneOn from 'assets/images/dayplan/icon-done-on.svg';
import iconDoneOff from 'assets/images/dayplan/icon-done-off.svg';

const steps = [5, 15, 30, 45, 60];

class DayPlanMeal extends Component {
    static propTypes = {
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        name: PropTypes.string.isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        originalId: PropTypes.number.isRequired,
        loadDayPlanMealReplacements: PropTypes.func.isRequired,
        mealId: PropTypes.number.isRequired,
        toggleModal: PropTypes.func.isRequired,
        toggleModalWithReplacements: PropTypes.func.isRequired,
        setMealEaten: PropTypes.func.isRequired,
        setId: PropTypes.number.isRequired,
        mealsEaten: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        preparationImageUrl: PropTypes.string,
        publicName: PropTypes.string.isRequired,
    };

    static defaultProps = {
        preparationImageUrl: undefined,
    };

    state = {
        preloadInside: false,
        preloadInsideEaten: false,
    };

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `<${step}`;
            }
        }
        return `>${steps[steps.length - 1]}`;
    }

    handleMealReplace() {
        this.loadMealReplacements();
    }

    handleMealClick() {
        this.props.toggleModal(this.props.mealId);
    }

    async handleSetMealEaten() {
        this.setState({ preloadInsideEaten: true });
        const isMealEaten = this.props.mealsEaten.find(
            meal => meal.mealId === this.props.mealId
        );

        try {
            const response = await this.props.setMealEaten({
                setId: this.props.setId,
                mealId: this.props.mealId,
                eaten: !isMealEaten,
            });

            if (response.data.me.dietSetMealEaten.code === 200) {
                this.setState({ preloadInsideEaten: false });
            }
        } catch (error) {
            throw new Error(`Failed to set meal eaten: ${error}`);
        }

        this.setState({ preloadInsideEaten: false });
    }

    loadMealReplacements = async () => {
        if (
            !window.location.href.includes('wymien-jadlospis') &&
            !window.location.href.includes('wymien-posilek')
        ) {
            this.setState({ preloadInside: true });
            const request = {
                originalMealId: this.props.originalId,
                limit: 10,
            };
            const response = await this.props.loadDayPlanMealReplacements(
                request
            );

            const replacements = response.data.me.diet.set.meal.replacements;
            const originalId = response.data.me.diet.set.meal.original.id;

            if (
                !window.location.href.includes('wymien-jadlospis') &&
                !window.location.href.includes('wymien-posilek')
            ) {
                this.props.toggleModalWithReplacements(
                    replacements,
                    originalId
                );
            }
            this.setState({ preloadInside: false });
        }
    };

    renderMealImage = () => {
        let mealImg;

        if (this.props.preparationImageUrl) {
            mealImg = this.props.preparationImageUrl
                .replace('(', '%28')
                .replace(')', '%29');
        } else {
            switch (this.props.name) {
                case 'Śniadanie':
                    mealImg = breakfast;
                    break;
                case 'Przekąska poranna':
                    mealImg = morningSnack;
                    break;
                case 'Obiad':
                    mealImg = dinner;
                    break;
                case 'Lunch':
                    mealImg = dinner;
                    break;
                case 'Przekąska wieczorna':
                    mealImg = eveningSnack;
                    break;
                case 'Przekąska':
                    mealImg = eveningSnack;
                    break;
                case 'Kolacja':
                    mealImg = supper;
                    break;
                case 'Obiadokolacja':
                    mealImg = supper;
                    break;
                default:
                    mealImg = breakfast;
            }
        }

        return (
            <div
                className="embed-responsive embed-responsive-16by9"
                role="button"
                onClick={() => this.handleMealClick()}
            >
                <div
                    style={{
                        backgroundImage: `url(${mealImg})`,
                    }}
                    className="embed-responsive-item"
                />
            </div>
        );
    };

    render() {
        const isMealEaten = this.props.mealsEaten.find(
            meal => meal.mealId === this.props.mealId
        );

        return (
            <section className="meal">
                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h2 className="text-center">{this.props.name}</h2>
                            <div className="white-box">
                                <Row>
                                    <Col md="6">{this.renderMealImage()}</Col>
                                    <Col md="6">
                                        <div
                                            role="button"
                                            className="meal-title"
                                            onClick={() =>
                                                this.handleMealClick()
                                            }
                                        >
                                            <h3>{this.props.publicName}</h3>
                                            <img src={iconForward} alt="" />
                                        </div>

                                        <p className="BTW">
                                            <img
                                                src={iconKcal}
                                                className="mr-1"
                                                alt="kcal"
                                            />
                                            <span>
                                                {this.props.kcal}&nbsp;kcal
                                            </span>
                                            <strong> • </strong>
                                            <span>
                                                {this.props.t(
                                                    'meal/proteins-short'
                                                )}
                                                {': '}
                                                {this.props.macro.protein.grams}
                                                &nbsp;g &nbsp;
                                                {this.props.t('meal/fat-short')}
                                                {': '}
                                                {this.props.macro.fat.grams}
                                                &nbsp;g &nbsp;
                                                {this.props.t(
                                                    'meal/carbohydrates-short'
                                                )}
                                                {': '}
                                                {
                                                    this.props.macro
                                                        .carbohydrates.grams
                                                }
                                                &nbsp;g
                                            </span>
                                        </p>
                                        <hr />

                                        <div
                                            className={
                                                isMealEaten ||
                                                this.state.preloadInsideEaten
                                                    ? 'd-flex justify-content-center'
                                                    : 'd-flex'
                                            }
                                        >
                                            {!isMealEaten &&
                                                !this.state
                                                    .preloadInsideEaten && (
                                                    <div className="border-right pr-3">
                                                        <Button
                                                            color="link"
                                                            onClick={() =>
                                                                this.handleMealReplace()
                                                            }
                                                            data-test="change-meal-button"
                                                            className="p-0 w-100"
                                                            style={{
                                                                height: '32px',
                                                                lineHeight:
                                                                    '32px',
                                                            }}
                                                        >
                                                            {this.state
                                                                .preloadInside ? (
                                                                <span
                                                                    className="spinner-border spinner-border-sm mr-2"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        iconChange
                                                                    }
                                                                    className="mr-1"
                                                                    alt=""
                                                                />
                                                            )}
                                                            {this.props.t(
                                                                'meal/exchange-meal-button'
                                                            )}
                                                        </Button>
                                                    </div>
                                                )}

                                            <div className="pl-3">
                                                <Button
                                                    color="link"
                                                    onClick={() =>
                                                        this.handleSetMealEaten()
                                                    }
                                                    data-test="change-meal-button"
                                                    className="p-0 w-100"
                                                    style={{
                                                        height: '32px',
                                                        lineHeight: '32px',
                                                    }}
                                                >
                                                    {this.state
                                                        .preloadInsideEaten ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm mr-2"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={
                                                                isMealEaten
                                                                    ? iconDoneOn
                                                                    : iconDoneOff
                                                            }
                                                            className="mr-1"
                                                            alt=""
                                                        />
                                                    )}

                                                    {this.props.t(
                                                        'meal/eaten-button'
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export { DayPlanMeal };
export default withLocale(DayPlanMeal);
