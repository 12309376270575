import React, { Component } from 'react';
import { node, oneOfType, arrayOf, bool, func } from 'prop-types';

import { withLocale } from '@dietlabs/components';
import { reportError, reportWarning } from '@dietlabs/utils';
import ErrorNetwork, {
    defaultOnActionClick as defaultNetworkErrorOnActionClick,
} from 'components/Error/ErrorNetwork';
import ErrorGeneric from 'components/Error/ErrorGeneric';
import { Provider as ErrorBoundaryProvider } from './ErrorBoundaryContext';

function fallbackTranslatorThatShouldNotBeUsed(text) {
    reportWarning(
        new Error(
            'Used translator in ErrorGeneric rendered by ErrorBoundary, despite all messages were passed'
        )
    );
    return text;
}

// ℹ Translations in this file do not use identifiers, but real
//   messages, because we must assume that translation may be broken

class ErrorBoundary extends Component {
    static propTypes = {
        children: oneOfType([node, arrayOf(node)]).isRequired,
        topLevel: bool,
        noRouter: bool,
        t: func.isRequired,
    };

    static defaultProps = {
        topLevel: false,
        noRouter: false,
    };

    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error) {
        this.handleError(error);
    }

    handleError(error) {
        this.setState({ error });
        reportError(error);
    }

    handleNetworkErrorActionClick() {
        this.handleGenericErrorActionClick();
        defaultNetworkErrorOnActionClick();
    }

    handleGenericErrorActionClick() {
        this.setState({ error: null });
    }

    render() {
        const { t } = this.props;
        const { error } = this.state;

        if (!error) {
            return (
                <ErrorBoundaryProvider value={err => this.handleError(err)}>
                    {this.props.children}
                </ErrorBoundaryProvider>
            );
        }

        error.handled = true;

        if (error.isNetworkError || error.networkError) {
            return (
                <ErrorNetwork
                    t={t}
                    topLevel={this.props.topLevel}
                    onActionClick={() => this.handleNetworkErrorActionClick()}
                    noRouter={this.props.noRouter}
                />
            );
        }

        return (
            <ErrorGeneric
                t={fallbackTranslatorThatShouldNotBeUsed}
                topLevel={this.props.topLevel}
                onActionClick={() => this.handleGenericErrorActionClick()}
                message={t('Something went wrong')}
                additionalMessage={t(
                    'Incident has been reported and we will work to solve it'
                )}
                actionLabel={t('Try again')}
                noRouter={this.props.noRouter}
            />
        );
    }
}

export { ErrorBoundary };
export default withLocale(ErrorBoundary);
