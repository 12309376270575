import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withSaleSource from 'view/SaleSources/withSaleSources';
import ShoppingCartProductAddComponent from 'components/ShoppingCart/Product/Add/ShoppingCartProductAddComponent';
import { promoCodeGetAction } from 'view/PromoCode/Get/PromoCodeGetAction';
import { FlashMessageError } from '@dietlabs/components/src/FlashMessage/FlashMessage';
import { withLocale } from '@dietlabs/components';
import { shoppingCartIndexGet } from 'view/ShoppingCart/Index/Load/ShoppingCartIndexGet';
import { FLASH_MESSAGES } from 'consts';
import withFlashMessage from '@dietlabs/components/src/FlashMessage/withFlashMessage';
import { shoppingCartProductAddAction } from './ShoppingCartProductAddAction';

const mapStateToProps = (state, ownProps) => ({
    productId: parseInt(ownProps.match.params.productId, 10),
    source: ownProps.match.params.source,
    saleProducts: state.saleProducts,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    ...ownProps,
    addProductToCart: (productId, source) => {
        if (source !== undefined) {
            ownProps.setSource(source);
        }
        dispatch(
            shoppingCartProductAddAction(
                productId,
                1,
                ownProps.getFirstSource(),
                ownProps.getLastSource()
            )
        );
    },
    getPromoCode: () => dispatch(promoCodeGetAction()),
    loadProducts: promoCode => dispatch(shoppingCartIndexGet(promoCode)),
    notifyThatProductIsInvalid: () =>
        ownProps.addMessage(
            new FlashMessageError(
                ownProps.t('cart/add/invalid-product-id'),
                FLASH_MESSAGES.CART.INVALID_PRODUCT_ID
            )
        ),
});

const mergeProps = (stateProps, dispatchProps) => ({
    productId: stateProps.productId,
    source: stateProps.source,
    addProductToCart: async (productId, source) => {
        let saleProducts = stateProps.saleProducts;
        if (!Object.keys(saleProducts).length) {
            const promoCode = dispatchProps.getPromoCode();
            const loadProductsResult = await dispatchProps.loadProducts(
                promoCode
            );
            saleProducts = loadProductsResult.sale.productVariants;
        }
        if (
            Object.values(saleProducts).filter(
                saleProduct => saleProduct.id === productId
            ).length
        ) {
            dispatchProps.addProductToCart(productId, source);
        } else {
            dispatchProps.notifyThatProductIsInvalid();
        }
    },
});

const ShoppingCartProductAddContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ShoppingCartProductAddComponent);

export { ShoppingCartProductAddContainer };
export default withSaleSource(
    withFlashMessage(withRouter(withLocale(ShoppingCartProductAddContainer)))
);
