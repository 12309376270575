import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { formatIsoDate } from 'utils/dateFormatter';
import measurementsImg from 'assets/images/measurments.jpg';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';
import { PATH_DAY_PLAN_INDEX } from '../DayPlanPaths';

class CurrentWeightRequired extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        renderTimeline: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline('top')}
                <section className="bg-white">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img
                                    className="mb-4 current-weight-needed-img"
                                    src={measurementsImg}
                                    role="presentation"
                                    alt=""
                                />
                                <h1>
                                    {this.props.t(
                                        'info-pages/current-weight-needed/title'
                                    )}
                                </h1>
                                <Button
                                    color="primary"
                                    className="mb-4"
                                    tag={Link}
                                    to={{
                                        pathname: PATH_MEASUREMENT_ADD,
                                        state: {
                                            from: {
                                                pathname: `${PATH_DAY_PLAN_INDEX}/${formatIsoDate(
                                                    new Date()
                                                )}`,
                                            },
                                        },
                                    }}
                                >
                                    {this.props.t(
                                        'info-pages/current-weight-needed/button'
                                    )}
                                </Button>

                                <p>
                                    {this.props.t(
                                        'info-pages/current-weight-needed/content'
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { CurrentWeightRequired };
export default withLocale(CurrentWeightRequired);
