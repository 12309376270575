import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import DayPlanQuery from './DayPlanQuery';

class DayPlanCacheConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    requestIdleCallbackIds = [];

    componentWillUnmount() {
        if (window.cancelIdleCallback) {
            this.requestIdleCallbackIds.forEach(id => {
                window.cancelIdleCallback(id);
            });
        }
    }

    idle = callback => {
        if (!window.requestIdleCallback) {
            callback();
            return;
        }

        this.requestIdleCallbackIds.push(window.requestIdleCallback(callback));
    };

    fetch = async () => {
        const cacheDayIndex = [1, -1, 2, 3, -2, -3, 4, 5, -4, -5, 6, 7, -6];

        cacheDayIndex.forEach(dayIndex => {
            this.idle(() =>
                this.props.client.query({
                    query: DayPlanQuery,
                    variables: {
                        date: formatIsoDate(addDays(this.props.date, dayIndex)),
                        canBeCopiedToDate: formatIsoDate(
                            addDays(this.props.date, dayIndex + 1)
                        ),
                        fetchPolicy: 'cache-first',
                    },
                })
            );
        });
    };

    render() {
        const { children } = this.props;

        return children({
            prefetchSurroundingDays: () => this.fetch(),
        });
    }
}

export default withApollo(DayPlanCacheConnectorApollo);
