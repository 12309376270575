import gql from 'graphql-tag';

const query = gql`
    query MotivationQuestions {
        motivation {
            public: test(identifier: "public") {
                identifier
                section {
                    identifier
                    question {
                        identifier
                        position
                        content
                    }
                }
            }
            dietSetup: test(identifier: "diet-setup") {
                identifier
                section {
                    identifier
                    question {
                        identifier
                        position
                        content
                    }
                }
            }
        }
    }
`;

export default query;
