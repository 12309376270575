export default class ShoppingCartDeliveryOption {
    constructor(
        id,
        name,
        doesRequireAddress,
        onlyVirtualProducts,
        price,
        countries,
        freeFromCartValue,
        isCod
    ) {
        this.id = id;
        this.name = name;
        this.doesRequireAddress = doesRequireAddress || false;
        this.onlyVirtual = Boolean(onlyVirtualProducts);
        this.onlyPhysical = !this.onlyVirtual;
        this.price = price;
        this.countries = countries || [];
        this.freeFromCartValue = freeFromCartValue;
        this.isCod = isCod;
    }
}
