import React from 'react';
import PropTypes from 'prop-types';
import Query from '@dietlabs/components/src/Apollo/Query';
import { ApiDateType } from 'types/CommonTypes';
import MeasurementFetchByDateQuery from './MeasurementFetchByDateQuery';

class MeasurementFetchByDateConnectorApollo extends React.Component {
    static propTypes = {
        date: ApiDateType,
        children: PropTypes.func.isRequired,
    };

    static defaultProps = {
        date: undefined,
    };

    static getMeasurementsFromApiResponse(data) {
        const weight = data.weight[0] ? data.weight[0].value : null;
        const hip = data.hip[0] ? data.hip[0].value : null;
        const waist = data.waist[0] ? data.waist[0].value : null;
        const thigh = data.thigh[0] ? data.thigh[0].value : null;
        const chest = data.chest[0] ? data.chest[0].value : null;
        const arm = data.arm[0] ? data.arm[0].value : null;

        return {
            weight,
            hip,
            waist,
            thigh,
            chest,
            arm,
        };
    }

    render() {
        const { date, children } = this.props;
        return (
            <Query query={MeasurementFetchByDateQuery} variables={{ date }}>
                {loadResult => {
                    const result = {
                        date,
                        loading: loadResult.loading,
                    };

                    if (!loadResult.loading) {
                        result.measurements = this.constructor.getMeasurementsFromApiResponse(
                            loadResult.data.me.measurementByType
                        );
                        result.systemOfMeasures =
                            loadResult.data.me.systemOfMeasures;
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default MeasurementFetchByDateConnectorApollo;
