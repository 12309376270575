import React from 'react';
import Layout from 'Layout/Layout';
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import motivation1 from 'assets/images/motivation1.jpg';
import motivation2 from 'assets/images/motivation2.png';
import motivation3 from 'assets/images/motivation3.png';

import convenrsation from 'assets/icons/interventions/conversation.svg';
import eye from 'assets/icons/interventions/eye.svg';
import flag from 'assets/icons/interventions/flag.svg';
import pin from 'assets/icons/interventions/pin.svg';

import { PATH_INTERVENTIONS } from './paths';

class InterventionsIntroPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Layout page="interventions">
                <header>
                    <Container>
                        <h1 className="text-center">
                            POZNAJ MODUŁ MOTYWACJI W DIECIE OXY
                        </h1>
                    </Container>
                </header>
                <section className="pt-0">
                    <Container>
                        <p>
                            Znasz już swoją motywację do diety i odchudzania.
                            Wiesz również na jakim poziomie jest Twoja silna
                            wola oraz jak reagujesz w trudnych sytuacjach.
                            Możesz sobie pogratulować, ponieważ pierwszy krok
                            już za Tobą. Czas dowiedzieć się co czeka Cię dalej.
                        </p>
                        <div className="py-5">
                            <img
                                src={motivation1}
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                        <p>
                            Na podstawie wyników badań przeprowadzonych wspólnie
                            z naukowcami z Uniwersytetu SWPS w Warszawie i w
                            odpowiedzi na liczne pytania naszych Klientów
                            dotyczących motywacji i sposobów na trzymanie się
                            diety, rozszerzyliśmy Dietę OXY właśnie o specjalny
                            moduł motywacyjny. Oprócz indywidualnie dopasowanego
                            planu żywieniowego dajemy Ci także proste w użyciu
                            narzędzia, dzięki którym będziesz się go trzymać!
                            Stworzyliśmy je w oparciu o naukowe badania i są
                            bardzo proste w użyciu.
                        </p>
                        <h2>Na czym będzie polegać moduł motywacji?</h2>
                        <p>To proste!</p>
                        <p>
                            Znasz już wynik badania Twojej motywacji. Wiesz już
                            co Cię motywuje do odchudzania i zdajesz sobie
                            sprawę. PO CO chcesz się odchudzać. Mając świadomość
                            z jakiego miejsca startujesz i ustalając jaki jest
                            Twój cel jesteś teraz gotowy, żeby otrzymać od nas
                            specjalnie przygotowane{' '}
                            <strong>narzędzia motywacyjne</strong>.
                        </p>

                        <div className="text-center">
                            <img
                                src={motivation2}
                                className="img-fluid"
                                alt=""
                            />
                        </div>

                        <p>Tak. Narzędzia.</p>
                        <p>
                            Brzmi poważnie i w zasadzie to całkiem poważna
                            sprawa, ale nie obawiaj się :) Są bardzo proste w
                            użyciu – tak naprawdę po prostu bawisz się nimi. W
                            każdym tygodniu od momentu, kiedy będziesz gotowa
                            rozpocząć ekscytującą drogę do zdrowia i wymarzonej
                            sylwetki, dostaniesz od nas 4 motywacyjne prezenty –
                            czyli właśnie owe narzędzia.
                        </p>
                        <Row className="text-center">
                            <Col>
                                <img
                                    src={pin}
                                    className="big-icon mb-3"
                                    alt=""
                                />
                                <h2>dawka inspiracji</h2>
                                <p>w postaci motywującego cytatu</p>
                            </Col>
                            <Col>
                                <img
                                    src={convenrsation}
                                    className="big-icon mb-3"
                                    alt=""
                                />
                                <h2>dawka refleksji</h2>
                                <p>w postaci pytania od coacha</p>
                            </Col>
                            <Col>
                                <img
                                    src={flag}
                                    className="big-icon mb-3"
                                    alt=""
                                />
                                <h2>kop energetyczny</h2>
                                <p>
                                    w postaci małego wyzwania - dzięki temu
                                    będziesz działać!
                                </p>
                            </Col>
                            <Col>
                                <img
                                    src={eye}
                                    className="big-icon mb-3"
                                    alt=""
                                />
                                <h2>doping psychologiczny</h2>
                                <p>
                                    który pozwoli CI lepiej poznać samego siebie
                                </p>
                            </Col>
                        </Row>
                        <p>
                            Żeby Motywacja w OXY była skuteczna wystarczy, że
                            znajdziesz chociaż chwilę w tygodniu na odrobinę
                            refleksji i zaangażowania w motywującą zabawę.
                            Jesteś gotowa na skuteczne zmiany z motywacją OXY?
                            Dietetyk i mentor zmian w Twoim smartphonie już na
                            Ciebie czekają :) Nie zastanawiaj się dłużej –
                            nadszedł czas, żeby działać z naszym wsparciem
                            jeszcze skuteczniej!
                        </p>
                        <div className="text-center">
                            <img
                                src={motivation3}
                                className="img-fluid py-5"
                                alt=""
                            />
                        </div>
                        <div className="text-center">
                            <Button
                                color="primary"
                                tag={Link}
                                to={PATH_INTERVENTIONS}
                            >
                                {this.props.t('interventions/go-to-motivation')}
                            </Button>
                        </div>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLocale(InterventionsIntroPage);
