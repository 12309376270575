import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { addDays, dateTimeToDate } from 'utils/date';
import DateLineChart from 'Layout/Chart/DateLineChart';
import DateLineChartPlaceholder from 'Layout/Chart/DateLineChartPlaceholder';
import withLoading from 'Layout/withLoading';

class HydrationHistoryChart extends Component {
    static propTypes = {
        // EsLint lies, they _are_ used -- in getDerivedStateFromProps
        // eslint-disable-next-line react/no-unused-prop-types
        dateFrom: PropTypes.instanceOf(Date).isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        dateTo: PropTypes.instanceOf(Date).isRequired,
        unit: PropTypes.string.isRequired,
        days: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.instanceOf(Date).isRequired,
                valueValue: PropTypes.number.isRequired,
                goalValue: PropTypes.number.isRequired,
            })
        ).isRequired,
        defaultDailyGoal: PropTypes.number.isRequired,
    };

    state = {};

    static getDerivedStateFromProps(props) {
        return {
            dateFrom: dateTimeToDate(props.dateFrom),
            dateTo: dateTimeToDate(props.dateTo),
        };
    }

    render() {
        const goalData = new Map(
            this.props.days.map(day => [
                dateTimeToDate(day.date).valueOf(),
                { x: dateTimeToDate(day.date), y: day.goalValue },
            ])
        );

        for (
            let date = this.state.dateFrom;
            date <= this.state.dateTo;
            date = addDays(date, 1)
        ) {
            const key = dateTimeToDate(date).valueOf();
            if (!goalData.has(key)) {
                goalData.set(key, {
                    x: dateTimeToDate(date),
                    y: this.props.defaultDailyGoal,
                });
            }
        }

        const series = [
            {
                name: 'goal',
                meta: { showPoint: false, showLine: true },
                data: Array.from(goalData.values()),
            },
            {
                name: 'value',
                meta: { showPoint: false, showLine: false, showBar: true },
                data: this.props.days.map(day => ({
                    x: dateTimeToDate(day.date),
                    y: day.valueValue,
                })),
            },
        ];

        const divisor = Math.min(
            (this.state.dateTo - this.state.dateFrom) / 1000 / 60 / 60 / 24,
            7
        );

        return (
            <DateLineChart
                series={series}
                options={{
                    axisX: { divisor },
                    axisY: { unit: this.props.unit, offset: 40, low: 0 },
                }}
            />
        );
    }
}

export { HydrationHistoryChart };
export default withLoading(HydrationHistoryChart, DateLineChartPlaceholder);
