import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import SendAnswersMutation from './SendAnswersMutation';

class PsychometricSendAnswersConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createSendAnswers = mutate => async (email, source, answers) => {
        const variables = {
            email,
            source,
            answers,
        };

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={SendAnswersMutation}>
                {mutate =>
                    children({
                        sendAnswers: this.createSendAnswers(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default PsychometricSendAnswersConnectorApollo;
