import gql from 'graphql-tag';

const query = gql`
    query DayPlanForTomorrow($date: Date!) {
        me {
            id
            dayPlanForTomorrow: dayPlan(date: $date) {
                __typename
                ... on UserDayPlanSuccess {
                    id
                    date
                    events {
                        __typename
                        ... on Meal {
                            id
                            name
                            dishes {
                                name
                                recipeNoteForPreviousDay
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default query;
