import React from 'react';

import MotivationQuestionsConnectorApollo from './Connector/Apollo/MotivationQuestions/MotivationQuestionsConnectorApollo';
import MotivationSendAnswersConnectorApollo from './Connector/Apollo/MotivationSendAnswers/MotivationSendAnswersConnectorApollo';
import MotivationTestContainer from './MotivationTestContainer';

class MotivationTestPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <MotivationQuestionsConnectorApollo>
                {({ ...questions }) => (
                    <MotivationSendAnswersConnectorApollo>
                        {({ ...sendAnswers }) => (
                            <MotivationTestContainer
                                {...questions}
                                {...sendAnswers}
                            />
                        )}
                    </MotivationSendAnswersConnectorApollo>
                )}
            </MotivationQuestionsConnectorApollo>
        );
    }
}

export default MotivationTestPage;
