import React from 'react';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import PropTypes from 'prop-types';
import ChangePasswordMutation from './ChangePasswordMutation';

class ChangePasswordConnector extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createChangePassword = mutation => async (currentPassword, newPassword) => {
        const response = await mutation({
            variables: {
                request: {
                    currentPassword,
                    newPassword,
                },
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        return (
            <Mutation mutation={ChangePasswordMutation}>
                {(mutate, { loading }) => {
                    const result = {
                        changePassword: this.createChangePassword(mutate),
                        loading,
                    };
                    return children(result);
                }}
            </Mutation>
        );
    }
}

export default ChangePasswordConnector;
