import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { reportWarning } from '@dietlabs/utils';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { holidayIcons } from 'consts';

import breakfast from 'assets/images/dayplan/plug/breakfast1-1.jpg';
import morningSnack from 'assets/images/dayplan/plug/morning-snack1-1.jpg';
import dinner from 'assets/images/dayplan/plug/dinner1-1.jpg';
import eveningSnack from 'assets/images/dayplan/plug/evening-snack1-1.jpg';
import supper from 'assets/images/dayplan/plug/supper1-1.jpg';

import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';
import { scrollToTop } from 'components/Helpers/scrollToTop';

class DayPlanDayReplacement extends Component {
    static propTypes = {
        trackEvent: PropTypes.func.isRequired,
        dayReplacement: PropTypes.shape({
            active: PropTypes.bool.isRequired,
            dietSetId: PropTypes.number.isRequired,
            events: PropTypes.array, //eslint-disable-line
            holiday: PropTypes.object, //eslint-disable-line
            categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        }).isRequired,
        search: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        replaceDay: PropTypes.func.isRequired,
        toggleModalWithDayReplacements: PropTypes.func.isRequired,
    };

    state = {
        preloadOutside: false,
    };

    changeDay = async () => {
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'exchange day');

        const replacement = {
            replacementId: this.props.dayReplacement.dietSetId,
        };

        this.setState({ preloadOutside: true });

        const response = await this.props.replaceDay(replacement);

        if (
            response.data.me.dietSetReplace.__typename ===
            'BasicMutationSuccess'
        ) {
            this.setState({ preloadOutside: false });
            this.props.toggleModalWithDayReplacements();
            window.onpopstate = () => {};
            window.history.go(-1);
            callNativeAppRemoveLastRoute('diet');
            setTimeout(() => {
                scrollToTop();
            }, 1000);
        } else {
            throw new Error(
                `Replace day failed: ${response.data.me.dietSetReplace.__typename}`
            );
        }
    };

    renderDietPhase() {
        if (
            this.props.dayReplacement.categories &&
            this.props.dayReplacement.categories[0]
        ) {
            const fastType = this.props.dayReplacement.categories[0].name.toLowerCase();
            return (
                <div className="diet-phase fast">
                    {this.props.t('fast')} {fastType}
                </div>
            );
        }

        return '';
    }

    renderMealImage = (mealName, preparationImageUrl) => {
        let mealImg;

        if (preparationImageUrl) {
            mealImg = preparationImageUrl
                .replace('(', '%28')
                .replace(')', '%29');
        } else {
            switch (mealName) {
                case 'Śniadanie':
                    mealImg = breakfast;
                    break;
                case 'Przekąska poranna':
                    mealImg = morningSnack;
                    break;
                case 'Obiad':
                    mealImg = dinner;
                    break;
                case 'Lunch':
                    mealImg = dinner;
                    break;
                case 'Przekąska wieczorna':
                    mealImg = eveningSnack;
                    break;
                case 'Przekąska':
                    mealImg = eveningSnack;
                    break;
                case 'Kolacja':
                    mealImg = supper;
                    break;
                case 'Obiadokolacja':
                    mealImg = supper;
                    break;
                default:
                    mealImg = breakfast;
            }
        }

        return (
            <>
                <div className="embed-responsive embed-responsive-1by1 d-md-none">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item"
                    />
                </div>
                <div className="embed-responsive embed-responsive-16by9 d-none d-md-block">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item"
                    />
                </div>
            </>
        );
    };

    renderReplacment() {
        const dayReplacement = this.props.dayReplacement;
        const mealsOnly = dayReplacement.events.filter(
            event => event.__typename === 'Meal'
        );

        return (
            <Row
                className={
                    dayReplacement.active
                        ? 'align-items-center'
                        : 'align-items-center not-active'
                }
            >
                <Col xs="12">
                    {this.props.dayReplacement.holiday ? (
                        <h2 className="text-center">
                            {this.renderHolidayIcon()}
                        </h2>
                    ) : (
                        ''
                    )}

                    {mealsOnly.map(event => {
                        let i = 0;
                        return (
                            <div className="event-meal" key={event.id}>
                                <Row>
                                    <Col xs="3" md="4" className="pr-0">
                                        {this.renderMealImage(
                                            event.name,
                                            event.preparationImageUrl
                                        )}
                                    </Col>
                                    <Col xs="9" md="8">
                                        <div>
                                            <strong>{event.name}</strong>{' '}
                                            <span className="d-md-none">
                                                {' '}
                                                -{' '}
                                            </span>
                                            <br className="d-none d-md-block" />
                                            <ul className="list-of-dishes">
                                                {event.dishes.map(dish => {
                                                    i += 1;
                                                    return (
                                                        <li key={dish.id}>
                                                            {i >= 2 ? ', ' : ''}
                                                            {dish.name}
                                                            {dish.ingredients
                                                                ? dish.ingredients.map(
                                                                      ingredient => {
                                                                          if (
                                                                              ingredient.name.toLowerCase() ===
                                                                              this.props.search.toLowerCase()
                                                                          ) {
                                                                              return (
                                                                                  <strong
                                                                                      key={
                                                                                          ingredient.productId
                                                                                      }
                                                                                  >
                                                                                      {' '}
                                                                                      (
                                                                                      {this.props.t(
                                                                                          'meal/menus-contain'
                                                                                      )}{' '}
                                                                                      {this.props.search.toLowerCase()}

                                                                                      ){' '}
                                                                                  </strong>
                                                                              );
                                                                          }

                                                                          return '';
                                                                      }
                                                                  )
                                                                : ''}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </Col>
                <Col xs="12" className="text-center">
                    <Button
                        color="primary"
                        data-test="button-replace-day"
                        onClick={async () => this.changeDay()}
                        className="mt-3 no-arrow min-width"
                        disabled={!dayReplacement.active}
                    >
                        {this.props.t('meal/exchange-dish-button')}
                    </Button>
                </Col>
                {this.state.preloadOutside ? <Loader /> : ''}
            </Row>
        );
    }

    renderHolidayIcon() {
        const Icon = holidayIcons[this.props.dayReplacement.holiday.icon];
        if (Icon) {
            return (
                <span className="SVGInline mr-3">
                    <Icon />
                </span>
            );
        }

        reportWarning(
            new Error(
                `Unknown holiday icon${this.props.dayReplacement.holiday.icon}`
            )
        );
        return '';
    }

    render() {
        return (
            <Col md="6">
                <div
                    className={
                        this.props.dayReplacement.holiday
                            ? 'white-box holiday'
                            : 'white-box'
                    }
                >
                    <div data-test="day-replacements">
                        {this.renderReplacment()}
                    </div>
                </div>
            </Col>
        );
    }
}
export { DayPlanDayReplacement };
export default withGTM(withLocale(DayPlanDayReplacement));
