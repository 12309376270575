import React from 'react';

import { Consumer } from './MarkProductContext';

const withMarkProduct = ChildComponent => props => (
    <Consumer>
        {({ markProductAsBought, markProductAsNotBought }) => (
            <ChildComponent
                {...props}
                markProductAsBought={markProductAsBought}
                markProductAsNotBought={markProductAsNotBought}
            />
        )}
    </Consumer>
);

export default withMarkProduct;
