function scrollSmooth(element, left, top) {
    element.scrollTo({ left, top, behavior: 'smooth' });
}

function scrollJump(element, left, top) {
    // eslint-disable-next-line no-param-reassign
    element.scrollLeft = left;
    // eslint-disable-next-line no-param-reassign
    element.scrollTop = top;
}

function getScrollLeft(element) {
    let { scrollLeft } = element;

    if (scrollLeft === undefined) {
        scrollLeft = element.scrollX;
    }

    return scrollLeft;
}

function getScrollTop(element) {
    let { scrollTop } = element;

    if (scrollTop === undefined) {
        scrollTop = element.scrollY;
    }

    return scrollTop;
}

function scrollTo(
    element,
    {
        left = getScrollLeft(element),
        top = getScrollTop(element),
        behavior = 'smooth',
    } = {}
) {
    if (!['jump', 'smooth'].includes(behavior)) {
        throw new Error(`Unknown scroll behavior: '${behavior}'`);
    }

    if (!Number.isFinite(left)) {
        throw new Error(`Invalid number given for scrollLeft: '${left}'`);
    }

    if (!Number.isFinite(top)) {
        throw new Error(`Invalid number given for scrollTop: '${top}'`);
    }

    const scrollLeft = getScrollLeft(element);
    const scrollTop = getScrollTop(element);

    if (scrollLeft === left && scrollTop === top) {
        // Nothing to do
        return;
    }

    if (behavior === 'jump') {
        scrollJump(element, left, top);
        return;
    }

    scrollSmooth(element, left, top);
}

export default scrollTo;
