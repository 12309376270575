import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';

class ShoppingListActions extends Component {
    static propTypes = {
        handlePrint: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
    };

    render() {
        const { handlePrint, t } = this.props;

        return (
            <React.Fragment>
                <Row className="d-print-none">
                    <Col xs="12" className="text-center d-none d-lg-block">
                        <Button
                            color="primary"
                            className="mt-3"
                            onClick={handlePrint}
                        >
                            {t('print')}
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withLocale(ShoppingListActions);
