import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import { UNIT_TYPES } from 'consts';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { getUnitForType } from 'utils/unit';
import { ApiDateType } from 'types/CommonTypes';
import MeasurementGroupSaveMutation from './MeasurementGroupSaveMutation';
import MeasurementFetchByDateQuery from './MeasurementFetchByDateQuery';

class MeasurementSaveByDateConnectorApollo extends React.Component {
    static propTypes = {
        date: ApiDateType,
        children: PropTypes.func.isRequired,
    };

    static defaultProps = {
        date: undefined,
    };

    saveMeasurements = apiSaveMeasurements => async formData => {
        const request = {
            date: formData.date,
        };

        if (formData.weight != null) {
            request.weight = {
                value: Number(formData.weight),
                unit: getUnitForType(
                    UNIT_TYPES.BODY_WEIGHT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.waist != null) {
            request.waist = {
                value: Number(formData.waist),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.hip != null) {
            request.hip = {
                value: Number(formData.hip),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.thigh != null) {
            request.thigh = {
                value: Number(formData.thigh),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.chest != null) {
            request.chest = {
                value: Number(formData.chest),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        if (formData.arm != null) {
            request.arm = {
                value: Number(formData.arm),
                unit: getUnitForType(
                    UNIT_TYPES.LENGTH_BODY_MEASUREMENT,
                    formData.systemOfMeasures
                ),
            };
        }

        const response = await apiSaveMeasurements({
            variables: {
                request,
            },
        });

        if (
            response.data.me.measurementGroupSave.__typename ===
            'ValidationException'
        ) {
            throw new ValidationErrors(
                response.data.me.measurementGroupSave.messages,
                response.data.me.measurementGroupSave.details
            );
        }

        let goalLost = false;
        let goalReached = false;

        goalLost =
            response.data.me.measurementGroupSave.goal
                .lostBecauseOfLoseWeight ||
            response.data.me.measurementGroupSave.goal.lostBecauseOfPutOnWeight;

        goalReached =
            response.data.me.measurementGroupSave.goal
                .reachedBecauseOfLoseWeight ||
            response.data.me.measurementGroupSave.goal
                .reachedBecauseOfPutOnWeight;

        return {
            goalLost,
            goalReached,
        };
    };

    render() {
        const { date, children } = this.props;
        return (
            <Mutation
                mutation={MeasurementGroupSaveMutation}
                refetchQueries={() => [
                    {
                        query: MeasurementFetchByDateQuery,
                        variables: { date },
                    },
                ]}
            >
                {(saveMeasurementsApi, saveResult) => {
                    const result = {
                        date,
                        saving: saveResult.loading,
                        saveMeasurements: this.saveMeasurements(
                            saveMeasurementsApi
                        ),
                    };

                    return children(result);
                }}
            </Mutation>
        );
    }
}

export default MeasurementSaveByDateConnectorApollo;
