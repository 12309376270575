import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Row, Col, Alert, Button } from 'reactstrap';
import Layout from 'Layout/Layout';
import Loader from 'Layout/Loader';
import PageContainer from 'Layout/Container/PageContainer';
import { ReactComponent as Envelope } from 'assets/icons/removeAccount/envelope.svg';
import { PATH_SETTINGS } from '../routes';

const RemoveAccountContainer = ({
    t,
    sendRequestToRemoveAccount,
    loading,
    activeSubscription,
    userEmail,
}) => {
    const [isEmailSent, setIsEmailSent] = useState(false);

    const handleSendRequestToRemoveAccount = async () => {
        const response = await sendRequestToRemoveAccount();
        const responseTypename = response.data.removeAccount.request.__typename;

        if (responseTypename === 'BasicMutationSuccess') {
            setIsEmailSent(true);
        } else {
            throw new Error('Something went wrong!');
        }
    };

    const renderAlert = () => {
        const availableOperators = ['AppStore', 'GooglePlay'];
        return activeSubscription.map(item => {
            if (availableOperators.includes(item.operator)) {
                let message;
                switch (item.operator) {
                    case 'AppStore':
                        message = 'remove-account/app-store/disclaimer';
                        break;
                    case 'GooglePlay':
                        message = 'remove-account/google-play/disclaimer';
                        break;
                    default:
                        message = '';
                }
                return (
                    <Alert color="new info" key={item.operator}>
                        <p
                            className="mb-0 black"
                            dangerouslySetInnerHTML={{
                                __html: t(message),
                            }}
                        />
                    </Alert>
                );
            }
            return '';
        });
    };

    const renderSuccessSendEmail = () => {
        return (
            <Row className="mt-3">
                <Col xs={{ size: 10, offset: 1 }}>
                    <div className="text-center">
                        <Envelope />
                        <p className="black bold mt-4">
                            {t('remove-account/message-sent')}
                        </p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('remove-account/check-your-mailbox'),
                            }}
                        />
                        <p>{t('remove-account/link-expire-info')}</p>
                    </div>
                </Col>
            </Row>
        );
    };
    return (
        <Layout page="remove-account">
            <Row>
                <Col>
                    <h1 className="d-none d-md-block text-center mt-4">
                        {t('remove-account/title')}
                    </h1>
                </Col>
            </Row>
            <PageContainer>
                <Row className="mt-4">
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        {renderAlert()}
                        {!isEmailSent ? (
                            <div className="text-sm-center">
                                <p className="black">
                                    {t('remove-account/email-confirm-info', {
                                        userEmail,
                                    })}
                                </p>
                                <Row className="pt-4">
                                    <Col xs={{ size: 10, offset: 1 }}>
                                        <Button
                                            color="primary"
                                            className="w-100 no-arrow"
                                            onClick={
                                                handleSendRequestToRemoveAccount
                                            }
                                        >
                                            {t('button/send-email')}
                                        </Button>
                                    </Col>
                                    <Col
                                        xs={{ size: 10, offset: 1 }}
                                        className="mt-3"
                                    >
                                        <Button
                                            tag={Link}
                                            to={PATH_SETTINGS}
                                            color="outline-primary"
                                            className="w-100"
                                        >
                                            {t('button/go-back')}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            renderSuccessSendEmail()
                        )}
                    </Col>
                </Row>
            </PageContainer>
            {loading ? <Loader fixed="no" /> : ''}
        </Layout>
    );
};

RemoveAccountContainer.propTypes = {
    t: PropTypes.func.isRequired,
    sendRequestToRemoveAccount: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    activeSubscription: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    userEmail: PropTypes.string.isRequired,
};

export default withLocale(RemoveAccountContainer);
