import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ChangeGoalConnector from './HydrationDayChangeGoalConnectorApollo';
import PortionRemoveConnector from './HydrationDayPortionRemoveConnectorApollo';

class HydrationDayMutationsConnectorApollo extends PureComponent {
    static propTypes = {
        date: PropTypes.instanceOf(Date).isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        queryResult: PropTypes.object.isRequired,
        children: PropTypes.func.isRequired,
    };

    render() {
        const { date, queryResult, children } = this.props;

        return (
            <ChangeGoalConnector date={date} queryResult={queryResult}>
                {(changeGoal, { data: changeGoalData }) => (
                    <PortionRemoveConnector
                        date={date}
                        queryResult={queryResult}
                    >
                        {(removePortion, { data: removePortionData }) =>
                            children({
                                changeGoal,
                                changeGoalData,
                                removePortion,
                                removePortionData,
                            })
                        }
                    </PortionRemoveConnector>
                )}
            </ChangeGoalConnector>
        );
    }
}

export { HydrationDayMutationsConnectorApollo };
export default HydrationDayMutationsConnectorApollo;
