import React from 'react';
import Layout from 'Layout/Layout';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import { Container, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { PATH_INTERVENTIONS } from 'Interventions/paths';
import { Link } from 'react-router-dom';
import MotivationTestPlaceholder from './MotivationTestPlaceholder';
import MotivationResult from './MotivationResult';

class MotivationTestContainer extends React.Component {
    static propTypes = {
        result: PropTypes.shape({
            coping: PropTypes.string,
            motivation: PropTypes.string,
            profile: PropTypes.string,
            willpower: PropTypes.string,
        }).isRequired,
    };

    renderResult = () => {
        if (this.props.result.profile) {
            return <MotivationResult content={this.props.result.profile} />;
        }

        return (
            <React.Fragment>
                <MotivationResult content={this.props.result.motivation} />
                <MotivationResult content={this.props.result.willpower} />
                <MotivationResult content={this.props.result.coping} />
            </React.Fragment>
        );
    };

    render() {
        return (
            <Layout page="interventions">
                <header>
                    <Container>
                        <h1 className="text-center">
                            Oto wyniki Twojego badania:
                            <br />
                            poznaj swoją największą motywację, która jeszcze
                            skuteczniej pozwoli Ci schudnąć!
                        </h1>
                    </Container>
                </header>
                <section className="pt-0">
                    <Container>
                        {this.renderResult()}

                        <h2 className="heading-1 text-center mt-5">
                            Teraz Dieta OXY to nie tylko wygodna dieta –
                            otrzymujesz dużo więcej…
                        </h2>
                        <ul className="list">
                            <li>SPOSOBY NA REGULARNOŚĆ POSIŁKÓW.</li>
                            <li>SPOSOBY NA GŁĘBOKI, REGENERUJĄCY SEN.</li>
                            <li>SPOSOBY NA MINIMALIZACJĘ SŁODYCZY.</li>
                            <li>SPOSOBY NA ODPOWIEDNIE NAWODNIENIE.</li>
                            <li>SPOSOBY NA WIĘCEJ RUCHU.</li>
                            <li>SPOSOBY NA POZYTYWNY NASTRÓJ.</li>
                        </ul>
                        <p>
                            Od teraz jeszcze skuteczniej przechodzisz na NOWY
                            STYL ŻYCIA!
                            <br />
                            Efekt: zdrowie, sylwetka, radość
                        </p>
                        <div className="text-center">
                            <Button
                                tag={Link}
                                to={PATH_INTERVENTIONS}
                                color="primary"
                            >
                                PRZEJDŹ DO MOTYWACJI
                            </Button>
                        </div>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default withLoading(
    withLocale(MotivationTestContainer),
    MotivationTestPlaceholder
);
