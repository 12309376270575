import React, { Component } from 'react';
import change2 from 'assets/icons/arrow_icon_off.svg';
import { withLocale } from '@dietlabs/components';
import withGTM from 'view/GoogleTagManager/withGTM';
import PropTypes from 'prop-types';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';

class DayPlanMealReplacement extends Component {
    static propTypes = {
        mealReplacement: PropTypes.shape({
            id: PropTypes.number.isRequired,
            dishes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        }).isRequired,
        trackEvent: PropTypes.func.isRequired,
        replaceMeal: PropTypes.func.isRequired,
        originalMealId: PropTypes.number.isRequired,
        mealKey: PropTypes.string.isRequired,
        closeAndScroll: PropTypes.func.isRequired,
        replaceMealSuccess: PropTypes.func.isRequired,
    };

    changeMeal = async () => {
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'exchange meal');
        this.props.closeAndScroll();

        const replacement = {
            originalMealId: this.props.originalMealId,
            replacementId: this.props.mealReplacement.id,
            key: this.props.mealKey,
            dishes: this.props.mealReplacement.dishes,
        };

        const response = await this.props.replaceMeal(replacement);

        if (
            response.data.me.dietSetReplaceMeal.__typename !==
            'BasicMutationSuccess'
        ) {
            throw new Error(
                `Replace meal failed: ${response.data.me.dietSetReplaceMeal.__typename}`
            );
        }

        this.props.replaceMealSuccess();
    };

    render() {
        const mealReplacement = this.props.mealReplacement;
        let i = 0;
        return (
            <li>
                <button
                    className="text-left px-0"
                    type="button"
                    onClick={() => this.changeMeal()}
                    data-test="replace-meal"
                >
                    <span className="stay-together">
                        <span className="btn-change">
                            <img
                                className="img-fluid img-sup"
                                src={change2}
                                alt="{this.props.t('meal/exchange-ingredient')}"
                            />
                        </span>
                    </span>
                    {mealReplacement.dishes.map(dish => {
                        i += 1;
                        return (
                            <span key={dish.id}>
                                {i >= 2 ? ', ' : ''}
                                {dish.name}
                            </span>
                        );
                    })}
                </button>
            </li>
        );
    }
}

export { DayPlanMealReplacement };
export default withGTM(withLocale(DayPlanMealReplacement));
