import React from 'react';
import Loader from 'Layout/Loader';
import Layout from 'Layout/Layout';

export class ShoppingCartConfirmPlaceholder extends React.Component {
    render() {
        return (
            <Layout page="cart">
                <div className="full-height">
                    <Loader />
                </div>
            </Layout>
        );
    }
}

export default ShoppingCartConfirmPlaceholder;
