import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InputText from 'components/FormElements/InputTextControlled';
import { Button, Label } from 'reactstrap';

class InterventionTaskStringComponent extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        task: PropTypes.shape({
            id: PropTypes.string,
            answers: PropTypes.arrayOf(PropTypes.string),
            label: PropTypes.string,
        }).isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        canAddField: PropTypes.bool,
        editMode: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        canAddField: true,
    };

    state = {
        messages:
            this.props.task.answers.length > 0 ? this.props.task.answers : [''],
    };

    componentDidUpdate(prevProps) {
        if (prevProps.task.answers !== this.props.task.answers) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ messages: this.props.task.answers });
        }
    }

    handleInputChange = event => {
        const name = parseInt(event.target.name.replace('input', ''), 10);
        const value = event.target.value;

        this.setState(prevState => {
            const newMessages = prevState.messages.map((item, i) => {
                if (i === name) {
                    return value;
                }
                return item;
            });

            let newMessages2 = [...newMessages];

            if (this.props.canAddField) {
                newMessages2 = newMessages.filter(
                    (item, i) => (item !== '' && i === name) || i !== name
                );
            }

            if (newMessages2.length === 0) {
                newMessages2 = [''];
            }

            this.props.setTaskAnswers(
                parseInt(this.props.task.id, 10),
                newMessages2
            );

            return { messages: newMessages2 };
        });
    };

    handleAddInput = () => {
        this.setState(prevState => ({
            messages: [...prevState.messages, ''],
        }));
    };

    renderForm = () => {
        if (this.state.messages.length > 0) {
            return this.state.messages.map((message, idx) => (
                <InputText
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    label=""
                    name={`input${idx}`}
                    value={message}
                    handleChange={this.handleInputChange}
                    disabled={!this.props.editMode}
                />
            ));
        }

        return (
            <InputText
                label=""
                name="input0"
                value=""
                handleChange={this.handleInputChange}
                disabled={!this.props.editMode}
            />
        );
    };

    render() {
        return (
            <div className="mt-3">
                <Label>{this.props.task.label}</Label>
                {this.renderForm()}
                {this.props.canAddField && this.props.editMode ? (
                    <Button
                        color="primary"
                        outline
                        onClick={this.handleAddInput}
                    >
                        {this.props.t('add-field')}
                    </Button>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default withLocale(InterventionTaskStringComponent);
