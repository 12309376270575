import React from 'react';
import PropTypes from 'prop-types';

import { withApollo } from 'react-apollo';

import PsychometricQuestionsQuery from './PsychometricQuestionsQuery';

class PsychotestQuestionsOnDemandConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }).isRequired,
    };

    fetch = async isFemale => {
        const result = await this.props.client.query({
            query: PsychometricQuestionsQuery,
            variables: {
                isFemale,
            },
            fetchPolicy: 'no-cache',
        });

        return result;
    };

    render() {
        const { children } = this.props;

        return children({
            questionsOnDemand: this.fetch,
        });
    }
}

export default withApollo(PsychotestQuestionsOnDemandConnectorApollo);
