import React from 'react';
import PropTypes from 'prop-types';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import DietModeChangeMutation from './DietModeChangeMutation';

class DietModeChangeConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    createDietModeChange = mutate => async request => {
        const variables = request;

        const response = await mutate({
            variables,
        });

        return response;
    };

    render() {
        const { children } = this.props;

        return (
            <Mutation mutation={DietModeChangeMutation}>
                {mutate =>
                    children({
                        dietModeChange: this.createDietModeChange(mutate),
                    })
                }
            </Mutation>
        );
    }
}

export default DietModeChangeConnectorApollo;
