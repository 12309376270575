import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepDietId extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        dietId: PropTypes.number,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dietId: null,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.dietId);
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.dietId);
    }

    render() {
        const { dietId, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <FormGroup tag="fieldset">
                        <Label>
                            {this.props.t('diet-settings/diet-id/label')}
                        </Label>
                        <InputRadio
                            label={[
                                <strong key="oxy-simple" className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-type/oxy-simple/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-id/oxy-simple/description'
                                ),
                            ]}
                            name="dietId"
                            value={3}
                            handleChange={handleInputChange}
                            checked={dietId === 3 || dietId === 4}
                        />

                        <InputRadio
                            label={[
                                <strong key="oxy-protein" className="d-block">
                                    {this.props.t(
                                        'diet-settings/diet-type/oxy-protein/title'
                                    )}
                                </strong>,
                                this.props.t(
                                    'diet-settings/diet-id/oxy-protein/description'
                                ),
                            ]}
                            name="dietId"
                            value={1}
                            handleChange={handleInputChange}
                            checked={dietId === 1 || dietId === 2}
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepDietId));
