import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, CustomInput, Label } from 'reactstrap';
import { withLocale } from '@dietlabs/components';

class MotivationQuestion extends React.Component {
    static propTypes = {
        content: PropTypes.string.isRequired,
        identifier: PropTypes.number.isRequired,
        position: PropTypes.number.isRequired,
        setAnswer: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
    };

    handleInputChange = event => {
        const value = parseInt(event.target.value, 10);
        this.props.setAnswer(this.props.identifier, value);
    };

    render() {
        return (
            <div className="question">
                <Label>{this.props.content}</Label>
                <Row>
                    <Col xs="6">{this.props.t('motivation-test/disagree')}</Col>
                    <Col xs="6" className="text-right">
                        {this.props.t('motivation-test/agree')}
                    </Col>
                </Row>
                <CustomInput
                    type="range"
                    id={`scale${this.props.identifier}`}
                    min={1}
                    max={7}
                    step="1"
                    style={{
                        '--value': `${(this.props.position / 6 - 1 / 6) *
                            100}%`,
                    }}
                    value={this.props.position}
                    onChange={this.handleInputChange}
                />
            </div>
        );
    }
}

export default withLocale(MotivationQuestion);
