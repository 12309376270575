import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'components/FormElements/InputNumberControlled';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepWeight extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        prevStep: PropTypes.func.isRequired,
        nextStep: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        weight: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }),
        changeNextBtnState: PropTypes.func.isRequired,
        errors: PropTypes.shape(),
        validateData: PropTypes.func.isRequired,
    };

    static defaultProps = {
        weight: {
            value: undefined,
            unit: 'kg',
        },
        errors: undefined,
    };

    componentDidMount() {
        this.checkErrors();
        // this.props.changeNextBtnState(!this.props.weight.value);
    }

    componentDidUpdate() {
        this.checkErrors();
        // this.props.changeNextBtnState(!this.props.weight.value);
    }

    checkErrors = () => {
        let errors = false;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (error.fieldName === 'weight.value') {
                    errors = true;
                }
            });
        }

        this.props.changeNextBtnState(!(this.props.weight.value && !errors));
    };

    render() {
        const { handleInputChange, weight, errors } = this.props;

        const optionalWeightProps = {};
        if (weight.value) {
            optionalWeightProps.value = Number(weight.value);
        }

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <InputNumber
                        label={this.props.t(
                            'diet-settings/current-body-weight'
                        )}
                        name="weight.value"
                        unit={weight.unit}
                        errors={errors}
                        step={0.1}
                        handleChange={handleInputChange}
                        onBlur={() => this.props.validateData()}
                        {...optionalWeightProps}
                        data-test="weight-input"
                    />
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepWeight));
