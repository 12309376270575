import { PATH_SETTINGS } from 'UserSettings/routes';
import { PATH_MEASUREMENT_INDEX } from 'Measurement/routes';
import { PATH_GUIDES } from 'Guides/paths';
import { PATHS } from '../paths';

export default [
    {
        path: PATH_MEASUREMENT_INDEX,
        translationKey: 'menu/measurements',
    },
    {
        path: PATH_GUIDES,
        translationKey: 'menu/guides',
    },
    {
        path: '/oxy365/160-pytania',
        translationKey: 'menu/faq',
    },
    {
        path: PATH_SETTINGS,
        translationKey: 'menu/settings',
    },
    {
        path: PATHS.DIET_SETTINGS,
        translationKey: 'menu/diet/settings',
    },
    {
        path: PATHS.EXTERNAL.SHOP,
        externalUrl: PATHS.EXTERNAL.SHOP,
        translationKey: 'menu/shop',
    },
    {
        path: PATHS.AUTH.LOGOUT,
        translationKey: 'menu/logout',
    },
];
