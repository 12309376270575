import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { PATHS } from 'config/paths';
import ShoppingCartUpSellProductContainer from 'view/ShoppingCart/UpSell/ShoppingCartUpSellProductContainer';
import ShoppingCartUpSellSummaryContainer from 'view/ShoppingCart/UpSell/ShoppingCartUpSellSummaryContainer';
import { SHOPPING_CART_INDEX_SAVE_VALIDATION_EXCEPTION } from 'view/ShoppingCart/Index/Save/ShoppingCartIndexSaveActionType';
import Layout from 'Layout/Layout';

export class ShoppingCartUpSellComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldRedirectToFirstStep: false,
        };
    }

    async saveCart() {
        const result = await this.props.saveCart();

        if (result.type === SHOPPING_CART_INDEX_SAVE_VALIDATION_EXCEPTION) {
            this.setState({ shouldRedirectToFirstStep: true });
        }
    }

    render() {
        let result = '';

        if (this.state.shouldRedirectToFirstStep) {
            result = <Redirect to={PATHS.CART.INDEX} />;
        } else if (
            this.state.shouldRedirectToConfirm ||
            this.props.userSawUpSelling
        ) {
            const pathToCartConfirm =
                PATHS.CART.CONFIRM.split(':')[0] + this.props.token;
            result = <Redirect to={pathToCartConfirm} />;
        } else {
            result = this.renderView();
        }

        return result;
    }

    renderView() {
        return (
            <Layout page="cart">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('cart/summary')}
                    </h1>
                </header>
                <Container>
                    <ShoppingCartUpSellSummaryContainer />
                </Container>
                <section className="text-center pay pb-5">
                    <Container>
                        <Row>
                            <Col md={{ size: 4, offset: 4 }}>
                                <Button
                                    data-test="save-cart-button"
                                    onClick={() => this.saveCart()}
                                    color="primary"
                                    className="w-100"
                                >
                                    {this.props.t('cart/next')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <ShoppingCartUpSellProductContainer />

                <section className="text-center pb-5">
                    <Container>
                        <Button
                            data-test="back-to-cart"
                            tag={Link}
                            to={PATHS.CART.INDEX}
                            color="link"
                        >
                            {this.props.t('cart/back-to-cart')}
                        </Button>
                    </Container>
                </section>
            </Layout>
        );
    }
}

ShoppingCartUpSellComponent.propTypes = {
    t: PropTypes.func.isRequired,
    saveCart: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    userSawUpSelling: PropTypes.bool.isRequired,
};

export default withLocale(ShoppingCartUpSellComponent);
