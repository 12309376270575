import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';

import Layout from 'Layout/Layout';
import Timeline from '../components/DayPlanTimeline';

class DayPlanIndexPlaceholder extends Component {
    render() {
        const availableDays = JSON.parse(localStorage.getItem('availableDays'));
        const date = this.props.date || new Date();

        return (
            <Layout page="diet">
                <Timeline
                    availableDays={availableDays}
                    date={date}
                    loading={!availableDays}
                />
                <section className="meal-navigation active">
                    <Container>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Nav fill>
                                    <NavItem className="placeholder">
                                        meal
                                    </NavItem>
                                    &nbsp;
                                    <NavItem className="placeholder">
                                        meal
                                    </NavItem>
                                    &nbsp;
                                    <NavItem className="placeholder">
                                        meal
                                    </NavItem>
                                    &nbsp;
                                    <NavItem className="placeholder">
                                        meal
                                    </NavItem>
                                    &nbsp;
                                    <NavItem className="placeholder">
                                        meal
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <header>
                    <Container className="text-center">
                        <Row>
                            <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                <h1 className="placeholder">&nbsp;</h1>
                                <div className="diet-phase placeholder">
                                    Detox
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </header>

                <section className="meal">
                    <Container>
                        <header role="button" className="placeholder">
                            <h2>&nbsp;</h2>
                            <p className="BTW">&nbsp;</p>
                        </header>
                    </Container>
                </section>

                <section className="meal">
                    <Container>
                        <header role="button" className="placeholder">
                            <h2>&nbsp;</h2>
                            <p className="BTW">&nbsp;</p>
                        </header>
                    </Container>
                </section>

                <section className="meal">
                    <Container>
                        <header role="button" className="placeholder">
                            <h2>&nbsp;</h2>
                            <p className="BTW">&nbsp;</p>
                        </header>
                    </Container>
                </section>

                <section className="meal">
                    <Container>
                        <header role="button" className="placeholder">
                            <h2>&nbsp;</h2>
                            <p className="BTW">&nbsp;</p>
                        </header>
                    </Container>
                </section>

                <section className="meal">
                    <Container>
                        <header role="button" className="placeholder">
                            <h2>&nbsp;</h2>
                            <p className="BTW">&nbsp;</p>
                        </header>
                    </Container>
                </section>
            </Layout>
        );
    }
}

DayPlanIndexPlaceholder.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
};

export default DayPlanIndexPlaceholder;
