export const getAllAvailablePaths = schema => {
    const result = [];
    const currentPath = [];

    schema.forEach(step => {
        const key = schema.findIndex(stepHelper => stepHelper.id === step.id);
        currentPath.push({ id: step.id });

        if (step.fork) {
            Object.keys(step.fork.children).forEach(forkKey => {
                getAllAvailablePaths(
                    step.fork.children[forkKey]
                ).forEach(subStep => result.push(currentPath.concat(subStep)));
            });
        } else if (parseInt(key, 10) + 1 === schema.length) {
            result.push(currentPath);
        }
    });

    return result;
};
