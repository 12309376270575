import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'reactstrap';
import InputEmail from 'components/FormElements/InputEmailControlled';
import Loader from 'Layout/Loader';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import ErrorBoundary from 'view/ErrorBoundary/ErrorBoundary';
import Layout from 'Layout/Layout';

import ValidationErrors from 'view/Validation/ValidationErrors';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import EmailFormatRule from 'view/Validation/ValidationRules/EmailFormatRule';

import { validateFields } from 'view/Validation/validateFields';
import { validateField } from 'view/Validation/validateField';

export class NewsletterUnsubscribe extends Component {
    static propTypes = {
        unsubscribe: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
    };

    state = {
        email: '',
        preload: false,
        success: false,
        errors: new ValidationErrors(),
    };

    validationRules = {
        email: [
            new RequiredRule({ translator: this.props.t }),
            new EmailFormatRule({ translator: this.props.t }),
        ],
    };

    handleInputChange = event => {
        this.setState({ [event.target.name]: event.target.value });

        if (this.validationRules[event.target.name]) {
            const field = event.target.name;
            const value = event.target.value;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    details: validateField(
                        field,
                        value,
                        this.validationRules[field],
                        prevState
                    ),
                },
            }));
        }
    };

    handleSubmit = async event => {
        event.preventDefault();

        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        if (frontEndErrors.length === 0) {
            this.setState({ preload: true });

            const response = await this.props.unsubscribe({
                email: this.state.email,
            });

            if (
                response.data.newsletter.unsubscribe.code &&
                response.data.newsletter.unsubscribe.code === 200
            ) {
                this.setState({
                    email: '',
                    preload: false,
                    success: true,
                    errors: new ValidationErrors(),
                });
            } else {
                this.setState({
                    success: false,
                    errors: response.data.newsletter.unsubscribe,
                    preload: false,
                });
            }
        } else {
            this.setState({
                success: false,
            });
        }
    };

    render() {
        return (
            <Layout page="newsletter-unsubscribe">
                <header>
                    <h1 className="text-center d-none d-md-block">
                        {this.props.t('newsletter/unsubscribe/title')}
                    </h1>
                </header>
                <section className="pt-0">
                    <ErrorBoundary>
                        <Container>
                            <Row>
                                <Col xs="12" sm={{ size: 6, offset: 3 }}>
                                    <Form
                                        onSubmit={this.handleSubmit}
                                        data-test="newsletter-unsubscribe-form"
                                        noValidate
                                    >
                                        {this.state.success ? (
                                            <Alert
                                                color="success"
                                                data-test="newsletter-unsubscribe-success"
                                            >
                                                {this.props.t(
                                                    'newsletter/unsubscribe/success'
                                                )}
                                                <br />
                                            </Alert>
                                        ) : (
                                            ''
                                        )}

                                        <InputEmail
                                            label={this.props.t('email')}
                                            name="email"
                                            value={this.state.email}
                                            errors={this.state.errors}
                                            handleChange={
                                                this.handleInputChange
                                            }
                                            validationRules={
                                                this.validationRules.email
                                            }
                                            data-test="email-input"
                                        />

                                        <div className="text-center">
                                            <Button
                                                color="primary"
                                                data-test="send-button"
                                            >
                                                {this.props.t(
                                                    'newsletter/unsubscribe/send'
                                                )}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            {this.state.preload ? <Loader /> : ''}
                        </Container>
                    </ErrorBoundary>
                </section>
            </Layout>
        );
    }
}

export default withLocale(NewsletterUnsubscribe);
