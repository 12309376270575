import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Row, Col, Button } from 'reactstrap';
import InterventionTaskStringComponent from './InterventionTaskStringComponent';
import InterventionTaskFakeCheckboxComponent from './InterventionTaskFakeCheckboxComponent';

class InterventionTaskGroupComponent extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        tasks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        editMode: PropTypes.bool.isRequired,
    };

    state = {
        tasks: this.props.tasks,
    };

    handleClick = () => {
        this.setState(prevState => {
            const newTasks = prevState.tasks.map(task => ({
                ...task,
                answers: [...task.answers, ''],
            }));

            return { tasks: newTasks };
        });
    };

    setTaskAnswers = (taskId, answers) => {
        this.setState(prevState => {
            const newTasks = prevState.tasks.map(task => {
                if (parseInt(task.id, 10) === taskId) {
                    return {
                        ...task,
                        answers,
                    };
                }
                return task;
            });

            return { tasks: newTasks };
        });

        this.props.setTaskAnswers(taskId, answers);
    };

    renderTask = task => {
        switch (task.variant) {
            case 'time':
                return (
                    <InterventionTaskStringComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.setTaskAnswers}
                        canAddField={false}
                        editMode={this.props.editMode}
                    />
                );
            case 'meal':
                return (
                    <InterventionTaskStringComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.setTaskAnswers}
                        canAddField={false}
                        editMode={this.props.editMode}
                    />
                );
            case 'hunger':
                return (
                    <InterventionTaskFakeCheckboxComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.setTaskAnswers}
                        canAddField={false}
                        editMode={this.props.editMode}
                    />
                );
            case 'appetite':
                return (
                    <InterventionTaskFakeCheckboxComponent
                        key={task.id}
                        task={task}
                        setTaskAnswers={this.setTaskAnswers}
                        canAddField={false}
                        editMode={this.props.editMode}
                    />
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    {this.state.tasks.map(task => (
                        <Col key={task.id}>{this.renderTask(task)}</Col>
                    ))}
                </Row>
                {this.props.editMode ? (
                    <Row>
                        <Col>
                            <Button
                                color="primary"
                                outline
                                onClick={this.handleClick}
                            >
                                {this.props.t('add-field')}
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    ' '
                )}
            </React.Fragment>
        );
    }
}

export default withLocale(InterventionTaskGroupComponent);
