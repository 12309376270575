import gql from 'graphql-tag';

export default gql`
    mutation psychotestSendAnswersMutation(
        $email: String
        $source: String
        $answers: [PsychometricTestQuestionAnswerInputType]!
    ) {
        psychotest {
            psychometricTestSend(
                email: $email
                source: $source
                answers: $answers
            ) {
                __typename
                ... on BasicMutationSuccess {
                    code
                }
                ... on ValidationException {
                    code
                    messages
                    details {
                        fieldName
                        messages
                    }
                }
            }
        }
    }
`;
