import { connect } from 'react-redux';
import ShoppingCartIndexDeliveryUserDataComponent from 'components/ShoppingCart/Index/ShoppingCartIndexDeliveryUserDataComponent';
import ValidationErrors from 'view/Validation/ValidationErrors';
import { shoppingCartSetUserDataAction } from 'view/ShoppingCart/UserData/ShoppingCartUserDataAction';

import ShoppingCartUserDataFactory from 'view/ShoppingCart/UserData/ShoppingCartUserDataFactory';

const mapStateToProps = state => {
    const userData = ShoppingCartUserDataFactory.createFromState(state);

    return {
        userData,
        validationError: ValidationErrors.createFromObject(
            state.shoppingCart.validationError
        ),
    };
};

const mapDispatchToProps = dispatch => ({
    setUserData: userData => dispatch(shoppingCartSetUserDataAction(userData)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartIndexDeliveryUserDataComponent);
