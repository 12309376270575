import React from 'react';
import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

class SystemOfMeasurementsFormPlaceholder extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        return (
            <Container className={this.props.className}>
                <Row>
                    <Col xs="12" md={{ size: 8, offset: 2 }}>
                        <div className="white-box">
                            <Form>
                                <FormGroup>
                                    <h2>
                                        {this.props.t('settings/metric-system')}
                                    </h2>

                                    <div className="form-group">
                                        <div className="custom-radio custom-control">
                                            <Label className="custom-control-label">
                                                {this.props.t('settings/si')}
                                            </Label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="custom-radio custom-control">
                                            <Label className="custom-control-label">
                                                {this.props.t(
                                                    'settings/imperial'
                                                )}
                                            </Label>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withLocale(SystemOfMeasurementsFormPlaceholder);
