import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

class PageContainer extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children } = this.props;
        return (
            <section className="pt-0">
                <Container>
                    <Row>
                        <Col>{children}</Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default PageContainer;
