import React from 'react';

import { Consumer } from './StepContext';

const withStep = ChildComponent => props => (
    <Consumer>
        {({ prevStep, nextStep, handleInputChange, changeNextBtnState }) => (
            <ChildComponent
                {...props}
                prevStep={prevStep}
                nextStep={nextStep}
                handleInputChange={handleInputChange}
                changeNextBtnState={changeNextBtnState}
            />
        )}
    </Consumer>
);

export default withStep;
