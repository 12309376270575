import React from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import deepClone from 'utils/deepClone';

import MotivationResultQuery from './MotivationResultQuery';

class MotivationResultConnectorApollo extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Query query={MotivationResultQuery} fetchPolicy="no-cache">
                {({ data, loading, cacheHit, hasNetworkError }) => {
                    const result = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                    };

                    if (!loading) {
                        result.result = deepClone(
                            data.me.motivationalTestResult
                        );
                    }

                    return children(result);
                }}
            </Query>
        );
    }
}

export default MotivationResultConnectorApollo;
