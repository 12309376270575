import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InputCheckbox from 'components/FormElements/InputCheckboxControlled2';
import { FormGroup, Label } from 'reactstrap';

class InterventionTaskCheckboxesComponent extends React.Component {
    static propTypes = {
        task: PropTypes.shape({
            id: PropTypes.string,
            answers: PropTypes.arrayOf(PropTypes.string),
            label: PropTypes.string,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    keyalias: PropTypes.string,
                    value: PropTypes.string,
                })
            ),
        }).isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        editMode: PropTypes.bool.isRequired,
    };

    state = {
        messages: this.props.task.answers,
    };

    handleInputChange = event => {
        const name = event.target.name;
        const checked = event.target.checked;

        if (checked) {
            this.setState(prevState => {
                const newMessages = [...prevState.messages, name];

                this.props.setTaskAnswers(
                    parseInt(this.props.task.id, 10),
                    newMessages
                );

                return { messages: newMessages };
            });
        } else {
            this.setState(prevState => {
                const newMessages = prevState.messages.filter(
                    item => item !== name
                );

                this.props.setTaskAnswers(
                    parseInt(this.props.task.id, 10),
                    newMessages
                );

                return { messages: newMessages };
            });
        }
    };

    renderForm = () => {
        if (!this.props.editMode) {
            return (
                <ul>
                    {this.props.task.options.map(option => {
                        if (!this.state.messages.includes(option.keyalias)) {
                            return null;
                        }
                        return <li key={option.keyalias}>{option.value}</li>;
                    })}
                </ul>
            );
        }

        return this.props.task.options.map(option => (
            <FormGroup key={option.keyalias}>
                <InputCheckbox
                    id={option.keyalias}
                    label={option.value}
                    name={option.keyalias}
                    value={option.keyalias}
                    handleChange={this.handleInputChange}
                    checked={!!this.state.messages.includes(option.keyalias)}
                />
            </FormGroup>
        ));
    };

    render() {
        return (
            <div className="mt-3">
                <Label>{this.props.task.label}</Label>
                {this.renderForm()}
            </div>
        );
    }
}

export default withLocale(InterventionTaskCheckboxesComponent);
