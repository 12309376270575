import UpdateAppPage from './UpdateAppPage';

const PATH_UPDATE_APP = '/update-your-application';
const routes = [
    {
        path: PATH_UPDATE_APP,
        component: UpdateAppPage,
        private: false,
        exact: true,
        title: 'route-title/update',
    },
];

export { PATH_UPDATE_APP, routes };
