import { connect } from 'react-redux';
import ShoppingCartBankTransferComponent from 'components/ShoppingCart/BankTransfer/ShoppingCartBankTransferComponent';
import { paymentLoadByToken } from 'view/Payment/Load/ByToken/PaymentLoadByTokenAction';

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
    loadPayment: token => paymentLoadByToken(token),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartBankTransferComponent);
