import React from 'react';

// import PropTypes from 'prop-types';
import DietSettingsConnectorApollo from 'DietSettings/Index/Connector/Apollo/Fetch/DietSettingsConnectorApollo';
import DietModeChangeConnectorApollo from './Connector/Apollo/DietModeChange/DietModeChangeConnectorApollo';
import GoalChangeConnectorApollo from './Connector/Apollo/GoalChange/GoalChangeConnectorApollo';
import MakeDecisionContainer from './MakeDecisionContainer';

class MakeDecisionPage extends React.Component {
    static propTypes = {};

    render() {
        return (
            <DietSettingsConnectorApollo>
                {({ ...dietSettingsData }) => (
                    <DietModeChangeConnectorApollo>
                        {({ ...dietModeChange }) => (
                            <GoalChangeConnectorApollo>
                                {({ ...goalChange }) => (
                                    <MakeDecisionContainer
                                        {...dietSettingsData}
                                        {...dietModeChange}
                                        {...goalChange}
                                    />
                                )}
                            </GoalChangeConnectorApollo>
                        )}
                    </DietModeChangeConnectorApollo>
                )}
            </DietSettingsConnectorApollo>
        );
    }
}

export default MakeDecisionPage;
