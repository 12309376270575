export const dietSettingsGetSchemaForParameters = (schema, parameters) => {
    const result = [];
    schema.forEach(step => {
        if (
            step.isVisible === undefined ||
            (step.isVisible && step.isVisible(parameters) === true)
        ) {
            if (step.fork) {
                const subStepIdentifier = step.fork.chooseChild(parameters);
                if (subStepIdentifier !== undefined) {
                    result.push({ id: step.id });
                    const subSteps = step.fork.children[subStepIdentifier];
                    result.push(
                        ...dietSettingsGetSchemaForParameters(
                            subSteps,
                            parameters
                        )
                    );
                } else {
                    result.push(step);
                }
            } else {
                result.push(step);
            }
        }
    });
    return result;
};
