import React from 'react';
import MotivationResultConnectorApollo from './Connector/Apollo/MotivationResult/MotivationResultConnectorApollo';
import MotivationResultContainer from './MotivationResultContainer';

class MotivationTestPage extends React.Component {
    render() {
        return (
            <MotivationResultConnectorApollo>
                {({ ...result }) => <MotivationResultContainer {...result} />}
            </MotivationResultConnectorApollo>
        );
    }
}

export default MotivationTestPage;
