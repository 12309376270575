import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import Layout from 'Layout/Layout';
import CurrentMeasurementsConnectorApollo from 'Measurement/CurrentMeasurements/Connector/Apollo/CurrentMeasurementsConnectorApollo';
import CurrentMeasurementsContainer from 'Measurement/CurrentMeasurements/CurrentMeasurementsContainer';
import MeasurementHistoryConnectorApollo from 'Measurement/MeasurementHistory/Connector/Apollo/MeasurementHistoryConnectorApollo';
import { MEASUREMENT_TYPES } from 'Measurement/constants';
import MeasurementHistoryContainer from 'Measurement/MeasurementHistory/MeasurementHistoryContainer';

class MeasurementsPage extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };

    render() {
        const { t } = this.props;
        const today = new Date();
        const monthAgo = new Date(today.getTime());
        monthAgo.setMonth(monthAgo.getMonth() - 1);

        const periodEnd = new Date();
        const periodLength = 31;

        return (
            <Layout page="measurement">
                <header>
                    <h1 className="text-center">{t('measurments/title')}</h1>
                </header>
                <CurrentMeasurementsConnectorApollo>
                    {currentMeasurementData => (
                        <CurrentMeasurementsContainer
                            {...currentMeasurementData}
                        />
                    )}
                </CurrentMeasurementsConnectorApollo>

                <MeasurementHistoryConnectorApollo
                    initialMeasurementType={MEASUREMENT_TYPES.WEIGHT}
                    periodLength={periodLength}
                    periodEnd={periodEnd}
                >
                    {measurementHistoryData => (
                        <MeasurementHistoryContainer
                            {...measurementHistoryData}
                        />
                    )}
                </MeasurementHistoryConnectorApollo>
            </Layout>
        );
    }
}

export default withLocale(MeasurementsPage);
