import React, { Component } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { reportWarning } from '@dietlabs/utils';
import withGTM from 'view/GoogleTagManager/withGTM';
import { EVENT_CATEGORY_DIET_PLAN } from 'view/GoogleTagManager/EventCategories';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import { holidayIcons } from 'consts';
import { ReactComponent as ChangeIcon } from 'assets/icons/change_icon_white.svg';

class DayPlanDayReplacement extends Component {
    static propTypes = {
        trackEvent: PropTypes.func.isRequired,
        dayReplacement: PropTypes.shape({
            active: PropTypes.bool.isRequired,
            dietSetId: PropTypes.number.isRequired,
            events: PropTypes.array, //eslint-disable-line
            holiday: PropTypes.object, //eslint-disable-line
            categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        }).isRequired,
        search: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        replaceDay: PropTypes.func.isRequired,
        changeDaySuccess: PropTypes.func.isRequired,
    };

    state = {
        preloadOutside: false,
    };

    changeDay = async () => {
        this.props.trackEvent(EVENT_CATEGORY_DIET_PLAN, 'exchange day');

        const replacement = {
            replacementId: this.props.dayReplacement.dietSetId,
        };

        this.setState({ preloadOutside: true });

        const response = await this.props.replaceDay(replacement);

        if (
            response.data.me.dietSetReplace.__typename ===
            'BasicMutationSuccess'
        ) {
            this.setState({ preloadOutside: false });
            this.props.changeDaySuccess();
        } else {
            throw new Error(
                `Replace day failed: ${response.data.me.dietSetReplace.__typename}`
            );
        }
    };

    renderDietPhase() {
        if (
            this.props.dayReplacement.categories &&
            this.props.dayReplacement.categories[0]
        ) {
            const fastType = this.props.dayReplacement.categories[0].name.toLowerCase();
            return (
                <div className="diet-phase fast">
                    {this.props.t('fast')} {fastType}
                </div>
            );
        }

        return '';
    }

    renderReplacment() {
        const dayReplacement = this.props.dayReplacement;
        const mealsOnly = dayReplacement.events.filter(
            event => event.__typename === 'Meal'
        );

        return (
            <Row
                className={
                    dayReplacement.active
                        ? 'align-items-center'
                        : 'align-items-center not-active'
                }
            >
                <Col xs="12" lg="9">
                    {this.props.dayReplacement.holiday ? (
                        <h2 className="text-center text-md-left">
                            {this.renderHolidayIcon()}
                        </h2>
                    ) : (
                        ''
                    )}

                    {mealsOnly.map(event => {
                        let i = 0;
                        return (
                            <div key={event.id}>
                                <strong>{event.name}</strong> —
                                <ul className="list-of-dishes">
                                    {event.dishes.map(dish => {
                                        i += 1;
                                        return (
                                            <li key={dish.id}>
                                                {i >= 2 ? ', ' : ''}
                                                {dish.name}
                                                {dish.ingredients
                                                    ? dish.ingredients.map(
                                                          ingredient => {
                                                              if (
                                                                  ingredient.name.toLowerCase() ===
                                                                  this.props.search.toLowerCase()
                                                              ) {
                                                                  return (
                                                                      <strong
                                                                          key={
                                                                              ingredient.productId
                                                                          }
                                                                      >
                                                                          {' '}
                                                                          (
                                                                          {this.props.t(
                                                                              'meal/menus-contain'
                                                                          )}{' '}
                                                                          {this.props.search.toLowerCase()}
                                                                          ){' '}
                                                                      </strong>
                                                                  );
                                                              }

                                                              return '';
                                                          }
                                                      )
                                                    : ''}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </Col>
                <Col xs="12" lg="3" className="text-center">
                    <Button
                        color="primary"
                        data-test="button-replace-day"
                        onClick={async () => this.changeDay()}
                        className="mt-3 mt-lg-0 no-arrow"
                        disabled={!dayReplacement.active}
                    >
                        <span className="SVGInline mr-2">
                            <ChangeIcon />
                        </span>
                        {this.props.t('meal/exchange-dish-button')}
                    </Button>
                </Col>
                {this.state.preloadOutside ? <Loader /> : ''}
            </Row>
        );
    }

    renderHolidayIcon() {
        const Icon = holidayIcons[this.props.dayReplacement.holiday.icon];
        if (Icon) {
            return (
                <span className="SVGInline mr-3">
                    <Icon />
                </span>
            );
        }

        reportWarning(
            new Error(
                `Unknown holiday icon${this.props.dayReplacement.holiday.icon}`
            )
        );
        return '';
    }

    render() {
        return (
            <li>
                {this.props.dayReplacement.holiday ? (
                    <Alert color="primary" data-test="holiday-alert">
                        {this.renderReplacment()}
                    </Alert>
                ) : (
                    <Alert color="secondary">{this.renderReplacment()}</Alert>
                )}
            </li>
        );
    }
}
export { DayPlanDayReplacement };
export default withGTM(withLocale(DayPlanDayReplacement));
