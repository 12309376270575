import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import withStep from './StepContext/withStep';

class StepActions extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        prevStep: PropTypes.func.isRequired,
        nextStep: PropTypes.func.isRequired,
        isNextButtonDisabled: PropTypes.bool.isRequired,
        currentStep: PropTypes.string.isRequired,
    };

    render() {
        const {
            nextStep,
            prevStep,
            isNextButtonDisabled,
            currentStep,
        } = this.props;

        if (currentStep === 'start') {
            return (
                <Row>
                    <Col className="text-center">
                        <Button
                            color="primary"
                            onClick={nextStep()}
                            disabled={isNextButtonDisabled}
                        >
                            {this.props.t('diet-settings/cta')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        if (currentStep === 'sex-brithday-height') {
            return (
                <Row>
                    <Col xs="6" className="pr-2"></Col>
                    <Col xs="6" className="pl-2">
                        <Button
                            color="primary"
                            onClick={nextStep()}
                            disabled={isNextButtonDisabled}
                            className="w-100"
                        >
                            {this.props.t('next')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        if (currentStep === 'finish') {
            return '';
        }

        return (
            <Row>
                <Col xs="6" className="pr-2">
                    <Button
                        color="primary"
                        outline
                        onClick={prevStep()}
                        type="button"
                        className="w-100"
                    >
                        {this.props.t('prev')}
                    </Button>
                </Col>
                <Col xs="6" className="pl-2">
                    <Button
                        color="primary"
                        onClick={nextStep()}
                        disabled={isNextButtonDisabled}
                        className="w-100"
                    >
                        {this.props.t('next')}
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default withStep(withLocale(StepActions));
