import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import deepClone from 'utils/deepClone';
import deepMerge from 'utils/deepMerge';

import HydrationSettingsMutation from './HydrationSettingsMutation';

class HydrationSettingsConnectorApollo extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        rawQueryResult: PropTypes.object.isRequired,
    };

    createSave = mutate => async settings => {
        const variables = { settings };
        const optimisticResponse = deepMerge(
            deepClone(this.props.rawQueryResult),
            {
                me: { hydrationSettings: settings },
            }
        );
        const { data } = await mutate({ variables, optimisticResponse });
        const result = data.me.hydrationSettings;
        if (result.__typename === 'ValidationException') {
            throw result;
        }
    };

    render() {
        return (
            <Mutation mutation={HydrationSettingsMutation}>
                {(mutate, { loading }) =>
                    this.props.children(this.createSave(mutate), { loading })
                }
            </Mutation>
        );
    }
}

export { HydrationSettingsConnectorApollo };
export default HydrationSettingsConnectorApollo;
