import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import dropImg from 'assets/icons/hydration/drop.png';
import Odometer from 'react-odometerjs';

class HydrationDayProgress extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        unit: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        goal: PropTypes.number.isRequired,
    };

    state = {
        odometer: 0,
    };

    componentDidMount() {
        this.setState({ odometer: this.props.value });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                odometer: this.props.value,
            });
        }
    }

    render() {
        const { t, unit, value, goal } = this.props;

        const percentage = (value / goal) * 100 + (value / goal) * 7;

        return (
            <div className="text-center">
                <div className="drop">
                    <img src={dropImg} className="img-fluid" alt="" />
                    <div className="value">
                        <div
                            className="waves"
                            style={{ height: `${percentage}%` }}
                        />
                        <div
                            className="waves2"
                            style={{ height: `${percentage}%` }}
                        />
                        <div className="text">
                            <Odometer value={this.state.odometer} format="d" />{' '}
                            {unit}
                            <br />
                            {t('hydration/progress/label')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { HydrationDayProgress };
export default withLocale(HydrationDayProgress);
