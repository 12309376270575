import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { PATH_MEASUREMENT_ADD } from 'Measurement/routes';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';

export class CurrentMeasurementsBox extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        value: PropTypes.number,
        unit: PropTypes.string,
    };

    static defaultProps = {
        value: undefined,
        unit: undefined,
    };

    render() {
        const { t, label, icon, value, unit } = this.props;

        return (
            <Link to={PATH_MEASUREMENT_ADD}>
                <div className="box">
                    <Row className="align-items-center">
                        <Col xs="3" md="2">
                            <img src={icon} alt="" className="img-fluid" />
                        </Col>
                        <Col xs="6" md="7">
                            {label}
                            <br />
                            {value ? (
                                <span className="value">
                                    {value} {unit}
                                </span>
                            ) : (
                                <span className="no-value">{t('no-data')}</span>
                            )}
                        </Col>
                        <Col xs="3" className="text-right">
                            <span className="SVGInline">
                                <RightArrow />
                            </span>
                        </Col>
                    </Row>
                </div>
            </Link>
        );
    }
}
export default withLocale(CurrentMeasurementsBox);
