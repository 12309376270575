import React from 'react';
import { Row, Col } from 'reactstrap';

import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';

import measurementFirst from 'assets/icons/measurement-first.svg';
import measurementCurrent from 'assets/icons/measurement-current.svg';
import measurementGoal from 'assets/icons/measurement-goal.svg';
import measurementLeft from 'assets/icons/measurement-left.svg';

class MakeDecisionCurrentMeasurements extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        firstMeasurement: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        lastMeasurement: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        goalWeight: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        dietMode: PropTypes.string.isRequired,
    };

    renderStabilisationMeasurements = () => (
        <div className="summary">
            <Row>
                <Col xs="3" className="text-center">
                    <img
                        src={measurementFirst}
                        className="makeDecision-icon"
                        alt=""
                    />
                    <h3>
                        <span className="big-number">
                            {Math.round(
                                this.props.firstMeasurement.value * 10
                            ) / 10}
                        </span>
                        {this.props.lastMeasurement.unit}
                        <br />
                        {this.props.t('make-decision/start')}
                    </h3>
                </Col>
                <Col xs="3" className="text-center">
                    <img
                        src={measurementCurrent}
                        className="makeDecision-icon"
                        alt=""
                    />
                    <h3>
                        <span className="big-number">
                            {Math.round(this.props.lastMeasurement.value * 10) /
                                10}
                        </span>
                        {this.props.lastMeasurement.unit}
                        <br />
                        {this.props.t('make-decision/now')}
                    </h3>
                </Col>
                <Col xs="3" className="text-center">
                    <img
                        src={measurementGoal}
                        className="makeDecision-icon"
                        alt=""
                    />
                    <h3>
                        <span className="big-number">
                            {Math.round(this.props.goalWeight.value * 10) / 10}
                        </span>
                        {this.props.goalWeight.unit}
                        <br />
                        {this.props.t('make-decision/goal')}
                    </h3>
                </Col>
                <Col xs="3" className="text-center">
                    <img
                        src={measurementLeft}
                        className="makeDecision-icon"
                        alt=""
                    />
                    <h3>
                        <span className="big-number">
                            {Math.round(
                                (this.props.lastMeasurement.value -
                                    this.props.goalWeight.value) *
                                    10
                            ) / 10}
                        </span>
                        {this.props.goalWeight.unit}
                        <br />
                        {this.props.t('make-decision/left')}
                    </h3>
                </Col>
            </Row>
        </div>
    );

    render() {
        const {
            t,
            dietMode,
            goalWeight,
            firstMeasurement,
            lastMeasurement,
        } = this.props;

        if (dietMode === 'stabilization') {
            return this.renderStabilisationMeasurements();
        }

        return (
            <div className="summary">
                <Row>
                    <Col xs="4" className="text-center">
                        <img
                            src={measurementFirst}
                            className="makeDecision-icon"
                            alt=""
                        />
                        <h3>
                            <span className="big-number">
                                {Math.round(firstMeasurement.value * 10) / 10}
                            </span>
                            {lastMeasurement.unit}
                            <br />
                            {t('make-decision/start')}
                        </h3>
                    </Col>
                    <Col xs="4" className="text-center">
                        <img
                            src={measurementCurrent}
                            className="makeDecision-icon"
                            alt=""
                        />
                        <h3>
                            <span className="big-number">
                                {Math.round(lastMeasurement.value * 10) / 10}
                            </span>
                            {lastMeasurement.unit}
                            <br />
                            {t('make-decision/now')}
                        </h3>
                    </Col>
                    <Col xs="4" className="text-center">
                        <img
                            src={measurementGoal}
                            className="makeDecision-icon"
                            alt=""
                        />
                        <h3>
                            <span className="big-number">
                                {Math.round(goalWeight.value * 10) / 10}
                            </span>
                            {goalWeight.unit}
                            <br />
                            {this.props.t('make-decision/goal')}
                        </h3>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withLocale(MakeDecisionCurrentMeasurements);
