import React from 'react';

import MeasurementIndexPage from 'Measurement/Index/MeasurementIndexPage';
import MeasurementEditPage from 'Measurement/Edit/MeasurementEditPage';
import MeasurementFetchByDateConnectorApollo from 'Measurement/MeasurementByDate/Connector/Apollo/MeasurementFetchByDateConnectorApollo';
import MeasurementSaveByDateConnectorApollo from 'Measurement/MeasurementByDate/Connector/Apollo/MeasurementSaveByDateConnectorApollo';
import MeasurementDeleteByDateConnectorApollo from 'Measurement/MeasurementByDate/Connector/Apollo/MeasurementDeleteByDateConnectorApollo';
import { formatIsoDate } from 'utils/dateFormatter';
import MeasurementEditRoute from 'Measurement/Edit/MeasurementEditRoute';

const PATH_MEASUREMENT_INDEX = '/measurements';
const PATH_MEASUREMENT_ADD = '/measurements/add';
const PATH_MEASUREMENT_EDIT =
    '/measurements/edit/:date([0-9]{4}-[0-9]{2}-[0-9]{2})';

const routes = [
    {
        path: PATH_MEASUREMENT_INDEX,
        component: MeasurementIndexPage,
        private: true,
        exact: true,
        title: 'route-title/measurements',
        mobileAppTabIdentifier: 'measure',
    },
    {
        path: PATH_MEASUREMENT_ADD,
        render: () => (
            <MeasurementFetchByDateConnectorApollo
                date={formatIsoDate(new Date())}
            >
                {fetchData => (
                    <MeasurementSaveByDateConnectorApollo {...fetchData}>
                        {saveData => (
                            <MeasurementDeleteByDateConnectorApollo
                                {...saveData}
                            >
                                {deleteData => (
                                    <MeasurementEditPage
                                        {...fetchData}
                                        {...saveData}
                                        {...deleteData}
                                    />
                                )}
                            </MeasurementDeleteByDateConnectorApollo>
                        )}
                    </MeasurementSaveByDateConnectorApollo>
                )}
            </MeasurementFetchByDateConnectorApollo>
        ),
        private: true,
        exact: false,
        title: 'route-title/measurements-add',
        mobileAppTabIdentifier: 'measure',
    },
    {
        path: PATH_MEASUREMENT_EDIT,
        render: MeasurementEditRoute,
        private: true,
        exact: false,
        title: 'route-title/measurements-edit',
        mobileAppTabIdentifier: 'measure',
    },
    {
        path: '/measure',
        redirectTo: PATH_MEASUREMENT_INDEX,
        exact: true,
    },
    {
        path: '/measure/:whatever(.*)',
        redirectTo: PATH_MEASUREMENT_ADD,
        exact: true,
    },
];

export {
    PATH_MEASUREMENT_INDEX,
    PATH_MEASUREMENT_ADD,
    PATH_MEASUREMENT_EDIT,
    routes,
};
