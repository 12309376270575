import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { PATH_DAY_PLAN_INDEX } from 'DayPlanNew/DayPlanPaths';
import { formatIsoDate } from 'utils/dateFormatter';
import Layout from 'Layout/Layout';
import introIcon1 from 'assets/icons/diet_intro/intro_icon_1.svg';
import introIcon2 from 'assets/icons/diet_intro/intro_icon_2.svg';
import introIcon3 from 'assets/icons/diet_intro/intro_icon_3.svg';
import introIcon4 from 'assets/icons/diet_intro/intro_icon_4.svg';
import introIcon5 from 'assets/icons/diet_intro/intro_icon_5.svg';
import introIcon6 from 'assets/icons/diet_intro/intro_icon_6.svg';
import introIcon7 from 'assets/icons/diet_intro/intro_icon_7.svg';
import PropTypes from 'prop-types';

class DayPlanIntro extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                date: PropTypes.string,
            }),
        }).isRequired,
    };

    render() {
        let dateString;

        if (this.props.location.state) {
            dateString = formatIsoDate(this.props.location.state.date);
        } else {
            dateString = formatIsoDate(new Date());
        }

        return (
            <Layout page="cms-article">
                <div className="bg-white">
                    <Container>
                        <header>
                            <h1 className="mb-4">
                                {this.props.t('diet-intro/title')}
                            </h1>
                            <Row>
                                <Col md={{ size: 8, offset: 2 }}>
                                    <h2 className="text-center">
                                        {this.props.t('diet-intro/subtitle1')}
                                    </h2>
                                    <h2 className="text-center">
                                        {this.props.t('diet-intro/subtitle2')}
                                    </h2>
                                </Col>
                            </Row>
                        </header>
                        <section className="pt-0">
                            <Row>
                                <Col
                                    md={{ size: 8, offset: 2 }}
                                    className="d-flex mb-4"
                                >
                                    <div>
                                        <img
                                            src={introIcon1}
                                            alt=""
                                            className="mr-4"
                                        />
                                    </div>
                                    <div>
                                        <h2>
                                            {this.props.t(
                                                'diet-intro/p1/title'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'diet-intro/p1/content'
                                            )}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ size: 8, offset: 2 }}
                                    className="d-flex mb-4"
                                >
                                    <div>
                                        <img
                                            src={introIcon2}
                                            alt=""
                                            className="mr-4"
                                        />
                                    </div>
                                    <div>
                                        <h2>
                                            {this.props.t(
                                                'diet-intro/p2/title'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'diet-intro/p2/content'
                                            )}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ size: 8, offset: 2 }}
                                    className="d-flex mb-4"
                                >
                                    <div>
                                        <img
                                            src={introIcon3}
                                            alt=""
                                            className="mr-4"
                                        />
                                    </div>
                                    <div>
                                        <h2>
                                            {this.props.t(
                                                'diet-intro/p3/title'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'diet-intro/p3/content'
                                            )}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ size: 8, offset: 2 }}
                                    className="d-flex mb-4"
                                >
                                    <div>
                                        <img
                                            src={introIcon4}
                                            alt=""
                                            className="mr-4"
                                        />
                                    </div>
                                    <div>
                                        <h2>
                                            {this.props.t(
                                                'diet-intro/p4/title'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'diet-intro/p4/content'
                                            )}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ size: 8, offset: 2 }}
                                    className="d-flex mb-4"
                                >
                                    <div>
                                        <img
                                            src={introIcon5}
                                            alt=""
                                            className="mr-4"
                                        />
                                    </div>
                                    <div>
                                        <h2>
                                            {this.props.t(
                                                'diet-intro/p5/title'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'diet-intro/p5/content'
                                            )}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ size: 8, offset: 2 }}
                                    className="d-flex mb-4"
                                >
                                    <div>
                                        <img
                                            src={introIcon6}
                                            alt=""
                                            className="mr-4"
                                        />
                                    </div>
                                    <div>
                                        <h2>
                                            {this.props.t(
                                                'diet-intro/p6/title'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'diet-intro/p6/content'
                                            )}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ size: 8, offset: 2 }}
                                    className="d-flex mb-4"
                                >
                                    <div>
                                        <img
                                            src={introIcon7}
                                            alt=""
                                            className="mr-4"
                                        />
                                    </div>
                                    <div>
                                        <h2>
                                            {this.props.t(
                                                'diet-intro/p7/title'
                                            )}
                                        </h2>
                                        <p>
                                            {this.props.t(
                                                'diet-intro/p7/content'
                                            )}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ size: 8, offset: 2 }}
                                    className="text-center"
                                >
                                    <h2>
                                        {this.props.t('diet-intro/p8/title')}
                                    </h2>
                                    <p>
                                        {this.props.t('diet-intro/p8/content')}
                                        {this.props.t('diet-intro/p8/content2')}
                                        {this.props.t('diet-intro/p8/content3')}
                                        {this.props.t('diet-intro/p8/content4')}
                                    </p>
                                    <Button
                                        color="primary"
                                        className="mt-4"
                                        tag={Link}
                                        to={{
                                            pathname: `${PATH_DAY_PLAN_INDEX}/${dateString}`,
                                            state: { forceRefreshData: true },
                                        }}
                                    >
                                        {this.props.t('diet-intro/start-diet')}
                                    </Button>
                                </Col>
                            </Row>
                        </section>
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default withLocale(withRouter(DayPlanIntro));
