import React from 'react';
import Mutation from '@dietlabs/components/src/Apollo/Mutation';
import PropTypes from 'prop-types';
import UserAgreementToNotifyAboutDietCourseMutation from './UserAgreementToNotifyAboutDietCourseMutation';
import Settings from '../../../Connector/Apollo/Settings';

class UserAgreementToNotifyAboutDietCourseConnector extends React.Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
    };

    changeAgreement = changeAgreement => async agreementValue => {
        const response = await changeAgreement({
            variables: {
                agreement: agreementValue,
            },
            update: proxy => {
                const data = proxy.readQuery({ query: Settings });

                proxy.writeQuery({
                    query: Settings,
                    data: {
                        me: {
                            ...data.me,
                            agreements: {
                                ...data.me.agreements,
                                dietCourse: agreementValue,
                            },
                        },
                    },
                });
            },
            optimisticResponse: {
                me: {
                    agreementToNotifyAboutDietCourse: {
                        code: 200,
                        __typename: 'BasicMutationSuccess',
                    },
                    __typename: 'MeMutation',
                },
            },
        });

        return response;
    };

    render() {
        const { children } = this.props;
        let result = {};

        return (
            <Mutation
                mutation={UserAgreementToNotifyAboutDietCourseMutation}
                refetchQueries={() => [
                    {
                        query: Settings,
                    },
                ]}
            >
                {(changeAgreement, changeAgreementResult) => {
                    result = {
                        ...result,
                        changeAgreement: this.changeAgreement(changeAgreement),
                        loading: changeAgreementResult.loading,
                    };
                    return children(result);
                }}
            </Mutation>
        );
    }
}

export default UserAgreementToNotifyAboutDietCourseConnector;
