import InterventionsIndexPage from './InterventionsIndexPage';
import InterventionPage from './InterventionPage';
import InterventionsIntroPage from './InterventionsIntroPage';

import {
    PATH_INTERVENTIONS,
    PATH_INTERVENTIONS_INTRO,
    PATH_SINGLE_INTERVENTION,
} from './paths';

const routes = [
    {
        path: PATH_INTERVENTIONS,
        component: InterventionsIndexPage,
        private: true,
        exact: true,
        title: 'route-title/interventions',
        mobileAppTabIdentifier: 'motivation',
    },
    {
        path: PATH_INTERVENTIONS_INTRO,
        component: InterventionsIntroPage,
        private: true,
        exact: true,
        title: 'route-title/interventions',
        mobileAppTabIdentifier: 'motivation',
    },
    {
        path: PATH_SINGLE_INTERVENTION,
        component: InterventionPage,
        private: true,
        exact: true,
        title: 'route-title/interventions',
        mobileAppTabIdentifier: 'motivation',
    },
];

export { routes };
