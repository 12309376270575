import React from 'react';
import SendRequestToRemoveAccountConnectorApollo from './ConnectorOld/SendRequestToRemoveAccount/SendRequestToRemoveAccountConnectorApollo';
import ActiveSubscriptionConnectorApollo from './ConnectorOld/ActiveSubscription/ActiveSubscriptionConnectorApollo';
import RemoveAccountRequestContainer from './RemoveAccountRequestContainer';

const RemoveAccountRequestPage = () => {
    return (
        <ActiveSubscriptionConnectorApollo>
            {ActiveSubscriptionData => (
                <SendRequestToRemoveAccountConnectorApollo>
                    {SendRequestToRemoveAccountData => {
                        return (
                            <RemoveAccountRequestContainer
                                sendRequestToRemoveAccount={() =>
                                    SendRequestToRemoveAccountData.sendRequestToRemoveAccount()
                                }
                                activeSubscription={
                                    ActiveSubscriptionData.data.me
                                        .activeSubscriptions
                                }
                                userEmail={ActiveSubscriptionData.data.me.email}
                                loading={SendRequestToRemoveAccountData.loading}
                            />
                        );
                    }}
                </SendRequestToRemoveAccountConnectorApollo>
            )}
        </ActiveSubscriptionConnectorApollo>
    );
};

export default RemoveAccountRequestPage;
