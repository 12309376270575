import { PROMO_CODE_NAMESPACE as NAMESPACE } from './PromoCodeNamespace';

const setCookieItem = ({ value, expires }) => {
    if (typeof document !== `undefined`) {
        let domain = document.location.host.replace('app.', '');
        domain = domain.replace('dev.', '');
        domain = domain.replace('stage.', '');

        if (!domain.startsWith('.')) {
            domain = `.${domain}`;
        }

        document.cookie = `${NAMESPACE}=${value};${expires};path=/;domain=${domain}`;
    }
};

export { setCookieItem };
