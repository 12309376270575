import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Query from '@dietlabs/components/src/Apollo/Query';
import { formatIsoDate } from 'utils/dateFormatter';
import deepClone from 'utils/deepClone';

import query from './HydrationDayQuery';

class HydrationDayConnectorApollo extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        fetchPolicy: PropTypes.string,
    };

    static defaultProps = {
        fetchPolicy: undefined,
    };

    render() {
        const { children, fetchPolicy } = this.props;
        const variables = { date: formatIsoDate(this.props.date) };

        return (
            <Query
                query={query}
                variables={variables}
                fetchPolicy={fetchPolicy}
            >
                {({ loading, cacheHit, hasNetworkError, data }) => {
                    const args = {
                        loading,
                        cacheHit,
                        hasNetworkError,
                        queryResult: data,
                    };
                    if (!loading) {
                        const goal = data.me.hydrationDay.goal;
                        const portions = data.me.hydrationDay.portions.map(
                            p => {
                                const portion = deepClone(p);
                                // id<0 means that it’s a optimistic
                                // response dummy non-persisted data
                                // Don’t pass is so that it’s more obvious
                                // we can’t make any operations on it
                                if (portion.id < 0) {
                                    delete portion.id;
                                }
                                return portion;
                            }
                        );
                        args.data = {
                            goal,
                            portions,
                        };
                    }
                    return children(args);
                }}
            </Query>
        );
    }
}

export default HydrationDayConnectorApollo;
