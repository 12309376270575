import React from 'react';
import PropTypes from 'prop-types';
import { withLocale } from '@dietlabs/components';
import InputText from 'components/FormElements/InputTextControlled';
import { Label } from 'reactstrap';

class InterventionTaskFakeCheckboxComponent extends React.Component {
    static propTypes = {
        task: PropTypes.shape({
            id: PropTypes.string,
            answers: PropTypes.arrayOf(PropTypes.string),
            label: PropTypes.string,
        }).isRequired,
        setTaskAnswers: PropTypes.func.isRequired,
        editMode: PropTypes.bool.isRequired,
    };

    state = {
        messages: this.props.task.answers,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.task.answers !== this.props.task.answers) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ messages: this.props.task.answers });
        }
    }

    handleInputChange = event => {
        const name = parseInt(event.target.name.replace('input', ''), 10);
        const value = event.target.value;

        this.setState(prevState => {
            const newMessages = prevState.messages.map((item, i) => {
                if (i === name) {
                    return value;
                }
                return item;
            });

            const newMessages2 = newMessages.filter(
                (item, i) => (item !== '' && i === name) || i !== name
            );

            this.props.setTaskAnswers(
                parseInt(this.props.task.id, 10),
                newMessages2
            );

            return { messages: newMessages2 };
        });
    };

    handleAddInput = () => {
        this.setState(prevState => ({
            messages: [...prevState.messages, ''],
        }));
    };

    renderForm = () => {
        if (this.state.messages.length > 0) {
            return this.state.messages.map((message, idx) => {
                if (!this.props.editMode) {
                    return message === '1' ? (
                        <InputText
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                            label=""
                            name={`input${idx}`}
                            value="✓"
                            handleChange={this.handleInputChange}
                            disabled
                        />
                    ) : (
                        <InputText
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                            label=""
                            name={`input${idx}`}
                            value="✕"
                            handleChange={this.handleInputChange}
                            disabled
                        />
                    );
                }
                return (
                    <InputText
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                        label=""
                        name={`input${idx}`}
                        value={message}
                        handleChange={this.handleInputChange}
                    />
                );
            });
        }

        return (
            <InputText
                label=""
                name="input0"
                value=""
                handleChange={this.handleInputChange}
            />
        );
    };

    render() {
        return (
            <div className="mt-3">
                <Label>{this.props.task.label}</Label>
                {this.renderForm()}
            </div>
        );
    }
}

export default withLocale(InterventionTaskFakeCheckboxComponent);
