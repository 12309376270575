import React, { PureComponent } from 'react';
import { Container } from 'reactstrap';

import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';

const DAY_COUNT = 20;

class TimelinePlaceholder extends PureComponent {
    repeat = (times, func) => {
        const result = [];
        for (let i = 0; i < times; i += 1) {
            result.push(func(i));
        }
        return result;
    };

    render() {
        return (
            <section className="timeline">
                <Container>
                    <nav>
                        <div className="timeline-arrow left">
                            <span className="SVGInline">
                                <LeftArrow />
                            </span>
                        </div>
                        <div className="timeline-arrow right">
                            <span className="SVGInline">
                                <RightArrow />
                            </span>
                        </div>
                        <ul>
                            {/* eslint-disable jsx-a11y/anchor-is-valid */
                            this.repeat(DAY_COUNT, key => (
                                <li className="nav-item ml-0 mr-1" key={key}>
                                    <a
                                        className="nav-link placeholder"
                                        tabIndex="-1"
                                        role="presentation"
                                    >
                                        Day 01.01
                                    </a>
                                </li>
                            ))
                            /* eslint-enable jsx-a11y/anchor-is-valid */
                            }
                        </ul>
                    </nav>
                </Container>
            </section>
        );
    }
}

export { TimelinePlaceholder };
export default TimelinePlaceholder;
