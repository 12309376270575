import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Error404 from 'view/Error/Error404';
import { paymentLoadByToken } from 'view/Payment/Load/ByToken/PaymentLoadByTokenAction';
import paymentClone from 'view/Payment/Clone/PaymentCloneAction';
import ShoppingCartConfirmComponent from 'components/ShoppingCart/Confirm/ShoppingCartConfirmComponent';
import ShoppingCartConfirmPlaceholder from 'components/ShoppingCart/Confirm/ShoppingCartConfirmPlaceholder';
import { SHOPPING_CART_INIT_BY_PAYMENT } from 'view/ShoppingCart/InitByPayment/ShoppingCartInitByPaymentActionType';
import withOnCriticalError from 'view/ErrorBoundary/withOnCriticalError';

export class ShoppingCartConfirmContainer extends React.Component {
    state = {
        payment: null,
    };

    componentDidMount() {
        this.loadPayment();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.token !== this.props.token) {
            this.setState({ payment: null }, () => {
                this.loadPayment();
            });
        }
    }

    async loadPayment() {
        const token = this.props.token;

        try {
            let payment = await this.props.loadPayment(token);
            if (!payment.exists) {
                payment = false;
            }
            this.setState({ payment });
        } catch (error) {
            this.props.onCriticalError(error);
        }
    }

    render() {
        if (this.state.payment === null) {
            return <ShoppingCartConfirmPlaceholder />;
        }

        if (this.state.payment === false) {
            return <Error404 />;
        }

        return (
            <ShoppingCartConfirmComponent
                payment={this.state.payment}
                clonePayment={this.props.clonePayment}
            />
        );
    }
}

ShoppingCartConfirmContainer.propTypes = {
    loadPayment: PropTypes.func.isRequired,
    clonePayment: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    onCriticalError: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    token: ownProps.match.params.token,
});

const mapDispatchToProps = dispatch => ({
    loadPayment: token =>
        paymentLoadByToken(token).then(payment => {
            dispatch({
                type: SHOPPING_CART_INIT_BY_PAYMENT,
                payment,
            });
            return payment;
        }),
    clonePayment: token => paymentClone(token),
});

export default withOnCriticalError(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ShoppingCartConfirmContainer)
    )
);
