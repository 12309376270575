import ShoppingCartUserData from './ShoppingCartUserData';

export default class ShoppingCartUserDataFactory {
    static createFromState(state) {
        return new ShoppingCartUserData(
            state.shoppingCart.userData.email,
            state.shoppingCart.userData.acceptConditions
        );
    }
}
