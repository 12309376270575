import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import reportPayment from 'view/Payment/Report/PaymentReport';
import ShoppingCartThankYouContainer from './ShoppingCartThankYouContainerNotConnected';

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
    authLoginByToken: token => global.localStorage.setItem('token', token),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(props => (
        <ShoppingCartThankYouContainer
            token={props.match.params.token}
            reportPayment={reportPayment}
            authLoginByToken={props.authLoginByToken}
        />
    ))
);
